import "./BookListGenre.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getApiCategories } from "../../services/apiCategories";
import { getNewBooks } from "../../redux/actions/book";
import BookList from "../../components/BookCatalog/BookList/BookList";
import BookNews from "../../components/HomeComponents/BookNews/BookNews";
import NameSection from "../../components/GenrePageComponents/NameSection/NameSection";
import Genres from "../../components/Genres/Genres";
import AuthorListGenre from "../../components/GenrePageComponents/AuthorListGenre/AuthorListGenre";
import MoreGenres from "../../components/HomeComponents/MoreGenres/MoreGenres";
import Button from "../../components/DesingSystem/Button/Button";

function BookListGenre() {
  const urlParams = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authors = useSelector((store) => store.book.authors);
  const new_books = useSelector((store) => store.book.new_books);
  const [genres, setGenres] = useState([]);
  const [category, setCategory] = useState(null);
  useEffect(() => {
    dispatch(getNewBooks({ category : urlParams.slug }));
    setCategory(urlParams.slug)
  }, [dispatch]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  return (
    <section className="book-list-genre">
      { new_books ?
        <React.Fragment>
          <NameSection name={ new_books[0].by_category } category={category} />
          <BookNews title="Más recientes" books={new_books} />
        </React.Fragment>
      :
        <NameSection name={ category ? category.charAt(0).toUpperCase() + category.slice(1) : '' } category={category} />
      }
      <div className="book-list-genre__list">
        <div className="book-list-genre__container-title">
          <h2 className="book-list-genre__title">Todos los libros del género</h2>
        </div>
        <BookList category={urlParams.slug} />
      </div>
      <div className="book-list-genre__authors">
        { authors &&
          <AuthorListGenre authors={authors}/>
        }
      </div>
      <MoreGenres />
    </section>
  );
}

export default BookListGenre;
