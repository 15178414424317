
import "./ConfirmationEmail.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";

function ConfirmationEmailKo() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [msg, setMsg] = useState(null)

  useEffect(() => {
    const msgParam = searchParams.get("msg")
    setMsg(msgParam)
  }, []);

  return (
    <section className="confirmation">
      <img
        className="confirmation__img"
        src="/images/silla-2.png"
        alt="not-found"
      />
      <div className="confirmation__section-text">
        <Icon name="tooltip" size="large" color="principal" />
        <h1 className="confirmation__title">¡Hubo un problema!</h1>
        <p className="confirmation__text">
          No hemos podido confirmar tu email. Por favor ponte en contacto con nosotros en <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a> {' '}
          indicando tu nombre, apellidos e email y nos pondremos en contacto contigo para completar tu registro.
        </p>

        { msg ?
          <p className="confirmation__text">
            Motivo: { msg }
          </p>
        : null }
        <Button className={"btn-orange"} text={"Volver a la home"} onClick={() => { navigate('/') }}  />
      </div>
    </section>
  );
}

export default ConfirmationEmailKo;
