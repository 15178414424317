import "./ModalRecoverPassword.scss";
import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button";
import InputText from "../../DesingSystem/InputText/InputText";
import { types } from "../../../constants/constants";
import ls from "../../../services/localStorage";

function ModalRecoverPassword(props) {
  const [email, setEmail] = useState("");
  const [messageOk, setMessageOk] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const doRecovery = async() =>{
    if(email && email !== ''){
      const token = ls.get("token");

      let responseRecover = await fetch(`${types.API_URL}user/recover-password`, {
        method: "POST",
        headers: {
          token : token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email:email}),
      });

      setErrorMessage(null)
      setMessageOk("Hemos enviado un mensaje con una contraseña temporal. Revisa tu email.")
    }else{
      setErrorMessage("Introduce un email por favor")
    }
  }

  return (
    <section className="modal-recover-password">
      <div onClick={(e) => e && e.stopPropagation()}></div>
      <Modal
        {...props}
        size="md"
        dialogClassName="modal-recover-password__modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-recover-password">
            <h3 className="modal-recover-password__title">
              Recuperar contraseña
            </h3>
            { messageOk && messageOk !== '' ?
              <React.Fragment>
                <p className="modal-recover-password__text">
                  {messageOk}
                </p>

                <Button className="btn-border-orange mt-4" text="Aceptar" onClick={() => { setMessageOk(null); props.onHide() }} />
              </React.Fragment>
            :
              <React.Fragment>
                <p className="modal-recover-password__text">
                  ¡No te preocupes! Nos pasa a todos. Ingresa un correo electrónico
                  para que podamos enviarte el link de recuperación de contraseña.
                </p>
                <InputText
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Introduce un correo electrónico"
                  onChange={(event) => { setEmail(event.target.value) }}
                />
                <div className="modal-recover-password__container-button">
                  { errorMessage && errorMessage !== '' ?
                    <p className="error">{ errorMessage }</p>
                  : null }
                  <Button className="btn-border-orange mt-4" text="Enviar link" onClick={() => { doRecovery() }} />
                </div>
              </React.Fragment>
            }
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ModalRecoverPassword;
