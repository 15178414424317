import "./Select.scss";

import React from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon/Icon";

const Select = ({ onSelect, selectedValue, defaultValue, options, empresa, error, active, ...props }) => {


  const selectClasses = `select-container__select ${error ? "error" : ""} ${
    active ? "active" : ""
  }`;
  const selectInput = React.useRef();

  return (
    <div className="select-container">
      <select {...props} className={selectClasses} ref={selectInput}>
        { defaultValue ?
          <option className="select-container__select-option" value="">{defaultValue}</option>
        : null }
        { options ?
          <React.Fragment>
            { Object.keys(options).map((option, index) => (
              <React.Fragment key={`${option}-${index}`} >
                { selectedValue && selectedValue === option ?
                  <option className="select-container__select-option" value={option} selected>
                    { options[option] }
                  </option>
                :
                  <option className="select-container__select-option" value={option} >
                    { options[option] }
                  </option>
                }
              </React.Fragment>
            ))}
          </React.Fragment>
        : null }
      </select>

      <Icon name='chevronDown' size='small' color='secondary-3' className='select-container__select-caret' />
    </div>
  );
};

Select.propTypes = {
  onSelect: PropTypes.func.isRequired,
  autonomo: PropTypes.string,
  empresa: PropTypes.string,
};

export default Select;
