import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Genres from "../../../components/Genres/Genres";
import { getBook } from "../../../redux/actions/book";
import EllipsisIcon from "../../../styles/images/ellipsis.svg";
import ModalBookDetail from "../../BookCatalog/ModalBookDetail/ModalBookDetail";
import ModalTags from "../../BookCatalog/ModalGenres/ModalGenres";
import GenreItem from "../../GenreItem/GenreItem";
import Icon from "../../Icon/Icon";
import "./BooksAuthorItem.scss";

function BooksAuthorItem(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [loadedBook, setLoadedBook] = useState(false);
  const book = useSelector((store) => store.book.book);
  const [maxTags, setMaxTags] = useState(1);
  const [modalTagsShow, setModalTagsShow] = useState(false);

  useEffect(() => {
    setMaxTags(window.innerWidth < 768 ? 1 : 2);
  });

  const renderGenres = () => {
    return props.book.categories.map((genre, index) => {
      if (index <= 0) {
        return (
          <GenreItem size={props.genreSize} key={`${props.book.title}-${genre._id}`} genre={genre} version="v3" />
        );
      }
      return null;
    });
  };

  const showModalDetail = (e) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    dispatch(getBook(props.book._id));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  return (
    <React.Fragment>
      <section className="books-author-item" onClick={showModalDetail}>
        <div className="books-author-item__container">
          <div className="books-author-item__cover">
            <img
              className="books-author-item__cover-image"
              src={props.book.picture_medium}
              alt="image1"
            />
          </div>
          <div className="books-author-item__container-content">
            <h3 className="books-author-item__container-title">
              {props.book.title}
            </h3>
            <p className="books-author-item__container-author">Por {props.author.firstName} {props.author.lastName}</p>
            <div className="books-author-item__genres-container">
              {renderGenres()}
              {props.book.categories.length > maxTags ? (
                <button
                  className={`books-author-item__genres-container-button books-author-item__genres-container-button--v3`}
                  onClick={showModalTags}
                >
                  <img src={EllipsisIcon} alt="More genres" />
                </button>

              ) : null}
              <ModalTags
                title={props.book.title}
                genres={props.book.categories}
                show={modalTagsShow}
                onHide={hideModalTags}
              />
            </div>
          </div>
        </div>

        <div className="books-author-item__extra-info">
          <div className="books-author-item__download">
            <Icon name="reviewLike" size="small" color="secondary-3" />
            <span className="books-author-item__download-text">{props.book.likes ? props.book.likes : 0}</span>
            <Icon name="reviewNeutral" size="small" color="secondary-3" />
            <span className="books-author-item__download-text">{props.book.neutral ? props.book.neutral : 0}</span>
            <Icon name="reviewDislike" size="small" color="secondary-3" />
            <span className="books-author-item__download-text">{props.book.dislikes ? props.book.dislikes : 0}</span>
            |
            <Icon name="download" size="small" color="secondary-3" />
            <span className="books-author-item__download-text">{props.book.sales ? props.book.sales : 0}</span>
          </div>
          <p className="books-author-item__price">{props.book.price.toString().replace('.', ',')}€</p>
        </div>
      </section>
      {book ? (
        <ModalBookDetail
          book={book}
          show={modalDetailShow}
          hide={hideModalDetail}
          index={props.index}
        />
      ) : null}
    </React.Fragment>
  );
}

export default BooksAuthorItem;
