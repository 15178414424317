import "./Filter.scss";

import { useState, useEffect } from "react";
import Icon from "../Icon/Icon";
import ModalFilter from "./ModalFilter";

const Filter = ({ children, title, filters, onFilterChange }) => {
  const [filtersSelected, setFiltersSelected] = useState([])
  const [sortSelected, setSortSelected] = useState(['OLD'])
  const [sortActive, setSortActive] = useState(false)
  const [showModalFilter, setShowModalFilter] = useState(false)
  const sortOptions = ['NEW', 'OLD', 'PRICE_HIGH', 'PRICE_LOW'];
  const optionsText = {
    'NEW': 'De más antiguos a más recientes',
    'OLD': 'De más recientes a más antiguos',
    'PRICE_HIGH': 'De más económico a más costoso',
    'PRICE_LOW': 'De más costoso a más económico'
  }

  useEffect(() => {
    // hide sort if click outside .filter__sort
    const handleClickOutside = (event) => {
      if (event.target.closest('.filter__sort')) return;
      setSortActive(false)
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [sortActive])

  useEffect(() => {
    onFilterChange({filtersSelected, sortSelected})
  }, [filtersSelected, sortSelected])


  const handleFilterSelection = (filter) => {
    setFiltersSelected(filter);
    setShowModalFilter(false);
  }

  return (
    <div className="filter">
      <div className="filter__header">
        <div className="filter__title">{title}</div>
        <div className="filter__actions">
          <div
            className={`filter__sort ${sortActive ? 'filter__sort--active' : ''}`}
            onClick={() => setSortActive(!sortActive)}
          >
            <span>{optionsText[sortSelected]}</span>
            <Icon name="chevronDown" color="secondary-1" size="small"/>
            <div className={`filter__sort-options ${sortActive ? 'filter__sort-options--active' : ''}`}>
              {sortOptions.map((option, index) => (
                <div
                  key={index}
                  className="filter__sort-option"
                  onClick={() => setSortSelected(option)}
                >
                  {sortSelected == option ? <Icon name="tick" color="principal" size="small"/> : null}
                  {optionsText[option]}
                </div>
              ))}
            </div>
          </div>
          {filters && filters.length ? (
            <>
              <div
                className="filter__filter"
                onClick={() => setShowModalFilter(true)}
              >
                <Icon name="filter" color="secondary-1" />
                {filtersSelected.length ? (
                <span className="filter__filter-badge">{filtersSelected.length}</span>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
      {children}
      {filters && filters.length ? (
        <ModalFilter
          isOpen={showModalFilter}
          onClose={() => setShowModalFilter(false)}
          onFilter={(filters) => handleFilterSelection(filters)}
          filters={filters}
        />
      ) : null}
    </div>
  )
}

export default Filter;
