import "./BundleCourse.scss";

import { ReactComponent as Clock } from "../../styles/icons/clock.svg";

/**
 * @typedef {import("../../services/apiBundle").Course} Course
 */

/**
 * @param {Object} props
 * @param {Course} props.course
 * @param {string} props.courseNumber
 */
const BundleCourse = ({ courseNumber, course: { title, hours, content, price, picture_big, frequency } }) => {
  let frequencyText = '';

  switch (frequency) {
    case 'totales':
      frequencyText = 'en total';
      break;
    case 'semana':
      frequencyText = 'por semana';
      break;
  }

  return (
    <div className="bundle-course">
      <div className="bundle-course__content">
        <span className="bundle-course__content-title">{courseNumber}</span>
        <h1 className="bundle-course__content-heading">{title}</h1>
        <p
          className="bundle-course__content-description"
          dangerouslySetInnerHTML={{ __html: `${content.replace(/\&lt;/g, '<')}` }}>
        </p>
        <div className="bundle-course__content-pricing">
          <span>
            {price}€ / persona (sin descuento)
          </span>
          <span>
            <Clock />
            {hours} horas {frequencyText}
          </span>
        </div>
      </div>
      <div className="bundle-course__image">
        <img src={picture_big} />
      </div>
      <div className="bundle-course__title-mobile">
        <span>{title}</span>
        <span>
          <Clock />
          {hours} horas {frequencyText}
        </span>
      </div>
    </div>
  );
};

export default BundleCourse;
