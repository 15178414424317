import "./BookItemPublic.scss";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ModalTags from "../../BookCatalog/ModalGenres/ModalGenres"
import GenreItem from "../../GenreItem/GenreItem";
import Icon from "../../Icon/Icon";
import ModalBookDetail from "../../BookCatalog/ModalBookDetail/ModalBookDetail";
import { getBook } from "../../../redux/actions/book";

function BookItemPublic(props) {
  const dispatch = useDispatch();
  const [modalTagsShow, setModalTagsShow] = useState(false);
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [loadedBook, setLoadedBook] = useState(false);
  const book = useSelector((store) => store.book.book);

  const renderGenres = () => {
    return props.book.categories.map((genre, index) => {
      if (index <= 1) {
        return (
          <GenreItem key={`${props.book.title}-${genre._id}`} genre={genre} />
        );
      }
      return null;
    });
  };

  const showModalDetail = async (e) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    await dispatch(getBook(props.book._id));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  return (
    <li className="book-public" onClick={showModalDetail}>
      <div className="book-public__section-header">
        <div className="book-public__section-download">
          <Icon name="download" size="small" color="secondary-3" />
          <span className="book-public__section-download-text">{ props.book.sales ? props.book.sales : 0 }</span>
        </div>
      </div>
      <div className="book-public__section-image">
        <img
          className="book-public__section-image-img"
          src={props.book.picture_medium}
          alt="img"
        />
        <h2 className="book-public__section-image-title">{props.book.title}</h2>
        <h3 className="book-public__section-image-author">
          Por {props.book.user.firstName} {props.book.user.lastName}
        </h3>
      </div>
      <div className="book-public__section-genres">
        <div className="book-public__section-genres-container">
          {renderGenres()}
          {props.book.categories.length > 2 ? (
            <p className="book-public__section-genres-button" onClick={showModalTags}>
              . . .
            </p>
          ) : null}
          <ModalTags
            title={props.book.title}
            genres={props.book.categories}
            show={modalTagsShow}
            onHide={hideModalTags}
          />
        </div>
        <div className="book-public__section-genres-price"> {props.book.price} €</div>
      </div>
      <div className="book-public__arrow">
        <Icon name="arrowDiagonal" size="large" color="secondary-6" />
      </div>

      {book ? (
        <ModalBookDetail
          book={book}
          show={modalDetailShow}
          hide={hideModalDetail}
          index={props.index}
        />
      ) : null}
    </li>
  );
}

export default BookItemPublic;
