import "./Landing.scss";
import PropTypes from "prop-types";

import LandingCarousel from "../../components/LandingCarousel/LandingCarousel";
import LandingForm from "../../components/LandingForm/LandingForm";
import LandingHero from "../../components/LandingHero/LandingHero";
import NameSection from "../../components/NameSection/NameSection";

/**
 * @param {Object} props
 * @param {string} props.service
 * @param {Object} props.content
 * @param {string} props.content.description
 * @param {string} props.content.summary
 * @param {string} props.content.formTitle
 * @param {string} props.content.formDescription
 * @param {Array} props.services
 */
const Landing = ({ service, content, services }) => {
  return (
    <>
      <NameSection name={service} color="#f05a24" />
      <div className="landing-container">
        <LandingHero
          description={content.description}
          summary={content.summary}
        />
        <LandingForm
          service={service}
          title={content.formTitle}
          description={content.formDescription}
        />
        <LandingCarousel
          services={services}
        />
      </div>
    </>
  );
};
Landing.propTypes = {
  service: PropTypes.string,
  content: PropTypes.shape({
    description: PropTypes.string,
    summary: PropTypes.string,
    price: PropTypes.string,
    formTitle: PropTypes.string,
    formDescription: PropTypes.string
  }),
  services: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      link: PropTypes.string
    })
  )
};

export default Landing;
