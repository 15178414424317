import "./HowItWorks.scss";

import React, { useState } from "react";
import Slider from "react-slick";
import Button from "../../../DesingSystem/Button/Button";

const HowItWorks = () => {
  const [readers, setReaders] = useState(false);
  const [writers, setWriters] = useState(true);
  const slider = React.useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1.1,
    className: "how-it-works__container-carrousel-item",
    adaptiveHeight: false,
  };

  const renderCarrouselReaders = () => {
    return [
      <div >
        <div id="image-1" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_descubre.svg" alt="img" />
        </div>{" "}
        <div id="text-1">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">01</p>
          <p className="how-it-works__container-carrousel-title">
            Descubre
          </p>
          <p className="how-it-works__container-carrousel-text">
            Encuentra nuevos títulos en nuestro catálogo y descubre tu a próximo autor favorito. Personaliza tu búsqueda 
            utilizando nuestros filtros y recibe recomendaciones basadas en tus intereses.
          </p>
        </div>
      </div>,
      <div>
        <div id="image-2" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_descarga.svg" alt="img" />
        </div>
        <div id="text-2">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">02</p>
          <p className="how-it-works__container-carrousel-title">
            Descarga
          </p>
          <p className="how-it-works__container-carrousel-text">
            Obtén todas las obras que desees en formato EPUB, compatibles con cualquier dispositivo de lectura. Realiza pagos 
            de forma fácil y rápida, y ahorra espacio y dinero con las obras en formato digital.
          </p>
        </div>
      </div>,
      <div>
        {" "}
        <div id="image-3" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_disfruta.svg" alt="img" />
        </div>
        <div id="text-3">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">03</p>
          <p className="how-it-works__container-carrousel-title">Disfruta</p>
          <p className="how-it-works__container-carrousel-text">
            Nuestro objetivo es brindar contenidos de calidad. Queremos que nuestros usuarios exploren un catálogo de 
            buenas obras, donde su única preocupación sea descargarlas y sumergirse en ellas.
          </p>
        </div>
      </div>,
    ];
  };

  const renderCarrouselWriters = () => {
    return [
      <div>
        <div id="image-1" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_publica.svg" alt="img" />
        </div>
        <div id="text-1">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">01</p>
          <p className="how-it-works__container-carrousel-title">
            Publica tu obra
          </p>
          <p className="how-it-works__container-carrousel-text">
            Publica todas las obras que quieras de forma sencilla y rápida,
            cumpliendo todos los requisitos legales de una editorial
            tradicional. Ahorra tiempo y dinero en comparación con métodos
            convencionales.
          </p>
        </div>
      </div>,
      <div>
        <div id="image-2" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_ganacias.svg" alt="img" />
        </div>
        <div id="text-2">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">02</p>
          <p className="how-it-works__container-carrousel-title">
            Obtén ganancias
          </p>
          <p className="how-it-works__container-carrousel-text">
            Comienza a ganar como escritor y obtén beneficios de todas las
            ventas de tus obras. Además, podrás acceder a un resumen detallado
            de tus ganancias en cualquier momento.
          </p>
        </div>
      </div>,
      <div>
        {" "}
        <div id="image-3" className="how-it-works__container-carrousel-image">
          <img src="/images/ilustration_crece.svg" alt="img" />
        </div>
        <div id="text-3">
          <div className="how-it-works__border"></div>
          <p className="how-it-works__container-carrousel-number">03</p>
          <p className="how-it-works__container-carrousel-title">
            Crece como autor
          </p>
          <p className="how-it-works__container-carrousel-text">
            Haz que tus obras lleguen más lejos y amplía tu visibilidad tanto
            entre los lectores como ante posibles editoriales interesadas. En
            Bookólicos, nos esforzamos por ser el primer escalón de muchos en tu
            carrera profesional.
          </p>
        </div>
      </div>,
    ];
  };

  const handleReaders = () => {
    setReaders(true);
    setWriters(false);
  };

  const handleWriters = () => {
    setReaders(false);
    setWriters(true);
  };

  return (
    <section className="how-it-works">
      <div className="why-bookolicos__border"> </div>
      <div className="how-it-works__container-title">
        <h3 className="how-it-works__container-title-title">
          Cómo funciona
          <br /> Bookólicos
        </h3>
        <div className="how-it-works__container-title-container-buttons">
          <div className="how-it-works__container-title-buttons">
            <div className="how-it-works__container-title-buttons-btn">
              <Button
                className={`${writers ? "btn-blackbg" : "btn-simple"}`}
                text="Para autores"
                onClick={handleWriters}
              />
            </div>
            <div className="how-it-works__container-title-buttons-btn">
              <Button
                className={`${readers ? "btn-blackbg" : "btn-simple"}`}
                text="Para lectores"
                onClick={handleReaders}
              />
            </div>
          </div>
        </div>
      </div>

      {readers && (
        <div className="how-it-works__container">
          <div id="image-1" className="how-it-works__container-image">
            <img src="/images/ilustration_descubre.svg" alt="img" />
          </div>
          <div id="image-2" className="how-it-works__container-image">
            <img src="/images/ilustration_descarga.svg" alt="img" />
          </div>
          <div id="image-3" className="how-it-works__container-image">
            <img src="/images/ilustration_disfruta.svg" alt="img" />
          </div>
          <div id="text-1">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">01</p>
            <p className="how-it-works__container-title">Descubre</p>
            <p className="how-it-works__container-text">
              Encuentra nuevos títulos en nuestro catálogo y descubre tu a
              próximo autor favorito. Personaliza tu búsqueda utilizando
              nuestros filtros y recibe recomendaciones basadas en tus
              intereses.
            </p>
          </div>
          <div id="text-2">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">02</p>
            <p className="how-it-works__container-title">Descarga</p>
            <p className="how-it-works__container-text">
              Obtén todas las obras que desees en formato EPUB,
              compatibles con cualquier dispositivo de lectura. Realiza pagos de
              forma fácil y rápida, y ahorra espacio y dinero con las obras en
              formato digital.
            </p>
          </div>
          <div id="text-3">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">03</p>
            <p className="how-it-works__container-title">Disfruta</p>
            <p className="how-it-works__container-text">
              Nuestro objetivo es brindar contenidos de calidad. Queremos que
              nuestros usuarios exploren un catálogo de buenas obras, donde su
              única preocupación sea descargarlas y sumergirse en ellas.
            </p>
          </div>
        </div>
      )}

      {writers && (
        <div className="how-it-works__container">
          <div id="image-1" className="how-it-works__container-image">
            <img src="/images/ilustration_publica.svg" alt="img" />
          </div>
          <div id="image-2" className="how-it-works__container-image">
            <img src="/images/ilustration_ganacias.svg" alt="img" />
          </div>
          <div id="image-3" className="how-it-works__container-image">
            <img src="/images/ilustration_crece.svg" alt="img" />
          </div>
          <div id="text-1">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">01</p>
            <p className="how-it-works__container-title">Publica tu obra</p>
            <p className="how-it-works__container-text">
              Publica todas las obras que quieras de forma sencilla y rápida,
              cumpliendo todos los requisitos legales de una editorial
              tradicional. Ahorra tiempo y dinero en comparación con métodos
              convencionales.
            </p>
          </div>
          <div id="text-2">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">02</p>
            <p className="how-it-works__container-title">Obtén ganancias</p>
            <p className="how-it-works__container-text">
              Comienza a ganar como escritor y obtén beneficios de todas las
              ventas de tus obras. Además, podrás acceder a un resumen detallado
              de tus ganancias en cualquier momento.
            </p>
          </div>
          <div id="text-3">
            <div className="how-it-works__border"></div>
            <p className="how-it-works__container-number">03</p>
            <p className="how-it-works__container-title">Crece como autor</p>
            <p className="how-it-works__container-text">
              Haz que tus obras lleguen más lejos y amplía tu visibilidad tanto
              entre los lectores como ante posibles editoriales interesadas. En
              Bookólicos, nos esforzamos por ser el primer escalón de muchos en
              tu carrera profesional.
            </p>
          </div>
        </div>
      )}

      {readers && (
        <div className="how-it-works__container-carrousel">
          <Slider ref={slider} {...settings}>
            {renderCarrouselReaders()}
          </Slider>
        </div>
      )}

      {writers && (
        <div className="how-it-works__container-carrousel">
          <Slider ref={slider} {...settings}>
            {renderCarrouselWriters()}
          </Slider>
        </div>
      )}
    </section>
  );
};

export default HowItWorks;
