import "./blog-list.scss";
import { useEffect, useState } from "react";
import NameSection from "../../components/NameSection/NameSection";
import Container from "react-bootstrap/Container";
import BlogItem from "../../containers/blog/blog-item";
import Button from "../../components/DesingSystem/Button/Button";
import React from "react";
import { getApiPosts } from "../../services/apiBlog";
import utils from "../../services/utils"

function BlogList () {
  const [results, setResults] = useState([]);

  useEffect(() => {
    getPosts()
  }, []);

  const getPosts = async() => {
    const results = getApiPosts()
    .then(async (response) => {
      let resultsArray = [];

      await Promise.all(response.map(result => {
        resultsArray.push({
          _id: result.id, 
          slug: result.slug,
          image: result._embedded["wp:featuredmedia"][0].source_url, 
          author: result._embedded.author[0].name, 
          date: result.date, 
          title: result.title.rendered, 
          readTime: utils.readingTime(result.content.rendered)
        })
      }))
      setResults(resultsArray);
    })
    .catch((error) => {
      console.log(error.message);
      setResults([]);
    });
  }

  /*const results = [
    { _id: '0', image: 'https://picsum.photos/600/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' },
    { _id: '1', image: 'https://picsum.photos/601/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' },
    { _id: '2', image: 'https://picsum.photos/602/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' },
    { _id: '3', image: 'https://picsum.photos/603/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' },
    { _id: '4', image: 'https://picsum.photos/604/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' },
    { _id: '5', image: 'https://picsum.photos/605/1200', author: 'Antonio Banderas', date: new Date(), title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', readTime: '2' }
  ]*/

  const renderResults = (results) => {
    return results.map(item => {
      return (
        <BlogItem key={item._id} {...item} />
      );
    })
  }

  return (
    <section className="blog-list">
      <NameSection name="Blog" color="#f05a24"/>
      <Container>
        <p className="blog-list__title">Todas las noticias</p>
        <div className="blog-list__items">
          {renderResults(results)}
        </div>
        {/*<div className="blog-list__load">
          <Button className={'btn-white big'} text="Cargar más noticias" />
        </div>*/}
      </Container>
    </section>
  );
}

export default BlogList;
