import React from "react";
import "./Button.scss";
import Icon from "../../Icon/Icon";
import PropTypes from 'prop-types';

const Button = ({ text, iconLeft, iconRight, ...props }) => {
  return (
    <button {...props} className={`btn ${props.className ? props.className : ""}`} type={props.type}>
      { iconLeft ?
        <Icon name={iconLeft} className={"btn-icon left"}  />
      : null }

      {text}

      { iconRight ?
        <Icon name={iconRight} className={"btn-icon right"} />
      : null }
    </button>
  );
};

Button.propTypes = {
  className:  PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};

Button.defaultProps = {
  className: 'btn-white',
  text: 'Button',
  iconLeft: null,
  iconRight: null,
  onClick: undefined,
  type: 'button',
};

export default Button;
