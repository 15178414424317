import jwt_decode from "jwt-decode";

const decode = (token) => {
  try{
    const tokenDecoded = jwt_decode(token);
    return tokenDecoded;
  } catch(e) {
    console.log(e.message)
    return null
  }
};

const validate = (token) => {
  try{
    const tokenDecoded = jwt_decode(token)
    return (Date.now() < tokenDecoded.exp * 1000)
  } catch(e) {
    console.log('Error validate token: ' + e.message + ' for token: ' + token)
    return false
  }
}

const getUser = (token) => {
  try{
    const tokenDecoded = jwt_decode(token)
    return tokenDecoded.user
  } catch(e) {
    console.log('Error validate token: ' + e.message + ' for token: ' + token)
    return null
  }
}

const objectToExport = {
  decode: decode,
  validate: validate,
  getUser: getUser
};

export default objectToExport;
