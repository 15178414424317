import "./Result.scss";
import GenreItem from "../GenreItem/GenreItem";
import EllipsisIcon from "../../styles/images/ellipsis.svg";
import {useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useIsPublic } from "../../hooks/use-is-public";
import { useNavigate } from "react-router-dom";
import ModalTags from "../BookCatalog/ModalGenres/ModalGenres";
import ModalBookDetail from "../BookCatalog/ModalBookDetail/ModalBookDetail";
import { getBook } from "../../redux/actions/book";
import * as dayjs from "dayjs";
import "dayjs/locale/es";
function Results (props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isPublic = useIsPublic();
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [modalTagsShow, setModalTagsShow] = useState(false);
  const book = useSelector((store) => store.book.book);
  const [loadedBook, setLoadedBook] = useState(false);

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  const showModalDetail = async (e, item) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    await dispatch(getBook(item._id));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    console.log("hide")
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const renderObra = (item) => (
    <div className="result__data">
      <p className="result__title pointer" onClick={(event)=>{ showModalDetail(event, item) }}>
        {item.title}
      </p>
      <p className="result__subtitle mb-5">Por {item.author}</p>
      <div className="result__genres-container">
        {renderGenres(item.genres, item.title)}
        <p className="result__price">{item.price}€</p>
      </div>
    </div>
  )
  const renderAutor = (item) => (
    <div className="result__data">
      <p className="result__title pointer" onClick={()=>{ isPublic ? navigate(`/perfil-autor/${ item.nickName }`) : navigate(`/perfil-del-autor/${ item.nickName }`) }}>
        {item.name}
      </p>
      <p className="result__subtitle">{item.publications < 2 ? `${item.publications} obra publicada` : `${item.publications || 0} obras publicadas` }</p>
      { item.genres.length > 0 ? (
      <>
        <span className="result__genres-text">Géneros que trata</span>
        <div className="result__genres-container">
          {renderGenres(item.genres, item.name)}
        </div>
      </>
      ) : null }
    </div>
  )
  const renderGenero = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1">{item.name}</p>
      <p className="result__info">{item.count} novela{item.count > 1 ? `s` : ''} con estas características</p>
    </div>
  )
  const renderNoticia = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1" onClick={()=>{ navigate(`/blog/${ item.slug }`)}}>
        {item.title}
      </p>
      <div className="result__info">
        <span>{item.author} <span className="result__info-extra">| {dayjs(item.date).format('DD MMM YYYY')}</span></span>
        <p className="result__info-readTime">{item.readTime} min de lectura</p>
      </div>
    </div>
  )
  const renderCategoria = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1">{item.name}</p>
      <p className="result__info">{item.count} novela{item.count > 1 ? `s` : ''} con estas características</p>
    </div>
  )

  const renderGenres = (genres, title) => {
    return genres.map((genre, index) => {
      if (index < 1) {
        return (
          <>
            <GenreItem
              key={`${genre}-${genre._id}-${index}`}
              genre={genre}
              version="v2"
            />
            {genres.length > 1 ? (
              <button
                className="result__genres-button"
                onClick={showModalTags}
              >
                <img src={EllipsisIcon} alt="More genres"/>
              </button>
            ) : null}
            <ModalTags
              title={title}
              genres={genres}
              show={modalTagsShow}
              onHide={hideModalTags}
            />
        </>
        );
      }
      return null;
    });
  };

  const renderResults = () => (
    <>
      <div className="searcher__results">
        { props.results.map((item, index) => {
          let result;
          switch (item.category) {
            case 'obra':
              result = renderObra(item);
              break;
            case 'autor':
              result = renderAutor(item);
              break;
            case 'genero':
              result = renderGenero(item);
              break;
            case 'noticia':
              result = renderNoticia(item);
              break;
            case 'categoria':
            default:
              result = renderCategoria(item);
              break;
          }
          return (
            <ResultItem
              key={`${index}-result-${item.category}`}
              category={item.category}
              image={item.image}
              imageFull={!!item.imageFull}
              nickName={item.nickName}
              slug={item.slug}
            >
              {result}
            </ResultItem>
          );
        }) }

      {loadedBook ? (
        <ModalBookDetail
          book={book}
          show={modalDetailShow}
          hide={hideModalDetail}
        />
      ) : null}
      </div>
    </>
  )
  return renderResults()
}

function ResultItem (props) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();
  const categoryParser = {
    obra: 'Obra',
    autor: 'Autor',
    genero: 'Género',
    noticia: 'Noticia',
    categoria: 'Categoría'
  }
  return (
    <>
      <div className="result">
        <div className="result__content">
          <div className="result__tag-container">
            <span className="result__tag">{categoryParser[props.category]}</span>
          </div>
          {props.children}
        </div>
        { props.category === 'autor' && (
          <div className={`result__image ${props.imageFull ? `result__image--full` : ''} pointer`} onClick={()=>{ isPublic ? navigate(`/perfil-autor/${ props.nickName }`) : navigate(`/perfil-del-autor/${ props.nickName }`) }}>
            <img src={props.image} alt=""/>
          </div>
        )}

        { props.category === 'obra' && (
          <div className={`result__image ${props.imageFull ? `result__image--full` : ''} pointer`} onClick={()=>{ isPublic ? navigate(`/descubre/${ props.slug }`) : navigate(`/book-list/${ props.slug }`) }}>
            <img src={props.image} alt=""/>
          </div>
        )}
      </div>
    </>
  )
}

export default Results;
