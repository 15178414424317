import "./InputSearch.scss";
import React, { useState, useEffect } from "react";
import Icon from "./../../Icon/Icon";

const serializeForm = function (form) {
  let obj = {};
  const formData = new FormData(form);
  for (let key of formData.keys()) {
    obj[key] = formData.get(key);
  }
  return obj;
};
const InputSearch = (props) => {
  const [value, setValue] = useState(props.value || '');

  const doSearch = () => {
    if(props.changeHandler){
      props.changeHandler(value)
    }
  }

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && event.currentTarget.value !== '') {
      doSearch()
    }
  }

  return (
    <div className="input-search__container">
      <div className={`input-search__wrapper ${props.transparent ? 'input-search__wrapper--transparent' : null} ${props.white ? 'input-search__wrapper--white' : null}`}>
        <Icon className="search" name="search" size="medium" color="secondary-1" />
        <input
          name="text"
          className={`input-search__input`}
          type="text"
          placeholder={props.placeholder}
          disabled={props.disabled}
          value={value}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
        {!props.hideSubmitButton ?
          <button className="btn btn-orange" onClick={doSearch()}>
            <span>Buscar</span>
            <Icon name="search" size="xlarge" color="secondary-7" />
          </button>
          : null
        }
      </div>
    </div>
  );
};
export default InputSearch;
