import "./Account.scss";
import React, { useState, useEffect, location } from "react";
import { useSelector, useDispatch } from "react-redux";
import { types } from "../../../constants/constants";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import { getUserById, updateAccount, updateAuthor } from "../../../redux/actions/user";
import InputText from "../../DesingSystem/InputText/InputText";
import ModalCancelAccount from "../ModalCancelAccount/ModalCancelAccount";
import ModalRecoverPassword from "../ModalRecoverPassword/ModalRecoverPassword";
import Button from "../../DesingSystem/Button/Button";

// MOCK
//import authorMock from "../../../mocks/author.json";

function Account() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const errorMessage = useSelector((store) => store.user.errorMessage);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);
  const [isNewPasswordEntered, setIsNewPasswordEntered] = useState(false);
  const [modalCancelAccount, setModalCancelAccount] = useState(false);
  const [modalRecoverPassword, setModalRecoverPassword] = useState(false);
  const [saveDataIcon, setSaveDataIcon] = useState("");
  const [saveDataButtonDisabled, setSaveDataButtonDisabled] = useState(false);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    dispatch(getUserById(tokenDecoded.user._id));
  }, []);

  useEffect(() => {
    if (user) {
      setEmail(user.email);
    }
  }, [user]);

    const hideModalRecoverPassword = (e) => {
    e && e.stopPropagation();
    setModalRecoverPassword(false);
  };

  const showModalRecoverPassword = (e) => {
    e && e.stopPropagation();
    /*document.body.style.overflow = 'hidden';*/
    setModalRecoverPassword(true);
  };

  const hideModalCancelAccount = (e) => {
    e && e.stopPropagation();
    setModalCancelAccount(false);
  };

  const showModalCancelAccount = (e) => {
    e && e.stopPropagation();
    /*document.body.style.overflow = 'hidden';*/
    setModalCancelAccount(true);
  };

  const handleInputEmailFocus = () => {
    setIsEmailEntered(true);
  };
  const handleInputEmailBlur = () => {
    setIsEmailEntered(false);
  };

  const handleInputPasswordFocus = () => {
    setIsPasswordEntered(true);
  };
  const handleInputPasswordBlur = () => {
    setIsPasswordEntered(false);
  };

  const handleInputNewPasswordFocus = () => {
    setIsNewPasswordEntered(true);
  };
  const handleInputNewPasswordBlur = () => {
    setIsNewPasswordEntered(false);
  };

  const saveData = async () => {
    setSaveDataButtonDisabled(true);
    setSaveDataIcon("loaderIcon");

    let attributes = {
      user: {},
    };
    attributes.user._id = user._id;
    attributes.user.email = email;
    attributes.user.password = password;
    attributes.user.new_password = newPassword;

    dispatch(updateAccount(attributes)).then(() => {
      setSaveDataIcon("");
      setSaveDataButtonDisabled(false);
      window.location.reload(true)
    });
  }

  return user ? (
    <div>
      <section className="account">
        <div className="account__containers">
          <h2 className="account__containers-title-section">Email</h2>
          <p className="account__containers-title">Email actual</p>
          <div className="account__containers-input">
            <InputText
              error={isEmailEntered}
              onFocus={handleInputEmailFocus}
              onBlur={handleInputEmailBlur}
              type="text"
              id="email"
              name="email"
              value={email}
              placeholder="Escribe tu email"
              onChange={(event) => {
                setEmail(event.target.value);
              }}
            />
          </div>
        </div>
        <div className="account__containers">
          <h2 className="account__containers-title-section">Contraseña</h2>
          <div className="account__containers-password">
            <div className="account__containers-password-space">
              <div>
                <p className="account__containers-title">Contraseña Actual</p>
              </div>
              <div>
                <InputText
                  error={isPasswordEntered}
                  onFocus={handleInputPasswordFocus}
                  onBlur={handleInputPasswordBlur}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Introduce tu contraseña actual"
                  onChange={(event) => {
                    setPassword(event.target.value);
                  }}
                />
              </div>
            </div>
            <div className="account__containers-password-space">
              <div>
                <p className="account__containers-title">Nueva contraseña</p>
              </div>
              <div>
                <InputText
                  error={isNewPasswordEntered}
                  onFocus={handleInputNewPasswordFocus}
                  onBlur={handleInputNewPasswordBlur}
                  type="password"
                  id="new_password"
                  name="new_password"
                  placeholder="Introduce tu nueva contraseña"
                  onChange={(event) => {
                    setNewPassword(event.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <p className="account__containers-forget-password" onClick={showModalRecoverPassword}>
            ¿Olvidaste tu contraseña?
          </p>
        </div>
      </section>

      {errorMessage && errorMessage !== "" ? (
        <p className="error text-end">{errorMessage.toString()}</p>
      ) : null}

      <div className="account__containers-section-btn">
        <div className="account__containers-button-space">
          <Button
            className="btn-gray"
            text="Eliminar cuenta"
            iconRight="deleteIcon"
            onClick={showModalCancelAccount}
          />
        </div>
        <section className="buttons">
          <div className="buttons__containers">
            <Button
              className="btn-orangebg"
              iconRight={saveDataIcon}
              text={"Guardar cambios"}
              disabled={saveDataButtonDisabled}
              onClick={() => {
                saveData();
              }}
            />
          </div>
        </section>
      </div>
      <ModalRecoverPassword
        show={modalRecoverPassword}
        onHide={hideModalRecoverPassword} />
      <ModalCancelAccount
        show={modalCancelAccount}
        onHide={hideModalCancelAccount} />
    </div>
  ) : null;
}

export default Account;
