import "./UiKit.scss";
//import { Link } from "react-router-dom";
import BookItem from "../../components/BookCatalog/BookItem/BookItem";
import BookList from "../../components/BookCatalog/BookList/BookList";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";
import booksAllMock from "../../mocks/books.json";
import bookMock from "../../mocks/book.json";
import Arrows from "../../components/DesingSystem/Arrows/Arrows";
import CheckBox from "../../components/DesingSystem/CheckBox/CheckBox";
import Profile from "../profile/Profile";

function UiKit() {
  return (
    <section className="ui-kit">
      <h1>Book card</h1>
      <BookItem book={bookMock} key={bookMock.id} />

      <h1>Book card list</h1>
      <BookList books={booksAllMock} />

      <h1>Icons</h1>
      <p className="m-0">Small size</p>
      <div className="m-0 mb-3">
        <Icon name="search" size={"small"} className={"me-1"} />
        <Icon name="profile" size={"small"} className={"me-1"} />
        <Icon name="notifications" size={"small"} className={"me-1"} />
        <Icon name="edit" size={"small"} className={"me-1"} />
        <Icon name="arrowLeft" size={"small"} className={"me-1"} />
        <Icon name="arrowRight" size={"small"} className={"me-1"} />
        <Icon name="share" size={"small"} className={"me-1"} />
      </div>

      <p>Medium size</p>
      <div className="m-0 mb-3">
        <Icon name="search" size={"medium"} className={"me-1"} />
        <Icon name="profile" size={"medium"} className={"me-1"} />
        <Icon name="notifications" size={"medium"} className={"me-1"} />
        <Icon name="edit" size={"medium"} className={"me-1"} />
        <Icon name="arrowLeft" size={"medium"} className={"me-1"} />
        <Icon name="arrowRight" size={"medium"} className={"me-1"} />
        <Icon name="share" size={"medium"} className={"me-1"} />
      </div>

      <p>Large size</p>
      <div className="m-0 mb-3">
        <Icon name="search" size={"large"} className={"me-1"} />
        <Icon name="profile" size={"large"} className={"me-1"} />
        <Icon name="notifications" size={"large"} className={"me-1"} />
        <Icon name="edit" size={"large"} className={"me-1"} />
        <Icon name="arrowLeft" size={"large"} className={"me-1"} />
        <Icon name="arrowRight" size={"large"} className={"me-1"} />
        <Icon name="share" size={"large"} className={"me-1"} />
      </div>

      <h1>Buttons</h1>
      <div className="row justify-content-start mb-4">
        <div className="col justify-content-center">
          <Button
            className={"btn-white big"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
        <div className="col justify-content-center">
          <Button
            className={"btn-orange big"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
        <div className="col justify-content-center">
          <Button
            className={"btn-gray big"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
      </div>
      <div className="row justify-content-start">
        <div className="col justify-content-center">
          <Button
            className={"btn-white"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
        <div className="col justify-content-center">
          <Button
            className={"btn-orange"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
        <div className="col justify-content-center">
          <Button
            className={"btn-gray"}
            text={"Button"}
            iconLeft={"add"}
            iconRight={"add"}
          />
        </div>
      </div>

      <h1>Arrows</h1>
      <div className="row justify-content-start">
        <div className="col">
          <Arrows size="small" />
          <Arrows size="medium" />
          <Arrows size="large" />
          <Arrows size="xlarge" />
        </div>
      </div>

      <div>
        <h1>Check-Box</h1>
        <CheckBox />
      </div>

      <div>
        <h1>Perfil</h1>
        <Profile/>
      </div>
    </section>
  );
}

export default UiKit;
