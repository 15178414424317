import React, { useEffect, useState, useImperativeHandle, forwardRef, useRef } from "react";
import * as dayjs from "dayjs";
import Modal from "react-bootstrap/Modal";
import Icon from "../Icon/Icon";
import Button from "../DesingSystem/Button/Button";
import { putApiDislikeSale, putApiLikeSale, putApiNeutralSale, putApiRemoveSaleReview } from "../../services/apiSale";
import "./ReviewForm.scss";

dayjs.locale('es');

const ReviewForm = forwardRef((props, ref)=> {
  
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [reviewRate, setReviewRate] = useState(props.purchase.like ? 'like' : props.purchase.dislike ? 'dislike' : props.purchase.neutral ? 'neutral' : '');
  const [message, setMessage] = useState(props.purchase.message);
  const [ratedAt, setRatedAt] = useState(props.purchase.rated_at);
  const [canPublish, setCanPublish] = useState(false);
  const [publishButtonIcon, setPublishButtonIcon] = useState('');
  
  useEffect(() =>{
    if(reviewRate === 'like' || reviewRate === 'dislike' || reviewRate === 'neutral'){
      setCanPublish(true)
      
    }else{
      setCanPublish(false);
    }
  }, [reviewRate])
  
  useImperativeHandle(ref, () => ({
    toggleModal() {
      (show === true) ?
        setShow(false)
      :
        setShow(true)
      ;
    }
  }));
  
  const toggleRate = (newRate) => {
    reviewRate === newRate ? 
      setReviewRate('')
    :
      setReviewRate(newRate)
    ;
  }
  
  const publishReview = async () => {
    setPublishButtonIcon('loaderIcon')
    let purchase = [];
    
    if(reviewRate === 'like'){
      purchase = await putApiLikeSale(props.purchase._id, message);
    }
    
    if(reviewRate === 'dislike'){
      purchase = await putApiDislikeSale(props.purchase._id, message);
    }
    
    if(reviewRate === 'neutral'){
      purchase = await putApiNeutralSale(props.purchase._id, message);
    }
    
    setPublishButtonIcon('')
    props.onReview(purchase)
    props.onHide()
    setShow(false)
  }
  
  const removeReview = async () => {
    let purchase = [];
    
    purchase = await putApiRemoveSaleReview(props.purchase._id);
    
    setMessage('')
    setReviewRate('')
    
    props.onReview(purchase)
    props.onHide()
    setShow(false)
    setConfirm(false)
  }
  
  const confirmRemoveReview = () =>{
    
  }
  
  const renderModal = () =>{
    return (
      <>
      <div className="row py-2">
        <div className="col-11">
          <div className="write-review">
            <h3 className="write-review__title">¿Qué te pareció la obra?</h3>
          </div>
        </div>
        <div className="col-1">
            <Icon 
              name="close"
              color="principal"
              size="medium"
              onClick={()=>{props.onHide(); setShow(false)}} />
          </div>
      </div>
      <div className="row mt-3">
        <div className="col-12">
          <h4 className="write-review__subtitle">Obra</h4>
          <section className="books-customer-item pb-4">
            <div className="books-customer-item__container">
              <div className="books-customer-item__cover">
                <img
                  className="books-customer-item__cover-image"
                  src={props.book.picture_medium}
                  alt="image1"
                />
              </div>
              <div className="books-customer-item__container-content">
                <div>
                  <h3 className="books-customer-item__container-title">
                    {props.book.title}
                  </h3>
                  
                  <p className="books-customer-item__container-author">Por {props.author.firstName} {props.author.lastName}</p>
                </div>
                
                <div className="books-customer-item__extra-info">
                  <div className="books-customer-item__download">
                    <Icon name="reviewLike" size="small" color="secondary-3" />
                    <span className="books-customer-item__download-text">{props.book.likes ? props.book.likes : 0}</span>
                    
                    <Icon name="reviewNeutral" size="small" color="secondary-3" style={{ cursor: 'default !important' }} />
                    <span className="books-customer-item__download-text">{props.book.neutral ? props.book.neutral : 0}</span>
                    
                    <Icon name="reviewDislike" size="small" color="secondary-3" />
                    <span className="books-customer-item__download-text">{props.book.dislikes ? props.book.dislikes : 0}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <h4 className="write-review__subtitle mt-4">Reseña</h4>
          <div className="row">
            <div className="col-12 text-center">
              <div className="write-review__buttons-wrapper">
                <div className={`write-review__button ${ reviewRate === 'like' ? 'active' : '' }`}>  
                  <Icon name="reviewLike" size="large" color="secondary-2" onClick={()=>{ toggleRate('like') }} />
                </div>
                <div className={`write-review__button ${ reviewRate === 'neutral' ? 'active' : '' }`}>
                  <Icon name="reviewNeutral" size="large" color="secondary-2" onClick={()=>{ toggleRate('neutral') }} />
                </div>
                <div className={`write-review__button ${ reviewRate === 'dislike' ? 'active' : '' }`}>
                  <Icon name="reviewDislike" size="large" color="secondary-2" onClick={()=>{ toggleRate('dislike') }} />
                </div>
              </div>
            </div>
            
            <div className="col-12 my-2">
              <textarea
                className='write-review__textarea'
                value={message}
                onChange={(e)=>{ setMessage(e.currentTarget.value) }}
                rows={3}
                id="review"
                name="review"
                placeholder="Escribe aquí tu reseña"
              />
              { props.purchase.rated_at ?
                <p className="write-review__date">Último cambio el: {dayjs(props.purchase.rated_at).format('DD/MM/YYYY')}</p>
              :
                null
              }
            </div>
                        
            <div className="col-12 my-1 text-center">
              { canPublish ?
                <Button
                  className={'btn-orangebg w-100'}
                  text={ props.purchase.rated_at ? "Guardar cambios" :  "Publicar reseña" }
                  iconRight={publishButtonIcon}
                  disabled={false}
                  onClick={async () => { await publishReview() }} />
              :  
                <Button
                  className={'btn-graybg w-100'}
                  text={ props.purchase.rated_at ? "Guardar cambios" :  "Publicar reseña" }
                  iconRight={publishButtonIcon}
                  disabled={true}
                  onClick={() => {}} />
              }
              
              { props.purchase.rated_at || reviewRate === 'like' || reviewRate === 'dislike' || reviewRate === 'neutral' ? 
                <button 
                  className={'write-review__button mt-2'} 
                  onClick={async () => { setConfirm(true) }}>
                  Eliminar reseña
                </button>
              :  
                null
              }
            </div>
          </div>
        </div>
      </div>
      </>
    )
  }
  
  return (
    <>
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={show}
        onHide={props.onHide}
        aria-labelledby="contained-modal-title-vcenter"
        className="write-review-modal"
        backdropClassName={"write-review-backdrop"}
      >
        <Modal.Body>
          <div>{renderModal()}</div>
        </Modal.Body>
      </Modal>
    </div>
    
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={confirm}
        aria-labelledby="contained-modal-title-vcenter"
        className="write-review-modal"
        backdropClassName={"write-review-backdrop"}
      >
        <Modal.Body>
          <div>
            <div className="row py-2">
              <div className="col-12">
                <div className="write-review">
                  <h3 className="write-review__title">¿Seguro que quieres eliminar la reseña?</h3>
                </div>
              </div>
            </div>
            
            <div className="row">
              <div className="col d-flex justify-content-center my-3">
                <Button
                    className={'btn-orange'}
                    text={ "Si" }
                    disabled={false}
                    onClick={async () => { await removeReview() }} />
                    
                <Button
                    className={'btn-orange'}
                    text={ "No" }
                    disabled={false}
                    onClick={async () => { setConfirm(false) }} />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    </>
  );
})

export default ReviewForm;