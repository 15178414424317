import "./CondicionesGenerales.scss";

function CondicionesGenerales() {
  return (
    <section className="aviso-legal">
      <p style={{ fontWeight: 400 }}>
        <strong>CONDICIONES GENERALES DE LA CONTRATACIÓN</strong>
      </p>
      <p><strong>1.- INTRODUCCI&Oacute;N E INFORMACI&Oacute;N PREVIA A LA CONTRATACI&Oacute;N</strong></p>
      <p>N&Eacute;STOR MART&Iacute;NEZ RODA, (En adelante &ldquo;el PRESTADOR&rdquo;) oferta y vende por medio de la p&aacute;gina web, www.bookolicos.com&nbsp;(en adelante, el &ldquo;Sitio Web&rdquo; o &ldquo;Portal&rdquo;), libros en formato electr&oacute;nico, servicios editoriales, formaci&oacute;n, servicios de promoci&oacute;n (podcast, webinars, eventos presenciales, redes sociales), informaci&oacute;n y noticias sobre el mundo literario, merchandising, networking (en adelante, el &ldquo;ejemplar o ejemplares&rdquo;).</p>
      <p>A trav&eacute;s del Sitio Web, se ofrece a los usuarios (en adelante, el &ldquo;Usuario&rdquo; o el &ldquo;Cliente&rdquo;) la posibilidad de adquirir ejemplares en formato electr&oacute;nico directamente del PRESTADOR.</p>
      <p>La adquisici&oacute;n de los ejemplares a trav&eacute;s del Portal, implica la aceptaci&oacute;n plena y sin reservas de las presentes Condiciones de Venta, as&iacute; como de cualesquiera otras condiciones particulares puestas en conocimiento del Usuario con car&aacute;cter previo a la adquisici&oacute;n de los ejemplares que, en su caso, sustituyen, completan y/o modifican las presentes Condiciones. En particular, el Usuario queda igualmente vinculado a las previsiones contenidas en el Aviso Legal, Pol&iacute;tica de Privacidad, T&eacute;rminos y Condiciones y Pol&iacute;tica de Cookies disponibles en el Sitio Web, en todo aquello que les pueda resultar de aplicaci&oacute;n.</p>
      <p>Las presentes Condiciones de Venta se aplicar&aacute;n sin excepci&oacute;n a todos los ejemplares adquiridos por el Usuario salvo que se acuerden otros t&eacute;rminos o condiciones expresamente y por escrito.</p>
      <p>Las presentes condiciones generales de contrataci&oacute;n regular&aacute;n expresamente las relaciones surgidas entre el PRESTADOR y los terceros (en adelante "Usuario") que contraten los ejemplares ofertados en el Portal. As&iacute; mismo, ser&aacute;n de aplicaci&oacute;n las condiciones particulares y cualquier otro acuerdo o contrato espec&iacute;fico que, en su caso, pueda establecerse entre ambas partes.</p>
      <p>Las presentes Condiciones Generales han sido elaboradas de conformidad con lo establecido en la Ley 34/2002, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico, la Ley 7/1998 sobre Condiciones Generales de Contrataci&oacute;n, y el Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios, modificado por la Ley 3/2014, de 27 de marzo y otras leyes complementarias y la dem&aacute;s normativa aplicable en materia de comercio electr&oacute;nico.</p>
      <p>La aceptaci&oacute;n del presente documento conlleva que el Usuario:</p>
      <ol>
      <li>a. Ha le&iacute;do, entiende y comprende lo aqu&iacute; expuesto.<br /> b. Que es una persona con capacidad suficiente para contratar.<br /> c. Que asume todas las obligaciones aqu&iacute; dispuestas.</li>
      </ol>
      <p>Las presentes condiciones tendr&aacute;n un periodo de validez indefinido y ser&aacute;n aplicables a todas las contrataciones realizadas a trav&eacute;s del Portal, propiedad del PRESTADOR. Este se reserva el derecho de modificar unilateralmente dichas Condiciones, sin que ello pueda afectar a los servicios que fueron adquiridos previamente a la modificaci&oacute;n.</p>
      <p>El Usuario se compromete a facilitar en todo momento informaci&oacute;n veraz, exacta y adecuada para su necesaria identificaci&oacute;n y acceso a los productos y art&iacute;culos ofertados por el PRESTADOR, siendo de su &uacute;nica y exclusiva responsabilidad la autenticidad y exactitud de estos. Cualquier modificaci&oacute;n o correcci&oacute;n de los datos proporcionados por los Usuarios durante la navegaci&oacute;n deber&aacute; efectuarse de conformidad con las indicaciones que en su caso se incluya en el Portal a tal efecto.</p>
      <p>De acuerdo con el art&iacute;culo 23.1 y 23.3 de la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico: "Los contratos celebrados por v&iacute;a electr&oacute;nica tendr&aacute;n la misma validez y producir&aacute;n todos los efectos del Ordenamiento Jur&iacute;dico Civil y Mercantil."</p>
      <p>El presente contrato, queda perfeccionado en el momento en que el Usuario <strong>pulse el bot&oacute;n/marque la casilla de HE LE&Iacute;DO Y ACEPTO LAS CONDICIONES DE COMPRA</strong>, de las presentes Condiciones Generales de Contrataci&oacute;n, y tras cubrir de manera correcta el&nbsp;formulario existente en el Portal. Asimismo, ser&aacute; igualmente v&aacute;lido la remisi&oacute;n de la aceptaci&oacute;n por cualquier otro medio electr&oacute;nico o tradicional en la sede del PRESTADOR. Tal y como establece el art&iacute;culo 28.3 b) de la Ley 34/2002 de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico, si el contrato se ha celebrado mediante intercambio de correo electr&oacute;nico u otra comunicaci&oacute;n electr&oacute;nica equivalente entre las partes, no ser&aacute; necesario confirmar la recepci&oacute;n de la aceptaci&oacute;n del contrato por el Usuario</p>
      <p>Si el Usuario, es una persona jur&iacute;dica, responder&aacute; en todo momento, de la validez y efectiva autorizaci&oacute;n o poder de las personas f&iacute;sicas que celebren el presente contrato y no podr&aacute; oponerse a los servicios contratados por falta de autorizaci&oacute;n o revocaci&oacute;n del poder.</p>
      <ol start="2">
      <li><strong>2.- ENTRADA EN VIGOR DE LAS CONDICIONES GENERALES DE CONTRATACI&Oacute;N</strong></li>
      </ol>
      <p>El presente documento de Condiciones Generales de Contrataci&oacute;n entrar&aacute; en vigor desde el momento en el que el Usuario <strong>pulse el bot&oacute;n/marque la casilla de HE LE&Iacute;DO Y ACEPTO LAS CONDICIONES DE COMPRA</strong> de las presentes Condiciones Generales de Contrataci&oacute;n. Desde ese momento, dicho Usuario, manifiesta que acepta y se adhiere de forma completa e incondicionada a las estipulaciones contenidas en las presentes Condiciones Generales de Contrataci&oacute;n.</p>
      <p>De conformidad con lo establecido en el art&iacute;culo 5.3 de la Ley 7/1998, de 13 de abril, de condiciones generales de la contrataci&oacute;n, el PRESTADOR garantiza que ha expuesto previamente las presentes Condiciones Generales a la generalidad de los posibles Usuarios, en fecha anterior a que &eacute;stos pudiesen contratar cualquier servicio independientemente de que para poder realizar cualquier registro o solicitud de servicio ser&aacute; necesaria la previa aceptaci&oacute;n de estas.</p>
      <ol start="3">
      <li><strong>3.- EL USUARIO</strong></li>
      </ol>
      <p>El acceso, la navegaci&oacute;n y uso del Sitio Web, confiere la condici&oacute;n de Usuario (en adelante referido, indistintamente, individualmente como Usuario o conjuntamente como Usuarios), por lo que se aceptan, desde que se inicia la navegaci&oacute;n por el Sitio Web, todas las Condiciones aqu&iacute; establecidas, as&iacute; como sus ulteriores modificaciones, sin perjuicio de la aplicaci&oacute;n de la correspondiente normativa legal de obligado cumplimiento seg&uacute;n el caso; as&iacute; como el cumplimiento de los <strong>T&Eacute;RMINO Y CONDICIONES DE USO</strong> recogidos en la propia p&aacute;gina web.</p>
      <p>El Usuario asume su responsabilidad de un uso correcto del Sitio Web. Esta responsabilidad se extender&aacute; a:</p>
      <ul>
      <li>Hacer uso de este Sitio Web &uacute;nicamente para realizar consultas y compras o adquisiciones legalmente v&aacute;lidas.</li>
      <li>No realizar ninguna compra falsa o fraudulenta. Si razonablemente se pudiera considerar que se ha hecho una compra de esta &iacute;ndole, podr&iacute;a ser anulada y se informar&iacute;a a las autoridades pertinentes.</li>
      <li>Facilitar datos de contacto veraces y l&iacute;citos, por ejemplo, direcci&oacute;n de correo electr&oacute;nico, direcci&oacute;n postal y/u otros datos (ver Aviso Legal y T&eacute;rmino y Condiciones de Uso).</li>
      </ul>
      <p>El Usuario declara ser mayor de 18 a&ntilde;os y tener capacidad legal para celebrar contratos a trav&eacute;s de este Sitio Web.</p>
      <p>El Sitio Web est&aacute; dirigido principalmente a Usuarios residentes en Espa&ntilde;a. El PRESTADOR no asegura que el Sitio Web cumpla con legislaciones de otros pa&iacute;ses, ya sea total o parcialmente.&nbsp;El PRESTADOR declina toda responsabilidad que se pueda derivar de dicho acceso.</p>
      <ol start="4">
      <li><strong>4.- REGISTRO DEL USUARIO</strong></li>
      </ol>
      <p>Dentro de <a href="http://www.bookolicos.com/">www.bookolicos.com</a>, se dispone de formulario de registro previo para el Usuario.</p>
      <p>Deber&aacute;n cumplimentar dicho formulario de registro, aportando toda la informaci&oacute;n necesaria para la adquisici&oacute;n de los ejemplares ofertados en la web.</p>
      <p>En el caso de que el registro se lleve a cabo en nombre de una empresa (persona jur&iacute;dica), la persona f&iacute;sica que realiza el registro y acepta las Condiciones Generales de Venta, manifiesta expresamente que cuenta con las facultades de representaci&oacute;n suficientes para contratar en nombre de esta y comprometer a la empresa a la que representa con las mencionadas condiciones. El PRESTADOR en ning&uacute;n caso se har&aacute;n responsable de la falta de veracidad o incorrecci&oacute;n en los datos o manifestaciones realizadas por el representante de la empresa.</p>
      <ol start="5">
      <li><strong>5.- PROCESO DE COMPRA O ADQUISICI&Oacute;N</strong></li>
      </ol>
      <p>Los Usuarios&nbsp;pueden comprar en el Sitio Web por los medios y formas previamente establecidos. Deber&aacute;n seguir el procedimiento de compra y/o adquisici&oacute;n online del PRESTADOR, durante el cual un producto y/o servicio puede ser seleccionado y a&ntilde;adido al carrito, cesta o espacio final de compra y, finalmente, hacer clic en el bot&oacute;n de <strong>COMPRAR</strong></p>
      <p>Asimismo, el Usuario deber&aacute; rellenar y/o comprobar la informaci&oacute;n que en cada paso se le solicita, aunque, durante el proceso de compra, antes de realizar el pago, se pueden modificar los datos de la compra.</p>
      <p>Seguidamente, el Usuario recibir&aacute; un mensaje en pantalla confirmando que el PRESTADOR ha recibido su pedido/solicitud de compra y/o prestaci&oacute;n del servicio, es decir, la confirmaci&oacute;n del pedido. Y, en su caso, se le informar&aacute;, igualmente, que puede proceder a la descarga en caso de tratarse de un producto.</p>
      <p>El Cliente sabe y acepta que el PRESTADOR, s&oacute;lo le enviar&aacute; sus facturas al correo electr&oacute;nico que haya facilitado durante el proceso de registro.</p>
      <p>Conforme a lo establecido por el Real Decreto 1619/2012, de 30 de noviembre, en el que se aprueba el Reglamento que regula las obligaciones de facturaci&oacute;n, el Usuario, al aceptar estas condiciones generales de contrataci&oacute;n, al mismo tiempo, consiente que el PRESTADOR expida y remita sus facturas en formato electr&oacute;nico.</p>
      <p>En el supuesto de que el Cliente quiera disponer de su factura en soporte papel, podr&aacute; imprimir la factura, conservando el archivo digital remitido por el PRESTADOR. La factura emitida contendr&aacute; los siguientes datos:</p>
      <ul>
      <li>Datos fiscales del emisor de la factura</li>
      <li>Datos fiscales del Cliente</li>
      <li>Forma de pago aplicada</li>
      <li>Importe total de los productos importe de los impuestos</li>
      <li>Importe neto a pagar</li>
      </ul>
      <p>El Usuario reconoce estar al corriente, en el momento de la compra, de ciertas condiciones particulares de venta que conciernen al producto y/o servicio en cuesti&oacute;n y que se muestran junto a la presentaci&oacute;n o, en su caso, imagen de &eacute;ste en su p&aacute;gina del Sitio Web, indicando, a modo enunciativo, pero no exhaustivo, y atendiendo a cada caso: t&iacute;tulo de la obra, precio, autor y modo en el que se llevar&aacute;n a cabo la entrega/env&iacute;o y reconoce que la realizaci&oacute;n del pedido de compra o adquisici&oacute;n materializa la aceptaci&oacute;n plena y completa de las condiciones particulares de venta aplicables a cada caso.</p>
      <p>Las comunicaciones, &oacute;rdenes de compra y pagos que intervengan durante las transacciones efectuadas en el Sitio Web podr&iacute;an ser archivadas y conservadas en los registros informatizados del PRESTADOR&nbsp;con el fin de constituir un medio de prueba de las transacciones, en todo caso, respetando las condiciones razonables de seguridad y las leyes y normativas vigentes que a este respecto sean de aplicaci&oacute;n, y particularmente atendiendo al Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (RGPD) y la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales, y a los derechos que asisten a los Usuarios conforme a la pol&iacute;tica de privacidad de este Sitio Web.</p>
      <ol start="6">
      <li><strong>6.- DISPONIBILIDAD</strong></li>
      </ol>
      <p>Todos los pedidos de compra recibidos por&nbsp;el PRESTADOR a trav&eacute;s del Sitio Web est&aacute;n sujetos a la disponibilidad de los productos y/o a que ninguna circunstancia o causa de fuerza mayor afecte al suministro de estos y/o a la prestaci&oacute;n de los servicios. Si se produjeran dificultades en cuanto al suministro de productos, el PRESTADOR se compromete a contactar al Usuario y reembolsar cualquier cantidad que pudiera haber sido abonada en concepto de importe. Esto ser&aacute; igualmente aplicable en los casos en los que la prestaci&oacute;n de un servicio deviniera irrealizable.</p>
      <ol start="7">
      <li><strong>7.- PRECIOS Y PAGO</strong></li>
      </ol>
      <p>Los precios exhibidos en el Sitio Web son los finales, en Euros (&euro;) e incluyen los impuestos, salvo que por exigencia legal, especialmente en lo relativo al IVA, se se&ntilde;ale y aplique cuesti&oacute;n distinta.</p>
      <p>En ning&uacute;n caso el Sitio Web a&ntilde;adir&aacute; costes adicionales al precio de un producto o de un servicio de forma autom&aacute;tica, sino solo aquellos que el Usuario haya seleccionado y elegido voluntaria y libremente.</p>
      <p>Los precios pueden cambiar en cualquier momento, pero los posibles cambios no afectar&aacute;n a los pedidos o compras con respecto a los que el Usuario ya haya&nbsp;recibido una confirmaci&oacute;n de pedido.</p>
      <p>Los medios de pago aceptados ser&aacute;n:&nbsp;Tarjeta de cr&eacute;dito o d&eacute;bito, y las pasarelas de pago Union Pay&trade;, Apple Pay&trade; y Google Pay&trade;.</p>
      <p>Las tarjetas de cr&eacute;dito estar&aacute;n sujetas a comprobaciones y autorizaciones por parte de la entidad bancaria emisora de estas. Si dicha entidad no autorizase el pago, el PRESTADOR no ser&aacute; responsable por ning&uacute;n retraso o falta de entrega y no podr&aacute; formalizar ning&uacute;n contrato con el Usuario.</p>
      <p>Una vez que&nbsp;el PRESTADOR reciba la orden de compra por parte del Usuario a trav&eacute;s del Sitio Web, se har&aacute; una pre-autorizaci&oacute;n en la tarjeta que corresponda para asegurar que existen fondos suficientes para completar la transacci&oacute;n. El cargo en la tarjeta se har&aacute; en el momento en que se env&iacute;e al Usuario la confirmaci&oacute;n de env&iacute;o y/o confirmaci&oacute;n del servicio que se presta en forma y, en su caso, lugares establecidos.</p>
      <p>En todo caso, al hacer clic en el bot&oacute;n de COMPRAR, el Usuario confirma que el m&eacute;todo de pago utilizado es suyo.</p>
      <ol start="8">
      <li><strong>8.- ENTREGA </strong></li>
      </ol>
      <p>Las entregas digitales, por ser el &uacute;nico medio posible para ello, se realizar&aacute;n inmediatamente tras las oportunas comprobaciones reflejadas en el punto 5 y 7, utilizando para ello los datos facilitados por el Usuario a lo largo del proceso de compra.</p>
      <p>Exceptuando aquellos casos en los que existan circunstancias imprevistas o extraordinarias o, en su caso, derivadas de la personalizaci&oacute;n de los productos, el pedido de compra consistente en los productos relacionados en cada confirmaci&oacute;n de compra ser&aacute; entregado en el plazo se&ntilde;alado en el Sitio Web.</p>
      <p>El PRESTADOR no se responsabilizar&aacute; en ning&uacute;n caso de la imposibilidad de efectuar la entrega.</p>
      <p>De conformidad con lo dispuesto en la Ley 37/1992, de 28 de diciembre, del Impuesto sobre el Valor A&ntilde;adido (IVA), los pedidos de compra para su entrega y/o prestaci&oacute;n se entender&aacute;n localizados en el territorio de aplicaci&oacute;n del IVA espa&ntilde;ol si la direcci&oacute;n de entrega est&aacute; en territorio espa&ntilde;ol salvo Canarias, Ceuta y Melilla. El tipo de IVA aplicable ser&aacute; el legalmente vigente en cada momento en funci&oacute;n del art&iacute;culo concreto de que se trate.</p>
      <ol start="9">
      <li><strong>9.- MEDIOS T&Eacute;CNICOS PARA CORREGIR ERRORES</strong></li>
      </ol>
      <p>Se pone en conocimiento del Usuario que en caso de que detecte que se ha producido un error al introducir datos necesarios para procesar su solicitud de compra en el Sitio Web, podr&aacute; modificar los mismos poni&eacute;ndose en contacto con&nbsp;el PRESTADOR a trav&eacute;s de los espacios de contacto habilitados en el Sitio Web, y, en su caso, a trav&eacute;s de aquellos habilitados para contactar con el servicio de atenci&oacute;n al cliente, y/o utilizando los datos de contacto facilitados en la cl&aacute;usula primera (Informaci&oacute;n general). Asimismo, estas informaciones tambi&eacute;n podr&iacute;an subsanarse por el Usuario a trav&eacute;s de su espacio personal de conexi&oacute;n al Sitio Web.</p>
      <p>En cualquier caso, el Usuario, antes de hacer clic en COMPRAR, tiene acceso al espacio, carrito, o cesta donde se van anotando su solicitud de compra y puede hacer modificaciones.</p>
      <p>De igual forma, se remite al Usuario a consultar el Aviso Legal y Condiciones Generales de Uso y, en concreto, la Pol&iacute;tica de Privacidad para recabar m&aacute;s informaci&oacute;n sobre c&oacute;mo ejercer su derecho de rectificaci&oacute;n seg&uacute;n lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (RGPD) y en la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales.</p>
      <ol start="10">
      <li><strong>10.- DEVOLUCIONES</strong></li>
      </ol>
      <p>En los casos en los que el Usuario adquiriera productos en o trav&eacute;s del Sitio Web del PRESTADOR, le asisten una serie de derechos, tal y como se enumeran y describen a continuaci&oacute;n:</p>
      <p><strong>Derecho de Desistimiento</strong></p>
      <p>El Usuario, en tanto que consumidor y usuario, realiza una compra en el Sitio Web y, por tanto, le asiste el derecho a desistir de dicha compra en un plazo de catorce (14) d&iacute;as naturales sin necesidad de justificaci&oacute;n.</p>
      <p>Para ejercer este derecho de desistimiento, el Usuario deber&aacute; notificar su decisi&oacute;n al PRESTADOR de manera clara e inequ&iacute;voca. Podr&aacute; hacerlo, en su caso, a trav&eacute;s de los espacios de contacto habilitados en el Sitio Web.</p>
      <p>Para cumplir el plazo de desistimiento, basta con que la comunicaci&oacute;n que expresa inequ&iacute;vocamente la decisi&oacute;n de desistir sea enviada antes de que venza el plazo correspondiente.</p>
      <p>El PRESTADOR reembolsar&aacute; al Usuario utilizando el mismo m&eacute;todo de pago que emple&oacute; este para realizar la transacci&oacute;n inicial de compra. Este reembolso no generar&aacute; ning&uacute;n coste adicional al Usuario. No obstante, el PRESTADOR&nbsp;podr&iacute;a retener dicho reembolso hasta haber recibido los productos o art&iacute;culos de la compra, o hasta que el Usuario presente una prueba de la devoluci&oacute;n de los mismos, seg&uacute;n qu&eacute; condici&oacute;n se cumpla primero.</p>
      <p>El Usuario reconoce saber que existen excepciones al derecho de desistimiento, tal y como se recoge en el art&iacute;culo 103 del Real Decreto Legislativo 1/2007, de 16 de noviembre, por el que se aprueba el texto refundido de la Ley General para la Defensa de los Consumidores y Usuarios y otras leyes complementarias.</p>
      <p>En este mismo sentido se rige la prestaci&oacute;n de un servicio que el Usuario pudiera contratar en este Sitio Web, pues esta misma Ley establece que no asistir&aacute; el Derecho de desistimiento a los Usuarios cuando la prestaci&oacute;n del servicio ha sido completamente ejecutada, o cuando haya comenzado, con el consentimiento expreso del consumidor y usuario y con el reconocimiento por su parte de que es consciente de que, una vez que el contrato haya sido completamente ejecutado porel PRESTADOR&nbsp;, habr&aacute; perdido su derecho de desistimiento.</p>
      <p><strong>Garant&iacute;as</strong></p>
      <p>El Usuario, en tanto que consumidor y usuario, goza de garant&iacute;as sobre los productos que pueda adquirir a trav&eacute;s de este Sitio Web, en los t&eacute;rminos legalmente establecidos para cada tipo de producto, respondiendo el PRESTADOR por tanto, por la falta de conformidad de los mismos que se manifieste en un plazo de <strong><u>tres</u></strong> a&ntilde;os desde la entrega del producto.</p>
      <p>En este sentido, se entiende que los productos son conformes con el contrato siempre que: se ajusten a la descripci&oacute;n realizada por el PRESTADOR&nbsp;y posean las cualidades <u>presentadas</u> en la misma; sean aptos para los usos a que ordinariamente se destinan los productos del mismo tipo; y presenten la calidad y prestaciones habituales de un producto del mismo tipo y que sean fundamentalmente esperables del mismo. Cuando esto no sea as&iacute; respecto de los productos entregados al Usuario, &eacute;ste deber&aacute; proceder tal y como se indica en el apartado Devoluci&oacute;n de productos defectuosos o error en el env&iacute;o. No obstante, algunos de los productos que se comercializan en el Sitio Web, podr&iacute;an presentar caracter&iacute;sticas no homog&eacute;neas siempre y cuando &eacute;stas deriven del tipo de material con el que se han fabricado, y que por ende formar&aacute;n parte de la apariencia individual del producto, y no ser&aacute;n un defecto.</p>
      <ol start="11">
      <li><strong>11.- EXONERACI&Oacute;N DE RESPONSABILIDAD</strong></li>
      </ol>
      <p>Salvo disposici&oacute;n legal en sentido contrario, el PRESTADOR no aceptar&aacute; ninguna responsabilidad por las siguientes p&eacute;rdidas, con independencia de su origen:</p>
      <ul>
      <li>cualesquiera p&eacute;rdidas que no fueran atribuibles a incumplimiento alguno por su parte;</li>
      <li>p&eacute;rdidas empresariales (incluyendo lucro cesante, de ingresos, de contratos, de ahorros previstos, de datos, p&eacute;rdida del fondo de comercio o gastos innecesarios incurridos); o de</li>
      <li>toda otra p&eacute;rdida indirecta que no fuera razonablemente previsible por ambas partes en el momento en que se formaliz&oacute; el contrato de compraventa de los productos entre ambas partes.</li>
      </ul>
      <p>Igualmente, el PRESTADOR tambi&eacute;n limita su responsabilidad en cuanto a los siguientes casos:</p>
      <ul>
      <li>Aplicaci&oacute;n de todas las medidas concernientes a proporcionar una visualizaci&oacute;n fiel del producto en el Sitio Web; sin embargo, no se responsabilizar&aacute; por las m&iacute;nimas diferencias o inexactitudes que puedan existir debido a falta de resoluci&oacute;n de la pantalla, o problemas del navegador que se utilice u otros de esta &iacute;ndole.</li>
      <li>Actuaci&oacute;n con la m&aacute;xima diligencia a efectos de poner a disposici&oacute;n de la empresa encargada del transporte del producto objeto del pedido de compra. Sin embargo, no se responsabilizar&aacute; por perjuicios provenientes de un mal funcionamiento del transporte, especialmente por causas como huelgas, retenciones en carreteras, y en general cualquiera otras propias del sector, que deriven en retrasos, p&eacute;rdidas o hurtos del producto.</li>
      <li>Fallos t&eacute;cnicos que por causas fortuitas o de otra &iacute;ndole, impidan un normal funcionamiento del servicio a trav&eacute;s de internet. Falta de disponibilidad del Sitio Web por razones de mantenimiento u otras que impidan disponer del servicio. El PRESTADOR pone todos los medios a su alcance a efectos de llevar a cabo el proceso de compra, pago y env&iacute;o/entrega de los productos; no obstante, se exime de responsabilidad por causas que no le sean imputables, caso fortuito o fuerza mayor.</li>
      <li>No se responsabilizar&aacute; del mal uso de los productos que hayan sido utilizados por el Usuario. Al mismo tiempo,&nbsp;tampoco se har&aacute; responsable de una devoluci&oacute;n err&oacute;nea realizada por el Usuario. Es responsabilidad del Usuario devolver el producto correcto.</li>
      <li>En general, el PRESTADOR no se responsabilizar&aacute; por ning&uacute;n incumplimiento o retraso en el cumplimiento de alguna de las obligaciones asumidas, cuando el mismo se deba a acontecimientos que est&aacute;n fuera de su control razonable, es decir, que se deban a causa de fuerza mayor, y &eacute;sta podr&aacute; incluir, a modo enunciativo, pero no exhaustivo:</li>
      <ul>
      <li>Huelgas, cierres patronales u otras medidas reivindicativas.</li>
      <li>Conmoci&oacute;n civil, revuelta, invasi&oacute;n, amenaza o ataque terrorista, guerra (declarada o no) o amenaza o preparativos de guerra.</li>
      <li>Incendio, explosi&oacute;n, tormenta, inundaci&oacute;n, terremoto, hundimiento, epidemia o cualquier otro desastre natural.</li>
      <li>Imposibilidad de uso de trenes, barcos, aviones, transportes de motor u otros medios de transporte, p&uacute;blicos o privados.</li>
      <li>Imposibilidad de utilizar sistemas p&uacute;blicos o privados de telecomunicaciones.</li>
      <li>Actos, decretos, legislaci&oacute;n, normativa o restricciones de cualquier gobierno o autoridad p&uacute;blica.</li>
      </ul>
      </ul>
      <p>De esta forma, las obligaciones quedar&aacute;n suspendidas durante el periodo en que la causa de fuerza mayor contin&uacute;e, y el PRESTADOR dispondr&aacute; de una ampliaci&oacute;n en el plazo para cumplirlas por un periodo de tiempo igual al que dure la causa de fuerza mayor. pondr&aacute; todos los medios razonables para encontrar una soluci&oacute;n que le permita cumplir con sus obligaciones a pesar de la causa de fuerza mayor.</p>
      <ol start="12">
      <li><strong>12.- INTEGRIDAD, ANULABILIDAD Y MODIFICACI&Oacute;N DE CONTRATO</strong></li>
      </ol>
      <p>Todos los acuerdos y compromisos verbales previos entre el PRESTADOR y el Usuario quedan sin efecto a la luz de las condiciones aqu&iacute; descritas, que constituyen la totalidad del acuerdo.</p>
      <p>Este contrato tiene fuerza vinculante entre las partes, y si algunas cl&aacute;usulas contractuales fuesen anulables o nulas de plenos derecho, se tendr&aacute;n por no puestas.</p>
      <p>Respecto a la modificaci&oacute;n del presente contrato, el PRESTADOR informar&aacute; de la misma al Usuario, mediante publicaci&oacute;n de la modificaci&oacute;n con 15 d&iacute;as de antelaci&oacute;n a su entrada en vigor, en un lugar claramente visible del Portal.&nbsp;</p>
      <p>El transcurso del mencionado plazo sin que el Usuario haya manifestado su oposici&oacute;n a tales modificaciones implicar&aacute; la plena aceptaci&oacute;n, por parte del Usuario de las nuevas condiciones. Si el Usuario no aceptase las modificaciones propuestas que le sean comunicadas, podr&aacute; renunciar al contrato y resolverlo, conforme lo estipulado en el presente contrato.</p>
      <p>Las modificaciones que resultaren claramente beneficiosas para el Cliente podr&aacute;n entrar en vigor inmediatamente.</p>
      <ol start="13">
      <li><strong>13.- INCUMPLIMIENTO</strong></li>
      </ol>
      <p>El incumplimiento, por parte del Usuario, de las previsiones contenidas en estas Condiciones y/o en el Aviso Legal disponible en el Sitio Web, as&iacute; como cualquier utilizaci&oacute;n del Sitio Web y/o de los servicios disponibles en el mismo que se considere indebida, abusiva o contraria a la Ley o al esp&iacute;ritu y finalidades del Sitio Web, facultar&aacute; al PRESTADOR , a suspender, bloquear y/o retirar definitivamente y sin previo aviso el acceso del Cliente al Sitio Web, as&iacute; como a exigir la indemnizaci&oacute;n de los da&ntilde;os y perjuicios que dicho incumplimiento o actuaci&oacute;n pudiera ocasionar al titular de la web.</p>
      <p><strong>14.- OBLIGACIONES DEL PRESTADOR</strong></p>
      <p>EL PRESTADOR, en virtud de las presentes condiciones generales de contrataci&oacute;n se compromete con el Usuario a las siguientes obligaciones:</p>
      <ol>
      <li>Facilitar al Usuario, tanto previamente como con posterioridad a su registro, la informaci&oacute;n que &eacute;ste requiera en relaci&oacute;n con la prestaci&oacute;n de los concretos productos vendidos en el marco de lo estipulado por la normativa aplicable y las presentes condiciones generales de contrataci&oacute;n.</li>
      <li>Realizar de forma diligente las tareas propias y necesarias para la puesta a disposici&oacute;n de los productos ofertados.</li>
      </ol>
      <p>iii. Observar la debida diligencia y atenci&oacute;n en la prestaci&oacute;n de los productos ofertados y en su relaci&oacute;n con su Usuario, as&iacute; como velar por los intereses de los mismos.</p>
      <ol>
      <li>Facilitar al Usuario, los canales de comunicaci&oacute;n con el fin de que el mismo pueda contactar con el PRESTADOR en relaci&oacute;n con cualquier incidencia o consulta relacionada con los productos ofertados</li>
      <li>Responder de la calidad y cualidades de los art&iacute;culos ofertados por el PRESTADOR y que se encuentren dentro de su exclusiva capacidad de control y/o direcci&oacute;n.</li>
      </ol>
      <ol start="15">
      <li><strong>15.- OBLIGACIONES DEL USUARIO</strong></li>
      </ol>
      <p>El Usuario que acceda al Portal y se registre o solicite los servicios ofertados, en aplicaci&oacute;n de las presentes condiciones generales de contrataci&oacute;n se compromete a cumplir con las siguientes obligaciones:</p>
      <ol>
      <li>Facilitar toda la informaci&oacute;n y datos necesarios para su registro o para la solicitud o prestaci&oacute;n de los servicios prestados, comprometi&eacute;ndose a facilitar informaci&oacute;n exacta, actualizada y verdadera.</li>
      <li>Abonar el precio por el ejemplar adquirido, de conformidad con lo estipulado en el correspondiente apartado de las presentes condiciones generales de contrataci&oacute;n y en el Portal.</li>
      </ol>
      <p>iii. Mantener actualizados en todo momento sus datos identificativos y en particular la informaci&oacute;n necesaria para la tramitaci&oacute;n del pago al prestador.</p>
      <ol start="16">
      <li><strong>16.- RESPONSABILIDADES</strong></li>
      </ol>
      <p>El Usuario ser&aacute; el &uacute;nico y exclusivo responsable de la veracidad, adecuaci&oacute;n y exactitud de todos los datos y manifestaciones que incluya en los formularios que env&iacute;e al PRESTADOR, con el fin de tramitar su alta como Usuario o cualquier otra comunicaci&oacute;n que emita con cualquier finalidad siendo &eacute;sta dirigida al prestador.</p>
      <p>De esta manera, el Usuario ser&aacute; el &uacute;nico responsable de cualquier omisi&oacute;n, inexactitud o error cometido a la hora de cumplimentar los datos requeridos para la prestaci&oacute;n de los servicios ofertados por el prestador.</p>
      <p>El mero env&iacute;o por parte del Usuario de un formulario de solicitud de los servicios no supone necesariamente la aceptaci&oacute;n del mismo por parte del prestador en tanto en cuanto &eacute;sta no haya contrastado la exactitud de la informaci&oacute;n y cualquier otro extremo requerido para su prestaci&oacute;n.</p>
      <p>El PRESTADOR no ser&aacute; responsable del contenido, calidad, cualidades o desempe&ntilde;o de terceros profesionales o empresas. El prestador realizar&aacute; sus mejores esfuerzos con el fin de asegurar la disponibilidad t&eacute;cnica de los servicios y la adecuada transmisi&oacute;n de la informaci&oacute;n, siempre en la medida de sus posibilidades. No obstante, debido a la naturaleza de la red Internet no es posible garantizar de forma absoluta estos extremos.</p>
      <p>El PRESTADOR no ser&aacute; responsable por da&ntilde;os y perjuicios de cualquier naturaleza que eventualmente se pudieran derivar de: la interrupci&oacute;n de funcionamiento o falta de disponibilidad de su p&aacute;gina web; la privacidad y la seguridad de la p&aacute;gina web en cuanto a su utilizaci&oacute;n por parte del Usuario, y/o el acceso no consentido de terceros no autorizados; la exactitud, actualizaci&oacute;n y exhaustividad de los contenidos de la web; la eventual transmisi&oacute;n de software u otros elementos que afecten negativamente a los sistemas inform&aacute;ticos; cualquier otra p&eacute;rdida o da&ntilde;o indirecto o de car&aacute;cter consecuencia que no fuera razonablemente previsible por ambas partes en el momento en que el Cliente solicit&oacute; los servicios del prestador, la demora en la prestaci&oacute;n de los servicios ofertados en tanto en cuanto dicha demora o falta de cumplimiento se deba a circunstancias ajenas al control del PRESTADOR.</p>
      <ol start="17">
      <li><strong>17.- COMUNICACIONES POR ESCRITO Y NOTIFICACIONES</strong></li>
      </ol>
      <p>Mediante el uso de este Sitio Web, el Usuario acepta que la mayor parte de las comunicaciones con el PRESTADOR&nbsp;sean electr&oacute;nicas (correo electr&oacute;nico o avisos publicados en el Sitio Web).</p>
      <p>A efectos contractuales, el Usuario consiente en usar este medio electr&oacute;nico de comunicaci&oacute;n y reconoce que todo contrato, notificaci&oacute;n, informaci&oacute;n y dem&aacute;s comunicaciones que&nbsp;el PRESTADOR&nbsp;env&iacute;e de forma electr&oacute;nica cumplen con los requisitos legales de ser por escrito. Esta condici&oacute;n no afectar&aacute; a los derechos reconocidos por ley al Usuario.</p>
      <p>El Usuario puede enviar notificaciones y/o comunicarse con&nbsp;el PRESTADOR&nbsp;a trav&eacute;s de los datos de contacto que en estas Condiciones se facilitan y, en su caso, a trav&eacute;s de los espacios de contacto del Sitio Web.</p>
      <p>Igualmente, salvo que se estipule lo contrario,&nbsp;el PRESTADOR puede contactar y/o notificar al Usuario en su correo electr&oacute;nico o en la direcci&oacute;n postal facilitada.</p>
      <ol start="18">
      <li><strong>18.- RENUNCIA</strong></li>
      </ol>
      <p>Ninguna renuncia del PRESTADOR a un derecho o acci&oacute;n legal concreta o la falta de requerimiento por su parte exonerar&aacute; del cumplimiento estricto por el Usuario de alguna de sus obligaciones supondr&aacute;, ni una renuncia a otros derechos o acciones derivados de un contrato o de las Condiciones, ni exonerar&aacute; al Usuario del cumplimiento de sus obligaciones.</p>
      <p>Ninguna renuncia del PRESTADOR&nbsp;a alguna de las presentes Condiciones o a los derechos o acciones derivados de un contrato surtir&aacute; efecto, a no ser que se establezca expresamente que es una renuncia y se formalice y se le comunique al Usuario por cualquier medio telem&aacute;tico feaciente.</p>
      <ol start="19">
      <li><strong>19.- NULIDAD</strong></li>
      </ol>
      <p>Si alguna de las presentes Condiciones fuera declaradas nulas y sin efecto por resoluci&oacute;n firme dictada por autoridad competente, el resto de las cl&aacute;usulas permanecer&aacute;n en vigor, sin que queden afectadas por dicha declaraci&oacute;n de nulidad.</p>
      <ol start="20">
      <li><strong>20.- ACUERDO COMPLETO</strong></li>
      </ol>
      <p>Las presentes Condiciones y todo documento al que se haga referencia expresa en estas constituyen el acuerdo &iacute;ntegro existente entre el Usuario y&nbsp;el PRESTADOR en relaci&oacute;n con el objeto de compraventa y sustituyen a cualquier otro pacto, acuerdo o promesa anterior convenida verbalmente o por escrito por las mismas partes.</p>
      <p>El Usuario y&nbsp;el PRESTADOR reconocen haber consentido la celebraci&oacute;n de un contrato sin haber confiado en ninguna declaraci&oacute;n o promesa hecha por la otra parte, salvo aquello que figura expresamente mencionado en las presentes Condiciones.</p>
      <ol start="21">
      <li><strong>21.- FIN DEL SERVICIO. EXTINCI&Oacute;N Y RESOLUCI&Oacute;N CONTRACTUAL</strong></li>
      </ol>
      <p>El servicio se entender&aacute; prestado cuando el ejemplar/es objeto de las presentes condiciones generales de contrataci&oacute;n, sea entregado al Usuario.</p>
      <p>Asimismo, en caso de que no haya sido enviado el ejemplar, bien por circunstancias imputables al PRESTADOR por motivos comerciales o de cualquier otra naturaleza no imputables al cliente, se entender&aacute; que dicho servicio ha finalizado con la devoluci&oacute;n del importe del mismo que el cliente, en su caso, hubiere abonado con car&aacute;cter previo al PRESTADOR</p>
      <p>El contrato se extinguir&aacute; cuando ambas partes cumplan con las obligaciones a las que se comprometen en el mismo, agotando su objeto, o cuando sea resuelto por alguna de ellas si concurre alguna de las causas previstas para la resoluci&oacute;n en el C&oacute;digo Civil.</p>
      <p>El presente contrato de condiciones generales podr&aacute; ser resuelto por cualquiera de las partes si la contraparte incumple de forma grave alguna de las obligaciones establecidas en el mismo.</p>
      <ol start="22">
      <li><strong>22.- CONFIDENCIALIDAD</strong></li>
      </ol>
      <p>Ni el PRESTADOR ni el Usuario, revelar&aacute;n a un tercero cualquier tipo de informaci&oacute;n de car&aacute;cter confidencial, secretos comerciales, datos econ&oacute;micos, know-how, m&eacute;todos de trabajo, datos personales o cualquier documento de cualquiera de las dos partes que lleve una menci&oacute;n de confidencialidad, o que de su naturaleza se deduzca su condici&oacute;n de confidencial, que haya recibido de la otra parte en relaci&oacute;n con la prestaci&oacute;n de los servicios objeto de estas condiciones generales de contrataci&oacute;n. Esta prohibici&oacute;n no tendr&aacute; efecto sobre una parte si cuenta con el consentimiento previo por escrito de la otra parte. Las partes se comprometen a adoptar las precauciones necesarias para conservar el car&aacute;cter secreto de las informaciones de car&aacute;cter confidencial de la otra como las que adopta habitualmente para sus propias informaciones de tal naturaleza. Tambi&eacute;n se obligan a comunicar tales informaciones, &uacute;nicamente, a los empleados o personas, que, debido a su posici&oacute;n y funci&oacute;n, necesiten conocerlas para poder realizar su trabajo. De cualquier modo, se les advertir&aacute; del car&aacute;cter confidencial de esas informaciones y su responsabilidad en caso de divulgarlas il&iacute;citamente.</p>
      <p>Las limitaciones establecidas en el apartado anterior no ser&aacute;n aplicables a las informaciones que: (i) sean regularmente conocidas sin tener car&aacute;cter confidencial o que estuvieran publicadas en la p&aacute;gina web del prestador sin estar restringido su acceso; (ii) que fueran de dominio p&uacute;blico o accesibles al p&uacute;blico con car&aacute;cter general, por un motivo diferente a la trasgresi&oacute;n de esta cl&aacute;usula; (iii) que se obtenga de un tercero que no est&eacute; obligado a guardar confidencialidad en relaci&oacute;n con la informaci&oacute;n; (iv) cuya revelaci&oacute;n por parte del receptor sea exigida legalmente.</p>
      <p>Las partes se comprometen a respetar las obligaciones que resultan de la presente estipulaci&oacute;n durante la vigencia del presente contrato, as&iacute; como durante los 2 a&ntilde;os siguientes a su terminaci&oacute;n o resoluci&oacute;n.</p>
      <ol start="23">
      <li><strong>23.- PROTECCI&Oacute;N DE DATOS</strong></li>
      </ol>
      <p>La informaci&oacute;n o datos de car&aacute;cter personal que el Usuario facilite al PRESTADOR&nbsp;en el curso de una transacci&oacute;n en el Sitio Web, ser&aacute;n tratados con arreglo a lo establecido en la Pol&iacute;tica de Privacidad o de Protecci&oacute;n de Datos (contenida, en su caso, en el Aviso Legal y Condiciones Generales de Uso). Al acceder, navegar y/o usar el Sitio Web el Usuario consiente el tratamiento de dicha informaci&oacute;n y datos y declara que toda la informaci&oacute;n o datos que facilita son veraces.</p>
      <ol start="24">
      <li><strong>24.- LEGISLACI&Oacute;N APLICABLE Y JURISDICCI&Oacute;N</strong></li>
      </ol>
      <p>El acceso, navegaci&oacute;n y/o uso de este Sitio Web y los contratos de compra de productos a trav&eacute;s de este se regir&aacute;n por la legislaci&oacute;n espa&ntilde;ola.</p>
      <p>Cualquier controversia, problema o desacuerdo que surja o est&eacute; relacionado con el acceso, navegaci&oacute;n y/o uso del Sitio Web, o con la interpretaci&oacute;n y ejecuci&oacute;n de estas Condiciones, o con los contratos de venta entre el PRESTADOR y el Usuario, ser&aacute; sometida a la jurisdicci&oacute;n no exclusiva de los juzgados y tribunales espa&ntilde;oles en Madrid.</p>
          
    </section>
  );
}

export default CondicionesGenerales;
