import "./BookList.scss";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getBooks, getMoreBooks, getBooksByCategory, getMoreBooksByCategory } from "../../../redux/actions/book"
import BookItem from "../BookItem/BookItem";
import Button from "../../DesingSystem/Button/Button";
import ModalBookDetail from "../ModalBookDetail/ModalBookDetail";
import { getApiBookBySlug } from "../../../services/apiBook";
import {types} from "../../../constants/constants";


function BookList(props) {
  const urlParams = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const books = useSelector((store) => store.book.books);
  const totalBooks = useSelector((store) => store.book.total);
  const [bookSlug, setBookSlug] = useState(null);
  const [limit, setLimit] = useState(types.PAGE_SIZE);
  const [offset, setOffset] = useState(0);
  const [modalDetailShow, setModalDetailShow] = useState(true);

  useEffect(() => {
    const slug = urlParams.slug
    if(slug){
      const results = getApiBookBySlug(slug)
      .then(async (response) => {
        setBookSlug(response)
      })
      .catch((error) => {
        console.log(error.message);
        setBookSlug(null)
      });
    }
  }, []);
  
  useEffect(() => {
    if(props.category != '' && typeof props.category !== 'undefined'){
      dispatch(getBooksByCategory({ limit: limit, offset: offset, sortDirection: -1, slug : urlParams.slug }))
    }else{
      dispatch(getBooks({ limit: limit, offset: offset, sortDirection: -1 }))
    }
  }, [dispatch]);


  const renderBooks = () => {
    return books.map((book, bookIndex) => {
      return <BookItem genreSize="xsmall" version="v3" book={book} key={book._id} index={bookIndex} />;
    });
  };

  const loadMoreResults = () => {
    const offsetPage = offset + limit;
    setOffset(offsetPage);

    if(props.category != '' && typeof props.category !== 'undefined'){
      dispatch(getMoreBooksByCategory({ limit: limit, offset: offsetPage, sortDirection: -1, slug : urlParams.slug }))
    }else{
      dispatch(getMoreBooks({ limit: limit, offset: offsetPage, sortDirection: -1 }))
    }
  }

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  return books ? (
    <div>
      <ul className="book-list">{renderBooks()}</ul>

      <div className="book-list__container-button">
        { totalBooks > books.length ?
          <Button className={'btn-blackbg'} text={'Cargar más títulos'} onClick={ () => { loadMoreResults() } } />
          : null }
      </div>

      <React.Fragment>
        {bookSlug ? (
          <ModalBookDetail
            book={bookSlug}
            show={modalDetailShow}
            hide={hideModalDetail}
          />
        ) : null}
      </React.Fragment>
    </div>
  ) : (
    <div className="row ">
      <div className="col">
        <p className="ms-5">No hay libros publicados</p>
      </div>
    </div>
  );
}

export default BookList;
