import "./CheckoutForm.scss";

import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import Button from "../DesingSystem/Button/Button";

/**
 * @param {Object} props
 * @param {string} [props.btnText]
 * @param {string} [props.btnClass]
 * @param {Function} [props.onCreateSale]
 * @param {Object} [props.confirmSetupConfig]
 * @param {Object} [props.confirmPaymentConfig]
 * @param {Function} [props.onSucceededPayment]
 * @param {string} [props.type]
 * @param {Function} [props.updatePaymentMethods]
 */
const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmButtonIcon, setConfirmButtonIcon] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  const options = {};

  const addPaymentMethod = async (event) => {
    event.preventDefault();

    setConfirmButtonDisabled(true);
    setConfirmButtonIcon('loaderIcon');

    if (stripe && elements) {
      try {
        const { error } = await stripe.confirmSetup({
          //`Elements` instance that was used to create the Payment Element
          elements,
          ...props.confirmSetupConfig
        });

        if (error) {
          setErrorMessage(error.message);
          setConfirmButtonDisabled(false);
          setConfirmButtonIcon('');
        } else {
          setConfirmButtonDisabled(false);;
          setConfirmButtonIcon('');
          if (props.updatePaymentMethods) {
            props.updatePaymentMethods();
          }
        }
      } catch (err) {
        setErrorMessage(err.message);
        setConfirmButtonDisabled(false);
        setConfirmButtonIcon('');
      }
    }
  };

  const processPayment = async (event) => {
    event.preventDefault();

    setConfirmButtonDisabled(true);
    setConfirmButtonIcon('loaderIcon');

    if (stripe && elements) {
      try {
        if (props.onCreateSale) {
          props.onCreateSale(elements._commonOptions.clientSecret.id);
        }

        const { error } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          ...props.confirmPaymentConfig,
        });

        if (error) {
          setErrorMessage(error.message);
          setConfirmButtonDisabled(false);
          setConfirmButtonIcon('');
        } else {
          if (props.onSucceededPayment) {
            props.onSucceededPayment();
          }
        }
      } catch (err) {
        setErrorMessage(err.message);
        setConfirmButtonDisabled(false);
        setConfirmButtonIcon('');
      }
    }
  };

  return (
    <form>
      <PaymentElement options={options} />
      {stripe ?
        <React.Fragment>
          {errorMessage !== '' ?
            <p className="m-0 mt-3 mb-3 error text-center">{errorMessage}</p>
            : null}

          {props.type === 'payment' ?
            <React.Fragment>
              <div className="row mt-5"></div>

              <Button
                text={`${props.btnText ? props.btnText : 'Guardar'}`}
                iconRight={confirmButtonIcon}
                disabled={confirmButtonDisabled}
                className={`${props.btnClass ? props.btnClass : 'btn-white'} m-auto mt-4`}
                onClick={processPayment}
              />
            </React.Fragment>
            :
            <Button
              text={`${props.btnText ? props.btnText : 'Guardar'}`}
              iconRight={confirmButtonIcon}
              disabled={confirmButtonDisabled}
              className={`${props.btnClass ? props.btnClass : 'btn-white'} m-auto mt-4`}
              onClick={addPaymentMethod}
            />
          }

        </React.Fragment>
        : null}
    </form>
  );
};

export default CheckoutForm;
