import "./FaqsPrivate.scss";

import { useNavigate } from "react-router-dom";
import { types } from "../../constants/constants";

import NameSection from "../../components/NameSection/NameSection";
import Faq from "../../components/aPublicArea/FaqsComponents/Faq/Faq";
import Button from "../../components/DesingSystem/Button/Button";
import React from "react";

function Faqs() {
  const navigate = useNavigate();
  const goToPrivate = () => {
    navigate("/contacto");
  };
  return (
    <section className="faqs-container">
      <NameSection name="FAQs" color="#f05a24" />

      <div className="faqs-items">
        <Faq
          question="¿Qué es Bookólicos?"
          answer={
            <div className="faqs-answer">
              Somos la primera plataforma/comunidad que conecta todos aquellos
              escritores y profesionales del sector literario con personas
              hambrientas de cultura y conocimiento. <br />
              Queremos que, como autor, monetices tu obra y, como lector,
              descubras títulos y autores cada día, formando parte de una
              comunidad literaria honesta y preocupada por mejorar la calidad
              del sector.
            </div>
          }
        />

        <Faq
          question="¿Para quién es Bookólicos?"
          answer={
            <div className="faqs-answer">
              Bookólicos nace como la primera plataforma digital (web)
              hispanoparlante para...
              <br />
              <ul>
                <li>
                  Personas que necesitan un aliado especialista en el canal
                  digital para dar recorrido a sus obras.
                </li>
                <li>
                  Personas que necesitan un aliado especialista en el canal
                  digital para captar talento y aumentar su volumen de
                  comercialización.
                </li>
                <li>
                  Personas que necesitan libros y referencias novedosas en
                  formato electrónico a un precio razonable.
                </li>
              </ul>
            </div>
          }
        />

        <Faq
          question="¿Qué objetivos perseguimos?"
          answer={
            <div className="faqs-answer">
              Nuestra intención es desarrollar una iniciativa viva de personas
              alrededor de tres conceptos: <br />
              <strong>Comunidad:</strong> Aunar en un solo entorno digital a
              todos los protagonistas de la ecuación literaria y crear un clima
              de concordia y colaboración.
              <br />
              <strong>Cultura:</strong> Establecer relaciones profesionales
              entre creadores, lectores, diseñadores, traductores, editores... a
              través de cursos, charlas o talleres que favorezcan la
              colaboración y el intercambio de experiencias e información de
              valor.
              <br />
              <strong>Innovación:</strong> Hemos creado un espacio expositivo
              para conectar autores (noveles y consagrados) y un marco digital
              que promueva la promoción, digitalización de catálogos y
              comercialización de obras en una comunidad virtual repleta de
              lectores, editoriales y profesionales del sector.
              <br />
              <strong>Responsabilidad social:</strong> Nuestra intención es
              aportar valor a nuestros miembros y al entorno (comercios locales
              no digitalizados, organizaciones...) donde nos encontramos
              impactando. Organizamos eventos benéficos y encuentros para
              promover el uso y consumo de la literatura digital (inclusión de
              mayores, promoción a jóvenes...).
              <br />
              <strong>Networking:</strong> Somos un espacio de trabajo abierto
              para nuestros socios con algunas prestaciones que les permitan
              teletrabajar, celebrar reuniones o hacer negocios con otros
              socios.
            </div>
          }
        />

        <Faq
          question="¿Quién forma el equipo de Bookólicos?"
          answer={
            <div className="faqs-answer">
              El proyecto lo fundamos Monse y Néstor. Puedes ampliar toda la
              información que quieras, visitando nuestra sección <br />{" "}
              <a
                className="book-info-tag__section-checkbox-link"
                href="/quienes-somos"
              >
                “Quiénes somos”
              </a>
              .
            </div>
          }
        />

        <Faq
          question={"¿Cómo leer un ebook comprado en Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Los ebook que compres en Bookólicos son archivos/ficheros
              compatibles con los principales lectores del mercado. Puedes
              descargarlos y guardarlos en tus sistemas de almacenamiento como
              consideres y no tienen restricción a la hora de utilizarse en uno
              o varios dispositivos electrónicos para lectura. <br />
              Para poder leer uno de nuestros ebooks solos necesitas tener un
              programa que permita la lectura en un formato estándar. ¿Con qué
              formato trabajamos? ePub: Es el formato más conocido y democrático
              para leer libros digitales, a diferencia del doc ó el PDF, tiene
              la capacidad de amoldarse a los diferentes tipos de pantalla,
              ajustando tamaños y formato de color.
            </div>
          }
        />

        <Faq
          question={
            "¿Qué permisos te solicitamos cuando te registras con Facebook ó Google?"
          }
          answer={
            <div className="faqs-answer">
              Cuando pulsas Login con Google, los permisos que pedimos son:
              <ul>
                <li>Tu nombre</li>
                <li>Tu dirección de correo electrónico</li>
                <li>Tu preferencia de idioma</li>
                <li>Tu foto de perfil</li>
              </ul>
              Cuando pulsas Login con Facebook, los permisos que pedimos son:
              <ul>
                <li>Tu nombre</li>
                <li>Tu dirección de correo electrónico</li>
                <li>Tu preferencia de idioma</li>
                <li>Tu foto de perfil</li>
              </ul>
              Si tienes alguna duda al respecto, puedes escribirnos a{" "}
              <a
                className="book-info-tag__section-checkbox-link"
                href="mailto: hola@bookolicos.com"
              >
                hola@bookolicos.com
              </a>
              <br />
              Si quieres modificar los permisos, puedes escribirnos y te
              ayudaremos a gestionarlo.
            </div>
          }
        />

        <Faq
          question={"¿Qué hace Bookólicos diferente?"}
          answer={
            <div className="faqs-answer">
              <strong>Reducción de costes:</strong> Reducimos las comisiones que
              se aplican actualmente a los autores por la venta de sus obras.
              <br />
              <strong>Mitigación del riesgo:</strong> Introducimos herramientas
              para mejorar la confianza del lector de cara a la compra y
              aportamos información totalmente transparente.
            </div>
          }
        />

        <Faq
          question={"¿Qué márgenes me cobra Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Los autores obtienen el { types.AUTHOR_FEE_PERCENTAGE }% sobre el precio de venta de cada libro
              digital. El restante { types.BOOKOLICOS_FEE_PERCENTAGE }%, va directamente a Bookólicos.
            </div>
          }
        />

        <Faq
          question={"¿Qué puedes hacer en Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Si eres un autor puedes publicar tus obras en la plataforma y
              ganar dinero por cada venta.
              <br />
              Proporcionamos conocimiento a los autores para garantizar la
              calidad de las obras que se publican.
              <br />
              Ofrecemos al lector libros y referencias emergentes en formato
              electrónico a un precio razonable
            </div>
          }
        />

        <Faq
          question={
            "¿Puedo distribuir libremente mis libros digitales comprados en Bookólicos?"
          }
          answer={
            <div className="faqs-answer">
              Los productos digitales que adquieres en Bookólicos están
              destinados para uso personal e intransferible. Nada te impide
              prestar tu ebook a amigos y/o conocidos. Sin embargo, abogamos por
              un consumo responsable, ya que nuestros precios son competitivos y
              nuestros productos son muy accesibles. <br />
              Nuestros productos incluyen información de Copyright en sus
              archivos, lo que nos permite rastrear aquellos casos en los que no
              se esté haciendo un uso razonable de los productos, como
              almacenarlos en plataformas tipo peer-to-peer. El objetivo de
              estas medidas es garantizar un seguimiento y protección adecuados
              de las obras de nuestros autores y reconocer su esfuerzo como
              profesionales.
            </div>
          }
        />

        <Faq
          question={
            "Soy periodista y me gustaría escribir sobre Bookólicos. ¿Cómo os contacto?"
          }
          answer={
            <div className="faqs-answer">
              Por favor escríbenos a{" "}
              <a
                className="book-info-tag__section-checkbox-link"
                href="mailto: hola@bookolicos.com"
              >
                hola@bookolicos.com
              </a>
            </div>
          }
        />

        <Faq
          question={"¿Cómo publicar un ebook en Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Para publicar un libro en Bookolicos, primero debes firmar y enviar el contrato de autor que recibiste al registrarte y completar tus datos bancarios en tu perfil para el cobro de tus obras. Luego, selecciona la opción "Publicar tu obra" en la parte superior derecha de la pantalla y sigue los pasos para completar el proceso de publicación.
              <br/>
              Es importante que tengas preparados el archivo en formato epub de tu obra, la sinopsis y la portada en formato jpeg. Durante el proceso, también se te pedirá que indiques el número de ISBN de tu obra. Si aún no tienes uno, en Bookolicos puedes obtenerlo seleccionando la opción "No tengo ISBN", y asignaremos uno a tu publicación.
              <br/>
              Una vez que completes el proceso, podrás encontrar tu obra en el catálogo de Bookolicos y en tu perfil.
              <br/>
              Los libros digitales que vendas en Bookólicos deben ser archivos/ficheros
              compatibles con los principales lectores del mercado. <br />
              Cuando estés realizando el proceso de subida de tu obra,
              asegúrate de utilizar el siguiente formato: <br />-{" "}
              <strong>ePub</strong>: Es el formato más conocido y democrático
              para leer libros digitales, a diferencia del doc o el PDF, tiene
              la capacidad de amoldarse a los diferentes tipos de pantalla,
              ajustando tamaños y formato de color. Igual que los PDFs tienen
              una extensión .pdf, los ePUB son .epub.
            </div>
          }
        />

        <Faq
          question={"¿Cuáles son los métodos de pago que admite Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Para poder ofrecerte un proceso de compra protegido y seguro, en Bookolicos utilizamos Stripe como plataforma de pagos por lo que puedes usar cualquier tarjeta de debito o crédito, sea Visa o Mastercard, para comprar las obras dentro de nuestra plataforma.
              <br/>
              Una vez hayas seleccionado la obra que quieres comprar, te mostraremos la pantalla de pago en donde tendrás que completar tu información bancaria.
              <br/>
              Para agilizar tus futuras compras, puedes guardar tus datos bancarios en tu perfil <i>(Tus datos - Datos generales - Método de pago - Añadir un método de pago)</i> o bien utilizar los medios de pago que tienes registrados en Google Pay desde Chrome o Apple Pay desde Safari.
            </div>
          }
        />

        <Faq
          question={"¿Cuál es el método de cobro que admite Bookólicos?"}
          answer={
            <div className="faqs-answer">
              Puedes cobrar las regalías de tus obras vendidas en Bookólicos siguiendo los siguientes pasos: <br />
              <ul>
                <li>Configura tu método de cobro. Para esto debes ir a tu perfil, en el apartado <strong>Tus datos – Datos generales - Método de cobro</strong> y añadir un método de cobro.</li>
                <li>El sistema te pedirá que te registres como autor o empresa. Por defecto, encontrarás la opción de autor. Recuerda rellenar todos los datos del formulario incluyendo el número de IBAN de la cuenta bancaria donde deseas que se depositen los abonos de tus obras vendidas.</li>
                <li>Puedes consultar los términos y condiciones de trabajo para este servicio a través del desplegable que hace referencia a nuestra pasarela de pago del servicio Stripe.</li>
              </ul>
            </div>
          }
        />
      </div>

      <div className="faqs">
        <div className="faqs__container-title">
          <div>
            <p className="faqs__title">¿Sigues con dudas?</p>
          </div>
          <div className="faqs__arrows">
            <Button
              className="btn-blackbg"
              text="Contáctanos"
              onClick={goToPrivate}
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default Faqs;
