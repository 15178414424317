import "./ComponentInfo.scss";

const ComponentInfo = (props) => {
  return (
    <section className="part-subtitle-why part-subtitle-why--white part-subtitle-why--no-mobile">
      <div className="part-subtitle-why__container-title">
        <p className="part-subtitle-why__title">
          {props.title}
        </p>
      </div>

      <div className="part-subtitle-why__container-subtitle">
        <p className="part-subtitle-why__text">
          {props.subtitle}
        </p>
      </div>
    </section>
  )
}

export default ComponentInfo;
