import React from "react";
import "./UploadCover.scss";
import Form from "react-bootstrap/Form";
import Button from "../../DesingSystem/Button/Button";
import Icon from "../../Icon/Icon";
import utils from "../../../services/utils";

function shortenFilename(filename, N) {
  if (filename.length <= N) {
    return filename;
  } else {
    const headLength = Math.floor((N - 3) / 2);
    const tailLength = N - 3 - headLength;
    return filename.substr(0, headLength) + "..." + filename.substr(-tailLength);
  }
}
function UploadCover(props) {
  const onChangeFile = (event) => {
    props.setBook({
      ...props.book,
      cover: event.target.files,
    });
  };



  const inputCoverFile = React.useRef();

  return (
    <section className="upload-cover">
      {props.book && (props.book.cover || props.book.picture_big) ? (
        <React.Fragment>
          <div className="upload-cover__cover-preview">
            <img
              src={
                props.book.cover
                  ? URL.createObjectURL(props.book.cover[0])
                  : props.book.picture_big
              }
            />
          </div>
          <div className="upload-cover__cover-info">
            <h3 className="upload-book__title ellipsis white">
              {props.book.cover && shortenFilename(props.book.cover[0].name, 20)}
            </h3>
            <p className="upload-book__text white">
              {props.book.cover && utils.getSize(props.book.cover[0].size)}
            </p>
            <Form.Group controlId="formFile" className="mb-3 upload-upload">
              <Form.Control
                type="file"
                className="upload-upload"
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                style={{ display: "none" }}
                ref={inputCoverFile}
              />
            </Form.Group>
            <div className="upload-cover__action">
              <Button
                className="btn-white-transparent"
                text="Cambiar portada"
                onClick={() => {
                  inputCoverFile.current.click();
                }}
              />
            </div>

            <p className="upload-cover__text white px-3 mt-4">Para garantizar que tu portada se visualiza correctamente te recomendamos que subas un archivo jpg ó png con 650px de alto x 500px de ancho. Max 5MB</p>
          </div>


        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="upload-cover__icon">
            <Icon name="file" size="medium" color="secondary-1" />
          </div>

          <div className="upload-cover__content">

            <h3 className="upload-cover__title">Sube la portada</h3>
            <p className="upload-cover__text px-5">Para garantizar que tu portada se visualiza correctamente te recomendamos que subas un archivo jpg ó png con 650px de alto x 500px de ancho.</p>
            <p className="upload-cover__text">Formato JPG/PNG. Max 5MB</p>
            {props.book && props.book.error && props.book.error.cover ? (
              <p className="upload-cover__text-error">{props.book.error.cover}</p>
            ) : null}
            <Form.Group controlId="formFile" className="mb-3 upload-upload">
              <Form.Control
                type="file"
                className="upload-upload"
                accept="image/png, image/jpeg"
                onChange={onChangeFile}
                style={{ display: "none" }}
                ref={inputCoverFile}
              />
            </Form.Group>
            <div className="upload-cover__action">
              <Button
                className="btn-white"
                text="Examinar"
                onClick={() => {
                  inputCoverFile.current.click();
                }}
              />
            </div>
          </div>
        </React.Fragment>
      )}

    </section>
  );
}

export default UploadCover;
