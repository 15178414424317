import "./ModalGenres.scss";
import Modal from "react-bootstrap/Modal";

import Genres from "../../Genres/Genres";

function ModalTags(props) {
  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-genres">
            <h3 className="modal-genres__title">Más Tags de </h3>
            <h4 className="modal-genres__author">"{props.title}"</h4>
            <Genres genres={props.genres} align="center" />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalTags;
