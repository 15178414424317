import "./ModalFilter.scss";

import Modal from "react-bootstrap/Modal";
import Icon from "../Icon/Icon";
import { useState } from "react";

const ModalFilter = ({ isOpen, onClose, onFilter, filters }) => {
  const [filtersSelected, setFiltersSelected] = useState([])

  const handleFilter = (filter) => {
    if (filtersSelected.includes(filter)) {
      setFiltersSelected(filtersSelected.filter(f => f !== filter))
    } else {
      setFiltersSelected([...filtersSelected, filter])
    }
  }

  const deleteFilters = () => {
    setFiltersSelected([])
  }

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={isOpen}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-filter__container"
      >
        <Modal.Body>
          <div className="modal-filter">
            <div className="modal-filter__header">
              <div className="modal-filter__close" onClick={onClose}>
                <Icon name="close" color="secondary-1" size="medium" />
              </div>
              <div className="modal-filter__title">Filtros</div>
            </div>
            <div className="modal-filter__content">
              {filters.map((filter, index) => (
                <div className="modal-filter__filter" key={index}>
                  <div className="modal-filter__filter-title">{filter.title}</div>
                  <div className="modal-filter__filter-items">
                    {filter.items.map((item, index) => (
                      <div
                        className={`modal-filter__filter-item` + (filtersSelected.includes(item) ? ' modal-filter__filter-item--selected' : '')}
                        key={index}
                        onClick={() => handleFilter(item)}
                      >
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
            <div className="modal-filter__footer">
              <div className="modal-filter__btn">
                <button className="btn btn-simple" onClick={deleteFilters}>
                  <span>Quitar filtros</span>
                </button>
              </div>
              <div className="modal-filter__btn">
                <button className="btn btn-orangebg" onClick={() => onFilter(filtersSelected)}>
                  <span>Aplicar filtros</span>
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ModalFilter;
