import React from "react";
import PropTypes from "prop-types";
import Icon from "./../../Icon/Icon";
import "./InputText.scss";

const InputText = ({
  type,
  label,
  placeholder,
  icon,
  iconSize,
  iconColor,
  error,
  active,
  disabled,
  iconBackground,
  iconBorder,
  iconClick,
  inputBackground,
  placeholderPadding,
  iconPosition,
  groupRight,
  size,
  ...props
}) => {
  const inputClasses = `input-text ${error ? "error" : ""} ${active ? "active" : ""} ${size ? `input-text--${size}` : ""}`;
  const iconClasses = `input-icon ${icon ? "show" : ""}`;

  const handleIconClick = (event) => {
    props.handleIconClick(event);
  }

  return (
<div className="input-container">
    {label && <label className="input-container__label">{label}</label>}
    <div className={`input-wrapper ${iconPosition === "right" ? "icon-right" : "icon-left"} ${error ? "error" : ""}`}>
      <input
        {...props}
        type={type}
        placeholder={placeholder}
        className={`${inputClasses} ${error ? "error" : ""}  ${inputBackground || ''} `}
        disabled={disabled}
        style={{ padding: placeholderPadding }}
      />
      {icon && !groupRight && (
        <span className={iconClasses} onClick={handleIconClick}>
          { iconClick ?
            <Icon name={icon} size={iconSize} color={iconColor} border={iconBorder} background={iconBackground}  onClick={iconClick} />
          :
            <Icon name={icon} size={iconSize} color={iconColor} border={iconBorder} background={iconBackground} />
          }
        </span>
      )}
      {groupRight && icon && (
        <span className="input__right-item">
          <Icon name={icon} size={iconSize} color={iconColor} border={iconBorder} background={iconBackground} />
        </span>
      )}
    </div>
  </div>
  );
};
InputText.propTypes = {
  type: PropTypes.oneOf(["text", "email", "password", "number", "tel"]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  icon: PropTypes.string,
  iconSize: PropTypes.oneOf(['small', 'medium', 'large']),
  iconColor:  PropTypes.oneOf(['principal', 'secondary-6', 'secondary-4']),
  iconBackground: PropTypes.oneOf(['4', '']),
  error: PropTypes.bool,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  inputBackground: PropTypes.string,
  iconPosition: PropTypes.oneOf(["left", "right"]),
  groupRight: PropTypes.bool
};

InputText.defaultProps = {
  iconSize: "medium",
  iconColor: "principal",
  iconBorder: null,
  iconBackground: null
};

export default InputText;
