import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../hooks/use-is-public";
import { getUserById } from "../../../redux/actions/user";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import ModalBuy from "../../ModalBuy/ModalBuy";
import "./BuyBook.scss";
import { postApiCreateSale } from '../../../services/apiSale';

/**
 * @param {Object} props
 * @param {Object} props.book
 */
function BuyBook(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [modalBuyBook, setModalBuyBook] = useState(false);
  const isPublic = useIsPublic();
  const user = useSelector((store) => store.user.user);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if (tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    }
  }, []);

  const showModalTags = () => {
    setModalBuyBook(true);
  };

  const hideModalTags = () => {
    setModalBuyBook(false);
  };
  const goToLogin = () => {
    navigate("/login");
  };

  const createSale = async (payment_intent) => {
    await postApiCreateSale({
      sale: {
        amount: props.book.price * 100,
        seller: props.book.user._id,
        customer: user._id,
        book: props.book._id,
        payment_intent,
        status: 0
      }
    });
  };

  return (
    <>
      <div className="buy">
        <p className="buy__price">{props.book.price.toString().replace('.', ',')}€</p>

        {!user ? (
          <button className="buy__button" onClick={goToLogin}>
            Comprar
          </button>
        ) : (
          <button className="buy__button" onClick={showModalTags}>
            Comprar
          </button>
        )}
      </div>
      {isPublic ?
        null : (
          <ModalBuy
            show={modalBuyBook}
            price={props.book.price}
            seller={props.book.user}
            onHide={hideModalTags}
            onCreateSale={createSale}
            onPaymentSuccess={
              response => navigate(`/confirmacion-venta?payment_intent=${response.paymentIntent.id}&payment_intent_client_secret=${response.paymentIntent.client_secret}&redirect_status=succeeded`)
            }
          />
        )}
    </>
  );
}

export default BuyBook;
