import "./BookInfoTags.scss";
import React, { useEffect, useState } from "react";
import InputText from "../../DesingSystem/InputText/InputText";
import CheckBox from "../../DesingSystem/CheckBox/CheckBox";
import Button from "../../DesingSystem/Button/Button";
import ModalTags from "../ModalTags/ModalTags";
import Genres from "../../Genres/Genres";
import Select from "../../DesingSystem/Select/Select";

function BookInfoTag(props) {
  const [modalTags, setModalTags] = useState(false);
  const [isPriceEntered, setIsPriceEntered] = useState(false);
  const [isPagesEntered, setIsPagesEntered] = useState(false);
  const [optionsLanguages, setOptionsLanguages] = useState({});
  const [isLanguageEntered, setIsLanguageEntered] = useState(false);

  useEffect(() => {
    setOptionsLanguages({
      'Alemán' : 'Alemán',
      'Albanés' : 'Albanés',
      'Árabe' : 'Árabe',
      'Armenio' : 'Armenio',
      'Bosnio' : 'Bosnio',
      'Búlgaro' : 'Búlgaro',
      'Checo' : 'Checo',
      'Chino' : 'Chino',
      'Croata' : 'Croata',
      'Castellano' : 'Castellano',
      'Catalán' : 'Catalán',
      'Coreano' : 'Coreano',
      'Danés' : 'Danés',
      'Escocés' : 'Escocés',
      'Euskera' : 'Euskera',
      'Finés' : 'Finés',
      'Francés' : 'Francés',
      'Gallego' : 'Gallego',
      'Griego' : 'Griego',
      'Húngaro' : 'Húngaro',
      'Inglés' : 'Inglés',
      'Irlandés' : 'Irlandés',
      'Islandés' : 'Islandés',
      'Italiano' : 'Italiano',
      'Japonés' : 'Japonés',
      'Neerlandés' : 'Neerlandés',
      'Noruego' : 'Noruego',
      'Polaco' : 'Polaco',
      'Portugués' : 'Portugués',
      'Rumano' : 'Rumano',
      'Ruso' : 'Ruso',
      'Serbio' : 'Serbio',
      'Sueco' : 'Sueco',
      'Turco' : 'Turco',
      'Ucraniano' : 'Ucraniano',
    })
  }, []);

  const handleCheckboxChange = (event) => {
    props.setBook({
      ...props.book,
      acceptPolicy: event.target.checked,
    });
  };

  const showModalTags = () => {
    setModalTags(true);
  };

  const hideModalTags = () => {
    setModalTags(false);
  };

  const onChangePrice = (event) => {
    props.setBook({
      ...props.book,
      price: event.target.value,
    });
  };

  const onChangePageNumber = (event) => {
    props.setBook({
      ...props.book,
      page_number: event.target.value,
    });
  };

  const onChangeLanguage = (event) => {
    props.setBook({
      ...props.book,
      language: event.target.value,
    });
  };

  const handleInputPriceFocus = () => {
    setIsPriceEntered(true);
  };

  const handleInputPriceBlur = () => {
    setIsPriceEntered(false);
  };

  const handleInputPagesFocus = () => {
    setIsPagesEntered(true);
  };

  const handleInputPagesBlur = () => {
    setIsPagesEntered(false);
  };

  const handleInputLanguageFocus = () => {
    setIsLanguageEntered(true);
  };

  const handleInputLanguageBlur = () => {
    setIsLanguageEntered(false);
  };

  return (
    <section className="book-info-tag">
      <div>
        <p className="book-info-tag__label">Precio de la Obra</p>
        <InputText
          error={isPriceEntered}
          onFocus={handleInputPriceFocus}
          onBlur={handleInputPriceBlur}
          type="number"
          id="price"
          name="price"
          placeholder="Introduce el precio de tu obra"
          required
          value={props.book && props.book.price ? props.book.price : ""}
          onChange={onChangePrice}
          iconPosition="right"
          icon="euro"
          iconColor="secondary-4"
          iconSize="small"
          groupRight
        />
        {props.book && props.book.error && props.book.error.price ? (
          <p className="m-0 mt-4 error text-center">{props.book.error.price}</p>
        ) : null}

        <p className="book-info-tag__label">Idioma</p>
        <Select
          id="companyType"
          selectedValue={ props.book && props.book.language ? props.book.language : "" }
          defaultValue={'Selecciona el idioma'}
          options={optionsLanguages}
          onSelect={onChangeLanguage}
          onChange={onChangeLanguage}>
        </Select>

        {props.book && props.book.error && props.book.error.language ? (
          <p className="m-0 mt-4 error text-center">
            {props.book.error.language}
          </p>
        ) : null}

        <p className="book-info-tag__label mb-2">Género y categorias</p>
        <p>{props.book && props.book.genre ? props.book.genre.tag : '' }</p>
        <React.Fragment>
          { props.book && props.book.tags && props.book.tags.length ? (
          <Genres className="mb-4" genres={props.book.tags} align="left" isNavegable={false} />
          ) : null }
          <Button
            iconLeft="pencil"
            text={props.book && props.book.tags && props.book.tags.length
              ? "Editar"
              : "Añadir"}
            onClick={showModalTags}
          />
        </React.Fragment>
      </div>
      <div>
        <div className="book-info-tag__section-checkbox">
          <CheckBox
            id="policy"
            name="policy"
            onChange={handleCheckboxChange}
            checked={props.book.acceptPolicy === true ? true : false}
          />

          <p className="book-info-tag__section-checkbox-text">
            Declaro que mi obra es completamente original y que tengo plena
            titularidad de los derechos de propiedad intelectual sobre la misma.
            <a
              className="book-info-tag__section-checkbox-link"
              href="https://bookolicos.com/files/CONTRATO_DE_EDICION_BOOKOLICOS.docx"
              target="_blank"
            >
              Ver condiciones de publicación
            </a>
          </p>

          {props.book && props.book.error && props.book.error.acceptPolicy ? (
            <p className="m-0 mt-4 error text-center">
              {props.book.error.acceptPolicy}
            </p>
          ) : null}
        </div>
      </div>
      <ModalTags
        show={modalTags}
        onHide={hideModalTags}
        book={props.book}
        setBook={props.setBook}
      />
    </section>
  );
}

export default BookInfoTag;
