import "./LoginGoogle.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { doGoogleLogin } from "../../../redux/actions/user";
import { useGoogleLogin } from "@react-oauth/google";
import { Navigate } from "react-router-dom";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import Icon from "../../../components/Icon/Icon"


function LoginGoogle() {
  const dispatch = useDispatch();
  const [token, setToken] = useState(ls.get("token"));

  const onSuccess = async (tokenResponse) => {
    dispatch(doGoogleLogin(tokenResponse.access_token)).then(async ()=>{
      setToken(await ls.get('token'))
    })
  };

  const onError = () => {
    console.log("Login Failed");
  };

  const login = useGoogleLogin({
    onSuccess: onSuccess,
    onError: onError,
  });

  return validateToken.validate(token) ? (
    <Navigate to="/" replace={true} />
  ) : (
    <button className="button" onClick={login}>
      <Icon
        name="google"
        size="medium"
        color="secondary-1"
      ></Icon>
      Continúa con Google
    </button>
  );
}

export default LoginGoogle;
