import "./Summary.scss";

import Genres from "../../Genres/Genres";

function Summary(props) {

  const isbnSelected = () => {
    switch (props.book.isbn) {
      case "PAID":
        return "Solicitado (10€)";
      case "OWN":
        return props.book.isbnOwn;
      case "NONE":
        return "Sin código";
      case "WON":
        return "He ganado un ISBN";
    }
  }

  return (
    <section className="summary">
      <h2 className="summary__name-book">{props.book.title}</h2>
      <h2 className="summary__subtitle-book">{props.book.subtitle}</h2>
      <div className="summary__container">
        <h3 className="summary__title">Precio</h3>
        <p className="summary__price">{`${props.book.price} €`}</p>
      </div>
      {props.book && props.book.isbn ? (
        <div className="summary__container">
          <h3 className="summary__title">Código ISBN</h3>
          <p className="summary__isbn">{isbnSelected()}</p>
        </div>
      ) : null}
      <div className="summary__container">
        <h3 className="summary__title">Idioma</h3>
        <p className="summary__price">{props.book.language ? props.book.language : '--'}</p>
      </div>
      <div className="summary__container-summary">
        <h3 className="summary__title">Resumen</h3>
        <p className="summary__summary">{props.book.sinopsis}</p>
      </div>
      <div className="summary__container-summary">
        <h3 className="summary__title">Género</h3>
        <p className="summary__summary">{props.book.genre.tag}</p>
      </div>
      <div className="summary__container-summary">
        <h3 className="summary__title">Tags</h3>
        {props.book && props.book.tags && props.book.tags.length ? (
          <Genres genres={props.book.tags} align="left" />
        ) : null}
      </div>
    </section>
  );
}
export default Summary;
