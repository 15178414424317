import './GetYourISBN.scss';

import Button from '../../components/DesingSystem/Button/Button'
import BannerPublic from '../../components/aPublicArea/BannerPublic/BannerPublic'
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";

const GetYourISBN = () => {
  const formUrl = "https://z549vgcwsc4.typeform.com/to/Kj3EgQWc?typeform-source=admin.typeform.com";
  const navigate = useNavigate();
  const sliderSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    elementType: "ol"
  }
  const sliderHowItWorksSettings = {
    className: "get-your-title__steps",
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ]
  }
  const sliderLegalBasesSettings = {
    className: "get-your-title__legal-bases",
    slidesToShow: 3,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2.2,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
        }
      }
    ]
  }
  const steps = [
    {
      number: '01',
      title: 'Completa el formulario',
      description: 'A continuación, encontrarás el formulario para proporcionar los datos necesarios y así conocer el título de tu obra y poder generar el ISBN. ¡Esperamos con emoción conocer tu trabajo literario!'
    },
    {
      number: '02',
      title: 'Regístrate en Bookólicos',
      description: 'Si todavía no estás registrado, ¿a qué estas esperando? Entra en la plataforma y registrate. De esta forma podremos comprobar que los datos son correctos y que formas parte de la comunidad Bookólica.'
    },
    {
      number: '03',
      title: 'Espera nuestra respuesta...',
      description: 'Nuestro equipo gestionará tu solicitud y se pondrá en contacto para completar el proceso y facilitarte la información relevante acerca del ISBN de tu obra.'
    },
    {
      number: '04',
      title: 'Sube tu obra y utiliza tu ISBN',
      description: 'Entra a tu cuenta de Bookólicos y sube tu obra. A la hora de pedirte el ISBN selecciona la opción “Ganador de ISBN”. Nuestro equipo le asignará a tu obra su código luego de corroborar tus datos. (Importante: antes de participar, lee bien todas las bases legales)'
    }
  ]

  const legalBases = [
    {
      number: '01',
      text: 'La presente promoción está limitada a un sólo código ISBN por autor y obra en formato digital e-pub. En caso de que el autor posea un código ISBN para su obra en dicho formato tiene la opción de publicarlo en la plataforma con dicho código.'
    },
    {
      number: '02',
      text: 'El código ISBN quedará asociado a la obra que el autor indique en el formulario de inscripción, no pudiendo asociarse de ninguna manera a otra obra.'
    },
    {
      number: '03',
      text: 'La obra para la que se solicita el código ISBN debe publicarse en la plataforma Bookólicos y el autor se compromete a tener la obra publicada en dicha plataforma por un período no inferior a seis meses.'
    },
  ]

  return (
    <>
      <section className='get-your-title__container-bg'>
        <section className='get-your-title__container'>
          <h1 className='get-your-title__title'>Consigue tu primer ISBN digital con <span>Book</span>ólicos</h1>
          <p className='get-your-title__subtitle'>
            Para tu primera obra publicada en Bookólicos te queremos regalar el ISBN,
            porque juntos llegamos más lejos
          </p>
          <Button text='¡Consigue tu ISBN!' className='btn-blackbg' onClick={() => { window.location.href = formUrl }} />
        </section>
      </section>
      <section className='get-your-title__section visible'>
        <div className='get-your-title__section-container'>
          <h2 className='get-your-title__section-title'>Cómo funciona</h2>
          <Slider {...{...sliderSettings, ...sliderHowItWorksSettings}}>
            {steps.map((step, index) => (
              <li className='get-your-title__steps-item'>
                <span>{step.number}</span>
                <h3>{step.title}</h3>
                <p>
                  {step.description}
                </p>
              </li>
            ))}
          </Slider>
        </div>
      </section>
      <section className='get-your-title__CTA'>
        <div className='get-your-title__container'>
          <h2 className='get-your-title__CTA-title'>¡Consigue tu Código ISBN digital!</h2>
          <p className='get-your-title__CTA-subtitle'>
            Completa el formulario y nuestro equipo se pondrá en contacto para comenzar la gestión de tu ISBN.
          </p>
          <Button text='Empieza el formulario' className='btn-orangebg' onClick={() => { window.location.href = formUrl }} />
        </div>
      </section>
      <section className='get-your-title__section--darker'>
        <div className='get-your-title__section'>
          <div className='get-your-title__section-container'>
            <h2 className='get-your-title__section-title'>Bases legales</h2>
            <p className='get-your-title__section-subtitle'>
              Antes de participar en el sorteo, es muy importante leer detalladamente los términos y condiciones y aceptarlos. Aquí te dejamos algunos, pero te recomendamos que los leas todos.
            </p>
            <Slider {...{...sliderSettings, ...sliderLegalBasesSettings}}>
              {legalBases.map((base, index) => (
                <li className='get-your-title__legal-bases-item'>
                  <span>{base.number}</span>
                  <p>{base.text}</p>
                  <Button text='Leer más' iconRight='chevronRight' className='btn-simple' onClick={() => navigate('/bases-legales')} />
                </li>
              ))}
            </Slider>
          </div>
        </div>
        <BannerPublic />
      </section>
    </>
  )
}

export default GetYourISBN
