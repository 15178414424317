import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button";
import "./DownloadBookModal.scss";

export default function DownloadBookModal({ show, book, onDownload, onHide }) {
  return (
    <div onClick={(e) => e && e.stopPropagation()} className="download-book-modal">
      <Modal
        show={show}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div>
            <p className="download-book-modal__title">{book.title}</p>
            <Button
              onClick={onDownload}
              className="download-book-modal__btn"
              text="Descargar obra"
            />
            <Button
              onClick={onHide}
              text="Cancel"
              className="btn btn-orangebg"
            />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}
