import "./Menu.scss";
import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Icon from "../../Icon/Icon";
import ls from "../../../services/localStorage";
import LogOff from "../LogOff/LogOff";

function Menu() {
  const navigate = useNavigate();
  const [isModalLogOffVisible, setIsModalLogOffVisible] = useState(false);

  const acceptModalLogOff = () => {
    ls.remove("token");
    navigate("/");
  }

  const hideModalLogOff = () => {
    setIsModalLogOffVisible(false);
  }

  const showModalLogOff = () => {
    setIsModalLogOffVisible(true);
  }

  return (
    <React.Fragment>
      <div className="menu">
        <h2 className="menu__section no_underline">Tus datos</h2>
        <div className="menu__section-container">
          <NavLink
            className={({ isActive }) =>
              `menu__sub-section ${
                isActive ? "menu--active" : "menu--inactive"
              }`
            }
            to="/profile/datos-generales"
          >
            Datos generales
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `menu__sub-section ${
                isActive ? "menu--active" : "menu--inactive"
              }`
            }
            to="/profile/perfil-autor"
          >
            Perfil autor
          </NavLink>
          <NavLink
            className={({ isActive }) =>
              `menu__sub-section ${
                isActive ? "menu--active" : "menu--inactive"
              }`
            }
            to="/profile/cuenta"
          >
            Cuenta
          </NavLink>
        </div>
        <NavLink
          className={({ isActive }) =>
            `menu__section ${isActive ? "menu--active" : "menu--inactive"}`
          }
          to="/profile/publicaciones"
        >
          Publicaciones
        </NavLink>
        <NavLink
          className={({ isActive }) =>
            `menu__section ${isActive ? "menu--active" : "menu--inactive"}`
          }
          to="/profile/ventas"
        >
          Ventas
        </NavLink>
        <h2 className="menu__section no_underline">Tus compras</h2>
          <NavLink
            className={({ isActive }) =>
              `menu__sub-section ${isActive ? "menu--active" : "menu--inactive"}`
            }
            to="/profile/tus-obras"
          >
            Tus obras
          </NavLink>

          {/*<NavLink
            className={({ isActive }) =>
              `menu__sub-section ${isActive ? "menu--active" : "menu--inactive"}`
            }
            to="/profile/tus-cursos"
          >
            Tus cursos
          </NavLink>*/}
      </div>

      <div className="mt-4">
        <a
          onClick={() => showModalLogOff()}
          className="d-flex align-items-center text-decoration-none logout"
        >
          <Icon name="logout" size="medium" className={"me-2"} />
          <span>Cerrar sesión</span>
        </a>
      </div>
      <LogOff show={isModalLogOffVisible} onAccept={acceptModalLogOff} onHide={hideModalLogOff} />
    </React.Fragment>
  );
}

export default Menu;
