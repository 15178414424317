import "./LandingCarousel.scss";

import PropTypes from "prop-types";

import { ReactComponent as ArrowDiagonal } from "../../styles/icons/arrow-diagonal.svg";
import { Link } from 'react-router-dom';

/**
 * @param {Object} props
 * @param {Object[]} props.services
 * @param {string} props.services[].name
 * @param {string} props.services[].link
 */
const LandingCarousel = ({ services }) => {
  return (
    <div className="landing-carousel">
      <h1>Más servicios</h1>
      <ul>
        {services.map(({ name, link }) => (
          <li key={link}>
            <Link to={link} className="landing-carousel__link">
              <span>{name}</span>
              <ArrowDiagonal />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};
LandingCarousel.propTypes = {
  services: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string,
      link: PropTypes.string
    })
  )
};

export default LandingCarousel;
