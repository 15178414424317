import "./LandingHero.scss";
import PropTypes from "prop-types";

/**
 * @param {Object} props
 * @param {string} props.description
 * @param {string} props.summary
 */
const LandingHero = ({ description, summary }) => {
  return (
    <div className="landing-hero">
      <div className="landing-hero__description">
        <div>{description}</div>
        <div>
          <p>¿Qué obtendrás?</p>
          <p>{summary}</p>
        </div>
      </div>
      <div className="landing-hero__price">
        <span>Precio por el servicio</span>
        <h1>Pide presupuesto</h1>
        <span>Pensamos que cada escritor y cada manuscrito son únicos, por ello te haremos una propuesta que se ajuste a tus necesidades</span>
      </div>
    </div>
  );
};
LandingHero.propTypes = {
  description: PropTypes.string,
  summary: PropTypes.string,
  price: PropTypes.string
};

export default LandingHero;
