import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const getApiEvents = (attributes) => {
  const esc = encodeURIComponent;
  const query = Object.keys(attributes) ? '?' + Object.keys(attributes).map(k => `${esc(k)}=${esc(attributes[k])}`).join('&') : ''
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}events${query}`, { 
    headers : { token : ( token !== null ? token : '') },
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    const result = await response.json()

    return result;
  })
};



export const postApiEventProposal = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}event/proposal`, {
    method: "POST",
    headers: {
      token : ( token !== null ? token : ''),
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  }); 
};