import "./GenresAuthor.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getApiCategories } from "../../../services/apiCategories";
import Genres from "../../../components/Genres/Genres";

function GenresAuthor(props) {
  const navigate = useNavigate();
  const [genres, setGenres] = useState([]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = (genre) => {
    navigate("/book-list-genre");
  };

  return (
    <section className="genres-author">
      <p className="genres-author__title">Géneros que escribe</p>
      <Genres className="genres-author__genres" genres={props.genres} align="left" onClick={tagClick} size="medium" />
    </section>
  );
}

export default GenresAuthor;
