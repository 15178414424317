import React from 'react';
import "./UploadBook.scss";
import Form from "react-bootstrap/Form";
import Button from "../../DesingSystem/Button/Button";
import Icon from "../../Icon/Icon";
import utils from "../../../services/utils"

function UploadBook(props) {
  const onChangeFile = (event) => {
    props.setBook({
      ...props.book,
      files: event.target.files,
    });
  };

  const inputBookFile = React.useRef()

  return (
    <section className="upload-book">
      <div className="upload-book__icon">
        <Icon
          name="file"
          size="medium"
          color="secondary-1"/>
      </div>
      { props.book && props.book.files ?
        <div className="upload-book__content">
          <h3 className="upload-book__title">{ props.book.files[0].name }</h3>
          <p className="upload-book__text">{ utils.getSize(props.book.files[0].size) }</p>
          <Form.Group controlId="formFile" className="mb-3 upload-upload">
            <Form.Control
              type="file"
              className="upload-upload"
              accept=".epub,.mobi"
              onChange={onChangeFile}
              style={{display:'none'}}
              ref={inputBookFile}
            />
          </Form.Group>

          <div className="upload-book__action">
            <Button
              className="btn-white"
              text="Cambiar archivo"
              onClick={()=>{ inputBookFile.current.click() }} />
          </div>
        </div>
      :
        <div className="upload-book__content">
          <h3 className="upload-book__title">Sube tu obra</h3>
          <p className="upload-book__text">Formato Epub. Max 100MB</p>

          { props.book && props.book.error && props.book.error.file ?
            <p className="upload-book__text-error">{ props.book.error.file }</p>
            : null }
          <Form.Group controlId="formFile" className="mb-3 upload-upload">
            <Form.Control
              type="file"
              className="upload-upload"
              accept=".epub,.mobi"
              onChange={onChangeFile}
              style={{display:'none'}}
              ref={inputBookFile}
            />
          </Form.Group>

          <div className="upload-book__action">
            <Button
            className="btn-white"
            text="Examinar"
            onClick={()=>{ inputBookFile.current.click() }} />
          </div>
        </div>
      }

    </section>
  );
}

export default UploadBook;
