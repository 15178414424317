import "./ModalEditBook.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Icon from "../../Icon/Icon";
import Button from "../../DesingSystem/Button/Button";

function ModalPublishBook(props) {
  const navigate = useNavigate();

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-publish-book-container"
      >
        <Modal.Body>
          <div className="modal-edit-book">
            <Icon
              name="tick"
              size="large"
              color="principal-border-transparent"
            />
            <h2 className="modal-publish-book__title">¡Enhorabuena!</h2>
            <p className="modal-publish-book__subtitle">Tu obra se ha editado con éxito</p>
            <p className="modal-publish-book__text">
              Recuerda que puedes editarla cuando quieras, entrando a la seccion
              de "Mis publicaciones" dentro de tu perfil.
            </p>
            <div className="modal-edit-book__buttons">
              <div className="modal-edit-book__btn">
                <Button
                  className={"btn-white-transparent"}
                  text={"Ir a Mis publicaciones"}
                  onClick={() => {
                    navigate("/profile/publicaciones");
                  }}
                />
              </div>
              <div className="modal-edit-book__btn">
                <Button
                  className={"btn-orangebg"}
                  iconRight="arrowRight"
                  text={"Ir a la home"}
                  onClick={() => {
                    navigate("/");
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPublishBook;
