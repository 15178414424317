import "./LandingForm.scss";

import PropTypes from "prop-types";

import { ReactComponent as ServicesImage } from "../../styles/images/services.svg";
import InputText from '../DesingSystem/InputText/InputText';
import Button from '../DesingSystem/Button/Button';
import { useState } from 'react';
import { postApiProcessForm } from "../../services/apiService";
import ModalFormSuccess from '../FormContainer/ModalFormSuccess';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {string} props.description
 * @param {string} props.service
 */
const LandingForm = ({ title, description, service }) => {
  const INITIAL_FORM_STATE = {
    firstname: '',
    lastname: '',
    email: '',
    comments: '',
    service
  };

  const [modalOpen, setModalOpen] = useState(false);

  const [formData, setFormData] = useState(INITIAL_FORM_STATE);

  const [formErrors, setFormErrors] = useState({
    firstname: '',
    lastname: '',
    email: ''
  });

  const [firstnameIsActive, setFirstnameIsActive] = useState(false);
  const [lastnameIsActive, setLastnameIsActive] = useState(false);
  const [emailIsActive, setEmailIsActive] = useState(false);
  const [commentsIsActive, setCommentsIsActive] = useState(false);

  const updateField = (field, ev) => {
    const newFormData = {};
    newFormData[field] = ev.target.value;
    setFormData(formData => ({ ...formData, ...newFormData }));
    setFormErrors(formErrors => ({ ...formErrors, [field]: '' }));
  };

  const sendForm = async () => {
    const errors = formErrors;
    let hasError = false;

    if (!formData.firstname) {
      errors.firstname = 'El nombre es mandatorio!';
      hasError = true;
    }

    if (!formData.lastname) {
      errors.lastname = 'Los apellidos son mandatorios!';
      hasError = true;
    }

    if (!formData.email) {
      errors.email = 'El correo es mandatorio!';
      hasError = true;
    } else if (formData.email.indexOf('@') === -1) {
      errors.email = 'El correo es invalido!';
      hasError = true;
    }

    if (hasError) {
      setFormErrors({ ...errors });
    } else {
      await postApiProcessForm(formData);
      setFormData(INITIAL_FORM_STATE);
      setModalOpen(true);
    }
  };

  return (
    <div className="landing-form">
      <div className="landing-form__container">
        <div className="landing-form__wrapper">
          <div className="landing-form__title">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
          <div className="landing-form__form">
            <div className="landing-form__names">
              <div>
                <label>Nombre*</label>
                <InputText
                  error={formErrors.firstname !== ''}
                  active={firstnameIsActive}
                  value={formData.firstname}
                  onChange={ev => updateField('firstname', ev)}
                  type="text"
                  id="firstname"
                  name="firstname"
                  placeholder="Ingresa tu nombre"
                  required
                  onFocus={() => setFirstnameIsActive(true)}
                  onBlur={() => setFirstnameIsActive(false)}
                />
              </div>
              <div>
                <label>Apellidos*</label>
                <InputText
                  error={formErrors.lastname !== ''}
                  active={lastnameIsActive}
                  value={formData.lastname}
                  onChange={ev => updateField('lastname', ev)}
                  type="text"
                  id="lastname"
                  name="lastname"
                  placeholder="Ingresa tus apellidos"
                  required
                  onFocus={() => setLastnameIsActive(true)}
                  onBlur={() => setLastnameIsActive(false)}
                />
              </div>
            </div>
            <div>
              <label>Correo electrónico*</label>
              <InputText
                error={formErrors.email !== ''}
                active={emailIsActive}
                value={formData.email}
                onChange={ev => updateField('email', ev)}
                type="text"
                id="email"
                name="email"
                placeholder="Ingresa tu correo electrónico"
                required
                onFocus={() => setEmailIsActive(true)}
                onBlur={() => setEmailIsActive(false)}
              />
            </div>
            <div>
              <label>¿Algo más que quieras comentar?</label>
              <textarea
                className={`${commentsIsActive ? "active" : ""}`}
                value={formData.comments}
                onChange={ev => updateField('comments', ev)}
                rows={3}
                id="comments"
                name="comments"
                placeholder="Escribe cualquier duda y/o mensaje que tengas para nosotros..."
                required
                onFocus={() => setCommentsIsActive(true)}
                onBlur={() => setCommentsIsActive(false)}
              />
            </div>
          </div>
          <Button
            className="button btn-orangebg big"
            text="Enviar formulario"
            onClick={sendForm}
          />
        </div>
        <ServicesImage />
      </div>
      <ModalFormSuccess
        isOpen={modalOpen}
        buttonOnClick={() => setModalOpen(false)}
        title="Formulario enviado"
        content="Hemos recibido tu pedido"
        buttonText="Cerrar"
      />
    </div>
  );
};
LandingForm.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  service: PropTypes.string
};

export default LandingForm;
