import "./CourseForm.scss";
import FormContainer from "../../components/FormContainer/FormContainer";
import InputText from "../../components/DesingSystem/InputText/InputText";
import { useState } from "react";
import CheckBox from "../../components/DesingSystem/CheckBox/CheckBox";
import { postApiCourseProposal } from "../../services/apiCourse";
import ModalFormSuccess from "../../components/FormContainer/ModalFormSuccess";

const CourseForm = () => {
  // First form part
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [isLastNameEntered, setIsLastNameEntered] = useState(false);
  const [lastName, setLastName] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [email, setEmail] = useState("");
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [phone, setPhone] = useState("");
  const [categorySelected, setCategorySelected] = useState([]);

  // Second form part
  const [isCourseNameEntered, setIsCourseNameEntered] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [isCoursePriceEntered, setIsCoursePriceEntered] = useState(false);
  const [coursePrice, setCoursePrice] = useState("");
  const [formErrors, setFormErrors] = useState();
  const [error, setError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);


  const handleInputNameFocus = () => { setIsNameEntered(true) }
  const handleInputNameBlur = () => { setIsNameEntered(false) }
  const handleInputLastNameFocus = () => { setIsLastNameEntered(true) }
  const handleInputLastNameBlur = () => { setIsLastNameEntered(false) }
  const handleInputEmailFocus = () => { setIsEmailEntered(true) }
  const handleInputEmailBlur = () => { setIsEmailEntered(false) }
  const handleInputPhoneFocus = () => { setIsPhoneEntered(true) }
  const handleInputPhoneBlur = () => { setIsPhoneEntered(false) }
  const handleInputCourseNameFocus = () => { setIsCourseNameEntered(true) }
  const handleInputCourseNameBlur = () => { setIsCourseNameEntered(false) }
  const handleInputCoursePriceFocus = () => { setIsCoursePriceEntered(true) }
  const handleInputCoursePriceBlur = () => { setIsCoursePriceEntered(false) }


  const modalTexts = {
    title: "¡Tu propuesta de curso se ha enviado correctamente!",
    content: "Nos pondremos en contacto contigo con la mayor brevedad posible.",
    buttonText: "Volver a cursos",
    backUrl: "/cursos"
  }

  const submitForm = async (e) => {
    e.preventDefault();
    // Form data
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    let errorVal = false;

    setFormErrors((formErrors) => Object.assign({}));

    if (!data.name || data.name == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { name: "El nombre es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.lastName || data.lastName == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { lastName: "El apellido es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.email || data.email == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { email: "El email es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.phone || data.phone == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { phone: "El teléfono es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.coursePrice || data.coursePrice == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { coursePrice: "El precio es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.courseName || data.courseName == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { courseName: "El nombre del curso es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.description || data.description == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { description: "La descripción es obligatoria" }));
      setError(true);
      errorVal = true;
    }

    if (!data.content || data.content == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { content: "El contenido es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (JSON.stringify(data).indexOf('tcnicas-de-escritura') === -1 && JSON.stringify(data).indexOf('post-escritura') === -1) {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { area: "<br />El área es obligatoria" }));
      setError(true);
      errorVal = true;
    }

    if(!errorVal){
      setShowSuccessModal(true)
      const response = await postApiCourseProposal(data);
    }
  }

  // Function that get a string and slugify it
  const slugify = (str) => {
    return str.toLowerCase().replace(/ /g,'-').replace(/[^\w-]+/g,'');
  }

  const filters = [
    {
      title: 'Técnicas de escritura',
      items: [
        'Escritura creativa',
        'Personajes',
        'Diálogos',
        'Géneros',
        'Cine',
        'Estructuras narrativas',
        'No ficción',
        'Poesía'
      ]
    },
    {
      title: 'Post escritura',
      items: [
        'Maquetación',
        'Marketing',
        'Ventas',
        'Ilustración',
        'Legal',
        'Diseño de portadas',
        'Booktrailer'
      ]
    }
  ]

  const handleCategorySelection = (event) => {
    if (categorySelected.includes(event.target.name)) {
      setCategorySelected(categorySelected.filter((item) => item !== event.target.name));
    } else {
      setCategorySelected([...categorySelected, event.target.name]);
    }
  }

  return (
    <div>
      <FormContainer
        title="Formulario de curso"
        modalTexts={modalTexts}
        onSubmit={submitForm}
        error={error}
      >
        <div className="course-form">
          <div className="course-form__section course-form__bordered">
            <h2 className="course-form__section-title">Datos personales</h2>
            <div className="course-form__section-body">
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.name ? true : false}
                  onFocus={handleInputNameFocus}
                  onBlur={handleInputNameBlur}
                  type="text"
                  id="name"
                  name="name"
                  label="Nombre*"
                  size="small"
                  placeholder="Ingresa tu nombre"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                />

                { formErrors && formErrors.name &&
                  <p className="error">{ formErrors.name }</p>
                }
              </div>
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.lastName ? true : false}
                  onFocus={handleInputLastNameFocus}
                  onBlur={handleInputLastNameBlur}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label="Apellidos*"
                  size="small"
                  placeholder="Ingresa tus apellidos"
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                />

                { formErrors && formErrors.lastName &&
                  <p className="error">{ formErrors.name }</p>
                }
              </div>
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.email ? true : false}
                  onFocus={handleInputEmailFocus}
                  onBlur={handleInputEmailBlur}
                  type="email"
                  id="email"
                  name="email"
                  label="Correo electrónico*"
                  size="small"
                  placeholder="Ingresa tu correo electrónico"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                />

                { formErrors && formErrors.email &&
                  <p className="error">{ formErrors.email }</p>
                }
              </div>
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.phone ? true : false}
                  onFocus={handleInputPhoneFocus}
                  onBlur={handleInputPhoneBlur}
                  type="tel"
                  id="phone"
                  name="phone"
                  label="Teléfono*"
                  size="small"
                  placeholder="Ingresa tu teléfono"
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                />

                { formErrors && formErrors.phone &&
                  <p className="error">{ formErrors.phone }</p>
                }
              </div>
            </div>
          </div>
          <div className="course-form__section mb-0 pb-0">
            <h2 className="course-form__section-title">Datos del curso</h2>
            <div className="course-form__section-body">
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.courseName ? true : false}
                  onFocus={handleInputCourseNameFocus}
                  onBlur={handleInputCourseNameBlur}
                  type="text"
                  id="courseName"
                  name="courseName"
                  label="Título del curso*"
                  size="small"
                  placeholder="Ingresa el título del curso"
                  onChange={(event) => {
                    setCourseName(event.target.value);
                  }
                  }
                />

                { formErrors && formErrors.courseName &&
                  <p className="error">{ formErrors.courseName }</p>
                }
              </div>
              <div className="course-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.coursePrice ? true : false}
                  onFocus={handleInputCoursePriceFocus}
                  onBlur={handleInputCoursePriceBlur}
                  type="number"
                  id="coursePrice"
                  name="coursePrice"
                  label="Precio unitario (iva incluido)*"
                  size="small"
                  placeholder="Introdece el precio de tu curso"
                  onChange={(event) => {
                    setCoursePrice(event.target.value);
                  }
                  }
                  iconPosition="right"
                  icon="euro"
                  iconColor="secondary-4"
                  iconSize="small"
                  groupRight
                />

                { formErrors && formErrors.coursePrice &&
                  <p className="error">{ formErrors.coursePrice }</p>
                }
              </div>
              <div className="course-form__section-body-item">
                <p className="course-form__input-label">Área del curso*</p>
                <p className="course-form__input-sublabel">Elige el área y las categorías que se identifican con tu curso:</p>
                {filters.map((filter, index) => (
                  <div className="course-form__checkbox-group" key={index}>
                    <CheckBox
                      className="course-form__checkbox"
                      id={slugify(filter.title)}
                      name={slugify(filter.title)}
                      label={filter.title}
                      onChange={handleCategorySelection}
                    />
                    { categorySelected.includes(slugify(filter.title)) ?
                      <div className="course-form__checkbox-items">
                        {filter.items.map((item, index) => (
                          <CheckBox
                            className="course-form__checkbox"
                            id={slugify(`${filter.title}-${item}`)}
                            name={slugify(`${filter.title}-${item}`)}
                            label={item}
                          />
                        ))}
                      </div>
                    : null }
                  </div>
                ))}
                { formErrors && formErrors.area &&
                  <p className="error" dangerouslySetInnerHTML={{ __html: formErrors.area }}></p>
                }
              </div>
              <div className="course-form__section-body-item course-form__section-body-item--full">
                <p className="course-form__input-label">Descripción del curso*</p>
                <textarea
                  className={ 'course-form__textarea' + (formErrors && formErrors.description ? ' error' : '') }
                  name="description"
                  id="description"
                  placeholder="Escribe una breve descripción del curso"
                  cols="30"
                  rows="10"
                ></textarea>

                { formErrors && formErrors.description &&
                  <p className="error">{ formErrors.description }</p>
                }
              </div>
              <div className="course-form__section-body-item course-form__section-body-item--full">
                <p className="course-form__input-label">Contenidos y temas del curso*</p>
                <textarea
                  className={"course-form__textarea" + (formErrors && formErrors.content ? ' error' : '')}
                  name="content"
                  id="content"
                  placeholder="Escribe los contenidos  y temas que se tocará en el curso"
                  cols="30"
                  rows="10"
                ></textarea>

                { formErrors && formErrors.content &&
                  <p className="error">{ formErrors.content }</p>
                }
              </div>
            </div>
          </div>
        </div>
      </FormContainer>

      <ModalFormSuccess
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        {...modalTexts}
      />
    </div>
  )
}

export default CourseForm;
