import "./ExitPage.scss";
import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button"

function ExitPage(props) {
  return (
    <section className="exit-page">
      <div onClick={(e) => e && e.stopPropagation()}></div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="exit-page">
            <h3 className="exit-page__title">
              ¿Estás seguro que quieres dejar el proceso de publicación?
            </h3>
            <div className="exit-page__container-button">
              <Button 
              className="btn-orange mt-4" 
              text="Seguir el proceso"
              onClick={props.onHide} />
              <button
                className="exit-page__button-exit"
                onClick={props.onAccept}
              >
                Abandonar
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ExitPage;
