import "./FirstBookolicos.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon/Icon";

function FirstBookolicos() {
  const navigate = useNavigate();
  const goToFbMonse = () => {
    navigate("");
  };
  const goToIgMonse = () => {
    navigate("");
  };
  const goToLiMonse = () => {
    navigate("");
  };
  const goToFbNestor = () => {
    navigate("");
  };
  const goToIgNestor = () => {
    navigate("");
  };
  const goToLiNestor = () => {
    navigate("");
  };
  return (
    <section className="first-bookolicos">
      <div className="first-bookolicos__border"> </div>
      <p className="first-bookolicos__title">Los primeros Bookólicos</p>
      <div className="first-bookolicos__container-containers">
        <div className="first-bookolicos__container-monse">
          <div className="first-bookolicos__container-monse-section-image">
            <img
              className="first-bookolicos__container-monse-section-image-img"
              src="/images/monse.png"
              alt="vision"
            />
          </div>
          <div className="first-bookolicos__container-monse-section-text">
            <p className="first-bookolicos__container-monse-title">
              Monse Saavedra
            </p>
            <p className="first-bookolicos__container-monse-text">
              Escritora vocacional y gran amante de todos los formatos en los
              que se cuentan historias: novelas, relatos, cine y comics. Está
              influenciada por autores como Stephen King, Neil Gaiman, Garth
              Ennis o Alan Moore y cineastas como Orson Wells, Scorsese,
              Tarantino o Billy Wilder. Diplomada en Escritura creativa, en la
              especialidad de guion de cine y tv, desarrolló su creatividad
              durante años en el medio audiovisual. De vuelta a sus orígenes
              narrativos descubre que el sector editorial está cambiando y
              decide estudiar marketing digital para poner en marcha un proyecto
              que aúne sus valores sobre la meritocracia y la colaboración con
              su pasión por contar historias, dando como resultado Bookólicos.
              Podéis encontrar su primera obra publicada en esta misma
              plataforma: una antología de relatos llamada Ecos del S.XXI.
            </p>
            <div className="first-bookolicos__container-monse-icons">
              <ul className="first-bookolicos__container-monse-icons-list">
                {/*<li className="first-bookolicos__container-monse-icons-list-item">
                  <Icon
                    name="facebook"
                    size="large"
                    color="secondary-3"
                    border="border-2"
                  />
                </li>*/}
                <li className="first-bookolicos__container-monse-icons-list-item">
                  <a href="https://instagram.com/saavedrastyle" target="_blank">
                    <Icon
                      name="instagram"
                      size="large"
                      color="secondary-3"
                      border="border-2"
                    />
                  </a>
                </li>
                <li className="first-bookolicos__container-monse-icons-list-item">
                  <a href="https://www.linkedin.com/in/monse-saavedra/" target="_blank">
                    <Icon
                      name="linkedin"
                      size="large"
                      color="secondary-3"
                      border="border-2"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="first-bookolicos__container-nestor">
          <div className="first-bookolicos__container-nestor-section-text">
            <p className="first-bookolicos__container-nestor-title">
              Néstor Martínez
            </p>
            <p className="first-bookolicos__container-nestor-text">
              Ávido lector y coleccionista de cómics que bebe de influencias tan
              variopintas como Allan Poe, Will Eisner, Alan Moore, Moebius o
              Corben. Actualmente combina su profesión como técnico de
              telecomunicaciones en el sector aeronáutico con su otra gran
              pasión, la aviación. Es Piloto Privado y, desde hace tres años,
              podcaster y creador del programa Hablando de Aviones.  En
              Bookólicos aporta su creatividad y conocimientos en diseño, sonido
              y comunicación, desde la perspectiva de la colaboración entre
              personas para impulsar proyectos.
            </p>
            <div className="first-bookolicos__container-nestor-icons">
              <ul className="first-bookolicos__container-nestor-icons-list">
                {/*<li className="first-bookolicos__container-nestor-icons-list-item">
                  <Icon
                    name="facebook"
                    size="large"
                    color="secondary-3"
                    border="border-2"
                  />
                </li>*/}
                <li className="first-bookolicos__container-nestor-icons-list-item">
                  <a href="https://instagram.com/nflight666" target="_blank">
                    <Icon
                      name="instagram"
                      size="large"
                      color="secondary-3"
                      border="border-2"
                    />
                  </a>
                </li>
                <li className="first-bookolicos__container-nestor-icons-list-item">
                  <a href="https://www.linkedin.com/in/néstor-martínez-roda-610642275/" target="_blank">
                    <Icon
                      name="linkedin"
                      size="large"
                      color="secondary-3"
                      border="border-2"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="first-bookolicos__container-nestor-section-image">
            <img
              className="first-bookolicos__container-nestor-section-image-img"
              src="/images/nestor.png"
              alt="mission"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

export default FirstBookolicos;
