import "./Result.scss";
import GenreItem from "../GenreItem/GenreItem";
import EllipsisIcon from "../../styles/images/ellipsis.svg";
import {useState} from "react";
import ModalTags from "../BookCatalog/ModalGenres/ModalGenres";
import * as dayjs from "dayjs";
import "dayjs/locale/es";
function Results (props) {
  const [modalTagsShow, setModalTagsShow] = useState(false);

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  const renderObra = (item) => (
    <div className="result__data">
      <p className="result__title">{item.title}</p>
      <p className="result__subtitle mb-5">Por {item.author}</p>
      <div className="result__genres-container">
        {renderGenres(item.genres, item.title)}
        <p className="result__price">{item.price}€</p>
      </div>
    </div>
  )
  const renderAutor = (item) => (
    <div className="result__data">
      <p className="result__title">{item.name}</p>
      <p className="result__subtitle">{item.publications < 2 ? `${item.publications} obra publicada` : `${item.publications || 0} obras publicadas` }</p>
      <span className="result__genres-text">Géneros que trata</span>
      <div className="result__genres-container">
        {renderGenres(item.genres, item.name)}
      </div>
    </div>
  )
  const renderGenero = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1">{item.name}</p>
      <p className="result__info">{item.count} novela{item.count > 1 ? `s` : ''} con estas características</p>
    </div>
  )
  const renderNoticia = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1">{item.title}</p>
      <div className="result__info">
        <span>{item.author} <span className="result__info-extra">| {dayjs(item.date).format('DD MMM YYYY')}</span></span>
        <p className="result__info-readTime">{item.readTime} min de lectura</p>
      </div>
    </div>
  )
  const renderCategoria = (item) => (
    <div className="result__data">
      <p className="result__title flex-grow-1">{item.name}</p>
      <p className="result__info">{item.count} novela{item.count > 1 ? `s` : ''} con estas características</p>
    </div>
  )

  const renderGenres = (genres, title) => {
    return genres.map((genre, index) => {
      if (index < 1) {
        return (
          <>
            <GenreItem
              key={`${genre}-${genre._id}-${index}`}
              genre={genre}
              version="v2"
            />
            {genres.length > 1 ? (
              <button
                className="result__genres-button"
                onClick={showModalTags}
              >
                <img src={EllipsisIcon} alt="More genres"/>
              </button>
            ) : null}
            <ModalTags
              title={title}
              genres={genres}
              show={modalTagsShow}
              onHide={hideModalTags}
            />
        </>
        );
      }
      return null;
    });
  };

  const renderResults = () => (
    <>
      <div className="searcher__results">
        <ResultItem></ResultItem>
      </div>
    </>
  )
  return renderResults()
}

function ResultItem (props) {
  return (
    <>
      <div className="result">
        <div className="result__content">
          <div className="result__tag-container">
            <img src="/images/search-category-loader.svg" alt="img" />
          </div>
          <img src="/images/search-body-loader.svg" alt="img" />
        </div>
        <div className={`result__image result__image--full`}>
        <img src="/images/search-image-loader.svg" alt="img" />
        </div>
      </div>
    </>
  )
}

export default Results;
