import "./Objectives.scss";
import Slider from "react-slick";
import React, { useState } from "react";

function Objectives() {
  const slider = React.useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1.1,
    className: "objectives__container-carrousel-item",
    adaptiveHeight: false,
  };

  const renderCarrouselObjetives = () => {
    return [
      <div className="objectives__container-mobile-objectives">
        <img
          className="objectives__container-mobile-objectives-img"
          src="/images/comunidad.png"
          alt="community"
        />
        <p className="objectives__container-mobile-objectives-title">
          Comunidad
        </p>
        <p className="objectives__container-mobile-objectives-text">
          El objetivo es crear un espacio donde se fomente una cultura de
          colaboración y apoyo entre todos los participantes.
        </p>
      </div>,
      <div className="objectives__container-mobile-objectives">
        <img
          className="objectives__container-mobile-objectives-img"
          src="/images/responsabilidad.png"
          alt="responsibility"
        />
        <p className="objectives__container-mobile-objectives-title">
          Responsabilidad social
        </p>
        <p className="objectives__container-mobile-objectives-text">
          Queremos brindarle valor a nuestros usuarios, a sus publicaciones, a
          las obras que leen y a su entorno profesional.
        </p>
      </div>,
      <div className="objectives__container-mobile-objectives">
        <img
          className="objectives__container-mobile-objectives-img"
          src="/images/oportunidades.png"
          alt="oportunity"
        />
        <p className="objectives__container-mobile-objectives-title">
          Oportunidades
        </p>
        <p className="objectives__container-mobile-objectives-text">
          Buscamos que autores independientes ganen visibilidad tanto de
          lectores como de posibles editoriales.
        </p>
      </div>,
      <div className="objectives__container-mobile-objectives">
        <img
          className="objectives__container-mobile-objectives-img"
          src="/images/crecimiento.png"
          alt="growing"
        />
        <p className="objectives__container-mobile-objectives-title">
          Crecimiento
        </p>
        <p className="objectives__container-mobile-objectives-text">
          Nuestro principal objetivo es progresar. Queremos ser la plataforma
          digital de referencia para la publicación, enseñanza y lectura.
        </p>
      </div>,
    ];
  };

  return (
    <section className="objectives">
      <div className="objectives__container-title">
        <p className="objectives__container-title-title">
          Principales objetivos
        </p>
        <p className="objectives__container-title-text">
          En Bookólicos trabajamos para convertir desafíos en
          oportunidades y transformar sueños en realidades tangibles.
        </p>
      </div>

      <div className="objectives__container">
        <div className="objectives__container-objectives">
          <img
            className="objectives__container-objectives-img"
            src="/images/comunidad.png"
            alt="community"
          />
          <p className="objectives__container-objectives-title">Comunidad</p>
          <p className="objectives__container-objectives-text">
            El objetivo es crear un espacio donde se fomente una cultura de
            colaboración y apoyo entre todos los participantes.
          </p>
        </div>
        <div className="objectives__container-objectives">
          <img
            className="objectives__container-objectives-img"
            src="/images/responsabilidad.png"
            alt="responsibility"
          />
          <p className="objectives__container-objectives-title">
            Responsabilidad social
          </p>
          <p className="objectives__container-objectives-text">
            Queremos brindarle valor a nuestros usuarios, a sus publicaciones, a
            las obras que leen y a su entorno profesional.
          </p>
        </div>
        <div className="objectives__container-objectives">
          <img
            className="objectives__container-objectives-img"
            src="/images/oportunidades.png"
            alt="oportunity"
          />
          <p className="objectives__container-objectives-title">
            Oportunidades
          </p>
          <p className="objectives__container-objectives-text">
            Buscamos que autores independientes ganen visibilidad tanto de
            lectores como de posibles editoriales.
          </p>
        </div>
        <div className="objectives__container-objectives">
          <img
            className="objectives__container-objectives-img"
            src="/images/crecimiento.png"
            alt="growing"
          />
          <p className="objectives__container-objectives-title">Crecimiento</p>
          <p className="objectives__container-objectives-text">
            Nuestro principal objetivo es progresar. Queremos ser la plataforma
            digital de referencia para la publicación, enseñanza y lectura.
          </p>
        </div>
      </div>

      <div className="objectives__container-mobile">
        {" "}
        <Slider ref={slider} {...settings}>
          {renderCarrouselObjetives()}
        </Slider>
      </div>
    </section>
  );
}

export default Objectives;
