import "./GenreItem.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { useIsPublic } from "../../hooks/use-is-public";

function GenreItem({ text, size, ...props }) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();

  const sizeClass = `genre-item--${size}`;

  const onClick = () => {
    if (props.onClick) {
      props.onClick(props.genre);
    }
  }

  return (
    <React.Fragment>
      { props.isNavegable ?
        <React.Fragment>
          {isPublic ?
            <a href={`/libros-por-genero/${props.genre.icon}`}>
              <p
                key={props.genre._id}
                className={`genre-item ${sizeClass} ${props.version && `genre-item--${props.version}`} ${
                  props.isSelected ? "genre-item--is-selected" : ""
                } ${
                  props.disabled ? "disabled" : ""
                }`}
              >
                {props.genre.tag}
              </p>
            </a>
          :
            <a href={`/catalogo-por-genero/${props.genre.icon}`}>
              <p
                key={props.genre._id}
                className={`genre-item ${props.version && `genre-item--${props.version}`} ${sizeClass} ${
                  props.isSelected ? "genre-item--is-selected" : ""
                } ${
                  props.disabled ? "disabled" : ""
                }`}
              >
                {props.genre.tag}
              </p>
            </a>
          }
        </React.Fragment>

      :
        <p
          key={props.genre._id}
          className={`genre-item ${sizeClass} ${
            props.isSelected ? "genre-item--is-selected" : ""
          } ${
            props.disabled ? "disabled" : ""
          }`}
          onClick={() => { onClick() }}
        >
          {props.genre.tag}
        </p>
      }
    </React.Fragment>
  );
}

GenreItem.propTypes = {
  className: PropTypes.string.isRequired,
  version: PropTypes.oneOf(['v3', 'v2', 'v1']),
  size: PropTypes.oneOf(['xsmall', 'small', 'medium', 'large']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

GenreItem.defaultProps = {
  className: "genre-item",
  size: "medium",
  version: "v1",
  onClick: undefined,
  isNavegable: true,
  disabled: false
};

export default GenreItem;
