import "./FormContainer.scss";

import Container from "react-bootstrap/Container";
import Button from "../DesingSystem/Button/Button";
import Icon from "../Icon/Icon";
import ModalFormSuccess from "./ModalFormSuccess";
import  { useState } from "react";
import { useNavigate } from "react-router-dom";

const FormContainer = ({ children, ...props }) => {
  const navigate = useNavigate();
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const submitHandler =  (e) => {
    try {
      e.preventDefault();
      props.onSubmit(e);
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className="form-container">
      <Container>
        <div className="form-container__back">
          <Button text="Volver" className="btn btn-simple" iconLeft="chevronLeft" onClick={() => navigate(props.modalTexts.backUrl)} />
        </div>
        <div className="form-container__header">
          <h1 className="form-container__title">{props.title}</h1>
          {/*<Icon name="share" size="medium" />*/}
        </div>
        <div className="form-container__header-mobile">
          <h1 className="form-container__title">{props.title}</h1>
          <Icon name="close" size="medium" onClick={() => navigate(props.modalTexts.backUrl)} />
        </div>
        <div className="form-container__body">
          <form onSubmit={submitHandler}>
              {children}
            <div className="form-container__footer">
              <p className="form-container__contact">¿Necesitas ayuda? <a href="/contacta-con-nosotros">Contáctanos</a></p>
              <Button text="Enviar formulario" className="btn btn-orangebg" type="submit" />
            </div>
          </form>
        </div>
      </Container>
    </div>
  )
}

export default FormContainer;
