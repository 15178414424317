import Landing from  "../landing/Landing";
import { useLocation } from "react-router-dom";

const StyleCorrection = () => {
  const location = useLocation();

  const content = {
    description: "No tengas miedo, este tipo de corrección no cambiará el estilo con el que escribiste tu obra, pero la llevará a otro nivel de excelencia literaria. Buscaremos errores gramaticales, revisaremos la adecuación del vocabulario utilizado y valoraremos la arquitectura del texto para asegurarnos de que todo lo que has contado en tu manuscrito se entienda perfectamente: coherencia, pobreza léxica, repeticiones, reiteraciones no estilísticas, obviedades, circunloquios, cacofonías innecesarias, pleonasmos válidos y pleonasmos antiestéticos, revisión profunda de la puntuación, etc.",
    summary: "Tu obra con la calidad literaria que merece; pulida y con brillo, para deleitar a tus lectores.",
    formTitle: "Contrata una corrección de estilo",
    formDescription: "Todos los servicios editoriales de Bookólicos se realizarán bajo presupuesto, previo contacto del autor con nosotros (vía email) para darnos detalles de su obra y sus necesidades."
  };

  let services = [];

  location.pathname.indexOf('servicio') !== -1 ?
    services = [{
      name: "Coaching",
      link: "/servicio-coaching"
    }, {
      name: "Lector cero",
      link: "/servicio-lector-cero"
    }, {
      name: "Corrección ortotipográfica",
      link: "/servicio-correccion-ortotipografica"
    }, {
      name: "Maquetación digital",
      link: "/servicio-maquetacion-digital"
    }]
  :
    services = [{
      name: "Coaching",
      link: "/coaching"
    }, {
      name: "Lector cero",
      link: "/lector-cero"
    }, {
      name: "Corrección ortotipográfica",
      link: "/correccion-ortotipografica"
    }, {
      name: "Maquetación digital",
      link: "/maquetacion-digital"
    }]
  ;

  return (
    <Landing
      service="Corrección de estilo"
      content={content}
      services={services}
    />
  );
};

export default StyleCorrection;
