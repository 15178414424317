import "./WhoContainer.scss";
import PartOfSubtitleWho from "../../../components/aPublicArea/WhoComponent/PartOfSubtitleWho/PartOfSubtitleWho";
import MissionAndVision from "../../../components/aPublicArea/WhoComponent/MissionAndVision/MissionAndVision";
import Objectives from "../../../components/aPublicArea/WhoComponent/Objectives/Objectives";
import FirstBookolicos from "../../../components/aPublicArea/WhoComponent/FirstBookolicos/FirstBookolicos";
import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";
import NameSection from "../../../components/NameSection/NameSection";
import React from "react";

//import Genres from "../../components/Genres/Genres";

function WhoContainer() {
  return (
    <section className="who-container">
      <NameSection name="Quiénes somos" color="#f05a24"/>
      <PartOfSubtitleWho />
      <MissionAndVision />
      <Objectives />
      <div className="who-container__bookolicos">
        <FirstBookolicos />
      </div>
      <BannerPublic />
    </section>
  );
}

export default WhoContainer;
