import "./SliderBooks.scss";
import Slider from "react-slick";
import { useRef } from "react";

function SliderBooks(props) {

  const slider = useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 2,
    adaptiveHeight: true,
    variableWidth: true,
    className: "slider-books__item",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToScroll: 1,
          variableWidth: true,
          adaptiveHeight: false,
          slidesToShow: 1,
        },
      },
    ],
  };
  const maxCaracteresTitle = 30;

  const onClick = (id) => {
    if (props.onRelatedClick) {
      props.onRelatedClick(id);
      slider.current.slickGoTo(0);
    }
  };

  const renderBooks = () => {
    return props.books.map((book) => {
      return (
        <div
          className="slider-books__item"
          key={book._id}
          onClick={() => {
            onClick(book._id);
          }}
        >
          <div className="slider-books__item-container">
            <img
              className="slider-books__item-image"
              src={book.picture_medium}
              alt={book.title}
            />
            <div className="slider-books__item-content">
              <p className="slider-books__item-content-title">
                {book.title.length > maxCaracteresTitle
                  ? book.title.substring(0, maxCaracteresTitle) + "..."
                  : book.title}
              </p>
              <div className="slider-books__item-content-section">
                {Object.keys(book.categories[0]).length ?
                  <p className="slider-books__item-content-section-genre">
                  {book.categories[0].tag} ·{" "}
                </p> : null
                }
                <p className="slider-books__item-content-section-sales">
                  123 ventas
                </p>
              </div>
            </div>
          </div>
          {/*
          <div className="slider-book__arrow">
            <Icon name="arrowDiagonal" size="xlarge" color="background-item" />
          </div>
          */}
        </div>
      );
    });
  };

  return props.books ? (
    <div className="slider-books">
      <Slider ref={slider} {...settings}>{renderBooks()}</Slider>
    </div>
  ) : null;
}

export default SliderBooks;
