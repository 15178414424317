import Landing from "../landing/Landing";
import { useLocation } from "react-router-dom";

const Coaching = () => {
  const location = useLocation();

  const content = {
    description: "¿Te has atascado en un punto de tu historia que no te permite terminarla? ¿Has puesto el punto final y sientes que hay cosas que mejorar, pero no sabes identificarlas o resolverlas? Te ofrecemos un servicio de tutorías personalizadas donde podrás comentar de forma pormenorizada tu problema y encontrar una solución. Cada autor tiene unas necesidades específicas y nos amoldamos a ellas proponiéndote el plan que mejor se adapte a ti.",
    summary: "Podrás solicitar sesiones puntuales o iniciar una orientación paso a paso en la que un profesional te asesorará conforme al material que hayas creado.",
    price: "45€ / sesión ó 5 sesiones por 300€",
    formTitle: "Contrata nuestro servicio de coaching",
    formDescription: "Todos los servicios editoriales de Bookólicos se realizarán bajo presupuesto, previo contacto del autor con nosotros (vía email) para darnos detalles de su obra y sus necesidades.",
  };

  let services = [];

  location.pathname.indexOf('servicio') !== -1 ?
    services = [{
      name: "Lector cero",
      link: "/servicio-lector-cero"
    }, {
      name: "Corrección ortotipográfica",
      link: "/servicio-correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/servicio-correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/servicio-maquetacion-digital"
    }]
  :
    services = [{
      name: "Lector cero",
      link: "/lector-cero"
    }, {
      name: "Corrección ortotipográfica",
      link: "/correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/maquetacion-digital"
    }]
  ;

  return (
    <Landing
      service="Coaching"
      content={content}
      services={services}
    />
  );
};

export default Coaching;
