import "./LegalBases.scss";

import NameSection from "../../components/NameSection/NameSection";
import { useEffect } from "react";

const LegalBases = () => {
  const legalBases = [
    {
      number: '01',
      text: 'La presente promoción está limitada a un sólo código ISBN por autor y obra en formato digital e-pub. En caso de que el autor posea un código ISBN para su obra en dicho formato tiene la opción de publicarlo en la plataforma con dicho código.'
    },
    {
      number: '02',
      text: 'El autor debe estar registrado en la plataforma Bookólicos.'
    },
    {
      number: '03',
      text: 'El código ISBN quedará asociado a la obra que el autor indique en el formulario de inscripción, no pudiendo asociarse de ninguna manera a otra obra.'
    },
    {
      number: '04',
      text: 'La obra para la que se solicita el código ISBN debe publicarse en la plataforma Bookólicos y el autor se compromete a tener la obra publicada en dicha plataforma por un período no inferior a seis meses.'
    },
    {
      number: '05',
      text: 'Bookólicos se reserva un plazo máximo de siete días para la asignación de dicho código a la obra que el autor publique en la plataforma. Una vez hayamos obtenido la respuesta de la Agencia Española del ISBN.'
    },
    {
      number: '06',
      text: 'La obra que se publique asociada a la promoción "50 primeros ISBN gratuitos" no tendrá asignado otro código ISBN para el mismo formato digital (e-pub). En caso de que Bookólicos detecte cualquier irregularidad al respecto, se reserva el derecho de dejar sin validez esta promoción para el autor que la solicite.'
    },
    {
      number: '07',
      text: 'Bookólicos no se hace responsable de la desestimación por parte de la Agencia Española del ISBN debido a la falsedad de datos, datos incorrectos o erróneos proporcionados por el autor o cualquier otra razón que pudiera indicar dicha Agencia para invalidar dicho código ISBN. El cualquier caso, Bookólicos pondrá todos los medios a su disposición para intentar solventar cualquier contingencia contactando lo antes posible con el autor y solicitándole tantos datos como la Agencia exija.'
    },
    {
      number: '08',
      text: ' Bookólicos no se hace responsable del uso que el autor haga del código ISBN fuera de la plataforma, reservándose el derecho a tomar medidas legales contra el autor si dicho uso perjudica de cualquier forma la imagen de la empresa, la plataforma o cualquier otro canal de Bookólicos.'
    },
    {
      number: '09',
      text: 'Esta promoción es válida hasta 31 de octubre o fin de existencias.'
    },
    {
      number: '10',
      text: 'Bookólicos se reserva el derecho de hacer modificaciones en esta promoción en cualquier momento.'
    }
  ]

  return (
    <div className="legal-bases">
      <NameSection name="Bases legales" color="#f05a24"  />
      <div className="legal-bases__container">
        {legalBases.map((legalBase, index) => {
          return (
            <>
              <div className="legal-bases__item">
                <span>{legalBase.number}</span>
                <p>
                  {legalBase.text}
                </p>
              </div>
            </>
          )
        })}
      </div>
    </div>
  );
}

export default LegalBases;
