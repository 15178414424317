const prod = {
  API_URL: "https://api.bookolicos.com/",
  /*API_URL: "https://api-dev.bookolicos.com/",*/
  STRIPE_API_KEY: "pk_live_51MlhrWC82AMUaff6Op7HykM2k4RDWBrGU0RoxFeiq9ev7jmxY69hNq2U3tq2cM6K4KnSObO94MAWgSzXNy6iq2tm00RCVG66Mc",
  /*STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",*/
  PAGE_SIZE: 9,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t",
  BOOKOLICOS_FEE_PERCENTAGE: "30",
  AUTHOR_FEE_PERCENTAGE: "70"
};

const dev = {
  /* API_URL: "https://api.bookolicos.com/",  */
  API_URL: "https://api-dev.bookolicos.com/",
  /*STRIPE_API_KEY: "pk_live_51MlhrWC82AMUaff6Op7HykM2k4RDWBrGU0RoxFeiq9ev7jmxY69hNq2U3tq2cM6K4KnSObO94MAWgSzXNy6iq2tm00RCVG66Mc",*/
  STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",
  PAGE_SIZE: 9,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t",
  BOOKOLICOS_FEE_PERCENTAGE: "30",
  AUTHOR_FEE_PERCENTAGE: "70"
};

const test = {
  API_URL: "http://localhost:4000/",
  STRIPE_API_KEY: "pk_test_51MlhrWC82AMUaff6ETLL7nkOhpKzkEHszMB0v2XcUG3n7mINkXreAOMjrjOoYoIJl7jhqpWRMfkgwabQUimZnsyS00CpGDrKM6",
  PAGE_SIZE: 9,
  TOKEN_SECRET: "w€B_T0k3N_5EcR3t",
  BOOKOLICOS_FEE_PERCENTAGE: "30",
  AUTHOR_FEE_PERCENTAGE: "70"
};

//console.log(process.env)

export const types = process.env.REACT_APP_ENVIRONMENT === "development" ? dev : (process.env.REACT_APP_ENVIRONMENT === "production" ? prod : test);
