import "./AvisoLegal.scss";

function AvisoLegal() {
  return (
    <section className="aviso-legal">

      <p style={{ fontWeight: 400 }}>
        <strong>AVISO LEGAL</strong>
      </p>
      <p>En cumplimiento de lo dispuesto en el art&iacute;culo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Informaci&oacute;n y del Comercio Electr&oacute;nico, en este aportado se proporciona al usuario de esta p&aacute;gina web toda la informaci&oacute;n relativa a las condiciones de acceso, uso y navegaci&oacute;n de este sitio web.</p>
      <p>&nbsp;</p>
      <p>El presente Aviso Legal puesto a disposici&oacute;n de todos los usuarios que naveguen por Internet, tal y como se ha dicho, fija las condiciones de acceso, uso y navegaci&oacute;n de la p&aacute;gina web https://www.bookolicos.com. As&iacute; mismo, regula cualquier otra actividad que dichos usuarios realicen en la antedicha p&aacute;gina web. En este sentido, los usuarios han de tener presente que este Aviso Legal complementa los dem&aacute;s textos legales puestos a su disposici&oacute;n en esta p&aacute;gina web.</p>
      <p>Por tanto, rogamos a todos los usuarios que dediquen unos minutos para la lectura del presente Aviso Legal antes dar cualquier uso a esta p&aacute;gina web o realizar cualquier actividad en la misma.</p>
      <ol>
      <li><strong>1.- Titularidad del sitio web</strong></li>
      </ol>
      <p>Se informa al usuario que:</p>
      <ul>
      <li><strong>El titular de esta p&aacute;gina web es</strong>: N&eacute;stor Mart&iacute;nez Roda en adelante, <strong>&ldquo;EL TITULAR&rdquo;</strong>.</li>
      <li><strong>Con domicilio sito en</strong>: Calle R&iacute;o Guadalquivir 6, 4 D. 28913 Legan&eacute;s, Madrid.</li>
      <li><strong>Direcci&oacute;n de correo electr&oacute;nico</strong>: hola@bookolicos.com</li>
      <li><strong>Tel&eacute;fono</strong>: 699760246</li>
      </ul>
      <p>&nbsp;</p>
      <ol start="2">
      <li><strong>2.- Finalidad de la p&aacute;gina web</strong></li>
      </ol>
      <p>El sitio web www.bookolicos.com tiene por finalidad:</p>
      <ul>
      <li>Ofertar servicios de asesor&iacute;a, correcci&oacute;n de obras manuscritas, promoci&oacute;n de autores.</li>
      <li>Servicios de intermediaci&oacute;n entre los escritores con editoriales y/o profesionales del sector de edici&oacute;n, imprenta, publicaci&oacute;n y comercializaci&oacute;n de libros.</li>
      <li>Gesti&oacute;n de compra &ndash; venta de libros electr&oacute;nicos, eventos y cursos.</li>
      <li>Organizaci&oacute;n de eventos para escritores.</li>
      <li>Recogida de datos:
      <ul>
      <li><u>Para enviar publicidad a potenciales clientes</u>: Con el debido consentimiento del usuario nos encargamos de enviarle comunicaciones comerciales relacionadas con nuestros productos y servicios, salvo que el usuario no otorgue su consentimiento a tal efecto o lo revoque.</li>
      <li><u>Para gesti&oacute;n el Registro de los Usuarios en la p&aacute;gina web</u>: cunado resulte necesario que el usuario se registre en la p&aacute;gina web, con el fin de usar y/o contratar productos o servicios ofrecidos mediante esta p&aacute;gina web.</li>
      </ul>
      </li>
      </ul>
      <ol start="3">
      <li><strong>3.- Aceptaci&oacute;n por el usuario del aviso legal y dem&aacute;s textos legales</strong></li>
      </ol>
      <p>El simple acceso, uso o navegaci&oacute;n de este sitio web supone la autom&aacute;tica aceptaci&oacute;n de la totalidad del presente Aviso Legal, as&iacute; como de las condiciones de acceso, de su uso y de navegaci&oacute;n, sin que ello conlleve a alg&uacute;n tipo de relaci&oacute;n comercial o laboral entre el usuario y el titular de esta p&aacute;gina web.</p>
      <p>Esto es, de modo enumerativo, la cumplimentaci&oacute;n de formularios, el env&iacute;o de solicitudes de informaci&oacute;n, consultas, quejas, ofertas de contrataci&oacute;n, curriculum vitae y, en general, realizar cualquier acto de naturaleza similar a los enumerados por medio de los formularios y/o buzones electr&oacute;nicos existentes en esta p&aacute;gina web implicar&aacute; la aceptaci&oacute;n sin reservas de todas y cada una de las normas integrantes del presente Aviso Legal.</p>
      <p>El usuario se compromete a dar un correcto uso a este sitio web, as&iacute; como a todos los contenidos que en el mismo aparezcan, debi&eacute;ndose realizar dicho uso de acuerdo con el presente aviso legal, el resto de los textos legales que figuren en la web y que sean aplicables en cada caso concreto y de acuerdo con la normativa legal aplicable. En consecuencia, resulta obligatorio que el usuario tambi&eacute;n lea la Pol&iacute;tica de Privacidad [enlazar con el texto de la Pol&iacute;tica de Privacidad], la Pol&iacute;tica de Cookies [enlazar con el texto de la Pol&iacute;tica de Cookies] y T&eacute;rminos y Condiciones de Uso [enlazar con el texto de T&eacute;rminos y Condiciones de Uso].</p>
      <p>En relaci&oacute;n con lo anterior, informar al usuario que le pueden resultar de aplicaci&oacute;n las condiciones legales adicionales a la compra de productos ofertados en esta p&aacute;gina web o a la contrataci&oacute;n de servicios, contenidos o servicios digitales comercializados mediante esta p&aacute;gina web. Al igual que si participa en alguna de las promociones, sorteos o concursos que se publiquen en la p&aacute;gina web. Por ello, resulta obligatorio que, en tal caso, el usuario tambi&eacute;n lea atentamente, y siempre antes de formalizar la compra, las Condiciones Generales de Contrataci&oacute;n [enlazar con el texto de Condiciones Generales de Contrataci&oacute;n] y, en su caso, Condiciones Particulares de Contrataci&oacute;n para cada producto o servicio, que son publicadas y puestas a su disposici&oacute;n del usuario en esta p&aacute;gina web y facilitadas al usuario en el momento de la contrataci&oacute;n.</p>
      <p>El titular del sitio web <a href="http://www.bookolicos.com/">www.bookolicos.com</a>, se reserva <strong><u>el derecho de modificar en cualquier momento el presente Aviso y/o dem&aacute;s textos legales</u></strong>, ya sea, con el fin de adecuar los mismos a la legalidad vigente, ya sea, por otros motivos. En consecuencia, ser&aacute; responsabilidad de todo usuario la lectura del Aviso Legal y de los dem&aacute;s textos legales vigente en cada una de las ocasiones en que acceda. El usuario siempre tendr&aacute; a su disposici&oacute;n las versiones anteriores de cada uno de los textos legales, para el caso de que hubieran sido modificados.</p>
      <p>&nbsp;</p>
      <ol start="4">
      <li><strong>4.- Exclusi&oacute;n de responsabilidad</strong></li>
      </ol>
      <p>EL TITULAR adoptar&aacute; todas las medidas t&eacute;cnicas y organizativas que el estado de la t&eacute;cnica y tecnolog&iacute;a permita para evitar cualquier fallo o error en el sitio web <a href="http://www.bookolicos.com/">www.bookolicos.com</a> pueda producirse.</p>
      <p>Por ello, EL TITULAR no se hace responsable de da&ntilde;os o perjuicios que pueda sufrir el usuario por error en los contenidos o por fallo en la p&aacute;gina web como, por ejemplo, puede ser la interrupci&oacute;n de su funcionamiento, el acceso a la misma de terceros no autorizados y/o la eventual transmisi&oacute;n de virus que pueda afectar negativamente a los sistemas inform&aacute;ticos tanto de EL TITULAR como del usuario, entre otros. Siempre y cuando lo anterior no fuere imputable a la inobservancia de medidas t&eacute;cnicas, negligencia o dolo de EL TITULAR.</p>
      <p><strong>&nbsp;</strong></p>
      <ol start="5">
      <li><strong>5.- Uso de cookies</strong></li>
      </ol>
      <p>La p&aacute;gina web <a href="http://www.bookolicos.com/">www.bookolicos.com</a> utiliza cookies propias y de terceros con fines estad&iacute;sticos, anal&iacute;ticos y publicitarios, tal y como se informa en la primera capa que aparece en el dispositivo electr&oacute;nico al acceder por primera vez a esta p&aacute;gina web. Donde el usuario tiene la posibilidad de aceptar todas las cookies, rechazar todas las cookies o aceptar algunas de las cookies. Instal&aacute;ndose siempre en su dispositivo las cookies estrictamente necesarias para el funcionamiento de la p&aacute;gina web. El usuario en todo caso podr&aacute; modificar o cancelar su consentimiento y/o rechazo a la instalaci&oacute;n de las cookies en su dispositivo electr&oacute;nico. Para m&aacute;s informaci&oacute;n acerca del uso de las cookies por esta p&aacute;gina web se ruega a todos los usuarios la lectura de nuestra Pol&iacute;tica de Cookies [enlazar con el texto de la Pol&iacute;tica de Cookies].</p>
      <p>&nbsp;</p>
      <ol start="6">
      <li><strong>6.- Protecci&oacute;n de datos personales</strong></li>
      </ol>
      <p>De acuerdo con lo establecido en Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales (LOPD-GDD) y en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos y por el que se deroga la Directiva 95/46/CE (RGPD), mediante el presente Aviso Legal informamos a todos los usuarios que mediante la presente p&aacute;gina web recopilamos sus datos personales y los tratamos de acuerdo con la Pol&iacute;tica de Privacidad [enlazar con el texto de la Pol&iacute;tica de Privacidad] que est&aacute; a disposici&oacute;n de los usuarios en esta p&aacute;gina web y cuya lectura es obligatoria para cada usuario antes de hacer cualquier uso de esta p&aacute;gina web, en especial, rellenar cualquier formulario o aceptar el uso de las Cookies que no sean estrictamente necesarias para el funcionamiento de la p&aacute;gina web.</p>
      <p>&nbsp;</p>
      <ol start="7">
      <li><strong>7.- Propiedad intelectual e industrial</strong></li>
      </ol>
      <p>EL TITULAR es propietaria de todos los contenidos que figuren en esta p&aacute;gina web, salvo que se indique lo contrario. Por tanto, EL TITULAR con car&aacute;cter general es titular de todos los derechos de propiedad intelectual e industrial de esta p&aacute;gina web. En concreto, con car&aacute;cter enunciativo, que no taxativo, son de su propiedad, todos los elementos de dise&ntilde;o gr&aacute;fico de la p&aacute;gina web, c&oacute;digo fuente, logotipos, textos, gr&aacute;ficos, im&aacute;genes, fotograf&iacute;as, iconos, ilustraciones, videos, sonidos, audios, marcas y todos los dem&aacute;s elementos que aparezcan en esta p&aacute;gina web.</p>
      <p>De la misma forma, quedan reservados todos los derechos de EL TITULAR sobre los nombres comerciales, marcas o signos distintivos de cualquier clase que est&eacute;n en esta p&aacute;gina web y est&eacute;n protegidos por el Real Decreto Legislativo 1/1996, de 12 de abril, por el que se aprueba el texto refundido de la Ley de Propiedad Intelectual, regularizando, aclarando y armonizando las disposiciones legales vigentes sobre la materia y por la Ley 17/2001, de 7 de diciembre, de Marcas.</p>
      <p>En rigor a lo dispuesto en el art&iacute;culo 8 y en el art&iacute;culo 32.1.II de la Ley de Propiedad Intelectual, <em>&ldquo;quedan expresamente prohibidas la reproducci&oacute;n, la distribuci&oacute;n y la comunicaci&oacute;n p&uacute;blica, incluida su modalidad de puesta a disposici&oacute;n, de la totalidad o parte de los contenidos de esta p&aacute;gina web, con fines comerciales, en cualquier soporte y por cualquier medio t&eacute;cnico, sin la autorizaci&oacute;n del Titular&rdquo;</em>.</p>
      <p>Concordando con lo anterior, EL TITULAR no concede ninguna licencia o autorizaci&oacute;n de uso al usuario de la p&aacute;gina web sobre sus derechos de propiedad intelectual e industrial o sobre cualquier otro derecho relacionado con esta p&aacute;gina web y los servicios o productos ofrecidos en esta.</p>
      <p><strong>&nbsp;</strong></p>
      <ol start="8">
      <li><strong>8.- Contacto</strong></li>
      </ol>
      <p>Cualquier usuario de la p&aacute;gina web <a href="http://www.bookolicos.com/">www.bookolicos.com</a> pueden ponerse en contacto con el titular de la misma a trav&eacute;s de las direcciones que a continuaci&oacute;n se indican, indicando en su mensaje, nombre y apellidos, los motivos de su consulta y cualquier otra cuesti&oacute;n que sea de su inter&eacute;s y que nos desee trasladar.</p>
      <ul>
      <li><strong>Correo electr&oacute;nico</strong>: hola@bookolicos.com</li>
      <li><strong>Correo postal</strong>: Calle R&iacute;o Guadalquivir 6, 4D. 28913 Legan&eacute;s, Madrid.</li>
      </ul>
      <p>Igualmente, EL TITULAR informa a todos sus usuarios que tienen a su disposici&oacute;n hojas de reclamaciones para plasmar en ellas las reclamaciones que consideren oportunas.</p>
      <p><strong>&nbsp;</strong></p>
      <ol start="9">
      <li><strong>9.- Legislaci&oacute;n aplicaci&oacute;n y Jurisdicci&oacute;n</strong></li>
      </ol>
      <p>Esta p&aacute;gina web queda sometida al marco legislativo espa&ntilde;ol que resulte de aplicaci&oacute;n. Para el caso de controversias surgidas del uso de esta p&aacute;gina web, ambas partes (el usuario y EL TITULAR) acuerdan sumisi&oacute;n expresa a los tribunales del municipio de Madrid.</p>
      <p>La anterior sumisi&oacute;n expresa a los tribunales de Madrid, no es de aplicaci&oacute;n para el caso de controversias que pudieran tener los usuarios de esta p&aacute;gina web que tengan consideraci&oacute;n de clientes de la p&aacute;gina web y, por ende, seg&uacute;n la normativa legal de aplicaci&oacute;n ostenten la condici&oacute;n de consumidores y usuarios. En tal caso, ambas partes estar&aacute;n sometidas a la jurisdicci&oacute;n de los Juzgados y Tribunales de la residencia del consumidor.</p>
      <p>EL TITULAR informa de manera expresa que no est&aacute; adherida a ning&uacute;n sistema de mediaci&oacute;n o arbitraje de consumo. No obstante, EL TITULAR informa por medio del presente Aviso Legal a todos aquellos usuarios que tengan consideraci&oacute;n de consumidores o usuarios seg&uacute;n la normativa legal de aplicaci&oacute;n, pueden acceder a distintos mecanismos de soluci&oacute;n alternativa de conflictos puestos a su disposici&oacute;n en cada Comunidad Aut&oacute;noma (Espa&ntilde;a).</p>
      <p>Texto de Aviso Legal redactado el día 10 de marzo de 2023</p>

    </section>
  );
}

export default AvisoLegal;
