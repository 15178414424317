import "./NameSection.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../Icon/Icon";

function NameSection(props) {
  const navigate = useNavigate();
  const styles = {
    backgroundImage: 'url(https://bookolicos-files.s3.eu-west-1.amazonaws.com/' + props.category + '.png)',
    backgroundColor: props.color || '#323232'
  }

  return (
    <section>
      <div className={`name-section__container ${props.full ? 'name-section__container-full' : ''}`} style={styles}>
        { props.hideClose ? <Icon name="close" color="secondary-6" size="medium" className="name-section__container-close" onClick={()=>{navigate('/')}} /> : null }
        { props.name && <h1 className="name-section__container-title">{ props.name }</h1> }
        { props.children &&
          <div className="name-section__children">
            {props.children}
          </div>
        }
      </div>
    </section>
  );
}
export default NameSection;
