import "./BooksAuthor.scss";
//import Icon from "../../Icon/Icon";
import BooksAuthorItem from "../BooksAuthorItem/BooksAuthorItem";
//import GenreItem from "../../GenreItem/GenreItem";

function BooksAuthor(props) {
  /*const renderGenres = () => {
    return props.book.categories.map((genre, index) => {
      if (index <= 1) {
        return (
          <GenreItem key={`${props.book.title}-${genre._id}`} genre={genre} />
        );
      }
      return null;
    });
  };*/
  const renderBooks = () => {
    return props.author.books.map((book) => {
      return <BooksAuthorItem book={book} author={props.author} key={book._id}/>;
    });
  };
  return (
    <section className="books-author">
      <p className="books-author__title">Sus obras</p>
      <div className="books-author__books">
        { renderBooks() }
      </div>
    </section>
  );
}

export default BooksAuthor;
