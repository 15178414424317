import * as dayjs from "dayjs";
import "dayjs/locale/es";
import React, { useState, useRef } from "react";
import { getApiSale, getApiSaleDownloadBook, putApiDislikeSale, putApiLikeSale } from "../../../services/apiSale";
import Button from "../../DesingSystem/Button/Button";
import Icon from '../../Icon/Icon';
import DownloadBookModal from "../DownloadBookModal/DownloadBookModal";
import ReviewForm from "../../ReviewForm/ReviewForm";
import "./ShoppingItem.scss";

function ShoppingItem({ purchase, updateList }) {
  const [downloads, setDownloads] = useState(purchase.downloads);
  const [liked, setLiked] = useState(purchase.like === 1);
  const [disliked, setDisliked] = useState(purchase.dislike === 1);
  const [neutral, setNeutral] = useState(purchase.neutral === 1);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  
  const modalReviewRef = useRef();

  const downloadBook = async () => {
    const blob = await getApiSaleDownloadBook({ token: purchase.token });

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${purchase.book.title}.epub`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);

    setDownloads(downloads + 1);
    setShowDownloadModal(false);
  };

  const toggleLike = async () => {
    setLiked(!liked);
    setDisliked(false);
    const { like, dislike } = await putApiLikeSale(purchase._id);
    setLiked(like === 1);
    setDisliked(dislike === 1);
  };

  const toggleDislike = async () => {
    setLiked(false);
    setDisliked(!disliked);
    const { like, dislike } = await putApiDislikeSale(purchase._id);
    setLiked(like === 1);
    setDisliked(dislike === 1);
  };
  
  const updatePurchaseReview = (purchase) => {
    setLiked(purchase.like)
    setDisliked(purchase.dislike)
    setNeutral(purchase.neutral)
  }

  return (
    <>
      <section className="shopping-item">
        <div className="shopping-item__container">
          <div className="shopping-item__container-component">
            <div>
              <img
                className="shopping-item__container-component-image"
                src={purchase.book.picture_medium ? purchase.book.picture_medium : '/images/image1.jpg'}
                alt="Imagen del libro"
              />
            </div>
            <div className="shopping-item__container-btn-colocation">
              <div className="shopping-item__container-component-component">
                <h3 className="shopping-item__container-component-title">
                  {purchase.book.title}
                </h3>
                <div className="shopping-item__container-component-section">
                  {purchase.book.sales === 1 ?
                    <p className="shopping-item__container-component-section-sales">
                      {purchase.book.sales ? purchase.book.sales : '0'} venta ·
                    </p>
                    :
                    <p className="shopping-item__container-component-section-sales">
                      {purchase.book.sales ? purchase.book.sales : '0'} ventas ·
                    </p>
                  }
                  <p className="shopping-item__container-component-section-date">
                    {purchase.created_at ? dayjs(purchase.created_at).format('D MMM YYYY') : '--'}
                  </p>
                </div>
                <div className="shopping-item__container-component-info-component">
                  {downloads === 1 ?
                    <p className="shopping-item__container-component-info">
                      {downloads ? downloads : '0'} vez descargado
                    </p>
                    :
                    <p className="shopping-item__container-component-info">
                      {downloads ? downloads : '0'} veces descargado
                    </p>
                  }
                </div>
              </div>
              <div className="shopping-item__container-btn-colocation-buttons">
                {purchase.token ?
                  <Button className="btn-orange download-btn" text="Descargar" onClick={() => downloadBook()} />
                  : null}
                <div className="shopping-item__container-btn-colocation-buttons-likes">                  
                  { liked || disliked || neutral ?
                    <button onClick={() => {modalReviewRef.current.toggleModal()}}>
                      Reseña <Icon name="tick" size="small" color={'secondary-3'} className={'ms-1'} />
                    </button>
                  :
                    <button onClick={() => {modalReviewRef.current.toggleModal()}}>
                      Escribir reseña
                    </button>
                  }
                </div>
              </div>
            </div>
            <div className="shopping-item__container-dot-menu">
              <button onClick={() => setShowDownloadModal(true)}>
                <Icon name="option" color="secondary-3" size="medium" />
              </button>
            </div>
          </div>
        </div>
      </section>
      <DownloadBookModal
        book={purchase.book}
        onHide={() => setShowDownloadModal(false)}
        onDownload={downloadBook}
      />
      
      <ReviewForm
        ref={modalReviewRef}
        show={showReviewModal}
        purchase={purchase}
        book={purchase.book}
        author={purchase.seller}
        onHide={updateList}
        onReview={updatePurchaseReview}
      />
    </>
  );
}

export default ShoppingItem;
