import "./PartOfTitleHome.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../DesingSystem/Button/Button";

function PartOfTitleHome() {
  const navigate = useNavigate();
  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <section className="part-title-home">
      <div className="part-title-home__container-bg">
        <p className="part-title-home__title">Llega más lejos con <i>Bookólicos</i></p>
        <p className="part-title-home__subtitle">
          La plataforma que ayuda a autores a conseguir su propio estilo y
          ofrece historias de calidad a lectores.
        </p>
        <div className="part-title-home__buttons">
          <div className="part-title-home__buttons-item-one">
            <Button
              className="btn-blackbg big"
              text="Empieza a escribir"
              onClick={goToLogin}
            />
          </div>
          <div className="part-title-home__buttons-item-two">
            <Button
              className="btn-blackbg big"
              text="Empieza a leer "
              onClick={goToLogin}
            />
          </div>
        </div>{" "}
      </div>
    </section>
  );
}

export default PartOfTitleHome;
