import "./BookNews.scss";
import React, { useEffect } from "react";
import Slider from "react-slick";

import BookNewsItem from "../BookNewsItem/BookNewsItem";
import { useSelector, useDispatch } from "react-redux";
import Arrows from "../../DesingSystem/Arrows/Arrows";

function BookNews(props) {
  const dispatch = useDispatch();
  const slider = React.useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 1.4,
    variableWidth: false,
    adaptiveHeight: false,
    className: "book-news__slider-item",

    responsive: [
      {
        breakpoint: 768,
        settings: {
          variableWidth: false,
          slidesToShow: 1.2
        }
      }
    ]
  };

  const onClickArrows = (direction) => {
    if (direction === "left") {
      slider.current.slickPrev();
    } else if (direction === "right") {
      slider.current.slickNext();
    }
  };

  useEffect(() => {
  }, [dispatch]);

  const renderBooks = () => {
    return props.books.map((book) => {
      return <BookNewsItem book={book} key={book._id} />;
    });
  };

  return (
    <div className="book-news">
      <div className="book-news__container-title">
        <div>
          <p className="book-news__title">{props.title}</p>
        </div>
        <div className="book-news__arrows">
          <Arrows
            size="medium"
            color="secondary-3"
            border="border-1"
            onClick={onClickArrows}
            showLeft={true}
            showRight={true}
          />
        </div>
      </div>
      {props.books ? (
        <div className="book-news__list">
          <Slider ref={slider} {...settings}>
            {renderBooks()}
          </Slider>
        </div>
      ) : null}
    </div>
  );
}

export default BookNews;
