import "./Arrows.scss";
import PropTypes from 'prop-types';

import Icon from "../../Icon/Icon";

function Arrows(props) {

  const onClick = (direction) => {
    if (props.onClick) {
      props.onClick(direction);
    }
  };

  return (
    <div className="arrows">
      { props.showLeft ?
        <div className="arrows__separation">
          <Icon
            name="chevronLeft"
            size={props.size}
            color={props.color}
            border={props.border}
            onClick={(event) => onClick('left')}
          />
        </div>
      : 
        <div className="arrows__separation">
          <Icon
            name="chevronLeft"
            size={props.size}
            color={props.color}
            border={props.border}
            className={'disabled'}
            onClick={() => {}}
          />
        </div>
      }

      { props.showRight ? 
        <div>
          <Icon
            name="chevronRight"
            size={props.size}
            color={props.color}
            border={props.border}
            onClick={(event) => onClick('right')}
          />
        </div>
      : 
        <div>
          <Icon
            name="chevronRight"
            size={props.size}
            color={props.color}
            border={props.border}
            className={'disabled'}
            onClick={() => {}}
          />
        </div>
      }
    </div>
  );
}

Arrows.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large', 'xlarge']),
  color: PropTypes.oneOf(['principal', 'secondary-1', 'secondary-3', 'secondary-6']),
  border: PropTypes.oneOf(['border-1']),
  onClick: PropTypes.func,
};

Arrows.defaultProps = {
  size: 'medium',
  color: 'secondary-1',
  border: 'border-1',
  onClick: undefined,
};


export default Arrows;
