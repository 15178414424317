import "./EventCard.scss";
import { Link } from "react-router-dom";
import Icon from "../Icon/Icon";
import * as dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

const EventCard = (props) => {
  const event = props.event;
  const navigate = useNavigate();
  const formatDate = (date) => {
    // Format date in Spanish to be like: 15 de Julio, 15:00.
    dayjs.locale('es');
    return dayjs(date).format('D [de] MMMM');
  }

  return (
    <div className={`event-card ${dayjs().isAfter(dayjs(event.date)) ? 'old' : '' }`}>
      <a href={ event.url } target="_blank">
        <div className="event-card__image">
          <Icon name="arrowDiagonal" color="secondary-7" size="xlarge" />
          <img className="event-card__image-bg" src={event.picture} alt="" />
        </div>
      </a>
      <div className="event-card__info">
        <div className="event-card__title">{event.title}</div>
        <div className="event-card__description">{event.description}</div>
        <div className="event-card__dates">
          <Icon name="calendar" color="secondary-2" />
          <div className="event-card__dates-text">
            {formatDate(event.date) + ' ' + event.hour}
          </div>
        </div>
        <div className="btn btn-blackbg"><a href={ event.url } target="_blank">Desde {event.price}€</a></div>
      </div>
    </div>
  )
}

export default EventCard;
