import "./ContactPrivate.scss";

import { useRef } from "react";
import NameSection from "../../components/NameSection/NameSection";
import Icon from "../../components/Icon/Icon";

function ContactPrivate() {
  const linkMail = useRef();

  return(
    <section className="contact-container">
      <NameSection name="Contacto" color="#f05a24" />

      <div className="row contact-container__row-title">
        <div className="col-10 offset-1 col-md-6 offset-md-3">
          <h2 className="contact-container__title">¿Sugerencias?</h2>
          <h2 className="contact-container__title">¿Algo que comentarnos?</h2>
        </div>
      </div>

      <div className="row mx-2 contact-container__row-options">

        <div className="col-12 col-md-5 pointer" onClick={() => { linkMail.current.click() }}>
          <div className="contact-container__option text-center py-5 my-1">
            <Icon name="write" size="large" color="secondary-1" className={'mx-auto'}/>
            <h3 className="contact-container__subtitle mt-5 mb-3">Escríbenos</h3>
            <p className="contact-container__text">
              <a href="mailto:hola@bookolicos.com" ref={linkMail}>hola@bookolicos.com</a>
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ContactPrivate;
