import "./Reasons.scss";

function Reasons() {
  return (
    <section className="reason">
      {/*<p className="reason__title">Los primeros Bookólicos</p>*/}

      <div className="reason__container-containers">
        <div className="reason__container-monse">
          <div className="reason__container-monse-section-image">
            <img
              className="reason__container-monse-section-image-img"
              src="/images/ilustration_publicar.svg"
              alt="vision"
            />
          </div>
          <div className="reason__container-monse-section-text">
            <p className="reason__container-monse-title">
              Publica tus obras y obtén ganancias
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-monse-text">
            Bookólicos te facilita el proceso de publicación como autor independiente y te ayuda con la promoción de tu obra. Obtén ganancias por tu trabajo y promueve la diversidad e inclusión literaria. ¡Monetiza tu talento y dedicación con nosotros!
            </p>
          </div>
        </div>

        <div className="reason__container-nestor">
          <div className="reason__container-nestor-section-text">
            <p className="reason__container-nestor-title">
              Descubre nuevos títulos y autores
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-nestor-text">
            Bookólicos ofrece un catálogo completo de obras literarias disponibles para su compra, con una amplia variedad de géneros y estilos. Ayúdanos a descubrir nuevos talentos y amplia tus opciones de lectura.
            </p>
          </div>
          <div className="reason__container-nestor-section-image">
            <img
              className="reason__container-nestor-section-image-img"
              src="/images/ilustration_nuevostitulos.svg"
              alt="mission"
            />
          </div>
        </div>

        <div className="reason__container-monse">
          <div className="reason__container-monse-section-image">
            <img
              className="reason__container-monse-section-image-img"
              src="/images/ilustration_por-para-autores.svg"
              alt="vision"
            />
          </div>
          <div className="reason__container-monse-section-text">
            <p className="reason__container-monse-title">
            Un espacio creado por y para autores y lectores
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-monse-text">
            Bookólicos nace de la experiencia de autores y lectores como tú. Comprendemos tus necesidades y deseos, por eso hemos creado un espacio sencillo y cómodo, donde lo único que importe sea tu adicción a las letras.
            </p>
          </div>
        </div>

        <div className="reason__container-nestor">
          <div className="reason__container-nestor-section-text">
            <p className="reason__container-nestor-title">
            Ahorra y contribuye al medio ambiente
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-nestor-text">
            El libro digital ahorra espacio y te permite decidir posteriormente si deseas adquirir una copia impresa, lo que ayuda a reducir las emisiones y evita la acumulación de libros en estanterías abarrotadas. En Bookólicos pensamos a largo plazo y en la huella que podemos dejar en el planeta.
            </p>
          </div>
          <div className="reason__container-nestor-section-image">
            <img
              className="reason__container-nestor-section-image-img"
              src="/images/ilustration_ahorrar.svg"
              alt="mission"
            />
          </div>
        </div>

        <div className="reason__container-monse">
          <div className="reason__container-monse-section-image">
            <img
              className="reason__container-monse-section-image-img"
              src="/images/ilustration_copywrite.svg"
              alt="vision"
            />
          </div>
          <div className="reason__container-monse-section-text">
            <p className="reason__container-monse-title">
            Derechos <br /> de autor
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-monse-text">
            Además de ofrecer un espacio para que nuestros usuarios publiquen, nos aseguramos de que el proceso sea completamente legal y de que tu obra esté protegida contra el plagio. Garantizamos la seguridad y la integridad de tu trabajo.
            </p>
          </div>
        </div>

        <div className="reason__container-nestor">
          <div className="reason__container-nestor-section-text">
            <p className="reason__container-nestor-title">
            Proyecto <br /> escalable
            </p>
            <div className="reason__border"> </div>
            <p className="reason__container-nestor-text">
            Lo que ves ahora es solo la punta del iceberg. En una segunda fase, acompañaremos a los autores en su proceso de escritura, ofreciendo cursos y correcciones para mejorar sus obras. También crearemos una comunidad donde los lectores y autores puedan interactuar libremente, fomentando el intercambio de ideas y debates literarios.
            </p>
          </div>
          <div className="reason__container-nestor-section-image">
            <img
              className="reason__container-nestor-section-image-img"
              src="/images/ilustration_escalable.svg"
              alt="mission"
            />
          </div>
        </div>


      </div>
    </section>
  );
}

export default Reasons;
