import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const postApiProcessForm = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}service/process-form`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes)
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      return responseJson;
      /*throw new Error(responseJson)*/
    }
    return response.json()
  });
};
