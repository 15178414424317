import "./FormCta.scss";
import Button from "../DesingSystem/Button/Button";
import { useNavigate } from "react-router-dom";

const FormCta = (props) => {
  const navigate = useNavigate();
  return (
    <div className="form-cta">
      <div className="form-cta__title">{props.text}</div>
      <Button className={'btn-blackbg'} text={'Completa el formulario'} onClick={ () => { navigate(props.url) } } />
    </div>
  )
}

export default FormCta;
