import "./MissionAndVision.scss";

function MissionAndVision() {
  return (
    <section className="mission-vision">
      <div className="mission-vision__container-mission">
        <div className="mission-vision__container-mission-section-text">
          <p className="mission-vision__container-mission-title">
            Nuestra misión
          </p>
          <p className="mission-vision__container-mission-text">
            En Bookólicos, fomentamos la profesionalización de los autores y la
            calidad de sus obras, para ofrecer el mejor producto posible a sus
            lectores. Uno de nuestros objetivos es brindar los recursos
            necesarios y simplificar procesos para ayudar a nuestros usuarios a
            alcanzar sus metas.
          </p>
        </div>
        <div className="mission-vision__container-mission-section-image">
          <img
            className="mission-vision__container-mission-section-image-img"
            src="/images/ilustration_nuestramision.svg"
            alt="mission"
          />
        </div>
      </div>

      <div className="mission-vision__container-vision">
        <div className="mission-vision__container-vision-section-image">
          <img
            className="mission-vision__container-vision-section-image-img"
            src="/images/ilustration_derechosautor.svg"
            alt="vision"
          />
        </div>
        <div className="mission-vision__container-vision-section-text">
          <p className="mission-vision__container-vision-title">
            Nuestra visión
          </p>
          <p className="mission-vision__container-vision-text">
          Queremos establecernos como la comunidad literaria líder en el idioma español. Un espacio de encuentro donde creemos que "Juntos llegamos más lejos". Buscamos fomentar la colaboración y apoyar a los  autores en su proceso creativo y, al mismo tiempo, brindar a los lectores nuevas obras literarias de alta calidad y disponibles a precios accesibles. Creemos en la importancia de crear un ambiente de interacción e intercambio de ideas, ya que estamos convencidos de que cuando trabajamos unidos, nuestras posibilidades de alcanzar logros significativos se multiplican.
          </p>
        </div>
      </div>
    </section>
  );
}

export default MissionAndVision;
