const initialState = {
  errorMessage: "",
  user: null,
  registerSuccess: false,
  error: false,
  userLogged: null,
  isLogged: false,
  isRegistered: undefined, // undefined
  emailToRegister: undefined,
  isLoggedErrorMessage: "",
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "IS_REGISTERED":
      return {
        ...state,
        isRegistered: action.payload,
        errorMessage: ""
      };

    case "IS_REGISTERED_SOCIAL":
      return {
        ...state,
        isRegistered: action.payload.isRegistered,
        emailToRegister: action.payload.emailToRegister,
        nameToRegister: action.payload.nameToRegister,
        lastNameToRegister: action.payload.lastNameToRegister,
        errorMessage: ""
      };

    case "REGISTER_SUCCESS":
      return {
        ...state,
        userLogged: action.payload,
        registerSuccess: true,
        isLogged: true,
        isLoggedErrorMessage: "",
        isRegistered: undefined,
        error: false,
        errorMessage: "",
      };

    case "SET_LOGIN":
      return {
        ...state,
        userLogged: action.payload,
        error: false,
        errorMessage: ""
      };

    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
        error: false,
        errorMessage: ""
      }; 

    case "GET_USERS_AUTHOR":
      console.log(action.payload)
      return {
        ...state,
        users: action.payload,
        error: false,
        errorMessage: ""
      };
    
    case "GET_USER_BY_ID":
      return {
        ...state,
        user: action.payload,
        error: false,
        errorMessage: ""
      };

    case "GET_USER_BY_NICKNAME":
      return {
        ...state,
        author: action.payload,
        error: false,
        errorMessage: ""
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
        error: false,
        errorMessage: ""
      };

    case "SET_USER_ERROR_MESSAGE":
      return {
        ...state,
        user : null,
        author: null,
        error: true,
        errorMessage: action.payload,
      };

    case "IS_LOGGED":
      return {
        ...state,
        userLogged: action.payload,
        isLogged: true,
        isLoggedErrorMessage: "",
        isRegistered: undefined,
        error: false,
        errorMessage: "",
      };

    case "IS_LOGGED_ERROR":
      return {
        ...state,
        isLogged: false,
        isLoggedErrorMessage: action.payload,
      };

    default:
      return state;
  }
};
