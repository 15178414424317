import "./ModalPublishBook.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Icon from "../../Icon/Icon";
import Button from "../../DesingSystem/Button/Button";
import { useState, useEffect } from "react";

function ModalPublishBook(props) {
  const navigate = useNavigate();
  const [copy, setCopy] = useState({
    title: "¡Enhorabuena!",
    subtitle: "Tu obra se ha publicado con éxito",
    text: "Recuerda que puedes editarla cuando quieras, entrando a la sección de 'Mis publicaciones' dentro de tu perfil."
  });

  useEffect(() => {
    if (props.paidISBN) {
      setCopy({
        title: "¡Pago confirmado!",
        subtitle: "Ya tienes tu código y tu obra publicada",
        text: `Tu obra ya tiene su código ISBN (Código: ${props.paidISBN}). Pronto recibirás un mail con la confirmación de tu compra. Recuerda que puedes editar tu obra cuando quieras, entrando a la sección de “Mis publicaciones” dentro de tu perfil.`
      })
    }
  }, [])

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.show}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-publish-book-container"
      >
        <Modal.Body>
          <div className="modal-publish-book">
            <div className="modal-publish-book__message">
              <Icon name="tick" size="large" color="principal-border-transparent" />
              <h2 className="modal-publish-book__title">{copy.title}</h2>
              <p className="modal-publish-book__subtitle">{copy.subtitle}</p>
              <p className="modal-publish-book__text">{copy.text}</p>
            </div>
            <div className="modal-publish-book__buttons">
              <div className="modal-publish-book__btn">
                <Button
                  className={"btn-white-transparent"}
                  text={"Ir a Mis publicaciones"}
                  onClick={() => {navigate("/profile/publicaciones")}}
                />
              </div>
              <div className="modal-publish-book__btn">
                <Button className={"btn-orangebg"}
                  iconRight="arrowLong"
                  text={"Ir a la home"}
                  onClick={() => { navigate("/") }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalPublishBook;
