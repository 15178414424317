import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../hooks/use-is-public";
import Avatar from "../../ProfileComponents/Avatar/Avatar";
import Icon from "../../Icon/Icon";
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { getApiBookReviews } from "../../../services/apiBook";

dayjs.extend(relativeTime);

function ReviewItem(props) {
  
  const [expanded, setExpanded] = useState('');
  
  return (
    <div className="reviews__container">
      <div className="reviews__container-image">
        { props.author && props.author.profilePic ? (
          <img
            className="reviews__container-image-img"
            src={props.review.customer.profilePic}
            alt="img"
          />
        ) : (
          <Avatar user={props.review.customer} size={'s'} />
        ) }
      </div>
      
      <div className="reviews__info">
        <div className="reviews__info-header">
          <p className="reviews__name">{props.review.customer ? props.review.customer.firstName + ' ' + props.review.customer.lastName : '--'}</p>
          <div className="reviews__section-likes">
            <Icon name="reviewLike" size="small" color={ props.review.like ? "principal" : "secondary-3" } />
            <Icon name="reviewNeutral" size="small" color={ props.review.neutral ? "principal" : "secondary-3" } />
            <Icon name="reviewDislike" size="small" color={ props.review.dislike ? "principal" : "secondary-3" } />
          </div>
        </div>
        
        <div className={`reviews__message ${expanded}`}>
          <p dangerouslySetInnerHTML={{ __html: (props.review.message ? props.review.message.replace(/\n/g, "<br />") : '') }} />
        </div>
        
        <div className="reviews__buttons">
          { props.review.message && props.review.message.length > 150 ?
            <button className="reviews__buttons-read-more" onClick={()=>{expanded === '' ? setExpanded('expanded') : setExpanded('')}}>
              {'Leer Más'}
            </button>
          :
          <button className="reviews__buttons-read-more"></button>
          }
          <p className="reviews__buttons-date">{dayjs(props.review.rated_at).fromNow()}</p>
        </div>
      </div>
    </div>
  );
}

export default ReviewItem;