import "./BannerPublic.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../DesingSystem/Button/Button";

function BannerPublic() {
  const navigate = useNavigate();

  const goToLogin = () => {
    navigate("/login");
  };

  return (
    <section className="banner-public">
      <div className="banner-public__container-bg">
        <p className="banner-public__title">
          Únete a Bookólicos y disfruta con lo que más te gusta
        </p>
        <div className="banner-public__buttons">
          <div className="banner-public__buttons-one">
            <Button
              className="btn-blackbg big"
              text="Empieza a escribir"
              onClick={goToLogin}
            />
          </div>
          <div className="banner-public__buttons-two">
            <Button
              className="btn-blackbg big"
              text="Empieza a leer"
              onClick={goToLogin}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
export default BannerPublic;
