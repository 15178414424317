import "./blog-item.scss";

import * as dayjs from "dayjs";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../hooks/use-is-public";

function BlogItem (props) {
  const navigate = useNavigate()
  const isPublic = useIsPublic()

  const handleClick = () => {
    if(isPublic){ 
      navigate(`/noticias/${props.slug}`)
    }else{
      navigate(`/blog/${props.slug}`)
    }

  }

  return (
    <div className="blog-item pointer" key={props._id} onClick={handleClick}>
      <div className="blog-item__image">
        <img src={props.image} alt={props.title}/>
      </div>
      <p className="blog-item__author">Por {props.author}
        <span className="blog-item__date"> | {dayjs(props.date).format('DD MMM YYYY')}</span>
      </p>
      <p className="blog-item__title">{props.title}</p>
      <span className="blog-item__readTime">{props.readTime} min de lectura</span>
    </div>
  );
}

export default BlogItem;
