import "./FormRegisterUser.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
//import { useNavigate } from "react-router-dom";
import ls from "../../../services/localStorage";
import InputText from "../../DesingSystem/InputText/InputText";
import Button from "../../DesingSystem/Button/Button";

function FormRegisterUser(props) {
  //const navigate = useNavigate();
  const [firstName, setFirstName] = useState(props.user ? props.user.firstName : "");
  const [lastName, setLastName] = useState(props.user ? props.user.lastName : "");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(props.user ? props.user.password : "");
  const [error, setError] = useState({});
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isLastNameEntered, setIsLastNameEntered] = useState(false);
  const [isPasswordEntered, setIsPasswordEntered] = useState(false);

  const handleInputEmailFocus = () => {
    setIsEmailEntered(true);
  };
  const handleInputEmailBlur = () => {
    setIsEmailEntered(false);
  };

  const handleInputNameFocus = () => {
    setIsNameEntered(true);
  };
  const handleInputNameBlur = () => {
    setIsNameEntered(false);
  };

  const handleInputLastNameFocus = () => {
    setIsLastNameEntered(true);
  };
  const handleInputLastNameBlur = () => {
    setIsLastNameEntered(false);
  };

  const handleInputPasswordFocus = () => {
    setIsPasswordEntered(true);
  };

  const handleInputPasswordBlur = () => {
    setIsPasswordEntered(false);
  };

 /* const errorMessage = useSelector((state) => {
    return state.user.errorMessage;
  });*/
  const emailToRegister = useSelector((state) => {
    return state.user.emailToRegister;
  });

  const nameToRegister = useSelector((state) => {
    return state.user.nameToRegister;
  });

  const lastNameToRegister = useSelector((state) => {
    return state.user.lastNameToRegister;
  });

  useEffect((attributes) => {
    const emailSaved = ls.get("APP_STATE_EMAIL");
    setEmail(emailSaved);

    setFirstName(typeof nameToRegister !== "undefined" ? nameToRegister : firstName)
    setLastName(typeof lastNameToRegister !== "undefined" ? lastNameToRegister : lastName)
    setPassword(typeof nameToRegister !== "undefined" && typeof lastNameToRegister !== "undefined" ? 'R4nD0mPa$$w0Rd' : '') // If social login add password to prevent API to decline register
  }, []);

  const onSubmit = async (event) => {
    event.preventDefault();
    let errorObject = {};

    if (firstName === "") {
      errorObject.firstName = <p className="register-user__error">Debes poner tu nombre</p>;
    }
    if (lastName === "") {
      errorObject.lastName = <p className="register-user__error">Debes poner tu apellido</p>;
    }
    if (email === "") {
      errorObject.email = <p className="register-user__error">Debes poner tu email</p>;
    }
    if (password === "") {
      errorObject.password = <p className="register-user__error">Debes poner tu contraseña</p>;
    }

    if (Object.keys(errorObject).length === 0) {
      await ls.set(
        "APP_STATE_EMAIL",
        typeof emailToRegister !== "undefined" ? emailToRegister : email
      );
      
      const attributes = {
        firstName: typeof nameToRegister !== "undefined" ? nameToRegister : firstName, //required
        lastName: typeof lastNameToRegister !== "undefined" ? lastNameToRegister : lastName, //required
        email: typeof emailToRegister !== "undefined" ? emailToRegister : email, //required
        password: password, //required
      };
      props.onStepSubmit(attributes);
    } else {
      setError(errorObject);
    }
  };

  const onChangeFirstName = (event) => {
    setFirstName(event.target.value);
  };

  const onChangeLastName = (event) => {
    setLastName(event.target.value);
  };

  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  };

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  };

  return (
    <section className="register-user">
      <h1 className="login-container__title">¡Bienvenido a Bookólicos!</h1>
      <form className="register-user__form" onSubmit={onSubmit}>
        <label className="register-user__form-label" htmlFor="name">
          Nombre*
        </label>
        <InputText
          error={isNameEntered}
          onFocus={handleInputNameFocus}
          onBlur={handleInputNameBlur}
          type="text"
          placeholder="Introduce tu nombre"
          value={
            typeof nameToRegister !== "undefined" ? nameToRegister : firstName
          }
          onChange={onChangeFirstName}
        />
        {Object.keys(error).length && Object.keys(error).includes('firstName') ? (
          <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error.firstName}</p>
        ) : null}

        <label className="register-user__form-label" htmlFor="name">
          Apellidos*
        </label>
        <InputText
          error={isLastNameEntered}
          onFocus={handleInputLastNameFocus}
          onBlur={handleInputLastNameBlur}
          type="text"
          placeholder="Introduce tus apellidos"
          value={
            typeof lastNameToRegister !== "undefined" ? lastNameToRegister : lastName
          }
          onChange={onChangeLastName}
        />
        {Object.keys(error).length && Object.keys(error).includes('lastName') ? (
          <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error.lastName}</p>
        ) : null}

        <label className="register-user__form-label" htmlFor="name">
          Email*
        </label>
        <InputText
          error={isEmailEntered}
          onFocus={handleInputEmailFocus}
          onBlur={handleInputEmailBlur}
          type="email"
          placeholder="Introduce tu email"
          value={
            typeof emailToRegister !== "undefined" ? emailToRegister : email
          }
          onChange={onChangeEmail}
          disabled
        />
        {Object.keys(error).length && Object.keys(error).includes('email') ? (
          <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error.email}</p>
        ) : null}

        { typeof nameToRegister !== "undefined" && typeof lastNameToRegister !== "undefined" ?
          null
        :
          <React.Fragment>
            <label className="register-user__form-label" htmlFor="name">
              Contraseña*
            </label>
            <InputText
              error={isPasswordEntered}
              onFocus={handleInputPasswordFocus}
              onBlur={handleInputPasswordBlur}
              type="password"
              placeholder="Introduce tu contraseña"
              autoComplete="new-password"
              value={password}
              onChange={onChangePassword}
            />

            {Object.keys(error).length && Object.keys(error).includes('password') ? (
              <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error.password}</p>
            ) : null}
          </React.Fragment>
        }

        <div className="register-user__container-btn mt-5 mt-md-4">
          <Button
            className={
              !email || !firstName || !lastName || !password
                ? "btn-gray w-90"
                : "btn-black-border w-90"
            }
            text={"Siguiente paso"}
            type={"submit"}
            // disabled={!email || !firstName || !lastName || !password}
          />
        </div>
      </form>

      <div className="register-user__container-btn">
        <button
          className="register-user__btn-return mb-5"
          onClick={() => {
            window.location.reload();
          }}
        >
          Volver
        </button>
      </div>
    </section>
  );
}

export default FormRegisterUser;
