import "./AuthorItemGenrePublic.scss";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../../hooks/use-is-public";
import Avatar from "../../../ProfileComponents/Avatar/Avatar";

function AuthorItemGenrePublic(props) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();
  const maxCaracteres = 10;
  return (
    <div
      className="author-item-public"
      onClick={() => {
        if (isPublic) {
          navigate("/perfil-autor/" + props.author.nickName);
        } else {
          navigate("/perfil-del-autor/" + props.author.nickName);
        }
      }}
    >
      <div className="author-item-public__container-image">
        {props.author.profilePic && props.author.profilePic !== "" ? (
          <img
            className="author-item-public__container-image-img"
            src={props.author.profilePic}
            alt="author-img"
          />
        ) : (
          <Avatar
            className={"avatar__squared"}
            user={{
              firstName: props.author.firstName,
              lastName: props.author.lastName,
            }}
            size={"xxl-authors"}
          />
        )}
      </div>
      <div className="author-item-public__container-text">
        <p className="author-item-public__name-author">{`${
          props.author.firstName.length > maxCaracteres
            ? props.author.firstName.substring(0, maxCaracteres) + "..."
            : props.author.firstName
        } ${
          props.author.lastName.length > maxCaracteres
            ? props.author.lastName.substring(0, maxCaracteres) + "..."
            : props.author.lastName
        }`}</p>
        {props.author.totalBooks === 1 ? (
          <p className="author-item-public__published">
            {" "}
            {`${props.author.totalBooks} obra publicada`}
          </p>
        ) : (
          <p className="author-item-public__published">
            {" "}
            {props.author.books && props.author.books.length === 1 ? (
              <React.Fragment>
                {`${props.author.books.length} obra publicada`}
              </React.Fragment>
            ) : (
              <React.Fragment>
                {`${
                  props.author.books ? props.author.books.length : 0
                } obras publicadas`}
              </React.Fragment>
            )}
          </p>
        )}
      </div>
    </div>
  );
}

export default AuthorItemGenrePublic;
