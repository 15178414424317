import "./ListAuthorsPublic.scss";

import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../../hooks/use-is-public";
import AuthorItemGenrePublic from "../../GenrePageComponentsPublic/AuthorItemGenrePublic/AuthorItemGenrePublic";


function AuthorsPublic(props) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();

  function renderAuthors(authors) {
    const limitedAuthors = props.authors;

    return limitedAuthors.users.map((author) => {
      if(author.books.length){
        const goToAuthors = () => {
          if(isPublic) {
            navigate("/perfil-autor/" + author.nickName);
          }else{
            navigate("/perfil-del-autor/" + author.nickName);
          }
        };

        return (
          <>
            <AuthorItemGenrePublic
              author={author}
              key={author._id}
            />
          </>
        );
        }else{
          return null
        }
    });
  }

  return props.authors ? (
    
    <div className="authors-public">
      {renderAuthors(props.authors)}
    </div>
  ) : null;

}

export default AuthorsPublic;
