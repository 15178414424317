import "./CoverSummary.scss";

function CoverSummary(props) {
  return (
    <section className="cover-summary">
      <div className="cover-summary__container">
        <img src={ URL.createObjectURL(props.book.cover[0]) } 
        className="cover-summary__container-img" 
        alt={ props.book.title + " cover" } />
      </div>

    </section>
  );
}
export default CoverSummary;
