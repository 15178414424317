import { types } from "../constants/constants";

export const postApiGenres = (attributes) => {
  return fetch(`${types.API_URL}genres/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then((response) => response.json());
};

export const getApiGenres = (attributes) => {
  return fetch(`${types.API_URL}genres`)
      .then((response) => response.json())
};

