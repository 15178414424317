import "./EditBook.scss";
import EditBookPublished from "../../components/PublishBook/EditBookPublished/EditBookPublished";

function EditBook() {
  return (
    <section className="edit-book">
      <EditBookPublished/>
    </section>
  );
}

export default EditBook;
