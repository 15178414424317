import "./Reviews.scss";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../hooks/use-is-public";
import ReviewItem from "./ReviewItem";
import * as dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/es';
import { getApiBookReviews } from "../../../services/apiBook";

dayjs.extend(relativeTime);

function Reviews(props) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();
  
  const [reviews, setReviews] = useState([]);
  const [totalReviews, setTotalReviews] = useState(0);
  const [limit, setLimit] = useState(3);
  const [offset, setOffset] = useState(0);
  
  useEffect(() =>{
    const getReviews = async () =>{
      setReviews([])
      setTotalReviews(0)
      
      const attributes = {
        bookId: props.book._id,
        limit: limit,
        offset: 0
      }
      const response = await getApiBookReviews(attributes)
      
      setReviews(response.reviews)
      setTotalReviews(response.total)
    }
    
    getReviews()
  }, [props.book])
  
  const renderReviews = () => {
    return reviews && reviews.map((review, reviewIndex) => {
      return <>
        <ReviewItem author={props.author} review={review} />
      </>;
    });
  };
  
  const loadMoreResults = async () =>{
    const newOffset = offset + limit;
    const attributes = {
      bookId: props.book._id,
      limit: limit,
      offset: newOffset
    }
    const response = await getApiBookReviews(attributes)
    
    const newReviews = reviews.concat(response.reviews)
    
    console.log(newReviews)
    
    setReviews(newReviews)
    setOffset(newOffset)
  }

  return (
    <div className="reviews">
      <p className="reviews__title">Reseñas ({ totalReviews })</p>
      
      { renderReviews() }
      
      { reviews && (totalReviews > reviews.length) ?
        <div className="reviews__more-reviews-wrapper mt-2">
            <button className="reviews__more-reviews-btn" onClick={ () => { loadMoreResults() } } >
              {'Ver más reseñas'}
            </button>
        </div>
      : null }
    </div>
  );
}

export default Reviews;
