import "./EditSummary.scss";
import React, { useState } from "react";
import Button from "../../DesingSystem/Button/Button";
import ModalTags from "../ModalTags/ModalTags";
import Genres from "../../Genres/Genres";
import GenreItem from "../../GenreItem/GenreItem";
import InputText from "../../DesingSystem/InputText/InputText";

function EditSummary(props) {
  const [isNameBookEntered, setIsNameBookEntered] = useState(false);
  const [isIsbnEntered, setIsIsbnEntered] = useState(false);
  const [isPriceEntered, setIsPriceEntered] = useState(false);
  const [modalTags, setModalTags] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
  };

  const onChangeInputName = (event) => {
    props.setBook({
      ...props.book,
      title: event.target.value,
    });
  };
  const onChangeInputIsbn = (event) => {
    props.setBook({
      ...props.book,
      isbn: event.target.value,
    });
  };
  const onChangeInputPrice = (event) => {
    props.setBook({
      ...props.book,
      price: event.target.value,
    });
  };
  const onChangeSinopsis = (event) => {
    props.setBook({
      ...props.book,
      summary: event.target.value,
    });
  };

  const showModalTags = () => {
    setModalTags(true);
  };

  const hideModalTags = () => {
    setModalTags(false);
  };

  const handleInputNameBookFocus = () => {
    setIsNameBookEntered(true);
  };
  const handleInputNameBookBlur = () => {
    setIsNameBookEntered(false);
  };
  const handleInputIsbnFocus = () => {
    setIsIsbnEntered(true);
  };
  const handleInputIsbnBlur = () => {
    setIsIsbnEntered(false);
  };
  const handleInputPriceFocus = () => {
    setIsPriceEntered(true);
  };
  const handleInputPriceBlur = () => {
    setIsPriceEntered(false);
  };

  return (
    <section className="edit-summary">
      <form className="edit-summary__form" onSubmit={onSubmit}>
        <label className="edit-summary__form-label" htmlFor="name">
          Nombre de la obra
        </label>
        <InputText
          error={isNameBookEntered}
          onFocus={handleInputNameBookFocus}
          onBlur={handleInputNameBookBlur}
          type="text"
          placeholder="Introduce el nombre de la obra"
          value={props.book.title}
          onChange={onChangeInputName}
        />

        <label className="edit-summary__form-label" htmlFor="name">
          Código ISBN
        </label>
        <InputText
          error={isIsbnEntered}
          onFocus={handleInputIsbnFocus}
          onBlur={handleInputIsbnBlur}
          type="text"
          placeholder="Introduce el ISBN"
          value={props.book.isbn}
          onChange={onChangeInputIsbn}
        />

        <div className="edit-summary__container-summary mb-3">
          <label className="edit-summary__form-label" htmlFor="name">
            Resumen de la trama
          </label>
          <textarea
            className="edit-summary__form-textarea"
            type="text"
            id="sinopsis"
            name="sinopsis"
            placeholder="Escribe aquí el resumen de tu obra."
            required
            value={props.book.summary}
            onChange={onChangeSinopsis}
          />
        </div>

        <label className="edit-summary__form-label" htmlFor="name">
          Precio de la obra
        </label>
        <InputText
          error={isPriceEntered}
          onFocus={handleInputPriceFocus}
          onBlur={handleInputPriceBlur}
          type="text"
          placeholder="Introduce el precio"
          value={`${props.book.price}`}
          onChange={onChangeInputPrice}
        />

        <div className="edit-summary__container-summary">
          <h3 className="edit-summary__title-tag">Género y Categorías</h3>
          {props.book && props.book.categories && props.book.categories.length ? (
            <React.Fragment>
              {props.book && props.book.genre && (
                <Genres genres={[props.book.genre]} align="left" className={ 'my-0' } isNavegable={false} />
              )}
              <Genres genres={props.book.categories} align="left" isNavegable={false} />
              <div className="edit-summary__container-buttons">
                <Button
                  iconLeft="pencil"
                  text={
                    props.book.categories && props.book.categories.length
                      ? "Editar tags"
                      : "Añadir tags"
                  }
                  onClick={showModalTags}
                />
              </div>
            </React.Fragment>
          ) : (
            <div className="edit-summary__container-buttons">
              <Button
                iconLeft="add"
                text={
                  props.book.categories && props.book.categories.length
                    ? "Editar tags"
                    : "Añadir tags"
                }
                onClick={showModalTags}
              />
            </div>
          )}
        </div>
      </form>

      <ModalTags
        show={modalTags}
        onHide={hideModalTags}
        book={props.book}
        setBook={props.setBook}
      />
    </section>
  );
}
export default EditSummary;
