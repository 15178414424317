import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";
import HowItWorks from "../../../components/aPublicArea/HomePublicComponents/HowItWorks/HowItWorks";
import LastNews from "../../../components/aPublicArea/HomePublicComponents/LastNews/LastNews";
import MoreGenres from "../../../components/aPublicArea/HomePublicComponents/MoreGenres/MoreGenres";
import PartOfTitleHome from "../../../components/aPublicArea/HomePublicComponents/PartOfTitleHome/PartOfTitleHome";
import VideoHome from "../../../components/aPublicArea/HomePublicComponents/VideoHome/VideoHome";
import WhyBookolicos from "../../../components/aPublicArea/HomePublicComponents/WhyBookolicos/WhyBookolicos";
import WritersPhrase from "../../../components/aPublicArea/HomePublicComponents/WritersPhrase/WritersPhrase";
import "./HomePublic.scss";

//import Genres from "../../components/Genres/Genres";

function HomePublic() {
  return (
    <section className="home-public">
      <PartOfTitleHome />
      <VideoHome />
      <div className="home-public__bg-one">
        <HowItWorks />
      </div>
      <WhyBookolicos />
      <div className="home-public__banner">
        <BannerPublic />
      </div>
      <div className="home-public__bg-one">
        <LastNews />
        <MoreGenres />
      </div>
      <WritersPhrase />
      <div>
        <BannerPublic className="home-public__banner" />
      </div>
    </section>
  );
}

export default HomePublic;
