import "./FormPublishBook.scss";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import UploadBook from "../UploadBook/UploadBook";
import UploadCover from "../UploadCover/UploadCover";
import BookInfoTags from "../BookInfoTags/BookInfoTags";
import BookInfo from "../BookInfo/BookInfo";
import Summary from "../Summary/Summary";
import CoverSummary from "../CoverSummary/CoverSummary";
import ExitPage from "../ExitPage/ExitPage";
import ModalPublishBook from "../../PublishBook/ModalPublishBook/ModalPublishBook"
import Icon from "../../Icon/Icon";
import Button from '../../DesingSystem/Button/Button'
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import { createBook } from "../../../redux/actions/book";
import { types } from "../../..//constants/constants";
import IsbnSelector from "../IsbnSelector/IsbnSelector";
import IsbnPayment from "../IsbnPayment/IsbnPayment";

function FormPublishBook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [book, setBook] = useState(undefined);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const [publishButtonIcon, setPublishButtonIcon] = useState('arrowLong');
  const [modalExitPage, setModalExitPage] = useState(false);
  const [modalPublishBook, setModalPublishBook] = useState(false);
  const errorMessage = useSelector((state) => { return state.book.errorMessage });

  const showModalExitPage = () => { setModalExitPage(true) };
  const hideModalExitPage = () => { setModalExitPage(false) };

  const acceptModalExitPage = () => {
    setModalExitPage(false);
    navigate("/book-list");
  };
  const hideModalPublishBook = (e) => {
    e && e.stopPropagation();
    setModalPublishBook(false);
  };

  const showModalPublishBook = (e) => {
    e && e.stopPropagation();
    setModalPublishBook(true);
  };

  const changeStep = (step) => {
    let error = false;
    window.scrollTo(0, 0);

    setBook({ ...book, error: undefined });

    if (step === 2) {
      if (!book.files) {
        setBook({ ...book, error: { file: "Debes subir tu obra" } });
        error = true;
      }

      if (!book.title || book.title === "") {
        setBook({ ...book, error: { title: "Debes escribir el nombre de tu obra" } });
        error = true;
      }
    }

    if (step === 3) {
      if (!book.cover) {
        setBook({ ...book, error: { cover : "Debes de subir la portada" } });
        error = true;
      }

      if (!book.price || book.price === "") {
        setBook({ ...book, error: { price: "Debes escribir el precio de tu obra" } });
        error = true;
      }

      if (book.acceptPolicy !== true) {
        setBook({ ...book, error: { acceptPolicy: "Debes aceptar las condiciones para publicar tu obra" } });
        error = true;
      }
    }

    if (error === false) setStep(step);
  };

  useEffect(() => {
    if (book && book.isbnPaid) {
      publishBook()
    }
  }, [book]);

  const publishBook = async () => {
    setPublishButtonDisabled(true)
    setPublishButtonIcon('loaderIcon')

    if (book.isbn === "PAID" && !book.isbnOwn && !book.isbnPaid) {
      setStep(5);
      return;
    }

    try{
      const token = ls.get("token");
      const loggedUser = validateToken.getUser(token);

      // Upload epub/mobi file
      let formDataBook = new FormData()

      formDataBook.append("file", book.files[0])

      let responseBookUpload = await fetch(`${types.API_URL}upload`, {
        method: "POST",
        headers : { token : token },
        body: formDataBook
      })

      if(!responseBookUpload.ok){ alert(await responseBookUpload.json()); return false; }

      const responseBookUploadJson = await responseBookUpload.json()

      // Upload cover image file
      let formDataCover = new FormData()

      formDataCover.append("file", book.cover[0])

      let responseCoverUpload = await fetch(`${types.API_URL}upload`, {
        method: "POST",
        headers : { token : token },
        body: formDataCover
      })

      if(!responseCoverUpload.ok){ alert(await responseCoverUpload.json()); return false; }

      const responseCoverUploadJson = await responseCoverUpload.json()

      // Build attrributes object to call API
      const arrayCategories = await book.tags.map(tag => {
        return tag._id
      })

      const getIsbn = () => {
        switch (book.isbn) {
          case "OWN":
            return book.isbnOwn
          case "PAID":
            return book.isbnPaid
          case "NONE":
            return null
          case "WON":
            return "WON"
        }
      }

      const attributes = {
        book : {
          title: book.title,
          subtitle: book.subtitle,
          summary: book.sinopsis,
          isbn: getIsbn(),
          price: book.price.replace(',', '.'),
          page_number: book.page_number,
          language: book.language,
          picture_big: responseCoverUploadJson.url_big,
          picture_medium: responseCoverUploadJson.url_medium,
          picture_thumb: responseCoverUploadJson.url_thumb,
          file: responseBookUploadJson.url_file,
          genre: book.genre,
          user: loggedUser,
          categories: arrayCategories
        }
      }

      dispatch(createBook(attributes))
      .then(() => {
        if(errorMessage === '') showModalPublishBook();
        setPublishButtonDisabled(false);
        setPublishButtonIcon('arrowLong');
      });
    } catch(err){
      setPublishButtonDisabled(false);
      setPublishButtonIcon('arrowLong');
      alert(err.message);
    }
  }

  const handleISBNPayment = async (isbn) => {
    await setBook({ ...book, isbnPaid: isbn });
    setStep(4);
  }

  const renderLeft = () => {
    switch (step) {
      case 1:
        return <UploadBook book={book} setBook={setBook} />;
      case 2:
        return <UploadCover book={book} setBook={setBook} />;
      case 4:
        return <CoverSummary book={book} />;
    }
  };

  const renderRight = () => {
    switch (step) {
      case 1:
        return <BookInfo book={book} setBook={setBook} />;
      case 2:
        return <BookInfoTags book={book} setBook={setBook} />;
      case 4:
        return <Summary book={book} />;
    }
  };

  const renderBottomBar = () => {
    if (step === 1) {
      const isDisabled = !book || !book.title || !book.sinopsis;
      return (
        <div className="form-publish-book__step">
          <p className="form-publish-book__step-text">01/04 General</p>
          <Button
          className={`${
            isDisabled
              ? "btn-graybg"
              : "btn-blackbg"
          }`}
          text="Siguiente paso"
          iconRight="arrowDiagonal"
          disabled={isDisabled}
          onClick={() => changeStep(2)} />
        </div>
      );
    } else if (step === 2) {
      const isDisabled = !book || !book.price || !book.acceptPolicy;
      return (
        <div className="form-publish-book__step">
          <p className="form-publish-book__step-text">02/04 Presentación</p>
          <div className="d-flex align-items-center">
            <button className="form-publish-book__button-return" onClick={() => setStep(1)}>Volver</button>
            <Button
            className={`${
              isDisabled
                ? "btn-graybg"
                : "btn-blackbg"
            }`}
            text="Siguiente paso"
            iconRight="arrowDiagonal"
            disabled={isDisabled}
            onClick={() => changeStep(3)} />
          </div>
        </div>
      );
    } else if (step === 3) {
      const isDisabled = !book || !book.isbn || (book.isbn === "OWN" && !(book.isbnOwn || '').match(/(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)/));
      return (
        <div className="form-publish-book__step">
          <p className="form-publish-book__step-text">03/04 Código ISBN</p>
          <div className="d-flex align-items-center">
            { errorMessage && errorMessage !== '' ?
              <span className="error">{ errorMessage }</span>
              :
              null
            }
            <button className="form-publish-book__button-return" onClick={() => setStep(2)}>Volver</button>
            <Button
              className={`${
                isDisabled
                  ? "btn-graybg"
                  : "btn-blackbg"
              }`}
              text="Siguiente paso"
              iconRight="arrowDiagonal"
              disabled={isDisabled}
              onClick={() => changeStep(4)} />
          </div>
        </div>
      );
    } else if (step === 4) {
      return (
        <div className="form-publish-book__step">
          <p className="form-publish-book__step-text">04/04 Resumen</p>
          <div className="d-flex align-items-center">
            { errorMessage && errorMessage !== '' ?
              <span className="error">{ errorMessage }</span>
            :
              null
            }
            <button className="form-publish-book__button-return" onClick={() => setStep(3)}>Volver</button>
            <Button
              className={'btn-orangebg ms-4'}
              text="Publicar"
              iconRight={publishButtonIcon}
              disabled={publishButtonDisabled}
              onClick={() => publishBook()} />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="form-publish-book">
      <ExitPage
        show={modalExitPage}
        onAccept={acceptModalExitPage}
        onHide={hideModalExitPage}
      />
      <div className="form-publish-book__header">
        { step > 1 ? <Icon className="back" name="arrowLeft" size="medium" color="secondary-3" onClick={() => { setStep(step - 1) }} /> : <div className="pe-4"></div>}
        <p className="form-publish-book__header-title">Publica tu obra</p>
        <Icon name="close" size="medium" onClick={showModalExitPage} color="principal" />
      </div>
      <div className="form-publish-book__content">
        { step === 3 ?
          (
            <IsbnSelector book={book} setBook={setBook} />
          )
        : null }
        { step === 5 ?
          (
            <IsbnPayment book={book} setBook={setBook} publish={handleISBNPayment} />
          )
        : null }
        { [1, 2, 4].includes(step) ?
          ( <>
              <div className="form-publish-book__content-item">{renderLeft()}</div>
              <div className="form-publish-book__content-item">{renderRight()}</div>
            </>
          )
        : null }
      </div>
      {step !== 5 ?
        <div className="form-publish-book__position-bar">{renderBottomBar()}</div>
      : null}
      <ModalPublishBook
        show={modalPublishBook}
        paidISBN={book && book.isbnPaid}
        onHide={hideModalPublishBook} />
    </div>
  );
}

export default FormPublishBook;
