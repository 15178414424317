import "./AuthorItemGenre.scss";
import { useNavigate } from "react-router-dom";
import Avatar from "../../ProfileComponents/Avatar/Avatar";


function AuthorItemGenre(props) {
  const navigate = useNavigate();
  const maxCaracteres = 10;
  return (
      <div className="author-item" onClick={() => { navigate("/perfil-del-autor/" + props.author.nickName)}}>
        <div className="author-item__container-image">
          { props.author.profilePic && props.author.profilePic !== '' ?
              <img className="author-item__container-image-img" src={props.author.profilePic} alt="author-img" />
            :
            <Avatar
              className={'avatar__squared'}
              user={{ firstName: props.author.firstName, lastName: props.author.lastName }}
              size={"xxl-authors"}
            />
            }
        </div>
        <div className="author-item-public__container-text">
        <p className="author-item__name-author">{`${
        props.author.firstName.length > maxCaracteres
          ? props.author.firstName.substring(0, maxCaracteres) + "..."
          : props.author.firstName
      } ${
        props.author.lastName.length > maxCaracteres
          ? props.author.lastName.substring(0, maxCaracteres) + "..."
          : props.author.lastName
      }`}</p>
        { props.author.totalBooks === 1 || props.totalBooks === 1 ?
          <p className="author-item__published"> {`${ (props.totalBooks ? props.totalBooks : props.author.totalBooks) || 0 } obra publicada`}</p>
        :
          <p className="author-item__published"> {`${ (props.totalBooks ? props.totalBooks : props.author.totalBooks) || 0 } obras publicadas`}</p>
        }
            </div>
      </div>
  );
}

export default AuthorItemGenre;
