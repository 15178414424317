import "./BookListAll.scss";
import BookList from "../../components/BookCatalog/BookList/BookList";
import NameSection from "../../components/NameSection/NameSection";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

function BookListAll() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userLogged = useSelector((store) => store.user.userLogged);

  return (
    <section className="book-list-all">
      <NameSection name="Catálogo" color="#f05a24" />
      <BookList />
    </section>
  );
}

export default BookListAll;
