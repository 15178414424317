import "./CheckBox.scss";
import PropTypes from "prop-types";

function CheckBox(props) {
  return (
    <div className={`check-box ${props.className}`}>
      { props.checked === true ?
        <input
          className={`check-box__input`}
          type="checkbox"
          id={props.id}
          name={props.name}
          onChange={props.onChange}
          checked
        />
      :
        <input
          className={`check-box__input`}
          type="checkbox"
          id={props.id}
          name={props.name}
          onChange={props.onChange}
        />
      }
      { props.hasLink ?
        <label className="check-box__text" dangerouslySetInnerHTML={{ __html: props.label }}></label>
      :
        <label className="check-box__text" htmlFor={props.id} onClick={(event) => { event.preventDefault() }}>{ props.label }</label>
      }
    </div>
  );
}

CheckBox.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
};

CheckBox.defaultProps = {
  id: "checkbox",
  value: "checkbox",
  name: "Ejemplo de checkbox",
};

export default CheckBox;
