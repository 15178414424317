import "./Bundle.scss";

import BundleCourse from "../../components/BundleCourse/BundleCourse";

import { useEffect, useState } from "react";
import NameSection from "../../components/NameSection/NameSection";

import { ReactComponent as Tick } from "../../styles/icons/tick.svg";
import { ReactComponent as BundleBuyImage } from "../../styles/images/bundle-buy.svg";
import { ReactComponent as BundleImage } from "../../styles/images/bundle.svg";
import ReviewAuthorImage from "../../styles/images/review-author.jpeg";

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BundleReview from "../../components/BundleReview/BundleReview";
import Button from "../../components/DesingSystem/Button/Button";
import ModalBuy from "../../components/ModalBuy/ModalBuy.jsx";
import { getApiBundle, postApiCreateBundleSale } from "../../services/apiBundle";

/**
 * @typedef {import("../../services/apiBundle").Bundle} Bundle
 */

/**
 * @typedef {import("../../components/BundleReview/BundleReview").Review} Review
 */

const Bundle = () => {
  const navigate = useNavigate();

  /** @type {[Review[], React.Dispatch<Review[]>]} bundleState */
  const [reviews, setReviews] = useState(null);

  /** @type {[Bundle, React.Dispatch<Bundle>]} bundleState */
  const [bundle, setBundle] = useState(null);

  /** @type {[number, React.Dispatch<number>]} courseBarTopState */
  const [coursesBarTop, setCoursesBarTop] = useState();

  /** @type {[string, React.Dispatch<string>]} activeCourseLinkState */
  const [activeCoursesLink, setActiveCoursesLink] = useState('courses');

  /** @type {[number, React.Dispatch<number>]} fullPriceState */
  const [fullPrice, setFullPrice] = useState(0);

  /** @type {[number, React.Dispatch<number>]} discountState */
  const [discount, setDiscount] = useState(0);

  /** @type {[boolean, React.Dispatch<boolean>]} discountState */
  const [showBuyModal, setShowBuyModal] = useState(false);

  const user = useSelector(store => store.user.user);

  const createSale = async (payment_intent) => {
    await postApiCreateBundleSale({
      salebundle: {
        amount: bundle.price,
        bundle: bundle._id,
        customer: user._id,
        payment_intent,
        status: 1
      }
    });
  };

  const getBundle = async () => {
    const { bundle } = await getApiBundle();

    const fullPrice = bundle.courses.reduce((fullPrice, course) => fullPrice + course.price, 0);
    const discount = Math.ceil((1 - bundle.price / fullPrice) * 100);

    setBundle(bundle);
    setFullPrice(fullPrice);
    setDiscount(discount);
  };

  useEffect(() => {
    setTimeout(() => {
      const coursesBar = document.querySelector('.bundle__main-menu');
      if (coursesBar) {
        setCoursesBarTop(coursesBar.getBoundingClientRect().top);
      }
    }, 100);
  }, [bundle]);

  useEffect(() => {
    if (!coursesBarTop) return () => {};

    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  }, [coursesBarTop]);

  const isSticky = () => {
    const coursesBar = document.querySelector('.bundle__main-menu');
    const scrollTop = window.scrollY;

    if (scrollTop >= coursesBarTop) {
      coursesBar.classList.add('sticky');
    } else {
      coursesBar.classList.remove('sticky');
    }
  };

  useEffect(() => {
    getBundle();

    setReviews([{
      review: "Gracias a los cursos de Bookolicos mi carrera como escritor despegó. Su curso de escritura rápida me proporcionó las herramientas esenciales para pulir mis habilidades y conectar con mi audiencia. ¡Una experiencia inigualable para escritores en ciernes!",
      name: "Carla Diez de Sotomayor 1",
      title: "escritora",
      img: ReviewAuthorImage
    }, {
      review: "El curso de creación de personajes es esencial para todo escritor. A través de ejercicios prácticos y consejos expertos, aprendí a dar vida a personajes memorables y auténticos. ¡Una inversión inestimable para mejorar mis historias!",
      name: "Carla Diez de Sotomayor 2",
      title: "escritora",
      img: ReviewAuthorImage
    }, {
      review: "Cada lección está cuidadosamente diseñada para nutrir la creatividad literaria de manera impactante. Gracias a esta experiencia educativa única, he experimentado una transformación en mi capacidad para escribir. Las lecciones detalladas me han permitido entender cómo construir personajes auténticos y diálogos cautivadores. Este curso ha sido verdaderamente inspirador y ha superado todas mis expectativas.",
      name: "Carla Diez de Sotomayor 3",
      title: "escritora",
      img: ReviewAuthorImage
    }, {
      review: "Gracias a este curso, ahora tengo las habilidades y la confianza para perseguir mi pasión por la escritura y soñar con mi primer libro.",
      name: "Carla Diez de Sotomayor 4",
      title: "escritora",
      img: ReviewAuthorImage
    }]);
  }, []);

  return bundle ? (
    <>
      <NameSection color="#f05a24" />
      <div className="bundle__hero">
        <div className="bundle__hero-details">
          <h1>{bundle.title}</h1>
          <p>{bundle.description}</p>
          <ul>
            {bundle.courses.map(course => (
              <li key={course._id}>
                <span><Tick /></span>
                <span>{course.title} ({course.price}€)</span>
              </li>
            ))}
          </ul>
          <div className="bundle__hero-details-pricing">
            <span>{fullPrice}€</span>
            <span>{bundle.price}€</span>
            <span>(Más 21% de IVA)</span>
          </div>
          <Button
            className="button btn-orangebg big"
            text="Cómpralo ya"
            onClick={() => setShowBuyModal(true)}
          />
        </div>
        <BundleImage />
      </div>
      <div className="bundle__main">
        <div className="bundle__main-menu">
          <div>
            <span>{bundle.title}</span>
            <span>|</span>
            <a
              onClick={() => setActiveCoursesLink('courses')}
              className={`${activeCoursesLink === 'courses' ? '' : 'link'}`}
              href="#courses"
            >
              Cursos
            </a>
            <a
              onClick={() => setActiveCoursesLink('reviews')}
              className={`${activeCoursesLink === 'reviews' ? '' : 'link'}`}
              href="#reviews"
            >
              Reviews
            </a>
            <a
              onClick={() => setActiveCoursesLink('buy')}
              className={`${activeCoursesLink === 'buy' ? '' : 'link'}`}
              href="#buy"
            >
              Precio
            </a>
          </div>
          <Button
            className="button btn-orangebg"
            text="Cómpralo ya"
            onClick={() => setShowBuyModal(true)}
          />
        </div>
        <div className="bundle__main-courses" id="courses">
          <h1>Cursos incluidos</h1>
          {bundle.courses.map((course, index) => (
            <div className="bundle__main-courses-course" key={course._id}>
              <BundleCourse
                course={course}
                courseNumber={`Curso ${index + 1}`}
              />
            </div>
          ))}
        </div>
        <div className="bundle__main-reviews" id="reviews">
          <div className="bundle__main-reviews-heading">
            <h1>Sube de nivel a tu ritmo</h1>
            <p>Otros compañeros que han impulsado sus carreras con nuestros cursos</p>
          </div>
          <div className="bundle__main-reviews-content">
            {reviews.map(review => (<BundleReview key={review.name} review={review} />))}
          </div>
        </div>
        <div className="bundle__main-buy" id="buy">
          <div className="bundle__main-buy-heading">
            <h1>Aprovecha el descuento de éste bundle.</h1>
            <p>Todos estos cursos te ayudarán a llevar sus habilidades como escritor/a a nuevas cotas. Domina desde los fundamentos hasta los toques finales.</p>
          </div>
          <div className="bundle__main-buy-ca">
            <div>
              <h1>Únete ahora</h1>
            </div>
            <div>
              <span>{fullPrice}€</span>
              <span>{bundle.price}€</span>
            </div>
            <Button
              className="button btn-blackbg big"
              text={`Cómpralo ahora y ahorra ${discount}%`}
              onClick={() => setShowBuyModal(true)}
            />
            <div>
              <span>¿Necesitas ayuda con el pago?</span>
              <a href="">Contacta con nosotros</a>
            </div>
          </div>
          <div className="bundle__main-buy-items">
            <div>
              <h1>¿Qué incluye?</h1>
            </div>
            <ul>
              {bundle.courses.map(course => (
                <li key={course._id}>
                  <span><Tick /></span>
                  <span>{course.title} ({course.price}€)</span>
                </li>
              ))}
            </ul>
          </div>
          <BundleBuyImage />
        </div>
      </div>
      <ModalBuy
        price={bundle.price}
        show={showBuyModal}
        onHide={() => setShowBuyModal(false)}
        onCreateSale={createSale}
        confirmPaymentConfig={{ redirect: 'if_required' }}
        confirmSetupConfig={{ redirect: 'if_required' }}
        secondStep={<div className="buy-book pt-3">
          <h3 className="buy-book__title text-center">
            ¡Enhorabuena! Tu compra se ha completado con éxito
          </h3>
          <p className="buy-book__text-download">
            En breves recibirás un correo con la confirmación de tu compra y el enlace para acceder al curso.
          </p>
          <div className="buy-book__container-button">
            <Button
              className="btn-orange mt-4"
              text="Ir a mis cursos"
              onClick={() => navigate("/profile/tus-compras")}
            />
            <Button
              className="btn-gray mt-4"
              text="Cerrar"
              onClick={() => setShowBuyModal(false)}
            />
          </div>
        </div>}
      />
    </>
  ) : null;
};

export default Bundle;
