import "./ConfirmationSale.scss";
import React, { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Button from "../../components/DesingSystem/Button/Button";
import { putApiUpdateSale, getApiSaleDownloadBook } from "../../services/apiSale";


function ConfirmationSale() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [sale, setSale] = useState(null);
  
  useEffect(() => {
    async function confirmSale() {
      const payment_intent = searchParams.get("payment_intent")
      const payment_intent_secret = searchParams.get("payment_intent_client_secret")

      const response = await putApiUpdateSale({
        status : 1,
        payment_intent : payment_intent,
        payment_intent_secret : payment_intent_secret
      })

      setSale(response)
    }

    confirmSale()
  }, []);

  const downloadBook = async () =>{
    const blob = await getApiSaleDownloadBook({ token : sale.token })

    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `${sale.book.title}.epub`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  }
  
  return (
    <section className="confirmation">
      <img
        className="confirmation__img"
        src="/images/silla-2.png"
        alt="sale-confirmation"
      />
      <div className="confirmation__section-text">
        { sale ?
          <React.Fragment>
            <h1 className="confirmation__title text-center">¡Enhorabuena! Tu compra se ha completado con éxito</h1>
            <p className="confirmation__text text-center">
                Tú eliges cuando comenzarla a leer ¡Puedes descárgatela ahora mismo,
                o cuando quieras! Solo recuerda que puedes encontrarla yendo a tu
                perfil, dentro de la sección “Mis compras” ¡Buena lectura!
            </p>
            <Button className={"btn-orange mb-2"} text={"Descargar ahora"} onClick={() => { downloadBook() }} />
            <Button className={"btn-gray"} text={"Descargar más tarde"} onClick={ () => navigate('/book-list') } />
          </React.Fragment>
        :
          <h1 className="confirmation__title text-center">Procesando tu compra...</h1>
        }
      </div>
    </section>
  );
}

export default ConfirmationSale;
