import "./IsbnPayment.scss";
import logoHeader from "../../../styles/images/logo.svg";
import Button from "../../DesingSystem/Button/Button";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import { getUserById } from "../../../redux/actions/user";
import { loadStripe } from "@stripe/stripe-js";
import { types } from "../../../constants/constants";
import {getStripePaymentMethods, postApiCreatePaymentIntent} from "../../../services/apiUser";
import Icon from "../../Icon/Icon";
import {Elements} from "@stripe/react-stripe-js";
import CheckoutISBNForm from "../../CheckoutISBNForm/CheckoutForm";
import poweredByStripe from "../../../styles/images/powered-by-stripe.svg";
import { postApiCreateIsbnSale } from "../../../services/apiSale";

function IsbnPayment(props) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [isbn, setIsbn] = useState("");
  const [active_payment_intent, setActivePaymentIntent] = useState("");
  const [optionsCheckout, setOptionsCheckout] = useState({});
  const [card_selected, setCardSelected] = useState("");
  const [errorPayment, setErrorPayment] = useState("");

  const [confirmButtonIcon, setConfirmButtonIcon] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);

  // Get user data
  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    dispatch(getUserById(tokenDecoded.user._id));
  }, []);


  // Get payment methods
  async function getPaymentMethods() {
    const paymentMethods = await getStripePaymentMethods(user._id);
    setPaymentMethods(paymentMethods);
  }

  // Get Stripe payment intent
  async function getPaymentIntent () {
    const pi = await postApiCreatePaymentIntent({
      amount: 1000,
      payment_method: null,
      ...(user.stripe_customer && { customer: user.stripe_customer }),
      account: null
    })

    await setActivePaymentIntent(pi.payment_intent.id);

    await setOptionsCheckout({
      locale: "es",
      clientSecret: pi.secret,
      appearance: {
        theme: "flat",
        rules: {
          ".Label": {
            fontSize: "12px",
            textTransform: "uppercase",
            color: "#656565",
            marginBottom: "10px",
            fontFamily: "Novela Regular",
          },
          ".Tab": {
            border: "1px solid #979797",
            backgroundColor: "white",
            marginBottom: "8px"
          },
          ".Tab--selected": {
            color: "#323232",
            border: "1px solid #979797",
            backgroundColor: "#F7F7F7"
          },
          ".Tab--selected:hover": {
            color: "#323232",
          },
          ".Tab:focus": {
            outline: "none",
            boxShadow: 0
          },
          ".Tab--selected:focus": {
            outline: "none",
            boxShadow: 0
          },
          ".TabIcon--selected": {
            fill: "#323232"
          },
          ".TabIcon--selected:hover": {
            fill: "#323232"
          },
          ".Input": {
            border: "1px solid #656565",
            borderRadius: "32px",
            backgroundColor: "#ffffff",
            paddingTop: "8px",
            paddingBottom: "8px",
            lineHeight: "24px",
            fontFamily: "Novela Regular",
          },
          ".Input:focus": {
            borderColor: "#f05a24",
            outline: "none",
            boxShadow: "none",
          },
        }
      }
    });
  }

  useEffect(() => {
    if (!user) {
      return;
    }

    getPaymentMethods();
    getPaymentIntent();
  }, []);

  // Stripe
  const stripe = loadStripe(types.STRIPE_API_KEY)

  const selectCard = (id) => {
    if (card_selected === id) {
      setCardSelected(null);
      getPaymentIntentNewCard();
    } else {
      setCardSelected(id);
      getPaymentIntent(id);
    }

    async function getPaymentIntent(id = null) {
      const pi = await postApiCreatePaymentIntent({
        amount: 1000,
        payment_method: id,
        ...(user.stripe_customer && { customer: user.stripe_customer }),
        account: null,
      });
      setActivePaymentIntent(pi.payment_intent.id);
      setOptionsCheckout({
        locale: "es",
        clientSecret: pi.secret,
        appearance: {
          theme: "flat",
          rules: {
            ".Label": {
              fontSize: "12px",
              textTransform: "uppercase",
              color: "#656565",
              marginBottom: "10px",
              fontFamily: "Novela Regular",
            },
            ".Tab": {
              border: "1px solid #979797",
              backgroundColor: "white",
              marginBottom: "8px"
            },
            ".Tab--selected": {
              color: "#323232",
              border: "1px solid #979797",
              backgroundColor: "#F7F7F7"
            },
            ".Tab--selected:hover": {
              color: "#323232",
            },
            ".Tab:focus": {
              outline: "none",
              boxShadow: 0
            },
            ".Tab--selected:focus": {
              outline: "none",
              boxShadow: 0
            },
            ".TabIcon--selected": {
              fill: "#323232"
            },
            ".TabIcon--selected:hover": {
              fill: "#323232"
            },
            ".Input": {
              border: "1px solid #656565",
              borderRadius: "32px",
              backgroundColor: "#ffffff",
              paddingTop: "8px",
              paddingBottom: "8px",
              lineHeight: "24px",
              fontFamily: "Novela Regular",
            },
            ".Input:focus": {
              borderColor: "#f05a24",
              outline: "none",
              boxShadow: "none",
            },
          }
        }
      })
    }

    async function getPaymentIntentNewCard() {
      const pi = await postApiCreatePaymentIntent({
        amount: 1000,
        payment_method: null,
        ...(user.stripe_customer && { customer: user.stripe_customer }),
        account: null,
      });

      setActivePaymentIntent(pi.payment_intent.id);

      setOptionsCheckout({
        locale: "es",
        clientSecret: pi.secret,
        appearance: {
          theme: "flat",
          rules: {
            ".Label": {
              fontSize: "12px",
              textTransform: "uppercase",
              color: "#656565",
              marginBottom: "10px",
              fontFamily: "Novela Regular",
            },
            ".Tab": {
              border: "1px solid #979797",
              backgroundColor: "white",
              marginBottom: "8px"
            },
            ".Tab--selected": {
              color: "#323232",
              border: "1px solid #979797",
              backgroundColor: "#F7F7F7"
            },
            ".Tab--selected:hover": {
              color: "#323232",
            },
            ".Tab:focus": {
              outline: "none",
              boxShadow: 0
            },
            ".Tab--selected:focus": {
              outline: "none",
              boxShadow: 0
            },
            ".TabIcon--selected": {
              fill: "#323232"
            },
            ".TabIcon--selected:hover": {
              fill: "#323232"
            },
            ".Input": {
              border: "1px solid #656565",
              borderRadius: "32px",
              backgroundColor: "#ffffff",
              paddingTop: "8px",
              paddingBottom: "8px",
              lineHeight: "24px",
              fontFamily: "Novela Regular",
            },
            ".Input:focus": {
              borderColor: "#f05a24",
              outline: "none",
              boxShadow: "none",
            },
          }
        }
      })
    }
  };

  const payWithSavedCard = async () => {
    setConfirmButtonDisabled(true)
    setConfirmButtonIcon('loaderIcon')

    try {
      // Save sale on ddbb with status pending
      const createResp = await postApiCreateIsbnSale({
        saleisbn: {
          ...(props.book && props.book.title && { title: props.book.title }),
          amount: 1000,
          customer: user._id,
          payment_intent: active_payment_intent,
          status: 1,
        },
      });
      setIsbn(createResp.isbn);

      const stripeJS = await loadStripe(types.STRIPE_API_KEY);

      const response = await stripeJS.confirmCardPayment(
        optionsCheckout.clientSecret,
        {
          payment_method: card_selected
        }
      );

      // Update sale on ddbb with status succeeded
      if (response.paymentIntent.status === "succeeded") {
        props.publish(createResp.isbn);
      } else {
        setErrorPayment(
          response.error
            ? response.error.message
            : "Error with confirm payment Stripe"
        );
      }
    } catch (error) {
      setErrorPayment(error);
      console.log(error.stack);
    } finally {
      setConfirmButtonDisabled(false)
      setConfirmButtonIcon('')
    }
  };

  const handleSuceessPayment = ({ isbn }) => {
    props.publish(isbn);
  }

  return (
    <section className="isbn-payment">
      <div className="isbn-payment__section isbn-payment__section--dark">
        <img
          className="isbn-payment__logo-header"
          src={logoHeader}
          alt="Logo Bookolicos"
        />
        <p className="isbn-payment__title">Compra de ISBN</p>
        <p className="isbn-payment__title-price">10.00€</p>
        <p className="isbn-payment__summary">
          <span>A pagar hoy</span>
          <span>10.00€</span>
        </p>
      </div>
      <div className="isbn-payment__section">
        <p className="isbn-payment__payment-title">Introduce tus datos de pago</p>
          <div className="isbn-payment__stripe">
            <h3 className="isbn-payment__stripe-title">Elige tu medio de pago</h3>

            <div className="isbn-payment__stripe-container-card mb-4">
              {paymentMethods &&
                paymentMethods.map((pm) => (
                  <div
                    className={`isbn-payment__stripe-container-card-item ${
                      card_selected === pm.id ? "selected" : ""
                    }`}
                    key={pm.id}
                    onClick={() => {
                      selectCard(pm.id);
                    }}
                  >
                    <Icon name={pm.card.brand} size="large" color="secondary-3" />
                    <p className="isbn-payment__stripe-container-card-item-text">
                      **** **** **** {pm.card.last4}
                      <br />
                      Exp. {pm.card.exp_month}/{pm.card.exp_year}
                    </p>
                  </div>
                ))}
            </div>

            {!paymentMethods || paymentMethods.length === 0 ? (
              <p className="mb-5 not-card">
                No tienes ningún método de pago almacenado
              </p>
            ) : null}

            {!card_selected && active_payment_intent ? (
              <React.Fragment>
                <h3 className="isbn-payment__stripe-title mb-4">
                  o utiliza una nueva tarjeta
                </h3>

                <div className="isbn-payment__stripe-container-card-form">
                  <Elements stripe={stripe} options={optionsCheckout}>
                    <CheckoutISBNForm
                      btnText={"Finalizar compra"}
                      btnClass={"btn-orangebg"}
                      onSucceededPayment={handleSuceessPayment}
                      book={props.book}
                    />
                  </Elements>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <Button
                  iconRight={confirmButtonIcon}
                  disabled={confirmButtonDisabled}
                  text={`${props.btnText ? props.btnText : "Finalizar compra"}`}
                  className={`btn-orangebg m-auto mt-4`}
                  onClick={payWithSavedCard}
                />
              </React.Fragment>
            )}

          <img src={poweredByStripe} className="mt-4 mx-auto pointer powered-by-stripe" onClick={() => { window.open('https://stripe.com','_blank').focus() }} />
        </div>
      </div>
    </section>
  );
}

export default IsbnPayment;
