import "./BookListGenrePublic.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getApiCategories } from "../../../services/apiCategories";
import { getNewBooks } from "../../../redux/actions/book";
import NameSection from "../../../components/NameSection/NameSection";
import MoreGenres from "../../../components/HomeComponents/MoreGenres/MoreGenres";
import AuthorListGenre from "../../../components/GenrePageComponents/AuthorListGenre/AuthorListGenre";
import BookListPublic from "../../../components/aPublicArea/BookListPublic/BookListPublic";
import BookNews from "../../../components/HomeComponents/BookNews/BookNews";
import GenreItem from "../../../components/GenreItem/GenreItem";
import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";
import FooterPublicTwo from "../../../components/aPublicArea/FooterPublicTwo/FooterPublicTwo";

function BookListGenrePublic() {
  const urlParams = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authors = useSelector((store) => store.book.authors);
  const new_books = useSelector((store) => store.book.new_books);
  const [genres, setGenres] = useState([]);
  const [category, setCategory] = useState(null);

  useEffect(() => {
    dispatch(getNewBooks({ category : urlParams.slug }));
    setCategory(urlParams.slug)
  }, [dispatch]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = (genre) => {
    navigate("/libros-por-genero/:slug");
  };

  const renderGenres = () => {
    return genres.map((genre) => {
      return (
        <GenreItem
          key={genre._id}
          genre={genre}
          onClick={tagClick}
          size="large"
        />
      );
    });
  };



  return(
    <section className="book-list-genre-public">
      { new_books ?
        <React.Fragment>
          <NameSection name={ new_books[0].by_category } category={category} />
          <BookNews title="Más recientes" books={new_books} />
        </React.Fragment>
      :
        <NameSection name={ category ? category.charAt(0).toUpperCase() + category.slice(1) : '' } category={category} />
      }
      <h2 className="book-list-genre-public__title">Todos los libros del género</h2>
      <BookListPublic category={urlParams.slug} />
      { authors &&
        <AuthorListGenre authors={authors}/>
      }
      <MoreGenres className="mb-4" />
      <BannerPublic />
      <div className="book-list-genre-public__footer">
      <FooterPublicTwo/>
      </div>
    </section>
  )
}

export default BookListGenrePublic;
