import "./BookForYou.scss";
import { useNavigate } from "react-router-dom";
import BookItem from "../../BookCatalog/BookItem/BookItem";
import Button from "../../DesingSystem/Button/Button";
import Slider from "react-slick";
import React from "react";

function BookForYou(props) {
  const navigate = useNavigate();
  const slider = React.useRef();

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    className: "book-for-you__slider-item",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const renderBooks = () => {
    return props.books ? props.books.slice(0, 3).map((book) => {
      return <BookItem book={book} key={book._id} />;
    }) : null;
  };

  const goToCatalog = () => {
    navigate("/book-list");
  };

  return (
    <div className="book-for-you">

      <div className="book-for-you__container-title">
        <div>
          <p className="book-for-you__title">Elegidos para ti</p>
        </div>
        <div className="book-for-you__button" onClick={goToCatalog}>
        <Button className={'btn-white big'} text={'Descubre más'} />
        </div>
      </div>

      { props.books ?
        <ul className="book-for-you__list">
          <Slider ref={slider} {...settings}>
            {renderBooks()}
          </Slider>
        </ul>
      : null }

      <div className="book-for-you__mobile-actions">
        <Button
          className="btn-blackbg"
          text="Descubre más"
          iconRight="arrowDiagonal"
          onClick={goToCatalog}
        />
      </div>
    </div>
  );
}

export default BookForYou;
