import "./LoginFacebook.scss";
import { useState } from "react";
import { useDispatch } from "react-redux";
import FacebookLogin from "@greatsumini/react-facebook-login";
import { doFacebookLogin } from "../../../redux/actions/user";
import { Navigate } from "react-router-dom";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import Icon from "../../Icon/Icon"

function LoginFacebook() {
  const dispatch = useDispatch();
  const [token, setToken] = useState(ls.get("token"));

  const appId = "1253328045563416";
  const textButton = "Continúa con Facebook"

  const onSuccess = (response) => {
    dispatch(doFacebookLogin(response.accessToken, response.userID)).then(async ()=>{
      setToken(await ls.get('token'))
    })
  };

  const onFail = (e) => {
    console.log("No se pudo completar el login con Facebook");
    console.table(e)
  };

  const onProfileSuccess = (response) => {
    //console.log("Get Profile Success!", response);
  };

  const facebookButtonContent = () => (
    <div className="facebook-button">
      <Icon
        name="facebook"
        size="medium"
        color="secondary-7"
      >
      </Icon>
      <span>Continúa con Facebook</span>
    </div>
  )

  return validateToken.validate(token) ? (
    <Navigate to="/" replace={true} />
  ) : (
    <FacebookLogin
      appId={appId}
      children={facebookButtonContent()}
      onSuccess={onSuccess}
      onFail={onFail}
      onProfileSuccess={onProfileSuccess}
      scope={'public_profile, email'}
      fields={'id,first_name,last_name,email'}
      style={{
        backgroundColor: "#323232",
        color: "#fff",
        fontSize: "16px",
        padding: "12px",
        border: "none",
        borderRadius: "32px",
        cursor: "pointer",
        boxSizing: "content-box",
        margin: "10px 0",
        width: "calc(100% - 20px)",
      }}
    />
  );
}

export default LoginFacebook;
