import ls from "../services/localStorage";
import { types } from "../constants/constants";

export const postApiIsRegistered = (attributes) => {
  return fetch(`${types.API_URL}auth/is-registered`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then((response) => response.json());
};

export const postApiLogin = (attributes) => {
  return fetch(`${types.API_URL}auth/login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const postApiRegister = (attributes) => {
  return fetch(`${types.API_URL}auth/register`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};

export const getApiUsers = () => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiUsersAuthor = () => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users/author`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiGetUserById = (userId) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users/id/${userId}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiGetUserByNickName = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}user/${attributes.nickName}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const getApiSearchResults = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}search?keyword=${attributes.keyword}&limit=${attributes.limit}&offset=${attributes.offset}`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const putApiUpdateUser = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}users/update/id/${attributes.user._id}`, {
    method: "PUT",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};

export const putApiUpdateAuthor = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}users/update-author/id/${attributes.user._id}`, {
    method: "PUT",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};

export const putApiUpdateAccount = (attributes) => {
  const token = ls.get("token");

  console.log(attributes)

  return fetch(`${types.API_URL}users/update-account/id/${attributes.user._id}`, {
    method: "PUT",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
  
};

export const getStripePaymentMethods = async (userId) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users/${userId}/payment-methods`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
}

export const getStripeConnectedAccount = async (userId) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users/${userId}/connected-account`, { 
    headers : { token : token } 
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
}

export const postApiCreatePaymentIntent = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}user/create-payment-intent`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const postApiPayWithSavedCard = (attributes) => {
  const token = ls.get("token");

  return fetch(`${types.API_URL}user/pay-with-saved-card`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const postApiCreateSetupIntent = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}user/create-setup-intent`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const postApiDeletePaymentMethod = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}user/delete-payment-method`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      throw new Error(responseJson)
    }
    return response.json()
  });
};

export const postApiCreateConnectedAccount = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}user/create-stripe-account`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      return responseJson;
      /*throw new Error(responseJson)*/
    }
    return response.json()
  });
};

export const postApiGetStripeOnboardingLink = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}user/get-stripe-onboarding-link`, {
    method: "POST",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      return responseJson;
      /*throw new Error(responseJson)*/
    }
    return response.json()
  });
};

export const deleteApiDeleteConnectedAccount = (attributes) => {
  const token = ls.get("token");
  
  return fetch(`${types.API_URL}users/delete-stripe-connected/id/${attributes.user._id}`, {
    method: "DELETE",
    headers: {
      token : token,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes)
  }).then(async (response) => {
    if(!response.ok){
      const responseJson = await response.json()
      return responseJson;
      /*throw new Error(responseJson)*/
    }
    return response.json()
  });
};


// no documentadas

export const postApiGoogleLogin = (params) => {
  return fetch(`${types.API_URL}auth/google-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).then((response) => response.json());
};

export const postApiFacebookLogin = (params) => {
  return fetch(`${types.API_URL}auth/facebook-login`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(params),
  }).then((response) => response.json());
};
