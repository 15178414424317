import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { types } from "../../constants/constants";
import { getUserById } from "../../redux/actions/user";
import {
  getStripePaymentMethods,
  postApiCreatePaymentIntent
} from "../../services/apiUser";
import ls from "../../services/localStorage";
import validateToken from "../../services/validateToken";
import poweredByStripe from "../../styles/images/powered-by-stripe.svg";
import CheckoutForm from "../CheckoutForm/CheckoutForm";
import Button from "../DesingSystem/Button/Button";
import Icon from "../Icon/Icon";
import "./ModalBuy.scss";

const cssRules = {
  ".Label": {
    fontSize: "12px",
    textTransform: "uppercase",
    color: "#656565",
    marginBottom: "10px",
    fontFamily: "Novela Regular",
  },
  ".Tab": {
    border: "1px solid #979797",
    backgroundColor: "white",
    marginBottom: "8px"
  },
  ".Tab--selected": {
    color: "#323232",
    border: "1px solid #979797",
    backgroundColor: "#F7F7F7"
  },
  ".Tab--selected:hover": {
    color: "#323232",
  },
  ".Tab:focus": {
    outline: "none",
    boxShadow: 0
  },
  ".Tab--selected:focus": {
    outline: "none",
    boxShadow: 0
  },
  ".TabIcon--selected": {
    fill: "#323232"
  },
  ".TabIcon--selected:hover": {
    fill: "#323232"
  },
  ".Label": {
    fontSize: "12px",
    textTransform: "uppercase",
    color: "#656565",
    marginBottom: "10px",
  },
  ".Input": {
    border: "1px solid #656565",
    borderRadius: "32px",
    backgroundColor: "#ffffff",
    paddingTop: "8px",
    paddingBottom: "8px",
    lineHeight: "24px",
    fontFamily: "Novela Regular",
  },
  ".Input:focus": {
    borderColor: "#f05a24",
    outline: "none",
    boxShadow: "none",
  }
};

/**
 * @param {Object} props
 * @param {string} props.btnText
 * @param {Object} [props.confirmSetupConfig]
 * @param {Object} [props.confirmPaymentConfig]
 * @param {number} props.price
 * @param {Object} props.seller
 * @param {boolean} props.show
 * @param {Function} props.onCreateSale
 * @param {Function} props.onHide
 * @param {Function} [props.onPaymentSuccess]
 * @param {import("react").ReactNode} [props.secondStep]
 */
function ModalBuy(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const [card_selected, setCardSelected] = useState("");
  const [active_payment_intent, setActivePaymentIntent] = useState("");
  const [paymentMethods, setPaymentMethods] = useState(null);
  const [optionsCheckout, setOptionsCheckout] = useState({});
  const [errorPayment, setErrorPayment] = useState("");
  const [confirmButtonIcon, setConfirmButtonIcon] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const user = useSelector((store) => store.user.user);
  const errorMessage = useSelector((store) => store.user.errorMessage);

  const confirmSetupConfig = props.confirmSetupConfig || { redirect: 'if_required' };
  const confirmPaymentConfig = props.confirmPaymentConfig || {
    confirmParams: { return_url: window.location.origin + '/confirmacion-venta' }
  };

  const stripe = loadStripe(types.STRIPE_API_KEY);

  const init = async () => {
    await getPaymentMethods();
    await getPaymentIntent();

    setLoaded(true);
  };

  async function getPaymentMethods() {
    const paymentMethods = await getStripePaymentMethods(user._id);
    setPaymentMethods(paymentMethods);
  }

  async function getPaymentIntent(id = null) {
    const pi = await postApiCreatePaymentIntent({
      amount: props.price * 100,
      payment_method: id,
      customer: user.stripe_customer,
      account: props.seller ? props.seller.stripe_account : null,
    });
    console.log("Create Payment intent");
    console.log({
      amount: props.price * 100,
      payment_method: id,
      customer: user.stripe_customer,
      account: props.seller ? props.seller.stripe_account : null,
    });

    setActivePaymentIntent(pi.payment_intent.id);

    setOptionsCheckout({
      locale: "es",
      clientSecret: pi.secret,
      appearance: {
        theme: "flat",
        rules: cssRules
      }
    });
  }

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);

    if (tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    } else {
      navigate('/login');
    }
  }, []);

  useEffect(() => {
    if (user) {
      init();
    }
  }, [user]);

  const selectCard = (id) => {
    if (card_selected === id) {
      setCardSelected(null);
      getPaymentIntent(null);
    } else {
      setCardSelected(id);
      getPaymentIntent(id);
    }
  };

  const payWithSavedCard = async () => {
    try {
      setConfirmButtonDisabled(true);
      setConfirmButtonIcon('loaderIcon');

      // Save sale on ddbb with status pending
      if (props.onCreateSale) {
        props.onCreateSale(active_payment_intent);
      }

      const stripeJS = await loadStripe(types.STRIPE_API_KEY);

      const response = await stripeJS.confirmCardPayment(
        optionsCheckout.clientSecret,
        {
          payment_method: card_selected,
          return_url: window.location.origin + "/confirmacion-venta",
        }
      );

      if (response.paymentIntent.status === "succeeded") {
        if (props.onPaymentSuccess) {
          props.onPaymentSuccess(response);
        }
        setStep(2);
      } else {
        setErrorPayment(
          response.error
            ? response.error.message
            : "Error with confirm payment Stripe"
        );
      }

      setConfirmButtonDisabled(false);
      setConfirmButtonIcon('');
    } catch (error) {
      setErrorPayment(error);
      console.log(error.stack);
    }
  };

  const renderModal = () => {
    if (loaded && step === 1) {
      return (
        <div className="buy-book">
          <h3 className="buy-book__title">Elige tu medio de pago</h3>

          <div className="buy-book__container-card mb-4">
            {paymentMethods.map((pm) => (
              <div
                className={`buy-book__container-card-item ${card_selected === pm.id ? "selected" : ""
                  }`}
                key={pm.id}
                onClick={() => selectCard(pm.id)}
              >
                <Icon name={pm.card.brand} size="large" color="secondary-3" />
                <p className="buy-book__container-card-item-text">
                  **** **** **** {pm.card.last4}
                  <br />
                  Exp. {pm.card.exp_month}/{pm.card.exp_year}
                </p>
              </div>
            ))}
          </div>

          {!paymentMethods || paymentMethods.length === 0 ? (
            <p className="mb-5 not-card">
              No tienes ningún método de pago almacenado
            </p>
          ) : null}

          {!card_selected ? (
            <React.Fragment>
              <h3 className="buy-book__title mb-4">
                o utiliza una nueva tarjeta
              </h3>

              <div className="buy-book__container-card-form">
                <Elements stripe={stripe} options={optionsCheckout}>
                  <CheckoutForm
                    type={"payment"}
                    btnText={"Finalizar compra"}
                    btnClass={"btn-orangebg"}
                    onCreateSale={props.onCreateSale}
                    confirmSetupConfig={confirmSetupConfig}
                    confirmPaymentConfig={confirmPaymentConfig}
                    onSucceededPayment={() => setStep(2)}
                  />
                </Elements>
              </div>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Button
                text={`${props.btnText ? props.btnText : "Finalizar compra"}`}
                iconRight={confirmButtonIcon}
                disabled={confirmButtonDisabled}
                className={`btn-orangebg m-auto mt-4`}
                onClick={payWithSavedCard}
              />
            </React.Fragment>
          )}

          <img
            src={poweredByStripe}
            className="mt-4 mx-auto pointer powered-by-stripe"
            onClick={() => { window.open('https://stripe.com', '_blank').focus(); }}
          />
        </div>
      );
    } else if (loaded && step === 2) {
      return props.secondStep;
    } else {
      return (
        <div className="buy-book">
          <h3 className="buy-book__title my-5">
            Cargando el formulario de pago...
          </h3>
        </div>
      );
    }
  };

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="buy-book-modal"
        backdropClassName="buy-book-backdrop"
      >
        <Modal.Body>
          <div>{renderModal()}</div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalBuy;
