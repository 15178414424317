import "./EditBookPublished.scss";
import { useNavigate, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ExitPage from "../ExitPage/ExitPage";
import ModalEditBook from "../ModalEditBook/ModalEditBook";
import Button from "../../DesingSystem/Button/Button";
import UploadCover from "../UploadCover/UploadCover";
//import Summary from "../Summary/Summary";
import EditSummary from "../EditSummary/EditSummary";
import { getBook } from "../../../redux/actions/book";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import { putApiUpdateBook } from "../../../services/apiBook";
import { types } from "../../..//constants/constants";

function EditBookPublished() {
  const navigate = useNavigate();
  const { bookId } = useParams();
  const dispatch = useDispatch();
  const book = useSelector((store) => store.book.book);
  const [bookEdited, setBookEdited] = useState(undefined);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setBookEdited(book);
  }, [book]);

  useEffect(() => {
    dispatch(getBook(bookId));
  }, [dispatch, bookId]);

  const [modalExitPage, setModalExitPage] = useState(false);
  const [modalEditBook, setModalEditBook] = useState(false);
  const [publishButtonDisabled, setPublishButtonDisabled] = useState(false);
  const [publishButtonIcon, setPublishButtonIcon] = useState('arrowDiagonal');
  let responseCoverUploadJson = {}
  

  const onEditBook = async () => {
    setPublishButtonDisabled(true)
    setPublishButtonIcon('loaderIcon')
    try{      
      const token = ls.get("token");
      const loggedUser = validateToken.getUser(token);

      if(bookEdited.cover){
        // Upload cover image file
        let formDataCover = new FormData()

        formDataCover.append("file", bookEdited.cover[0])

        let responseCoverUpload = await fetch(`${types.API_URL}upload`, { 
          method: "POST",
          headers : { 
            token : token
          },
          body: formDataCover
        })

        if(!responseCoverUpload.ok){ alert(await responseCoverUpload.json()); return false; }

        responseCoverUploadJson = await responseCoverUpload.json()
      }

      // Build attrributes object to call API
      const arrayCategories = await Promise.all(bookEdited.categories.map(category => {
        return category._id
      }))

      const attributes = {
        book : {
          _id : bookEdited._id,
          user : bookEdited.user,
          title: bookEdited.title,
          summary: bookEdited.summary,
          isbn: bookEdited.isbn,
          price: bookEdited.price.toString().replace(',', '.'),
          genre: bookEdited.genre,
          categories: arrayCategories
        }
      }

      if(bookEdited.cover){
        attributes.book.picture_big = responseCoverUploadJson.url_big
        attributes.book.picture_medium = responseCoverUploadJson.url_medium
        attributes.book.picture_thumb = responseCoverUploadJson.url_thumb
      }

      const responseUpdateBook = await putApiUpdateBook(attributes);
      showModalEditBook()

    } catch(err){
      setPublishButtonDisabled(false)
      setPublishButtonIcon('arrowRight')
      setErrorMessage(err.message)
      console.log(err.message + err.stack);
    }
  }


  const showModalExitPage = () => {
    setModalExitPage(true);
  };

  const hideModalExitPage = () => {
    setModalExitPage(false);
  };

  const acceptModalExitPage = () => {
    setModalExitPage(false);
    navigate("/book-list");
  };

  const hideModalEditBook = (e) => {
    e && e.stopPropagation();
    setModalEditBook(false);
  };

  const showModalEditBook = (e) => {
    e && e.stopPropagation();
    setModalEditBook(true);
  };

  const renderBottomBar = () => {
    const isDisabled = !bookEdited || !bookEdited.title || !bookEdited.summary || !bookEdited.price;
    return (

      <div className="edit-book-published__container-buttons">
        <div>
          <p className="edit-book-published__edit">Editar publicación</p>
        </div>

        <div className="d-flex align-items-center">
          { errorMessage && errorMessage !== '' ? 
            <span className="error me-3">{ errorMessage }</span>
          :
            null
          }
          <p
            className="edit-book-published__cancel"
            onClick={showModalExitPage}
          >
            Cancelar
          </p>
          <Button
            className={`${isDisabled ? "btn-graybg" : "btn-blackbg"} ms-4`}
            onClick={onEditBook}
            text="Guardar cambios"
            iconRight={publishButtonIcon}
            disabled={publishButtonDisabled}
          />
        </div>
      </div>
    );
  };
  return bookEdited ? (
    <div className="edit-book-published">
      <Container>
        <Row className="edit-book-published__exit-page">
          <ExitPage
            show={modalExitPage}
            onAccept={acceptModalExitPage}
            onHide={hideModalExitPage}
          />
        </Row>
        <Row className="edit-book-published__section-book">
          <Col sm={6}>
            {<UploadCover book={bookEdited} setBook={setBookEdited} />}
          </Col>
          <Col sm={6}>
            {<EditSummary book={bookEdited} setBook={setBookEdited} />}
          </Col>
        </Row>
        <Row className="edit-book-published__position-bar">
          {renderBottomBar()}
        </Row>
        <ModalEditBook
          show={modalEditBook}
          onHide={hideModalEditBook}
        />
      </Container>
    </div>
  ) : null;
}

export default EditBookPublished;
