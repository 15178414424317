import "./SearcherComponent.scss";
import React, {useEffect, useState } from 'react';
import { types } from "../../constants/constants";
import Container from "react-bootstrap/Container";
import Results from "./Results";
import ResultsSkeleton from "./ResultsSkeleton";
import Button from "../DesingSystem/Button/Button";
import Icon from "../Icon/Icon";

import { getApiSearchResults } from "../../services/apiUser";

function Searcher(props) {
  const [loaded, setLoaded] = useState(false);
  const [results, setResults] = useState([]);
  const [totalResults, setTotalResults] = useState(0);
  const [totalBooks, setTotalBooks] = useState(0);
  const [totalAuthors, setTotalAuthors] = useState(0);
  const [limit, setLimit] = useState(types.PAGE_SIZE);
  const [offset, setOffset] = useState(0);
  const [btnIcon, setBtnIcon] = useState('');
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [filter, setFilter] = useState('todo');

  useEffect(() => {
    getSearchResults();
  }, [props.searchText]);

  const getSearchResults = async () => {
    setLoaded(false)
    const results = getApiSearchResults({
      keyword: props.searchText,
      limit: limit,
      offset: offset
    })
    .then(async (response) => {
      setResults(response.results);
      setTotalResults(response.totalResults);
      setLoaded(true);
    })
    .catch((error) => {
      console.log(error.message);
      setResults([]);
      setLoaded(true);
    });
  }

  const loadMoreResults = () => {
    setBtnIcon('loaderIcon')
    setBtnDisabled(true)
    const offsetPage = offset + limit;
    setOffset(offsetPage);

    const resultsResponse = getApiSearchResults({
      keyword: props.searchText,
      limit: limit,
      offset: offsetPage
    })
    .then(async (response) => {
      const arrayResults = results.concat(response.results)
      setResults(arrayResults);
      setBtnIcon('')
      setBtnDisabled(false)
    })
    .catch((error) => {
      console.log(error.message);
      setResults([])
      setBtnIcon('')
      setBtnDisabled(false)
    });
  }

  const handleradioChange = (e) => {
    document.querySelectorAll('.searcher__filter-item').forEach(el => el.classList.remove("checked"));
    e.target.parentElement.classList.add("checked");
    setFilter(e.target.value)
  };

  const filters = [
    { value: 'todo', text: 'Todo', count: results.length },
    { value: 'obra', text: 'Obras', count: results.filter((result) => result.category === 'obra').length },
    { value: 'autor', text: 'Autores', count: results.filter((result) => result.category === 'autor').length },
    { value: 'noticia', text: 'Noticias', count: results.filter((result) => result.category === 'noticia').length },
    /*{ value: 'genero', text: 'Género', count: 4 },
    { value: 'categoria', text: 'Categoría', count: 6 }*/
  ]

  const renderFilter = () => (
    <>
      <p className="searcher__filter-text">¿Qué buscabas?</p>
      <div className="searcher__filter-items">
        {filters.map(filterItem => (
          <label key={filterItem.value} className="searcher__filter-item">
            <input
              type="radio"
              name="filter"
              value={filterItem.value}
              onChange={handleradioChange}
              checked={filterItem.value === filter ? true : false }
            />
            {filterItem.text} {`(${filterItem.count})`}
          </label>
        ))}
      </div>
    </>
  );

  /*const genres = [
    {
      "_id": "642afd6c75d8979e9d7cbfd5",
      "icon": "ciencia-ficcion",
      "tag": "Ciencia Ficción"
    },
    {
      "_id": "63ed7b5c14fa3e5f163e878f",
      "icon": "fantasia",
      "tag": "Fantasía"
    }
  ]

  const results = [
    { category: 'obra', title: 'Las aventuras de Salva', author: 'Laura Palmer', genres, price: '10', image: 'https://picsum.photos/106/144' }, // Obra
    { category: 'autor', imageFull: true, name: 'Salva Durán', publications: 1, genres, image: 'https://picsum.photos/232/176' }, // autor
    { category: 'genero', imageFull: true, name: 'Novelas', count: 20, image: 'https://picsum.photos/232/176' }, // genero
    { category: 'noticia', imageFull: true, title: 'Lorem ipsum dolor sit amet consectetur. Ac aliquam viverra eu etiam faucibus.', author: 'Antonio Banderas', date: new Date(), readTime: 2, image: 'https://picsum.photos/232/176' }, // noticia
    { category: 'categoria', imageFull: true, name: 'Aventura', count: 20, image: 'https://picsum.photos/232/176' } // categoria
  ]*/

  return (
    <Container>
      <section className="Searcher">
        <div className="searcher__filter">
          {renderFilter()}
        </div>

        {loaded && results.length ?
          <Results results={filter === 'todo' ? results : results.filter((result) => result.category === filter)}/>
        :
          <React.Fragment>
            {(!results.length || !props.searchText) && loaded == true ?
              <div className="searcher__info">
                {!results.length ?
                  <Icon name="sad" size="large" color="secondary-1" className="mb-4"/>
                : null}
                <p className="searcher__info-text">
                  {!results.length ? `No se han encontrado resultados para ${props.searchText}` : ``}
                  {!props.searchText ? 'Introduce algo en el buscador para ver resultados' : ''}
                </p>
              </div>
            :
              <ResultsSkeleton />
            }
          </React.Fragment>
        }

        { totalResults > results.length && (
          <div className="row mt-5">
            <div className="col">
              <Button className={'btn-blackbg m-auto'} text={'Cargar más resultados'} iconRight={btnIcon} disabled={btnDisabled} onClick={ () => { loadMoreResults() } } />
            </div>
          </div>
        )}
      </section>
    </Container>
  );
}

export default Searcher;
