const initialState = {
  error: false,
  errorMessage: "",
  book: null,
  books: null,
  new_books: null,
  featured_books: null,
  total: null
};

export const bookReducer = (state = initialState, action) => {
  switch (action.type) {
    case "LOADING":
      return {
        ...state,
        loaded: false
      };

    case "CLEAN_REDUX":
      return {
        ...state,
      };

    case "GET_BOOKS":
      return {
        ...state,
        total: action.payload.total,
        books: action.payload.books,
        error: false,
        errorMessage: "",
      };

    case "GET_MORE_BOOKS":
      const arrayBooks = state.books.concat(action.payload.books)
      return {
        ...state,
        total: action.payload.total,
        books: arrayBooks,
        error: false,
        errorMessage: "",
      };

    case "GET_MORE_BOOKS_BY_CATEGORY":
      const arrayBooksCategory = state.books.concat(action.payload.books)
      return {
        ...state,
        total: action.payload.total,
        books: arrayBooksCategory,
        error: false,
        errorMessage: "",
      };

    case "GET_NEW_BOOKS":
      return {
        ...state,
        total: action.payload.total,
        new_books: action.payload.books,
        error: false,
        errorMessage: "",
      };

    case "GET_FEATURED_BOOKS":
      return {
        ...state,
        total: action.payload.total,
        featured_books: action.payload.books,
        error: false,
        errorMessage: "",
      };

    case "GET_BOOKS_BY_CATEGORY":
      return {
        ...state,
        total: action.payload.total,
        books: action.payload.books,
        authors: action.payload.authors,
        error: false,
        errorMessage: "",
      };

    case "GET_BOOKS_BY_USER":
      return {
        ...state,
        total: action.payload.total,
        user_books: action.payload.books,
        error: false,
        errorMessage: "",
      };

    case "GET_BOOK":
      return {
        ...state,
        book: action.payload,
        loaded: true,
      };

    case "CREATE_BOOK":
      return {
        ...state,
        book: action.payload,
      };

    case "SET_BOOK_ERROR_MESSAGE":
      return {
        ...state,
        error: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
