import "./IsbnSelector.scss";

import Icon from "../../Icon/Icon"
import InputText from "../../DesingSystem/InputText/InputText";
import React, { useState } from "react";
import ModalIsbnExplanation from "./ModalIsbnExplanation";

function IsbnSelector(props) {
  const [modalIsbnExplanation, setModalIsbnExplanation] = useState(false);
  const [error, setError] = useState('');

  const showModalIsbnExplanation = (e) => {
    e.preventDefault();
    setModalIsbnExplanation(true);
  }
  const hideModalIsbnExplanation = () => {
    setModalIsbnExplanation(false);
  }

  const handleInputChange = (event) => {
    props.setBook({
      ...props.book,
      ...(event.target.value !== "OWN" && { isbnOwn: "" }),
      isbn: event.target.value,
    });
  }

  const handleInputTextChange = (event) => {
    let value = ""
    if (!event.target.value.match(/(?=(?:\D*\d){10}(?:(?:\D*\d){3})?$)/)) {
      setError("El código ISBN debe tener 10 o 13 dígitos");
      value = ""
    } else {
      setError("");
      value = event.target.value
    }
      props.setBook({
        ...props.book,
        isbnOwn: value,
      })
  }

  return (
    <section className="isbn-selector">
      <div className="isbn-selector__title">Código ISBN | <span className="isbn-selector__title-tooltip" onClick={showModalIsbnExplanation}>¿Qué es?<Icon name="tooltip" color="secondary-3" /></span></div>
      <div className="isbn-selector__input-container">
        <div className="isbn-selector__input-radio">
          <label htmlFor="isbn1">
            <input type="radio" id="isbn1" name="isbn" value="PAID" onChange={handleInputChange} />
            <p>Obtén tu código ISBN (Recomendado)</p>
            <span className="isbn-selector__price">10€</span>
          </label>
        </div>
        <div className="isbn-selector__input-radio">
          <label htmlFor="isbn2">
            <input type="radio" id="isbn2" name="isbn" value="OWN" onChange={handleInputChange} />
            <p>Utiliza tu código ISBN (Recomendado) </p>
            <span className="isbn-selector__price">0€</span>
          </label>
          {props.book && props.book.isbn === "OWN" && (
            <div className="isbn-selector__input">
              <InputText
                error={Boolean(error)}
                type="text"
                placeholder="Introduce tu código ISBN"
                onChange={handleInputTextChange}
                maxlength="13"
              />
              {error ? (
                <p className="m-0 mt-2 mb-4 ms-2 error text-left">{error}</p>
              ) : null}
            </div>)}
        </div>
        <div className="isbn-selector__input-radio">
          <label htmlFor="isbn3">
            <input type="radio" id="isbn3" name="isbn" value="NONE" onChange={handleInputChange} />
            <p>Publicar sin código ISBN</p>
            <span className="isbn-selector__price">0€</span>
          </label>
        </div>
        <div className="isbn-selector__input-radio">
          <label htmlFor="isbn4">
            <input type="radio" id="isbn4" name="isbn" value="WON" onChange={handleInputChange} />
            <p>He ganado un ISBN</p>
            <span className="isbn-selector__price">0€</span>
          </label>
        </div>
      </div>
      <ModalIsbnExplanation
        show={modalIsbnExplanation}
        onHide={hideModalIsbnExplanation}
      />
    </section>
  );
}

export default IsbnSelector;
