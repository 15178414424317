import "./PoliticaCookies.scss";

function PoliticaCookies() {
  return (
    <section className="politica-cookies">
      
      <p style={{ fontWeight: 400 }}>
        <strong>POLÍTICA DE COOKIES</strong>
      </p>
      <p>Mediante el presente texto legal TODO USUARIO que acceda y visualice en su dispositivo electr&oacute;nico esta p&aacute;gina web <strong>QUEDA ADVERTIDO DE QUE EN ESTA P&Aacute;GINA WEB SE UTILIZAN COOKIES</strong>, mediante su instalaci&oacute;n en el dispositivo electr&oacute;nico.</p>
      <p>Las cookies son ficheros electr&oacute;nicos, m&aacute;s bienes, programas de peque&ntilde;as dimensiones que se instalan en el ordenador del usuario al momento de su acceso a la p&aacute;gina web <strong><u>siempre previa informaci&oacute;n y consentimiento del propio usuario</u></strong>. Existe una gran variedad de cookies y se pueden catalogar en base a su finalidad que, entre otras cosas, pueden tener por finalidad hacer funcionar la p&aacute;gina web en el dispositivo electr&oacute;nico del usuario, pero tambi&eacute;n, pueden recopilar y enviar al titular de la p&aacute;gina web, a su proveedor de servicios o a terceros informaci&oacute;n sobre los h&aacute;bitos de navegaci&oacute;n del usuario, sobre el tiempo que pasa en la p&aacute;gina web, cuantos clics hace y durante cuanto tipo, el n&uacute;mero de IP, sus b&uacute;squedas y, a cambi&oacute; algunas de las cookies visualizan al usuario publicidad enfocada a &eacute;l y en base a sus anteriores b&uacute;squedas.</p>
      <p>En definitiva, algunas cookies por si solas o en combinaci&oacute;n con otras, permiten ya sea a EL TITULAR de la esta p&aacute;gina web, ya sea al proveedor de servicios o ya sea a un tercer perfilar y reconocer al usuario, en base a sus b&uacute;squedas y su comportamiento en el Internet.</p>
      <p>Mientras tanto, hay otras cookies que &uacute;nicamente posibilitan el funcionamiento de la p&aacute;gina web, estas cookies se conocen como cookies funcionales y su instalaci&oacute;n en el dispositivo electr&oacute;nico resulta necesaria para posibilitar el acceso del usuario a la p&aacute;gina web.</p>
      <ol>
      <li><strong>1.- Declaraci&oacute;n inicial sobre uso de las cookies en esta p&aacute;gina web</strong></li>
      </ol>
      <p>Esta p&aacute;gina web es de titularidad de N&eacute;stor Mart&iacute;nez Roda en adelante, &ldquo;EL TITULAR&rdquo;. Como se ha venido diciendo, esta p&aacute;gina web utiliza cookies mediante su instalaci&oacute;n en el dispositivo electr&oacute;nico del usuario en el momento de acceso de &eacute;ste a la misma y siempre despu&eacute;s de que haya sido informado y haya prestado a tal efecto su consentimiento (CONSENTIMIENTO INFORMADO).</p>
      <p>Las cookies pueden ser propias, esto es, de EL TITULAR de la p&aacute;gina web y pueden ser de terceros, esto es, todas aquellas personas f&iacute;sicas o jur&iacute;dicas que no sean TITULAR de la p&aacute;gina web.</p>
      <p>Tanto las cookies propias como las de terceros pueden tener distintos fines. As&iacute; pues, las cookies pueden ser estad&iacute;sticas, anal&iacute;ticas y publicitarias.</p>
      <p>Esta primera informaci&oacute;n b&aacute;sica se ofrece al usuario en la primera capa, banner de cookies, que aparece en la pantalla del dispositivo electr&oacute;nico del usuario siempre que accede por primera vez a esta p&aacute;gina web. Donde el usuario puede ACEPTAR, RECHAZAR, ACEPTAR PARCIALMENTE ALGUNAS COOKIES o MODIFICAR SU ELECCI&Oacute;N.</p>
      <p>Ahora bien, las cookies que tienen consideraci&oacute;n de funcionales por funciones que cumplen para hacer posible el funcionamiento de la p&aacute;gina web, son de instalaci&oacute;n autom&aacute;tica en el dispositivo electr&oacute;nico del usuario y dejan de funcionar una vez que el usuario abandona la p&aacute;gina web.</p>
      <p>Por otro lado, si el usuario acepta las cookies por error o una vez que las haya aceptado, cambia su opini&oacute;n acerca de ello, puedes borrar las cookies que fueron instaladas desde su navegador. Al final de este texto el usuario va a encontrar una explicaci&oacute;n de c&oacute;mo hacerlo.</p>
      <p>A continuaci&oacute;n, le facilitamos al usuario m&aacute;s detalles sobre las cookies para que pueda tomar su decisi&oacute;n sobre si quiere aceptar o rechazar la instalaci&oacute;n de las cookies.</p>
      <ol start="2">
      <li><strong><u>2.- &iquest;Qu&eacute; son las cookies?</u></strong></li>
      </ol>
      <p>Tal y como se ha venido explicando, las cookies son archivos inform&aacute;ticos que se instalan en navegador y pueden ser clasificadas seg&uacute;n los siguientes par&aacute;metros:</p>
      <ul>
      <li><u>Seg&uacute;n la entidad que las gestione</u>:
      <ul>
      <li><strong>Cookies propias</strong>: Se trata de aquellas cookies que se env&iacute;an al dispositivo inform&aacute;tico (tel&eacute;fono m&oacute;vil, ordenador, Tablet, etc.) del usuario desde un equipo o dominio gestionado por EL TITULAR de esta p&aacute;gina web y desde el que se presta el servicio solicitado por el usuario.</li>
      <li><strong>Cookies de terceros</strong>: Son aquellas cookies que se env&iacute;an al dispositivo inform&aacute;tico del usuario desde un equipo o dominio que no es gestionado por EL TITULAR de esta p&aacute;gina web, sino por otra entidad, ya sea persona f&iacute;sica o jur&iacute;dica.</li>
      </ul>
      </li>
      <li><u>Seg&uacute;n su finalidad</u>:
      <ul>
      <li><strong>Cookies t&eacute;cnicas o funcionales</strong>: Las <strong>cookies funcionales</strong> son las que permiten al usuario navegar en la p&aacute;gina web y hacen funcionar la misma en el dispositivo electr&oacute;nico del usuario. Permiten que el usuario pueda disfrutar de las diferentes opciones, operaciones y/o servicios que se ofrecen mediante la p&aacute;gina web, como por ejemplo, permiten controlar el tr&aacute;fico y la comunicaci&oacute;n de datos entre el servidor de la p&aacute;gina web o el servidor de quien provee servicios a la p&aacute;gina web y el dispositivo electr&oacute;nico del usuario, posibilitan identificar el inicio de sesi&oacute;n por el usuario, realizar los procesos de compra de un producto o servicio, gestionar el pago, controlar y detectar el fraude en la seguridad del servicio de navegaci&oacute;n por la p&aacute;gina web, gestionar la suscripci&oacute;n del usuario a alguno de los servicios ofrecidos en la p&aacute;gina web, visualizar los pedidos, compras y facturas, utilizar elementos de seguridad durante la navegaci&oacute;n, almacenar contenidos para la difusi&oacute;n de v&iacute;deos o sonido, habilitar contenidos din&aacute;micos.</li>
      <li><strong>Cookies de preferencias o personalizaci&oacute;n</strong>: Son las que personalizan la p&aacute;gina web a cada usuario en la medida de lo posible. Con estas cookies una p&aacute;gina web se configura de manera espec&iacute;fica para cada usuario, lo que permite que su experiencia en la navegaci&oacute;n por la p&aacute;gina web se &uacute;nica y exclusiva. As&iacute;, por ejemplo, con la aceptaci&oacute;n de estas cookies al usuario se le mostrar&aacute; la p&aacute;gina web en el idioma de su navegador, se determinar&aacute; su pa&iacute;s o regi&oacute;n de acceso, los resultados de sus anteriores b&uacute;squedas, entre otros.</li>
      <li><strong>Cookies de an&aacute;lisis o mediaci&oacute;n/estad&iacute;sticas</strong>: Con el uso de estas cookies el titular de estas puede realizar un seguimiento y an&aacute;lisis del comportamiento del usuario en la p&aacute;gina web, lo que, per se implica recabar datos de &iacute;ndole personal al usuario. Lo m&aacute;s relevante de este tipo de cookies es la medici&oacute;n de los impactos que tienen los anuncios en el usuario, el n&uacute;mero de veces que se han visualizado los anuncios y el tiempo que pasa el usuario en la p&aacute;gina web. La informaci&oacute;n recabada mediante estas cookies tiene por finalidad generar unas estad&iacute;sticas con fines publicitarios y de marketing. No obstante, tambi&eacute;n sirven para mejorar el funcionamiento de la p&aacute;gina web mediante el an&aacute;lisis de los datos que ha generado el comportamiento del usuario en la misma.</li>
      <li><strong>Cookies publicitarias o de marketing</strong>: Son aquellas que permiten, mediante recopilaci&oacute;n de informaci&oacute;n acerca de los gustos e intereses del usuario, visualizar a &eacute;ste anuncios de publicidad especifica dirigida al propio usuario. El funcionamiento de estas cookies consiste en recopilaci&oacute;n de informaci&oacute;n de distintas b&uacute;squedas que realizar el usuario de forma previa o incluso en la propia p&aacute;gina web y bas&aacute;ndose en esa informaci&oacute;n ofrecer al usuario publicidad personalizada.</li>
      </ul>
      </li>
      <li><u>Seg&uacute;n el plazo de tiempo que permanecen activadas en el equipo terminal</u>:
      <ul>
      <li><strong>Cookies de sesi&oacute;n</strong>: Son todas aquellas cookies que est&aacute;n dise&ntilde;adas para tener un tiempo de funcionamiento muy limitado, esto es, solo hace su funci&oacute;n mientras el usuario permanece en la p&aacute;gina web y dejan de funcionar una vez que el usuario abandona la p&aacute;gina web.</li>
      <li><strong>Cookies persistentes</strong>: A diferencia de las anteriores, estas son cookies que una vez instaladas y, aunque el usuario abandone la p&aacute;gina web, siguen funcionando en el dispositivo electr&oacute;nico del usuario recabando diversa informaci&oacute;n ya no solo de la navegaci&oacute;n y comportamiento del usuario en esa p&aacute;gina web en concreto, sino tambi&eacute;n, en las distintas p&aacute;ginas webs. La duraci&oacute;n del funcionamiento de estas cookies puede ser de viarios minutos, horas, d&iacute;as, meses o hasta varios a&ntilde;os.</li>
      </ul>
      </li>
      </ul>
      <p>Por tanto, se trata de una tecnolog&iacute;a que permite a los titulares de las p&aacute;ginas webs, a sus editores o a terceros obtener una gran variedad de informaci&oacute;n acerca de la navegaci&oacute;n y comportamiento en la red del usuario.</p>
      <p>Las cookies, en funci&oacute;n de su finalidad, recopilan muchos datos sobre las p&aacute;ginas webs vistadas por el usuario anteriormente, el tiempo de su navegaci&oacute;n en la p&aacute;gina web, las b&uacute;squedas realizadas en dichas p&aacute;ginas webs, entre otros datos que pueden obtener, tal y como se ha visto. Por otro lado, a partir de todos esos datos, mediante procedimientos automatizados se elaboran perfiles individualizados de cada usuario, en los cuales, se puede alcanzar a determinarse la edad, la religi&oacute;n, el sexo, orientaci&oacute;n sexual, enfermedades, intereses, ideolog&iacute;a pol&iacute;tica, etc. Todos ellos son datos que tiene consideraci&oacute;n legal de datos de categor&iacute;a sensible.</p>
      <p>Es importante que el USUARIO SE CONOCEDOR QUE LAS COOKIES PUEDEN RESULTAR INTRUSIVAS EN SU PRIVACIDAD.</p>
      <ol start="3">
      <li><strong>3.- &iquest;Qu&eacute; cookie se utilizan en esta p&aacute;gina web?</strong></li>
      </ol>
      <p>En esta p&aacute;gina web se utilizan las siguientes cookies:</p>
      <ul>
      <li>Nombre de la cookie:
      <ul>
      <li>Dominio: en el que se instala la cookie</li>
      <li>Titular: de la cookie</li>
      <li>Tipo:</li>
      <li>Categoria:</li>
      <li>Descripci&oacute;n:</li>
      <li>Caducidad:</li>
      </ul>
      </li>
      </ul>
      <ol start="4">
      <li><strong>4.- Obligaciones legales de la Pol&iacute;tica de Cookies</strong></li>
      </ol>
      <p>En base al principio de transparencia mediante la presente pol&iacute;tica de cookies se ofrece al usuario una informaci&oacute;n clara, completa, actualizada y sencilla acerca de las cookies que se utilizan en esta p&aacute;gina web. Todo ello con el objetivo de informar correctamente al usuario para que as&iacute; pueda aceptar o rechazar la instalaci&oacute;n de las cookies en su dispositivo.</p>
      <p>Con esta informaci&oacute;n el titular de esta p&aacute;gina web hace una declaraci&oacute;n acerca de la responsabilidad de uso de las cookies, comprometi&eacute;ndose a cumplir todas las exigencias y obligaciones legales a tal efecto.</p>
      <ol start="5">
      <li><strong>5.- El consentimiento</strong></li>
      </ol>
      <p>Como se viene indicando en este texto de Pol&iacute;tica de Cookies, el usuario puede tanto consentir o como rechazar la instalaci&oacute;n en su dispositivo electr&oacute;nico de las cookies, siempre que sean no funcionales para esta p&aacute;gina web, en el momento de acceder a la misma. En consecuencia de ello, nada m&aacute;s acceder a la p&aacute;gina web al usuario se le muestra el llamado &ldquo;Banner de Cookies&rdquo; o &ldquo;Avisador de Cookies&rdquo; que cuenta con los siguientes bonotes: Aceptar, Rechazar o Modificar. As&iacute; miso, el usuario previa elecci&oacute;n cuenta con una informaci&oacute;n breve y con la posibilidad de acceder tanto a la Pol&iacute;tica de Cookies como a la Pol&iacute;tica de Privacidad para su lectura e informaci&oacute;n. Con lo cual, EL TITULAR pone a disposici&oacute;n del usuario todos los medios para que su consentimiento sea <strong>INFORMADO</strong>.</p>
      <p>Por lo cual, en el caso de que el usuario pulsa el botono de Aceptar, otorga un consentimiento expreso e informado para la instalaci&oacute;n de las Cookies en su dispositivo electr&oacute;nico.</p>
      <p>Mientras tanto, si presiona el bot&oacute;n Rechazar, rechaza la instalaci&oacute;n de todas las cookies en su dispositivo a excepci&oacute;n de las cookies funcionales.</p>
      <p>Por &uacute;ltimo, cuando el usuario pulsa el bot&oacute;n de Modificar, tiene la opci&oacute;n de seleccionar las cookies que se le instalar&aacute;n en el dispositivo, esto es, por ejemplo, puede optar por instalar las cookies de marketing, pero no las estad&iacute;sticas.</p>
      <p>En consecuencia, la simple navegaci&oacute;n del usuario por la p&aacute;gina web sin previo consentimiento expreso o no consentimiento para la instalaci&oacute;n de las cookies no presupone aceptar las cookies de manera tacita, esto es, que el usuario tiene que aceptar el uso de las cookies de manera expresa.</p>
      <ol start="6">
      <li><strong>6.- &iquest;C&oacute;mo se pueden modificar y desinstalar las cookies?</strong></li>
      </ol>
      <p>El usuario de la p&aacute;gina web en todo momento puede bloquear o eliminar las cookies que fueron previamente instaladas en su dispositivo inform&aacute;tico. No obstante, el usuario debe tener en cuenta que bloqueando o eliminando algunas de las cookies, determinados servicios ofrecidos en esta p&aacute;gina web podr&iacute;an dejar de funcionar.</p>
      <p>El usuario puede deshabilitar y borrar las cookies desde su navegador de internet, siguiendo un procedimiento concreto para cada navegador. A continuaci&oacute;n, se ofrecen al usuario los enlaces con instrucciones de c&oacute;mo hacer el borrado de las cookies en su equipo en funci&oacute;n del navegador que utiliza:</p>
      <ul>
      <li>Google Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=es-419">https://support.google.com/chrome/answer/95647?hl=es-419</a></li>
      <li>Internet Explorer: <a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-managecookies#ie=ie-10">https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-managecookies#ie=ie-10</a></li>
      <li>Mozilla Firefox: <a href="https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias">https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias</a></li>
      <li>Apple Safari: <a href="https://support.apple.com/en-us/HT201265">https://support.apple.com/en-us/HT201265</a></li>
      </ul>
      <p>&nbsp;</p>
      <ol start="7">
      <li><strong>7.- Actualizaciones y cambios en la Pol&iacute;tica de Cookies</strong></li>
      </ol>
      <p>EL TITULAR puede modificar esta Pol&iacute;tica de Cookies en funci&oacute;n de nuevas exigencias legislativas, reglamentarias o con la finalidad de adaptar dicha pol&iacute;tica a efectos de la implementaci&oacute;n de mejoras en esta p&aacute;gina web.</p>
      <p>Cuando se produzcan cambios significativos en esta Pol&iacute;tica de Cookies se comunicar&aacute; al usuario mediante un aviso informativo en la p&aacute;gina web.</p>

    </section>
  );
}

export default PoliticaCookies;