import "./Sales.scss";
import SalesItem from "../SalesItem/SalesItem";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getApiSalesBySeller } from "../../../services/apiSale";

function Sales() {
  const user = useSelector((store) => store.user.user);
  const [sales, setSales] = useState(null);
  const [salesTotal, setSalesTotal] = useState(0);
  const [salesAmount, setSalesAmount] = useState(null);
  const [salesViews, setSalesViews] = useState(null);

  useEffect(() => {
    const getSales = async () =>{
      const response = await getApiSalesBySeller({
        user_id : user._id
      })

      let sumSales = 0
      await Promise.all(response.sales.map(sale => {
        sumSales += sale.book.sales
      }))

      setSales(response.sales)
      setSalesTotal(sumSales)
      setSalesAmount(response.salesAmount.toFixed(2))
      setSalesViews(response.salesViews)
    }

    getSales()
  }, [user]);

  return (
    <section className="sales">
      <div className="sales__container">
        <div>
          <h3 className="sales__container-title">Resumen</h3>
          { sales ? 
            <div className="sales__container-data">
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ salesAmount ? salesAmount.toString().replace('.', ',') : 0 }€</p>
                <p className="sales__container-data-section-text">Ganancias totales</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ salesTotal ? salesTotal : 0 }</p>
                <p className="sales__container-data-section-text">Ventas totales</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ user && user.books ? user.books.length : 0 }</p>
                <p className="sales__container-data-section-text">Obras publicadas</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ salesViews ? salesViews : 0 }</p>
                <p className="sales__container-data-section-text">Visitas a obras</p>
              </div>
            </div>
          :
            <div className="sales__container-data">
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">0€</p>
                <p className="sales__container-data-section-text">Ganancias totales</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">0</p>
                <p className="sales__container-data-section-text">Ventas totales</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ user && user.books ? user.books.length : 0 }</p>
                <p className="sales__container-data-section-text">Obras publicadas</p>
              </div>
              <div className="sales__container-data-section">
                <p className="sales__container-data-section-number">{ salesViews ? salesViews : 0 }</p>
                <p className="sales__container-data-section-text">Visitas a obras</p>
              </div>
            </div>
          }
        </div>
      </div>
      <div className="sales__component">
        <div>
          <h3 className="sales__component-title">
            Ventas por obra
          </h3>
        </div>
        { sales ?   
          <div className="sales__component-items">
            { sales && sales.map(sale => (
              <SalesItem sale={sale} key={ sale._id } />
            ))}
          </div>
        :
          <p>No has vendido ningún libro todavía</p>
        }
      </div>
    </section>
  );
}

export default Sales;
