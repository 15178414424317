import "./ModalIsbnExplanation.scss";
import { useNavigate } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

function ModalIsbnExplanation (props) {
  const navigate = useNavigate();

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.show}
        onHide={props.onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-isbn-explanation-container"
      >
        <Modal.Body>
          <div className="modal-isbn-explanation">
            <h2 className="modal-isbn-explanation__title">Código ISBN</h2>
            <p className="modal-isbn-explanation__text">
              El International Standard Book Number (ISBN) es un número internacional para dotar a cada libro de un código numérico que lo identifique y facilitar su circulación en el mercado. No es obligatorio por ley para poder publicar una obra ni protege los derechos de autor de la misma. Tampoco es un número que certifique la calidad de la publicación. No obstante, ayuda a dara conocer el libro en las bases de datos oficiales y aumenta el potencial de las ventas. Dicha numeración se gestiona a través de la Agencia del ISBN que se integra dentro de la Federación de Gremios de Editores de España. Los libros, en todos sus formatos, requieren de un ISBN diferente. Así pues, tu ISBN de una publicación en papel no puede usarse para la publicación en formato digital. El número ASIN que proporcionan algunas plataformas a los libros digitales no es un ISBN, solo es una numeración de producto. Por lo tanto no puedes usar en Bookólicos dicho número como un ISBN digital.
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalIsbnExplanation;
