
const getSize = (size) => {
  var sizes = [' Bytes', ' KB', ' MB', ' GB',
              ' TB', ' PB', ' EB', ' ZB', ' YB'];
    
  for (var i = 1; i < sizes.length; i++) {
      if (size < Math.pow(1024, i))
      return (Math.round((size / Math.pow(
          1024, i - 1)) * 100) / 100) + sizes[i - 1];
  }
  return size;
}

const isURLValid = (str) => {
  try {
    const newUrl = new URL(str);
    return newUrl.protocol === 'http:' || newUrl.protocol === 'https:';
  } catch (err) {
    return false;
  }
}

const readingTime = (text) => {
  const wpm = 225;
  const words = text.trim().split(/\s+/).length;
  const time = Math.ceil(words / wpm);
  return time;
}

const createExcerpt = (content, maxNumberOfWords, trailingIndicator = '...') => {
  const listOfWords = content.trim().split(' ');
  const truncatedContent = listOfWords.slice(0, maxNumberOfWords).join(' ');
  const excerpt = truncatedContent + trailingIndicator;
  const output = listOfWords.length > maxNumberOfWords ? excerpt : content;
  
  return output;
};

const objectToExport = {
  getSize: getSize,
  isURLValid : isURLValid,
  readingTime : readingTime,
  createExcerpt : createExcerpt
};

export default objectToExport;
