import "./Searcher.scss";
import { useEffect } from "react";
import { useSearchParams } from 'react-router-dom';
import SearcherComponent from "../../components/SearcherComponent/SearcherComponent";
import NameSection from "../../components/NameSection/NameSection";
import InputSearch from "../../components/DesingSystem/InputSearch/InputSearch";
import React, {useState} from "react";

function Searcher() {
  const [searchParams, setSearchParams] = useSearchParams()
  const [textEntered, setTextEntered] = useState(searchParams.get('keyword') === null ? '' : searchParams.get('keyword'));

  const handleSearchChange = (keyword) => {
    setTextEntered(keyword === null ? '' : keyword)
  }

  return (
    <section className="searcher">
      <NameSection full>
        <div className="searcher__input-wrapper">
          <InputSearch
            id="text"
            name="text"
            placeholder="Busca obras, autores, géneros..."
            changeHandler={handleSearchChange}
            value={textEntered}
          />
        </div>
      </NameSection>
      <SearcherComponent searchText={textEntered}/>
    </section>
  );
}

export default Searcher;
