import "./VideoHome.scss";
import React, { useEffect, useRef } from "react";

function VideoHome() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <section className="video-home">
      <div className="video-home__container">
        <video playsInline
          loop
          muted
          alt="All the devices"
          src="https://s3-monobo-test-nodejs.s3.eu-west-1.amazonaws.com/video_hero_bookolicos.mp4"
          ref={videoEl}
          className="video-home__video" />
      </div>
    </section>
  );
}

export default VideoHome;
