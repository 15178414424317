import React from "react";
import "./Icon.scss";
import Icons from "../../styles/icons";
import PropTypes from "prop-types";

const Icon = ({ name, size, background, border, color, ...props }) => {
  const Icon = Icons[name];
  const sizeClass = `icon--${size}`;
  const backgroundClass = `icon--background-${background}`;
  const colorClass = `icon--color-${color}`;
  const borderClass = `icon--${border}`;

  return (
    <div
      {...props}
      className={`icon ${sizeClass} ${background ? backgroundClass : ""} ${
        props.className ? props.className : ""
      } ${border ? borderClass : ""}`}
    >
      {color ? (
        <Icon
          width="100%"
          height="100%"
          className={colorClass}
        />
      ) : (
        <Icon width="100%" height="100%" />
      )}
    </div>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf(['add', 'amex', 'arrowDiagonal', 'arrowLeft', 'arrowLong', 'arrowRight', 'chevronUp', 'chevronDown', 'chevronLeft', 'chevronRight', 'close', 'deleteIcon', 'download', 'edit', 'eye', 'face', 'faceTwo', 'facebook', 'file', 'google', 'instagram', 'linkedin', 'loaderIcon', 'logout', 'mastercard', 'mail', 'menu', 'mobile', 'notifications', 'pencil', 'profile', 'search', 'share', 'tick', 'tooltip', 'twitter', 'visa', 'write', 'euro']),
  size: PropTypes.oneOf(['small', 'medium', 'medium2', 'large', 'xlarge']),
  background: PropTypes.oneOf(['1', '2', '3', '4', '5', '6']),
  color: PropTypes.oneOf(['principal', 'secondary-1', 'secondary-2', 'secondary-3', 'secondary-4', 'secondary-6', 'secondary-7', 'principal-border-transparent']),
  border: PropTypes.oneOf(['border-1', 'border-2','background-2','background-4','background-5']),
  className: PropTypes.string,
};

Icon.defaultProps = {
  name: "add",
  size: "small",
  background: null,
  color: "principal",
  border: null,
  className: "",
};

export default Icon;
