import "./SliderBooksPublic.scss";
import Slider from "react-slick";
import ModalBookDetail from "../../../BookCatalog/ModalBookDetail/ModalBookDetail";
import Icon from "../../../Icon/Icon";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getBook } from "../../../../redux/actions/book";

function SliderBooksPublic(props) {
  const dispatch = useDispatch();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4.2,
    className: "slider-books__items",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3.3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.2,
        },
      },
    ],
  };
  const maxCaracteres = 25;
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [loadedBook, setLoadedBook] = useState(false);
  const bookDetail = useSelector((store) => store.book.book);

  const showModalDetail = async (e, bookId) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    await dispatch(getBook(bookId));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const renderBooks = () => {
    return props.books.map((book) => {
      return (
        <div
          className="slider-books-public__item"
          key={book._id}
          onClick={(e) => showModalDetail(e, book._id)}
        >
          <div className="slider-books-public__section-image">
            <img
              className="book-public__section-image-img"
              src={book.picture_medium}
              alt="img"
            />
            <h2 className="slider-books-public__section-image-title">
              {book.title.substring(0, maxCaracteres) + "..."}
            </h2>
            <h3 className="slider-books-public__section-image-author">
              Por{" "}
              {book.user.firstName.length > maxCaracteres
                ? book.user.firstName.substring(0, maxCaracteres) + "..."
                : book.user.firstName}{" "}
              {book.user.lastName.length > maxCaracteres
                ? book.user.lastName.substring(0, maxCaracteres) + "..."
                : book.user.lastName}
            </h3>
            <div className="slider-books-public__section-image-price">
              {book.price} €
            </div>
            <div className="slider-books-public__arrow">
              <Icon name="arrowDiagonal" size="large" color="secondary-6" />
            </div>
          </div>
        </div>
      );
    });
  };

  return props.books ? (
    <div className="slider-books-public">
      <Slider {...settings}>{renderBooks()}</Slider>
      {bookDetail ? (
        <ModalBookDetail
          book={bookDetail}
          show={modalDetailShow}
          hide={hideModalDetail}
        />
      ) : null}
    </div>
  ) : null;
}

export default SliderBooksPublic;
