import "./GreetingsSection.scss";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ls from "../../../services/localStorage"
import validateToken from "../../../services/validateToken"
import { getUserById } from "../../../redux/actions/user";

function GreetingsSection(props) {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if(tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    }
  }, []);

  return (
    <section>
      <div className="greetings__container">
        <div>
          <h1 className="greetings__container-title">
            { user ? 
              <span>¡Hola { user.firstName }!</span>
              : null }
          </h1>
        </div>
        {/*<div>
          <div className="greetings__container-img">
            <img src="/images/silla.png" alt="chair" />
          </div>
        </div>*/}
      </div>
    </section>
  );
}

export default GreetingsSection;
