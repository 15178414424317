import "./LastNews.scss";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getNewBooks } from "../../../../redux/actions/book";
import SliderBooksPublic from "../SliderBooksPublic/SliderBooksPublic";
import Button from "../../../DesingSystem/Button/Button";

function LastNews() {
  const dispatch = useDispatch();
  const new_books = useSelector((store) => store.book.new_books);
  const navigate = useNavigate();

  const goToCatalog = () => {
    navigate("/descubre");
  };

  useEffect(() => {
    dispatch(getNewBooks());
  }, [dispatch]);

  return (
    <section className="last-news">
      <p className="last-news__title">Últimas novedades</p>
      <p className="last-news__subtitle">
        Navega, descubre y déjate seducir por las historias más recientes <br />
        de nuestros autores Bookólicos.
      </p>
      <SliderBooksPublic books={new_books} />
      <div className="last-news__button">
        <div className="last-news__button-btn-tabletdesktop">
          <Button
            className="btn-white"
            text="Descubre el catálogo"
            onClick={goToCatalog}
          />
        </div>
        <div className="last-news__button-btn-mobile">
          {" "}
          <Button
            className="btn-blackbg"
            text="Descubre el catálogo"
            onClick={goToCatalog}
          />
        </div>
      </div>
    </section>
  );
}

export default LastNews;
