import ls from "../services/localStorage";
import { types } from "../constants/constants";

/**
 * @returns {Promise<{ bundle: Bundle }>}
 */
export const getApiBundle = async () => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}bundle`, {
    headers: { token: token ?? '' }
  });

  const result = await response.json();
  if (!response.ok) {
    throw new Error(result);
  } else {
    return result;
  }
};

export const postApiCreateBundleSale = async (attributes) => {
  const token = ls.get("token");

  const response = await fetch(`${types.API_URL}sale/bundle/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: token ?? ''
    },
    body: JSON.stringify(attributes)
  });

  const result = await response.json();
  if (!response.ok) {
    throw new Error(result);
  } else {
    return result;
  }
};

/**
 * @typedef {Object} Bundle
 * @property {string} _id
 * @property {Course[]} courses
 * @property {string} description
 * @property {number} price
 * @property {string} title
 */

/**
 * @typedef {Object} Course
 * @property {string} _id
 * @property {string} content
 * @property {number} price
 * @property {string} title
 * @property {string} picture_big
 * @property {string} hours
 * @property {string} frequency
 */
