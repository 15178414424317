import "./FormIsRegistered.scss";

import { useState } from "react";

import { useDispatch } from "react-redux";
import { isRegistered } from "../../../redux/actions/user";

import ls from "../../../services/localStorage";

import LoginGoogle from "../LoginGoogle/LoginGoogle";
import LoginFacebook from "../LoginFacebook/LoginFacebook";
import Button from "../../DesingSystem/Button/Button";
import InputText from "../../DesingSystem/InputText/InputText";


function FormIsRegistered(props) {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const attributes = { email: email };
    ls.set("APP_STATE_EMAIL", email);
    dispatch(isRegistered(attributes));
  };

  const saveEmail = (event) => {
    const enteredEmail = event.target.value;
    setEmail(enteredEmail);
    ls.set("APP_STATE_EMAIL", email);
    setIsEmailEntered(enteredEmail !== "");
  };

  const handleInputFocus = () => {
    setIsEmailEntered(true);
  };

  const handleInputBlur = () => {
    setIsEmailEntered(false);
  };

  return (
    <section className="is-registered">

      <h1 className="login-container__title">¡Bienvenido a Bookólicos!</h1>
      <div className="container">
        <div className="row">
          <div className="col-12 px-3 px-md-0 col-md-6 offset-md-3">
            <form className="is-registered__form" onSubmit={onSubmit}>
              <InputText
                onChange={saveEmail}
                value={email}
                placeholder="Ingrese su email"
                error={isEmailEntered}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                type="email"
                required
              />

              <Button
                className={`mt-3 ${
                  email === "" ? "btn-graybg w-100" : "btn-orangebg w-100"
                }`}
                text={"Continuar"}
                type={"submit"}
              />
            </form>
          </div>
        </div>

        <div className="row">
          <div className="col-12 px-3 px-md-0  col-md-8 offset-md-2">
            <div className="divider line razor">
              <p className="m-0">o</p>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 px-3 px-md-0  col-md-6 offset-md-3">
            <div className="is-registered__facebook-google">
              <LoginFacebook />
              <LoginGoogle />
            </div>
          </div>
        </div>

      </div>
    </section>
  );
}

export default FormIsRegistered;
