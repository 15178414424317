import "./Confirmation.scss";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";

function Confirmation() {
  return (
    <section className="confirmation">
      <img
        className="confirmation__img"
        src="/images/silla-2.png"
        alt="not-found"
      />
      <div className="confirmation__section-text">
        <Icon name="faceTwo" size="large" color="principal" />
        <h1 className="confirmation__title">¡Todo listo!</h1>
        <p className="confirmation__text">
          Gracias por verificar tu correo electrónico. Ahora sigue disfrutando de Bookólicos.
        </p>
        <Button className={"btn-orange"} text={"Volver a la home"} />
      </div>
    </section>
  );
}

export default Confirmation;
