import {
  getApiBooks,
  getApiBooksByUser,
  getApiBooksByCategory,
  getApiNewBooks,
  getApiFeaturedBooks,
  getApiBook,
  postApiCreateBook
} from "../../services/apiBook";

export const cleanRedux = () => {
  return async function (dispatch) {
    return dispatch({
      type: "CLEAN_REDUX",
      payload: '',
    });
  };
}

export const getBooks = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiBooks(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_BOOKS",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getMoreBooks = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiBooks(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_MORE_BOOKS",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getBooksByUser = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiBooksByUser(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_BOOKS_BY_USER",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getBooksByCategory = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiBooksByCategory(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_BOOKS_BY_CATEGORY",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getMoreBooksByCategory = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiBooksByCategory(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_MORE_BOOKS_BY_CATEGORY",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getNewBooks = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiNewBooks(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_NEW_BOOKS",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getFeaturedBooks = (attributes = {}) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });

    return getApiFeaturedBooks(attributes)
      .then((response) => {
        return dispatch({
          type: "GET_FEATURED_BOOKS",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getBook = (bookId) => {
  return async function (dispatch) {
    dispatch({ type: "LOADING" });
    
    return getApiBook(bookId)
      .then((response) => {
        return dispatch({
          type: "GET_BOOK",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const createBook = (attributes) => {
  return async function (dispatch) {
    return postApiCreateBook(attributes)
      .then((response) => {
        return dispatch({
          type: "CREATE_BOOK",
          payload: response,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_BOOK_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};