import "./ModalCancelAccount.scss";
import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button"

function ModalCancelAccount(props) {
  return (
    <section className="modal-cancel-account">
      <div onClick={(e) => e && e.stopPropagation()}></div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="modal-cancel-account">
            <h3 className="modal-cancel-account__title">
              ¿Quieres eliminar tu cuenta?
            </h3>
            <p className="modal-cancel-account__text">
              Si deseas eliminar tu cuenta escribenos un email a <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a> con tu nombre, apellidos, email y, si lo deseas, el motivo por el que quieres eliminar tu cuenta. Muchas gracias
            </p>
            <div className="modal-cancel-account__container-button">
              <Button
              className="btn-orange mt-4"
              text="Aceptar"
              onClick={props.onHide} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default ModalCancelAccount;
