import "./BundleReview.scss";

/**
 * @typedef {Object} Review
 * @property {string} review
 * @property {string} name
 * @property {string} img
 * @property {string} title
 */

/**
 * @param {Object} props
 * @param {Review} props.review
 */
const BundleReview = ({ review: { review, name, img, title } }) => {
  return (
    <div className="bundle-review">
      <div>
        <img src={img} />
        <div>
          <h2>{name}</h2>
          <h3>{title}</h3>
        </div>
      </div>
      <p>"{review}"</p>
    </div>
  );
};

export default BundleReview;
