import ShoppingItem from "../ShoppingItem/ShoppingItem";
import "./Shopping.scss";

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getApiSalesByCustomer, getApiSalesCoursesByCustomer } from "../../../services/apiSale";
import Button from "../../DesingSystem/Button/Button";
import CoursesMock from "../../../mocks/courses.json";
import {navigate} from "@storybook/addon-links";
import * as dayjs from "dayjs";

function ShoppingCourses() {
  const user = useSelector((store) => store.user.user);
  const [purchases, setPurchases] = useState(null);
  const [purchasedCourses, setPurchasedCourses] = useState(null);

  useEffect(() => {
    const getCourses = async () => {
      const response = await getApiSalesCoursesByCustomer({
        user_id : user._id
      })

      setPurchasedCourses(response.sales)
    }

    getCourses()
  }, [user]);


  return (
    <>
      <section className="shopping shopping--margin-top">
        <div className="shopping__container">
          <h3 className="shopping__container-title">Tus cursos</h3>
        </div>
        { purchasedCourses && purchasedCourses.length ?
          <div className="shopping__component">
            { purchasedCourses.map(purchase => (
              <PurchasedCourse purchase={purchase.course} />
            ))}
          </div>
          :
          <p>No has comprado ningún curso todavía</p>
        }
      </section>
    </>
  );
}

const PurchasedCourse = (props) => {
  const formatDate = (date) => {
    // Format date as 15 Jul in spanish
    return dayjs(date).format("DD MMM");
  }

  return (
    <div className="purchased-course">
      <div className="purchased-course__info">
        <h3 className="purchased-course__info-title">{props.purchase.title}</h3>
        <p className="purchased-course__info-professor">{props.purchase.teacher}</p>
        <div className="purchased-course__info-dates">
          {props.purchase.date ? props.purchase.date : 'Asíncrono'} |
          <span>{props.purchase.hours}h</span>
        </div>
      </div>
      <div className="purchased-course__button">
        <Button
          className="btn-white"
          text="Ir al curso"
          iconRight="arrowRight"
          onClick={() => window.location.href = props.purchase && props.purchase.url ? (props.purchase.url.indexOf('http') !== -1 ? props.purchase.url : 'https://' + props.purchase.url) : '#'}
        />
      </div>
    </div>
  )
}

export default ShoppingCourses;
