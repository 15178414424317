import "./AuthorItem.scss";
import { useNavigate } from "react-router-dom";
import { useIsPublic } from "../../../hooks/use-is-public";
import Avatar from "../../ProfileComponents/Avatar/Avatar";
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';

function AuthorItem(props) {
  const navigate = useNavigate();
  const isPublic = useIsPublic();

  const tagClick = () => {
    if(isPublic){
      navigate(`/perfil-autor/${props.author.nickName}`);
    }else{
      navigate(`/perfil-del-autor/${props.author.nickName}`);
    }
  };

  return (
    <div className="author">
      <p className="author__title">Autor</p>
      <div className="author__container" onClick={tagClick}>
        <div className="author__container-image">
          { props.author && props.author.profilePic ? (
            <img
              className="author__container-image-img"
              src={props.author.profilePic}
              alt="img"
            />
          ) : (
            <Avatar user={props.author} size={'m'} />
          ) }
        </div>
        <div className="author__info">
        <p className="author__name">{props.author ? props.author.firstName + ' ' + props.author.lastName : '--'}</p>
        <div className="author__container-info">
          <p className="author__container-info-works">{props.books ? (props.books.length + 1) : 0} obras publicadas</p>
         {/* <p className="author__container-info-publication">
            Desde { props.author ? dayjs(props.author.created_at).format('MMMM YYYY') : '--' }
          </p>*/}
        </div>
        </div>
      </div>
    </div>
  );
}

export default AuthorItem;
