import "./NotFound.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";

function NotFound() {
  const navigate = useNavigate();

  return (
    <section className="not-found">
      <img
        className="not-found__img"
        src="/images/404pageNotFound.png"
        alt="not-found"
      />
      <div className="not-found__section-text">
        <Icon name="face" size="large" color="principal" />
        <h1 className="not-found__title">Oh no...</h1>
        <p className="not-found__text">
          ¡Lo sentimos! Parece que no encontramos la página que solicitaste.
        </p>
        <Button className={"btn-orange"} text={"Volver a la home"} onClick={() => { navigate('/') }} />
      </div>
    </section>
  );
}

export default NotFound;
