import "./PublicationsItem.scss";
import * as dayjs from "dayjs";
import "dayjs/locale/es";

import Button from "../../DesingSystem/Button/Button";
import Icon from "../../Icon/Icon";
import { useNavigate } from "react-router-dom";

function PublicationsItem(props) {
  dayjs.locale("es");
  const navigate = useNavigate();

  const goToEditBook= () => {
    navigate(`/editar-libro/${props.book._id}`);
  };

  return (
    <section className="publications-item">
      <div>
        <img
          className="publications-item__image"
          src={props.book.picture_medium}
          alt="image1"
        />
      </div>

      <div className="publications-item__container">
        <div className="publications-item__content">
          <h3 className="publications-item__title">{props.book.title}</h3>
          <div className="publications-item__section-sales">
            <p className="publications-item__sales">
              {props.book.sales} ventas ·
            </p>
            <p className="publications-item__date">
              {dayjs(props.book.created_at).format("D MMM YYYY")}
            </p>
          </div>
          <Button className="btn-white" iconRight="pencil" text="Editar" onClick={goToEditBook}/>
        </div>

        {/*<div>
          <Icon name="deleteIcon" size="small" color="secondary-3" />
        </div>*/}
      </div>
    </section>
  );
}

export default PublicationsItem;
