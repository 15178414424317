import "./HeaderPublic.scss";

import React, { useEffect, useState } from "react";
import logoHeader from "../../../styles/images/logo.svg";
import logoHeaderWhite from "../../../styles/images/logo-silla-pequeña-blanco.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useMediaQuery } from 'react-responsive'
import Icon from "../../Icon/Icon";
import Button from "../../DesingSystem/Button/Button";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import SearcherComponent from "../../SearcherComponent/SearcherComponent";
import InputSearch from "../../DesingSystem/InputSearch/InputSearch";
import {useSolidHeader} from "../../../hooks/use-solid-header";

function HeaderPublic() {
  const navigate = useNavigate();
  const location = useLocation();
  const isSolidHeader = useSolidHeader();
  const isTabletOrDesktop = useMediaQuery({ query: '(min-width: 768px' })
  const [isServicesActive, setIsServicesActive] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [headerFixed, setHeaderFixed] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const isActive = location.pathname.startsWith("/perfil-autor/");
  const isHomePublic = location.pathname === "/";

  useEffect(() => {
    setCurrentLocation(location.pathname)
  }, [location]);

  useEffect(() => {
    setIsServicesActive(false)
  }, [location]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (!isTabletOrDesktop) {
          setHeaderFixed(true);
        } else {
          setHeaderFixed(window.pageYOffset > 360);
          if (window.pageYOffset <= 10) {
            setHeaderFixed(false);
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    const token = ls.get("token");
    async function isUserLogged() {
      const isTokenOK = await validateToken.validate(token);
      if (
        isTokenOK &&
        location.pathname !== "/contacto" &&
        location.pathname !== "/faqs"
      ) {
        navigate("/home");
      }
    }

    isUserLogged();
  }, [navigate]);

  const showSearch = () => {
    console.log("show search");
    setSearchOpen(true);
  };

  const hideSearch = () => {
    console.log("hide search");
    setSearchOpen(false);
  };

  const handleSubmit = (keyword) => {
    navigate('/busqueda/?keyword=' + keyword)
    window.location.href = '/busqueda/?keyword=' + keyword;
  }

  const goToPrivate = () => {
    if(currentLocation){
      navigate("/login?return=" + currentLocation);
    } else {
      navigate("/login");
    }
  };

  const toggleServicesMenu = () => {
    isServicesActive ? setIsServicesActive(false) : setIsServicesActive(true);
  };

  return (
    <React.Fragment>
      <section
        className={`header-public-fixed ${
          !isSolidHeader && (!searchOpen && !headerFixed) ? "hide-animation" : ""
        }`}
      >
        { isServicesActive &&
          <div className="header-public-fixed__full px-1 pb-5">
            <div className="px-5 mx-4 d-flex flex-row">
              <div style={{ width: '31%' }}>
                <Link
                  className={`header-public-fixed__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-lector-cero"}
                >
                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Lector cero
                  </span>
                </Link>
                <Link
                  className={`header-public-fixed__full__link d-flex align-items-center mb-1`}
                  to={"/servicio-correccion-estilo"}
                >
                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Correción de estilo
                  </span>
                </Link>
              </div>

              <div style={{ width: '31%' }}>
                <Link
                  className={`header-public-fixed__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-coaching"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Coaching
                  </span>
                </Link>

                <Link
                  className={`header-public-fixed__full__link d-flex align-items-center mb-1`}
                  to={"/servicio-maquetacion-digital"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Maquetación digital
                  </span>
                </Link>
              </div>

              <div style={{ width: '35%' }}>
                <Link
                  className={`header-public-fixed__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-correccion-ortotipografica"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Corrección ortotipográfica
                  </span>
                </Link>
              </div>
            </div>
          </div>
        }
        <div className="header-public-fixed__general-container">
          <div className="header-public-fixed__general-container-logo">
            <Link to={"/"}>
              <img
                className="header-public-fixed__img"
                src={logoHeader}
                alt="Logo Bookolicos"
              ></img>
            </Link>
            <div className="header-public-fixed__container">
              <Link
                className="header-public-fixed__container-list"
                to={"/descubre"}
              >
                Catálogo
              </Link>

              <Link
                className="header-public-fixed__container-why"
                to={"/por-que-bookolicos"}
              >
                ¿Por qué Bookólicos?
              </Link>

              <Link
                className="header-public-fixed__container-who"
                to={"/quienes-somos"}
              >
                Quiénes somos
              </Link>

              <Link
                className="header-public-fixed__container-who"
                to={"/agenda"}
              >
                Eventos
              </Link>
              
              <Link
                className="header-public-fixed__container-who"
                to={"/agenda"}
              >
                Eventos
              </Link>
              <Link
                  className="header-public-fixed__container-who d-inline-flex align-items-center pointer"
                  onClick={(e) => { e.preventDefault(); toggleServicesMenu(); }}
                >
                    Servicios <Icon name="chevronDown" color="secondary-3" size="small" className="p-1 ms-1" />
                </Link>
            </div>
            <div>
              <InputSearch
                id="text"
                name="text"
                placeholder="Busca obras, autores, géneros..."
                hideSubmitButton={true}
                changeHandler={handleSubmit}
              />
            </div>
          </div>
          <div style={{ 'zIndex' : '1' }}>
            <Button
              className="btn-blackbg"
              text="Acceder"
              onClick={goToPrivate}
            />
          </div>
        </div>
      </section>

      {!isSolidHeader ?
      <section
        className={`header-public ${isActive ? "header-public__bg" : ""}`}
      >
        { isServicesActive &&
          <div className="header-public__full px-1 pb-5">
            <div className="px-5 mx-4 d-flex flex-row">
              <div style={{ width: '31%' }}>
                <Link
                  className={`header-public__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-lector-cero"}
                >
                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Lector cero
                  </span>
                </Link>
                <Link
                  className={`header-public__full__link d-flex align-items-center mb-1`}
                  to={"/servicio-correccion-estilo"}
                >
                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Correción de estilo
                  </span>
                </Link>
              </div>

              <div style={{ width: '31%' }}>
                <Link
                  className={`header-public__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-coaching"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Coaching
                  </span>
                </Link>

                <Link
                  className={`header-public__full__link d-flex align-items-center mb-1`}
                  to={"/servicio-maquetacion-digital"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Maquetación digital
                  </span>
                </Link>
              </div>

              <div style={{ width: '35%' }}>
                <Link
                  className={`header-public__full__link d-flex align-items-center mb-4`}
                  to={"/servicio-correccion-ortotipografica"}
                >

                  <Icon name="arrowRight" color="principal" size="medium" className="me-1 p-1" />
                  <span>
                    Corrección ortotipográfica
                  </span>
                </Link>
              </div>
            </div>
          </div>
        }
        <div
          className={`header-public ${
            isHomePublic ? "header-public__margin-top" : ""
          }`}
        >
          <div className="header-public__general-container">
            <div className="header-public__general-container-logo">
              <Link to={"/"}>
                <img
                  className="header-public__img"
                  src={logoHeaderWhite}
                  alt="Logo Bookolicos"
                ></img>
              </Link>
              <div className="header-public__container">
                <Link
                  className="header-public__container-list"
                  to={"/descubre"}
                >
                  Catálogo
                </Link>

                <Link
                  className="header-public__container-why"
                  to={"/por-que-bookolicos"}
                >
                  ¿Por qué Bookólicos?
                </Link>

                <Link
                  className="header-public__container-who"
                  to={"/quienes-somos"}
                >
                  Quiénes somos
                </Link>

                <Link
                  className="header-public__container-who"
                  to={"/agenda"}
                >
                  Eventos
                </Link>

                <Link
                  className="header-public__container-who d-inline-flex align-items-center pointer"
                  onClick={(e) => { e.preventDefault(); toggleServicesMenu(); }}
                >
                    Servicios <Icon name="chevronDown" color="secondary-6" size="small" className="p-1 ms-1" />
                </Link>
              </div>
              <div>
                <InputSearch
                  id="text"
                  name="text"
                  placeholder="Busca obras, autores, géneros..."
                  hideSubmitButton={true}
                  transparent
                  white
                  changeHandler={handleSubmit}
                />
              </div>
            </div>
            <div>
              <Button
                className="btn-blackbg"
                text="Acceder"
                onClick={goToPrivate}
              />
            </div>
          </div>
        </div>
      </section>
        : null}
    </React.Fragment>
  );
}

export default HeaderPublic;
