import { useLocation } from "react-router-dom";

export const useIsLanding = () => {
  const location = useLocation();

  const locations = [
    "consigue-tu-isbn",
    "bases-legales"
  ]

  // return if location.pathname includes any of the locations
  return locations.some((path) => location.pathname.includes(path));
}
