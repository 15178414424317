import "./Publications.scss";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PublicationsItem from "../PublicationsItem/PublicationsItem";
import { getBooksByUser } from '../../../redux/actions/book'

function Publications() {
    const dispatch = useDispatch();
    const books = useSelector((store) => store.book.user_books);

    useEffect(() => {
        const token = ls.get('token')
        const user = validateToken.getUser(token)

        dispatch(getBooksByUser({ user : user._id}));
    }, [dispatch]);

    const renderBooks = () => {
        return books && books.map((book) => {
            return <PublicationsItem book={book} key={book._id} />;
        });
    };

    return (
        <React.Fragment>
            { books ? 
                <section className="publications">
                    <h3 className="sales__container-title">Tus publicaciones</h3>
                    { renderBooks() }
                </section>
            : 
                <section className="publications">
                    <h3 className="sales__container-title">Tus publicaciones</h3>
                    <p>No has publicado ningún libro todavía</p>
                </section>
            }

        </React.Fragment>

    );
}

export default Publications;
