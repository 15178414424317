import React, { useState, useEffect } from "react";
import "./blog-detail.scss";
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';
import Container from "react-bootstrap/Container";
import Icon from "../../components/Icon/Icon";
import Slider from "react-slick";
import BlogItem from "./blog-item";
import { Modal } from "react-bootstrap";
import Button from "../../components/DesingSystem/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getApiPosts, getApiPostBySlug } from "../../services/apiBlog";
import { useIsPublic } from "../../hooks/use-is-public";
import utils from "../../services/utils"
import copy from "copy-to-clipboard";

function BlogDetail () {
  const navigate = useNavigate();
  const urlParams = useParams();
  const isPublic = useIsPublic()

  const [results, setResults] = useState([]);
  const [detail, setDetail] = useState([]);
  const [shareResult, setShareResult] = useState('');

  const slider = React.useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    variableWidth: true,
    className: "blog-detail__slider-item",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const slug = urlParams.slug
    getPost(slug)
    getPosts()
  }, [urlParams]);

  const getPosts = async() => {
    const results = getApiPosts()
    .then(async (response) => {
      let resultsArray = [];

      await Promise.all(response.map(result => {
        resultsArray.push({
          _id: result.id,
          slug: result.slug,
          image: result._embedded["wp:featuredmedia"][0].source_url,
          author: result._embedded.author[0].name,
          date: result.date,
          title: result.title.rendered,
          readTime: utils.readingTime(response[0].content.rendered)
        })
      }))
      setResults(resultsArray);
    })
    .catch((error) => {
      console.log(error.message);
      setResults([]);
    });
  }

  const getPost = async(slug) => {
    const results = getApiPostBySlug(slug)
    .then(async (response) => {
      const resultItem = {
        _id: response[0].id,
        slug: response[0].slug,
        image: response[0]._embedded["wp:featuredmedia"][0].source_url,
        author: response[0]._embedded.author[0].name,
        date: response[0].date,
        title: response[0].title.rendered,
        readTime: utils.readingTime(response[0].content.rendered),
        data: response[0].content.rendered,
        excerpt: response[0].excerpt.rendered
      }
      setDetail(resultItem);
    })
    .catch((error) => {
      console.log(error.message);
      setDetail({});
    });
  }

  const handleShare = async () => {
    const protocol = window.location.protocol;
    const domain = window.location.hostname;
    const port = window.location.port;
    const base_url = `${protocol}//${domain}${port? ":"+port : ""}/`

    if(navigator.share){
      const shareData = {
        title: detail.title,
        text: detail.excerpt,
        url: `${base_url}blog/${detail.slug}`,
      };
      try{
        await navigator.share(shareData);
      }catch(err){
        console.log(err.stack)
      }
    }else{
      copy(`${base_url}blog/${detail.slug}`);
      setShareResult(true);
      setTimeout(() => { setShareResult(false) }, 3000)
      //alert('Se ha copiado la url al portapapeles.');
    }
  }
  const renderNews = () => {
    //console.log(results)
    return results.slice(0, 3).map(item => {
      return (<BlogItem key={item._id} {...item} />)
    })
  }

  return (
    <Container>
      <section className="blog-detail">
        <div className="blog-detail__info">
          <h1 className="blog-detail__title">{detail.title}</h1>
          <p className="blog-detail__author">Por {detail.author}
            <span className="blog-detail__date"> | {dayjs(detail.date).format('DD MMM YYYY')}</span>
          </p>
        </div>
        <div className="blog-detail__image">
          <img src={detail.image} alt={detail.title}/>
        </div>
        <div className="blog-detail__data">
          <span className="blog-detail__readTime">{detail.readTime} min de lectura</span>
          <div className="blog-detail__data-content" dangerouslySetInnerHTML={{__html: detail.data}}></div>
        </div>
        <p className="blog-detail__share">¿Te gustó la noticia?&nbsp;
          <span className="link" onClick={handleShare}>Compártela <Icon name="share" color="secondary-3" className="ms-1" /></span>
        </p>
      </section>
      <section className="blog-detail__news">
        <h2 className="blog-detail__news-title">Más noticias</h2>
        <div className="blog-detail__list">
          <Slider ref={slider} {...settings}>
            {renderNews()}
          </Slider>
        </div>
        <div className="blog-list__load">
          { isPublic ?
            <Button className={'btn-white big'} text="Ver todas" iconRight="arrowDiagonal" onClick={()=>{navigate("/noticias")}} />
          :
            <Button className={'btn-white big'} text="Ver todas" iconRight="arrowDiagonal" onClick={()=>{navigate("/blog")}} />
          }
        </div>
      </section>

      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={shareResult && shareResult === true}
      >
        <Modal.Body>
          <div>
            <h4 className="share-result">
              URL copiada en el portapapeles
            </h4>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  )
}

export default BlogDetail;
