import "./BookDetail.scss";
import BookDetailComponent from "../../components/BookCatalog/BookDetailComponent/BookDetailComponent";

function BookDetail(props) {
  return (
    <section className="book-detail">
      <BookDetailComponent hide={props.hide} />
    </section>
  );
}

export default BookDetail;
