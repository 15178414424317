import "./AccordionFaqs.scss";
import Button from "../DesingSystem/Button/Button";
import Faq from "../aPublicArea/FaqsComponents/Faq/Faq";
import { useNavigate } from "react-router-dom";

const AccordionFaqs = (props) => {
  const faqs = props.faqs;
  const navigate = useNavigate();

  return (
    <div className="accordion-faqs">
      <div className="accordion-faqs__header">
        <h2 className="accordion-faqs__title">Algunas preguntas frecuentes</h2>
        <Button className={'btn-white'} text={'Ir a FAQs'} onClick={() => navigate('/preguntas-generales')} />
      </div>
      <div className="accordion-faqs__body">
          {faqs.map((faq, index) => (
            <Faq
              key={index}
              question={faq.question}
              answer={
                <div className="faqs-answer" dangerouslySetInnerHTML={{ __html: faq.answer }}>
                </div>
              }
            />
          ))}
      </div>
      <div className="accordion-faqs__footer">
        <Button
          className={'btn-blackbg'}
          text={'Ir a FAQs'}
          onClick={() => navigate('/preguntas-generales')}
          iconRight={'arrowRight'}
        />
      </div>

    </div>
  )
}

export default AccordionFaqs;
