
export const getApiPosts = () => {
  return fetch(`https://blog.bookolicos.com/wp-json/wp/v2/posts/?_embed&v=${new Date()}`)
    .then((response) => response.json())
};

export const getApiPostBySlug = (slug) => {
  return fetch(`https://blog.bookolicos.com/wp-json/wp/v2/posts?_embed&slug=${slug}&v=${new Date()}`)
    .then((response) => response.json())
};
