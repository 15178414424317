import "./TerminosCondiciones.scss";

function TerminosCondiciones() {
  return (
    <section className="terminos-condiciones">
      
      <p>
        <strong>TÉRMINOS Y CONDICIONES DE USO</strong>
      </p>
      <p style={{ fontWeight: 400 }}>
        La página web{" "}
        <a href="https://www.bookolicos.com/">https://www.bookolicos.com</a> está
        dirigida a todos los públicos, siendo el acceso a este sitio web libre y
        gratuito para todos los usuarios.
      </p>
      <p style={{ fontWeight: 400 }}>&nbsp;</p>
      <p style={{ fontWeight: 400 }}>1. Consideraciones generales</p>
      <p style={{ fontWeight: 400 }}>
        El acceso y utilización de este sitio web atribuye al visitante la condición
        de usuario. Ello supone que el usuario acepta las condiciones de uso
        vigentes en cada momento y se compromete a hacer un uso adecuado de los
        contenidos del sitio web, observar y cumplir rigurosamente con lo dispuesto
        en este texto de TÉRMINOS Y CONDICIONES DE USO de la página web y con las
        normas legales que sean de aplicación.
      </p>
      <p style={{ fontWeight: 400 }}>
        Por medio del presente aviso N&eacute;stor Mart&iacute;nez Roda en adelante, “EL
        TITULAR” de esta página web informa al visitante acerca de los términos y
        condiciones de uso de esta. Por ello mismo, cada persona que vista esta
        página web viene obligada a la lectura de este texto legal y a todos los
        demás textos legales que están puestos a disposición del usuario en la
        página web.
      </p>
      <p style={{ fontWeight: 400 }}>
        De esta manera, EL TITULAR de esta página web presume que todo aquel usuario
        que ha accedido a la página web ha realizado una atenta lectura de todos y
        cada uno de los textos legales y acepta expresamente, de forma informada,
        sin reserva alguna, tanto estos términos y condiciones de uso y acceso a la
        página web, como los demás textos legales.
      </p>
      <p style={{ fontWeight: 400 }}>
        En esta página web se admite registro de usuarios y, pro ende, el
        usuario/visitante de esta página web se compromete mediante el presente
        texto legal y con la mera navegación en la página web a:
      </p>
      <ul>
        <li>
          Suministrar información veraz sobre los datos de registro y a mantenerlos
          actualizados.
        </li>
        <li>
          No difundir contenidos contrarios a la ley o a la dignidad de la persona:
          que sean discriminatorios por razón de nacimiento, raza, sexo, religión,
          opinión o cualquier otra condición o circunstancia personal o social; o
          que, de cualquier modo, sean contrarios a la ley y al orden público.
        </li>
        <li>
          Utilizar esta página web en conformidad con los presentes términos y
          condiciones de uso, respetando la ley, el orden público y la moral.
        </li>
        <li>
          Cualesquiera otros que, a juicio de EL TITULAR sean inadecuados para su
          publicación.
        </li>
      </ul>
      <p style={{ fontWeight: 400 }}>
        EL TITULAR se reserva el derecho a efectuar las modificaciones que estime
        convenientes, añadiendo, cambiando o eliminando contenidos o servicios
        prestados a través del sitio web; así como el de retirar cualquier
        aportación o comentario que contravenga las reglas descritas anteriormente.
      </p>
      <p style={{ fontWeight: 400 }}>
        EL TITULAR no se hará responsable en ningún caso de las opiniones vertidas
        por el usuario a través de las herramientas de participación ofrecidas por
        el sitio web.&nbsp;
      </p>
      <p style={{ fontWeight: 400 }}>&nbsp;</p>
      <p style={{ fontWeight: 400 }}>2. Conductas prohibidas</p>
      <p style={{ fontWeight: 400 }}>
        Se prohíbe a los usuarios, a modo enunciativos y no limitativo, realizar las
        siguientes conductas en la página web:
      </p>
      <ul>
        <li>
          Usar el email proporcionado a efectos de contacto para realizar
          actividades de envío masivo de correo no solicitado.
        </li>
        <li>
          Enviar comunicaciones de texto e imágenes que puedan vulnerar las normas
          legales vigentes.
        </li>
        <li>Comunicación de contenido que sea engañoso o falso.</li>
        <li>
          Envío de información que puedan suponer una infracción del derecho de la
          propiedad intelectual o industrial de un tercero.
        </li>
        <li>
          Comunicación de información o contenido ilegal que pueda ser denigratorio
          por razón de sexo, raza, color, origen, etc. Información que pueda suponer
          la comisión de un delito de calumnias e injurias. Contenidos digitales
          pornográficos o que atenten a la privacidad e intimidad de cualquier
          persona y, en todo caso, contenidos que puedan ser constitutivos de
          cualquier tipo de delito.
        </li>
        <li>
          Comunicación y suministro de información o contenidos que contengan
          programas, códigos o algoritmos informáticos nocivos, maliciosos o
          peligrosos, a modo de ejemplo, virus informáticos y todo tipo de archivos
          o programas de código informático con la capacidad de interrumpir,
          destruir, comprometer o limitar la funcionalidad de dispositivos,
          hardware, software o redes de telecomunicaciones;
        </li>
      </ul>
      <p style={{ fontWeight: 400 }}>&nbsp;</p>
      <p style={{ fontWeight: 400 }}>&nbsp;</p>
      <p style={{ fontWeight: 400 }}>&nbsp;</p>


    </section>
  );
}

export default TerminosCondiciones;