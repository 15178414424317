import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBook } from "../../../redux/actions/book";
import EllipsisIcon from "../../../styles/images/ellipsis.svg";
import ModalBookDetail from "../../BookCatalog/ModalBookDetail/ModalBookDetail";
import ModalTags from "../../BookCatalog/ModalGenres/ModalGenres";
import GenreItem from "../../GenreItem/GenreItem";
import Icon from "../../Icon/Icon";
import "./BookNewsItem.scss";

function BookNewsItem(props) {
  const dispatch = useDispatch();
  const [modalTagsShow, setModalTagsShow] = useState(false);
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [loadedBook, setLoadedBook] = useState(false);
  const book = useSelector((store) => store.book.book);
  const maxCaracteres = 150;

  const renderGenres = () => {
    return props.book.categories.map((genre, index) => {
      if (index < 1) {
        return (
          <GenreItem
            key={`${props.book.title}-${genre._id}-${index}`}
            genre={genre}
            version="v3"
          />
        );
      }
      return null;
    });
  };



  const showModalDetail = (e) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    dispatch(getBook(props.book._id));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  return (
    <li className="book-news-item" onClick={showModalDetail}>
      <div className="book-news-item__info">
        <div className="book-news-item__info-basic-info">
          <h2 className="book-news-item__info-title">{props.book.title}</h2>
          <h3 className="book-news-item__info-author">
            {props.book.user.firstName + " " + props.book.user.lastName}
          </h3>
          <p className="book-news-item__info-sinopsis">
            {props.book.summary.length > maxCaracteres
              ? props.book.summary.substring(0, maxCaracteres) + "..."
              : props.book.summary}
          </p>
        </div>
        <div className="book-news-item__info-genres">
          <div className="book-news-item__info-genres-container">
            {renderGenres()}
            {props.book.categories.length > 1 ? (
              <button
                className="book-news-item__info-genres-button"
                onClick={showModalTags}
              >
                <img src={EllipsisIcon} alt="More genres" />
              </button>
            ) : null}
            <ModalTags
              title={props.book.title}
              genres={props.book.categories}
              show={modalTagsShow}
              onHide={hideModalTags}
            />
          </div>
          <div className="book-news-item__info-genres-price">
            {" "}
            {props.book.price.toString().replace('.', ',')} €
          </div>
        </div>
      </div>

      <div className="book-news-item__cover">
        <div className="book-news-item__cover-download">
          <div className="book-news-item__cover-download-container">
            <div>
              <Icon name="reviewLike" size="small" color="secondary-3" />
              <span className="book-news-item__cover-download-text">
                {props.book.likes ? props.book.likes : 0}
              </span>
            </div>
            
            <div>
              <Icon name="reviewNeutral" size="small" color="secondary-3" />
              <span className="book-news-item__cover-download-text">
                {props.book.neutral ? props.book.neutral : 0}
              </span>
            </div>
            
            <div>
              <Icon name="reviewDislike" size="small" color="secondary-3" />
              <span className="book-news-item__cover-download-text">
                {props.book.dislikes ? props.book.dislikes : 0}
              </span>
            </div>
            |
            <div>
              <Icon name="download" size="small" color="secondary-3" />
              <span className="book-news-item__cover-download-text">
                {props.book.sales ? props.book.sales : 0}
              </span>
            </div>
          </div>
        </div>
        <img
          className="book-news-item__cover-image"
          src={props.book.picture_medium}
          alt="img"
        />
      </div>

      <div className="book-news-item__arrow">
        <Icon name="arrowDiagonal" size="large" color="secondary-6" />
      </div>

      {book ? (
        <ModalBookDetail
          book={book}
          show={modalDetailShow}
          hide={hideModalDetail}
        />
      ) : null}
    </li>
  );
}

export default BookNewsItem;
