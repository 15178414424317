import React, { useState, useEffect } from "react";
import "./News.scss";
import * as dayjs from 'dayjs';
import 'dayjs/locale/es';
import Slider from "react-slick";
import BlogItem from "../../../containers/blog/blog-item";
import Button from "../../DesingSystem/Button/Button";
import { useNavigate, useParams } from "react-router-dom";
import { getApiPosts, getApiPostBySlug } from "../../../services/apiBlog";
import utils from "../../../services/utils"

function News() {
  const navigate = useNavigate();
  const urlParams = useParams();

  const [results, setResults] = useState([]);
  const [detail, setDetail] = useState([]);

  const slider = React.useRef();
  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 3,
    variableWidth: true,
    className: "blog-detail__slider-item",
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const slug = urlParams.slug
    getPost(slug)
    getPosts()
  }, [urlParams]);

  const getPosts = async() => {
    const results = getApiPosts()
    .then(async (response) => {
      let resultsArray = [];

      await Promise.all(response.map(result => {
        resultsArray.push({
          _id: result.id,
          slug: result.slug,
          image: result._embedded["wp:featuredmedia"][0].source_url,
          author: result._embedded.author[0].name,
          date: result.date,
          title: result.title.rendered,
          readTime: utils.readingTime(response[0].content.rendered)
        })
      }))
      setResults(resultsArray);
    })
    .catch((error) => {
      console.log(error.message);
      setResults([]);
    });
  }

  const getPost = async(slug) => {
    const results = getApiPostBySlug(slug)
    .then(async (response) => {
      const resultItem = {
        _id: response[0].id,
        slug: response[0].slug,
        image: response[0]._embedded["wp:featuredmedia"][0].source_url,
        author: response[0]._embedded.author[0].name,
        date: response[0].date,
        title: response[0].title.rendered,
        readTime: utils.readingTime(response[0].content.rendered),
        data: response[0].content.rendered,
        excerpt: response[0].excerpt.rendered
      }
      setDetail(resultItem);
    })
    .catch((error) => {
      console.log(error.message);
      setDetail({});
    });
  }

  const handleShare = async () => {
    if(navigator.share){
      const shareData = {
        title: detail.title,
        text: detail.excerpt,
        url: `https://bookolicos.com/blog/${detail.slug}`,
      };
      try{
        await navigator.share(shareData);
      }catch(err){
        console.log(err.stack)
      }
    }else{
      alert('Tu navegador no soporta esta funcionalidad');
    }
  }

  const renderNews = () => {
    return results.slice(0, 3).map(item => {
      return (<BlogItem key={item._id} {...item} />)
    })
  }

  return (
    <section className="blog-detail__news">
      <h2 className="blog-detail__news-title">Noticias</h2>
      <div className="blog-detail__list">
        <Slider ref={slider} {...settings}>
          {renderNews()}
        </Slider>
      </div>
      <div className="blog-list__load mb-5">
        <Button className={'btn-white big'} text="Ver todas" iconRight="arrowDiagonal" onClick={()=>{navigate("/blog")}} />
      </div>
    </section>
  )
}
export default News;
