import { useState } from "react";
import "./Welcome.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ls from "../../../services/localStorage";
import { doLogin } from "../../../redux/actions/user";
import Button from "../../DesingSystem/Button/Button";
import InputText from "../../DesingSystem/InputText/InputText";
import ModalRecoverPassword from "../../ProfileComponents/ModalRecoverPassword/ModalRecoverPassword";

function Welcome() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [modalRecoverPassword, setModalRecoverPassword] = useState(false);
  const isLogged = useSelector((state) => {
    return state.user.isLogged;
  });
  const errorMessage = useSelector((state) => {
    return state.user.errorMessage;
  });

  const onSubmit = async (event) => {
    event.preventDefault();
    const email = ls.get("APP_STATE_EMAIL");
    const attributes = {
      auth_credentials: {
        email: email,
        password: password,
      },
    };

    dispatch(doLogin(attributes)).then(() =>{
      if (isLogged) {
        navigate("/");
      }
    });
  };

  const handleInputFocus = () => {
    setIsEmailEntered(true);
  };

  const handleInputBlur = () => {
    setIsEmailEntered(false);
  };

  const hideModalRecoverPassword = (e) => {
    e && e.stopPropagation();
    setModalRecoverPassword(false);
  };

  const showModalRecoverPassword = (e) => {
    e && e.stopPropagation();
    setModalRecoverPassword(true);
  };

  return (
    <section className="welcome">
      <h1 className="login-container__title">¡Bienvenido otra vez!</h1>
      <div className="container">
        <div className="row">
          <div className="col-12 px-3 px-md-0 col-md-6 offset-md-3">
            <form className="login__form" onSubmit={onSubmit}>
              <InputText
                error={isEmailEntered}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
                required
                type="password"
                placeholder="Introduce tu contraseña"
                name="password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              {errorMessage && errorMessage !== "" ? (
                <p className="m-0 mb-3 error text-center">{errorMessage}</p>
              ) : null}
              <div className="welcome__container-btn">
                <Button
                  className={password ? "btn-orange w-100" : "btn-graybg w-100"}
                  text={"Ingresar"}
                  type={"submit"}
                />
              </div>
              <div className="welcome__container-btn">
                <button
                  className="welcome__btn-return"
                  onClick={() => {
                    window.location.reload();
                  }}
                >
                  Volver
                </button>
              </div>

              <p className="account__containers-forget-password text-center mb-5" onClick={showModalRecoverPassword}>
                ¿Olvidaste tu contraseña?
              </p>
            </form>
          </div>
        </div>

        <ModalRecoverPassword
          show={modalRecoverPassword}
          onHide={hideModalRecoverPassword} />

      </div>
    </section>
  );
}

export default Welcome;
