import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getBook } from "../../../redux/actions/book";
import EllipsisIcon from "../../../styles/images/ellipsis.svg";
import GenreItem from "../../GenreItem/GenreItem";
import Icon from "../../Icon/Icon";
import ModalBookDetail from "../ModalBookDetail/ModalBookDetail";
import ModalTags from "../ModalGenres/ModalGenres";
import "./BookItem.scss";

function BookItem(props) {
  const dispatch = useDispatch();
  const [modalTagsShow, setModalTagsShow] = useState(false);
  const [modalDetailShow, setModalDetailShow] = useState(false);
  const [loadedBook, setLoadedBook] = useState(false);
  const book = useSelector((store) => store.book.book);
  const [maxTags, setMaxTags] = useState(1);

  const renderGenres = () => {
    return props.book.categories.map((genre, index) => {
      if (index <= 0) {
        return (
          <GenreItem
            size={props.genreSize}
            key={`${props.book.title}-${genre._id}`}
            genre={genre}
            version={props.version || "v2"}
          />
        );
      }
      return null;
    });
  };

  useEffect(() => {
    setMaxTags(window.innerWidth < 768 ? 1 : 2);
  });

  const showModalDetail = (e) => {
    e && e.stopPropagation();
    setLoadedBook(false);
    dispatch(getBook(props.book._id));
    setLoadedBook(true);
    setModalDetailShow(true);
  };

  const hideModalDetail = (e) => {
    e && e.stopPropagation();
    setModalDetailShow(false);
  };

  const showModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(true);
  };

  const hideModalTags = (e) => {
    e && e.stopPropagation();
    setModalTagsShow(false);
  };

  return (
    <li className="book" onClick={showModalDetail}>
      <div className="book__section-header mb-4">
        <div className="book__section-download">
          <Icon name="download" size="small" color="secondary-3" />
          <span className="book__section-download-text">{props.book.sales ? props.book.sales : 0}</span>
        </div>
        <div className="book__section-likes">
          <Icon name="reviewLike" size="small" color="secondary-3" />
          <span className="book__section-download-text">{props.book.likes ? props.book.likes : 0}</span>
          <Icon name="reviewNeutral" size="small" color="secondary-3" />
          <span className="book__section-download-text">{props.book.neutral ? props.book.neutral : 0}</span>
          <Icon name="reviewDislike" size="small" color="secondary-3" />
          <span className="book__section-download-text">{props.book.dislikes ? props.book.dislikes : 0}</span>
        </div>
      </div>
      <div className="book__section-image">
        <div className="book__section-cover">
          <img
            className="book__section-image-img"
            src={props.book.picture_medium}
            alt="img"
          />
        </div>
        <h2 className="book__section-image-title">{props.book.title}</h2>
        <h3 className="book__section-image-author">
          Por {props.book.user.firstName} {props.book.user.lastName}
        </h3>
      </div>
      <div className="book__section-genres">
        <div className="book__section-genres-container">
          {renderGenres()}
          {props.book.categories.length > maxTags ? (
            <button
              className={`book__section-genres-button ${props.version && `book__section-genres-button--${props.version}`}`}
              onClick={showModalTags}
            >
              <img src={EllipsisIcon} alt="More genres" />
            </button>

          ) : null}
          <ModalTags
            title={props.book.title}
            genres={props.book.categories}
            show={modalTagsShow}
            onHide={hideModalTags}
          />
        </div>
        <div className="book__section-genres-price"> {props.book.price.toString().replace('.', ',')} €</div>
      </div>
      <div className="book__arrow">
        <Icon name="arrowDiagonal" size="large" color="secondary-6" />
      </div>

      {book ? (
        <ModalBookDetail
          book={book}
          show={modalDetailShow}
          hide={hideModalDetail}
          index={props.index}
        />
      ) : null}
    </li>
  );
}

export default BookItem;
