import "./Faq.scss";
import React, { useState } from "react";

import Icon from "../../../../components/Icon/Icon";

function Faq({ question, answer, ...props }) {
  const [open, setOpen] = useState(false);

  const toggleFaq = () => {
    setOpen(!open);
  };

  return (
    <div className="faq-wrapper">
      <div className="faq-question" onClick={ () => { toggleFaq() } }>
        <h2>{ question }</h2>
        <Icon name={open ? "chevronUp"  : "chevronDown"} color='secondary' size="small" />
      </div>

      <div className={`faq-answer ${ open ? 'open' : 'closed' }`}>
        { answer }
      </div>
    </div>
  );
}

export default Faq;