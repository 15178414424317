import "./NameSection.scss";

function NameSection(props) {
  return (
    <section>
      <div className="name-section__container" style={{ backgroundImage : 'url(https://bookolicos-files.s3.eu-west-1.amazonaws.com/' + props.category + '.png)', backgroundColor: '#323232' }}>
        <h1 className="name-section__container-title">{ props.name }</h1>
      </div>
    </section>
  );
}
export default NameSection;
