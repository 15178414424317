import "./Genres.scss";

import GenreItem from "../GenreItem/GenreItem";

function Genres(props) {
  const genresClass = `genres--${props.align} ${props.className ? props.className : ''}`;

  const isSelected = (genre) => {
    if (props.genresSelected && props.genresSelected.find((g) => g.tag === genre.tag)) {
      return true;
    } else {
      return false;
    }
  }

  const renderGenres = () => {
    return props.genres.map((genre) => {
      return (
        <GenreItem
          key={genre._id}
          genre={genre}
          isSelected={isSelected(genre)}
          onClick={props.onClick}
          size={props.size}
          isNavegable={props.isNavegable} />
      );
    });
  };
  return (
    <div className={`genres ${genresClass}`}>{renderGenres()}</div>
  );
}

export default Genres;
