import React from "react";
import { useState, useEffect } from "react";
import InputText from "../../DesingSystem/InputText/InputText";

import "./BookInfo.scss";

function BookInfo(props) {
  useEffect(() => {
    props.setBook({
      ...props.book,
    });
  }, []);
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [isSubtitleEntered, setIsSubtitleEntered] = useState(false);
  const [availableText, setAvailableText] = useState(1000);

  const onChangeTitle = (event) => {
    props.setBook({
      ...props.book,
      title: event.target.value,
    });
  };
  const onChangeSubTitle = (event) => {
    props.setBook({
      ...props.book,
      subtitle: event.target.value,
    });
  };
  const onChangeSinopsis = (event) => {
    setAvailableText(1000 - event.target.value.length);
    props.setBook({
      ...props.book,
      sinopsis: event.target.value,
    });
  };
  const handleInputNameFocus = () => {
    setIsNameEntered(true);
  };

  const handleInputNameBlur = () => {
    setIsNameEntered(false);
  };
  const handleInputSubtitleFocus = () => {
    setIsSubtitleEntered(true);
  };

  const handleInputSubtitleBlur = () => {
    setIsSubtitleEntered(false);
  };


  return (
    <section className="book-info">
      <div className="book-info__form">
        <label className="book-info__form-label" htmlFor="title">
          Nombre de la Obra
        </label>
        <InputText
          error={isNameEntered}
          onFocus={handleInputNameFocus}
          onBlur={handleInputNameBlur}
          type="text"
          id="title"
          name="title"
          placeholder="Introduce el nombre de tu obra"
          required
          value={props.book && props.book.title ? props.book.title : ""}
          onChange={onChangeTitle}
        />
      </div>
      <div className="book-info__form">
        <label className="book-info__form-label mt-3" htmlFor="subtitle">
          Subtitulo de la Obra
        </label>
        <InputText
          error={isSubtitleEntered}
          onFocus={handleInputSubtitleFocus}
          onBlur={handleInputSubtitleBlur}
          type="text"
          id="subtitle"
          name="subtitle"
          placeholder="Introduce el subtitulo de tu obra"
          required
          value={props.book && props.book.subtitle ? props.book.subtitle : ""}
          onChange={onChangeSubTitle}
        />
      </div>
      <div className="book-info__form">
        <label className="book-info__form-label mt-3" htmlFor="name">
          Sinopsis
        </label>
        <textarea
          className="book-info__form-textarea"
          type="text"
          id="sinopsis"
          name="sinopsis"
          placeholder="Escribe aquí el resumen de tu obra."
          maxLength={1000}
          required
          value={props.book && props.book.sinopsis ? props.book.sinopsis : ""}
          onChange={onChangeSinopsis}
        />
        <p className="book-info__form-textarea-info">{availableText} caracteres disponibles</p>
      </div>
    </section>
  );
}

export default BookInfo;
