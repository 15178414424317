import "./PartOfSubtitleWhy.scss";

function PartOfSubtitleWhy() {
  return (
    <section className="part-subtitle-why">
      <div className="part-subtitle-why__container-title">
        <p className="part-subtitle-why__title">
          Descubre y publica obras en un solo lugar{" "}
        </p>
      </div>

      <div className="part-subtitle-why__container-subtitle">
        <p className="part-subtitle-why__text">
        Buscamos trascender como una plataforma convencional para la publicación de libros digitales. Nos esforzamos por convertirnos en el espacio que tanto lectores como autores necesitan.
        </p>
      </div>
    </section>
  );
}

export default PartOfSubtitleWhy;
