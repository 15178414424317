import Landing from "../landing/Landing";
import { useLocation } from "react-router-dom";

const ReaderZero = () => {
  const location = useLocation();

  const content = {
    description: "Si quieres estar seguro de que los personajes de tu obra enganchan, la trama es coherente y creíble, la estructura es sólida y, en definitiva, que tu manuscrito está listo para comenzar las correcciones editoriales, necesitas que lo revise un lector cero profesional. Cuando escribimos estamos tan sumergidos en nuestra historia que no somos objetivos, solo repasamos lo que ya hay en nuestra mente. La visión de un profesional sobre tu obra te ayudará a detectar fallos que no habías visto e impulsará aquellos aspectos que merezca la pena resaltar más.",
    summary: "Te facilitaremos un informe de lectura exhaustivo que incluirá los puntos fuertes de tu obra y las mejoras que deberían implementarse página por página.",
    formTitle: "Contrata un lector cero",
    formDescription: "Todos los servicios editoriales de Bookólicos se realizarán bajo presupuesto, previo contacto del autor con nosotros (vía email) para darnos detalles de su obra y sus necesidades."
  };

  let services = [];

  location.pathname.indexOf('servicio') !== -1 ?
    services = [{
      name: "Coaching",
      link: "/servicio-coaching"
    }, {
      name: "Corrección ortotipográfica",
      link: "/servicio-correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/servicio-correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/servicio-maquetacion-digital"
    }]
  :
    services = [{
      name: "Coaching",
      link: "/coaching"
    }, {
      name: "Corrección ortotipográfica",
      link: "/correccion-ortotipografica"
    }, {
      name: "Corrección de estilo",
      link: "/correccion-estilo"
    }, {
      name: "Maquetación digital",
      link: "/maquetacion-digital"
    }]
  ;

  return (
    <Landing
      service="Lector cero"
      content={content}
      services={services}
    />
  );
};

export default ReaderZero;
