import "./Tabs.scss"

const Tabs = ({ content }) => {
  const handleTabClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const tabId = e.target.getAttribute('href');
    const tab = document.querySelector(tabId);
    const tabContent = document.querySelectorAll('.tabs__content-item');
    const tabLinks = document.querySelectorAll('.tabs__list-item-link');

    tabContent.forEach(item => {
      item.classList.remove('active');
    });

    tabLinks.forEach(item => {
      item.classList.remove('active');
    });

    tab.classList.add('active');
    e.target.classList.add('active');
  }

  return (
    <div className="tabs">
      <ul className="tabs__list">
        {content.map((item, index) => {
          return (
            <li key={index} className="tabs__list-item">
              <a href={`#tab-${index}`} className={`tabs__list-item-link ${index === 0 ? 'active' : ''}`} onClick={handleTabClick}>{item.title}</a>
            </li>
          )
        }
        )}
      </ul>
      <div className="tabs__content">
        {content.map((item, index) => {
          return (
            <div key={index} id={`tab-${index}`} className={`tabs__content-item ${index === 0 ? 'active' : ''}`} dangerouslySetInnerHTML={{ __html: item.htmlData }}></div>
          )
        }
        )}
      </div>
    </div>
  )
}

export default Tabs;
