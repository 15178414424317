import "./ModalTags.scss";
import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Genres from "../../Genres/Genres";

import { getApiGenres } from "../../../services/apiGenres";

// MOCK
import genresMock from '../../../mocks/genres.json';

function ModalTags(props) {
  const [step, setStep] = useState(1);
  const [genre, setGenre] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allGenres, setAllGenres] = useState([]);

  useEffect(() => {
    //setAllGenres(genresMock);
    getApiGenres({}).then((response) => {
      setAllGenres(response);
    });
  }, []);

  const tagClick = (tagClicked) => {
    if (tags.find((g) => g.tag === tagClicked.tag)) {
      setTags(tags.filter((g) => g.tag !== tagClicked.tag));
    } else if (tags.length < 10) {
      setTags([...tags, tagClicked]);
    }
  };

  const categoryClick = (categoryClicked) => {
    if (categories.find((g) => g.tag === categoryClicked.tag)) {
      setCategories(categories.filter((g) => g.tag !== categoryClicked.tag));
    } else if (categories.length < 5) {
      setCategories([...categories, categoryClicked]);
    }

  };

  const genreClick = (genreClicked) => {
    setGenre(genreClicked);
    setAllCategories(genreClicked.categories)
    setCategories([])
    setStep(2);
  };

  const goToStep1 = () => {
    setStep(1);
  };

  const goToStep2 = () => {
    setStep(2);
  };

  const goToStep3 = () => {
    setStep(3);
  };

  const goToFinish = () => {
    props.setBook({
      ...props.book,
      genre: genre,
      tags: categories,
      categories: categories
    });
    props.onHide();
    setStep(1);
  };

  const renderModal = () => {
    if (step === 1) {
      return (
        <div className="tags__container-tag">
          <div className="tags__title-content">
            <h2 className="tags__title">Elige el género literario que va con tu obra</h2>
          </div>
          { allGenres && allGenres.map((genreObj, key) => (
            <React.Fragment key={key}>
            <button onClick={() => genreClick(genreObj)} className={ `tags__container-tag-tag ${genre && genre._id === genreObj._id ? 'tags__container-tag-tag--selected' : ''}` }>
              { genreObj.tag }
            </button>
            </React.Fragment>
          ))}
        </div>
      );
    } else if (step === 2) {
      return (
        <div className="tags__container-tag">
          <div className="tags__title-content">
            <h2 className="tags__title">
              Ayuda a definir mejor el género elegido
            </h2>
            <p className="tags__text">Selecciona un máximo de 5 opciones</p>

          </div>
          <div className="tags__genres-selector">
            { allCategories ?
              <Genres
                genres={allCategories}
                genresSelected={categories}
                align="center"
                onClick={categoryClick}
                isNavegable={false}
              />
            : null}
          </div>

          <div className="tags__container-button">
            <button className="tags__button" onClick={goToFinish}>
              Siguiente
            </button>
            <button className="tags__button-return" onClick={goToStep1}>
              Volver
            </button>
          </div>
        </div>
      );

    } else if (step === 3) {
      return (
        <div>
          <h2 className="tags__title">
            ¡Muy bien! ya casi esta. Ahora selecciona las categorías
          </h2>
          <p className="tags__text">Selecciona un máximo de 5 opciones</p>
          <Genres
            genres={allGenres}
            genresSelected={tags}
            align="center"
            onClick={tagClick}
            isNavegable={false}
          />
          <div className="tags__container-button">
            <button className="tags__button" onClick={goToFinish}>
              Finalizar
            </button>
            <button className="tags__button-return" onClick={goToStep2}>
              Volver
            </button>
          </div>
        </div>
      );
    }
  };

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        className="tags__modal"
        show={props.show}
        onHide={props.onHide}
        size="lg"
        backdrop="static"
        centered
      >
        <Modal.Body>
          {renderModal()}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalTags;
