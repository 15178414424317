import "./PartOfSubtitleWho.scss";

function PartOfSubtitleWho() {
  return (
    <section className="part-subtitle-who">
      <div className="part-subtitle-who__container-title">
        <p className="part-subtitle-who__title">
          En Bookólicos, somos tanto lectores como autores
        </p>
      </div>

      <div className="part-subtitle-who__container-subtitle">
        <p className="part-subtitle-who__text">
          Esta experiencia dual nos permite comprender a la perfección las
          necesidades de nuestros usuarios, y nuestra misión es satisfacerlas en
          un solo lugar.
        </p>
      </div>
    </section>
  );
}

export default PartOfSubtitleWho;
