import "./WritersPhrase.scss";

function WritersPhrase() {
  return (
    <section className="writers-phrase">
      <p className="writers-phrase__text">
        <i>El proyecto de Bookólicos me ha parecido una maravilla para conectar a todos los profesionales del mundo editorial y para llegar a los lectores de una forma más directa y personal. ¡Desde luego una gran iniciativa!
        <br />
        Nunca me han convencido las plataformas habituales de publicación de libros electrónicos y llevaba tiempo deseando que naciera una que cumpliera mis expectativas y que no formara parte de los gigantes que controlan los mercados. En Bookólicos he encontrado lo que buscaba.</i>
      </p>
      <div className="writers-phrase__container-writer">
      <p className="writers-phrase__name">Tali Rosu,</p>
      <p className="writers-phrase__book">autora de <i>Carne de Chino: una deliciosa distopía</i></p>
      </div>
    </section>
  );
}

export default WritersPhrase;
