import "./WhyBookolicos.scss";
import { useNavigate } from "react-router-dom";
import Button from "../../../DesingSystem/Button/Button";
import BannerPublic from "../../BannerPublic/BannerPublic";

function WhyBookolicos() {
  const navigate = useNavigate();

  const goToWhyBookolicos = () => {
    navigate("/por-que-bookolicos");
  };

  return (
    <section className="why-bookolicos">
      <div className="why-bookolicos__border"> </div>
      <p className="why-bookolicos__title">¿Por qué Bookólicos?</p>

      <div className="why-bookolicos__container-containers">
        <div className="why-bookolicos__container-impar">
          <div className="why-bookolicos__container-impar-section-image">
            <img
              className="why-bookolicos__container-impar-section-image-img-one"
              src="/images/1.png"
              alt="vision"
            />
          </div>
          <div className="why-bookolicos__container-impar-section-text">
            <p className="why-bookolicos__container-impar-title">
              Un espacio de libertad creativa
            </p>
            <div className="why-bookolicos__border"> </div>
            <p className="why-bookolicos__container-impar-text">
              Buscamos que nuestro catálogo de obras sea lo más extenso y
              diverso posible. En nuestra plataforma, encontrarás desde novelas
              hasta cómics, abarcando todos los géneros literarios. En
              Bookólicos nos asegurarnos de que haya un espacio para todos los
              gustos y preferencias sin ningún tipo de censura.
            </p>
            <div>
              <Button
                className="btn-white"
                text="Más información"
                iconRight="chevronRight"
                onClick={goToWhyBookolicos}
              />
            </div>
          </div>
        </div>

        <div className="why-bookolicos__container-par">
          <div className="why-bookolicos__container-par-section-text">
            <p className="why-bookolicos__container-par-title">
              Oportunidades para todo el mundo{" "}
            </p>
            <div className="why-bookolicos__border"> </div>
            <p className="why-bookolicos__container-par-text">
              Nos esforzamos por brindar oportunidades a los autores
              independientes ofreciéndoles una mayor visibilidad. Queremos
              apoyar el talento literario de aquellos escritores que buscan
              hacerse un nombre en la industria.
            </p>
            <div>
              <Button
                className="btn-white"
                text="Más información"
                iconRight="chevronRight"
                onClick={goToWhyBookolicos}
              />
            </div>
          </div>
          <div className="why-bookolicos__container-par-section-image">
            <img
              className="why-bookolicos__container-par-section-image-img-two"
              src="/images/2.png"
              alt="mission"
            />
          </div>
        </div>

        <div className="why-bookolicos__container-impar">
          <div className="why-bookolicos__container-impar-section-image">
            <img
              className="why-bookolicos__container-impar-section-image-img-one"
              src="/images/3.svg"
              alt="vision"
            />
          </div>
          <div className="why-bookolicos__container-impar-section-text">
            <p className="why-bookolicos__container-impar-title">
              Comunidad <br /> creativa{" "}
            </p>
            <div className="why-bookolicos__border"> </div>
            <p className="why-bookolicos__container-impar-text">
              En Bookólicos nos rodeamos de personas que creen en nuestro
              objetivo y comparten nuestra pasión. Trabajamos junto al talento
              para hacer realidad nuestra visión.
            </p>
            <div>
              <Button
                className="btn-white"
                text="Más información"
                iconRight="chevronRight"
                onClick={goToWhyBookolicos}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default WhyBookolicos;
