import "./MoreGenres.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getApiCategories } from "../../../../services/apiCategories";
import GenreItem from "../../../GenreItem/GenreItem";
import Genres from "../../../../components/Genres/Genres";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};
function MoreGenres() {
  const navigate = useNavigate();
  const [genres, setGenres] = useState([]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = () => {
    navigate("/libros-por-genero/:slug");
  };

  const renderGenresMobile = () => {
    return shuffleArray(genres).map((genre) => {
      if(genre.state){
        return (
          <GenreItem
            key={genre._id}
            genre={genre}
            onClick={tagClick}
            size="large"
          />
        );
      }else{
        return (
          <GenreItem
            key={genre._id}
            disabled={true}
            genre={genre}
            isNavegable={false}
            onClick={()=>{}}
            size="large"
          />
        );
      }
    });
  };

  const renderGenres = () => {
    return genres.map((genre) => {
      if(genre.state){
        return (
          <GenreItem
            key={genre._id}
            genre={genre}
            onClick={tagClick}
            size="large"
          />
        );
      }else{
        return (
          <GenreItem
            key={genre._id}
            disabled={true}
            genre={genre}
            isNavegable={false}
            onClick={()=>{}}
            size="large"
          />
        );
      }
    });
  };

  return (
    <section className="more-genres">
      <p className="more-genres__title mb-5">Explora más categorías</p>
      {/*<p className="more-genres__subtitle">
        La plataforma que ayuda a autores a conseguir su propio <br />
        estilo y ofrece historias de calidad a lectores.
      </p>*/}
      <div className="more-genres__genres">
        <div className={`genres genres--center`}>  
          {renderGenres()}
        </div>
      </div>
      <div className="more-genres__marquee">
        <div className="more-genres__genres-mobile">
          {renderGenresMobile()}
        </div>
        <div className="more-genres__genres-mobile">
          {renderGenresMobile()}
        </div>
        <div className="more-genres__genres-mobile">
          {renderGenresMobile()}
        </div>
      </div>
    </section>
  );
}

export default MoreGenres;
