import "./InfoAuthor.scss";
import utils from "../../../services/utils"

function InfoAuthor(props) {
  return (
    <section className="info-author">
      <p className="info-author__title">Acerca del autor</p>
      <p className="info-author__text">
        <span dangerouslySetInnerHTML={{ __html: (props.author.authorDescription ? props.author.authorDescription.replace(/\n/g, "<br />") : '') }} />
      </p>
      <div className="info-author__list-container">
        <ul className="info-author__list">
          { props.author.facebookUrl && utils.isURLValid(props.author.facebookUrl) ?
            <li>
              <a
                className="info-author__list-item"
                href={props.author.facebookUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f"></i>
              </a>
            </li>
          : null }

          { props.author.facebookUrl && utils.isURLValid(props.author.instagramUrl) ?
            <li>
              <a
                className="info-author__list-item"
                href={props.author.instagramUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-instagram"></i>
              </a>
            </li>
          : null }

          { props.author.facebookUrl && utils.isURLValid(props.author.twitterUrl) ?
            <li>
              <a
                className="info-author__list-item"
                href={props.author.twitterUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter"></i>
              </a>
            </li>
          : null }

          { props.author.facebookUrl && utils.isURLValid(props.author.linkedinUrl) ?
            <li>
              <a
                className="info-author__list-item"
                href={props.author.linkedinUrl}
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in"></i>
              </a>
            </li>
          : null }
        </ul>
      </div>
    </section>
  );
}

export default InfoAuthor;
