import "./WhyContainer.scss";
import { types } from "../../../constants/constants";
import PartOfSubtitleWhy from "../../../components/aPublicArea/WhyComponent/PartOfSubtitleWhy/PartOfSubtitleWhy";
import Reasons from "../../../components/aPublicArea/WhyComponent/Reasons/Reasons";
import Faq from "../../../components/aPublicArea/FaqsComponents/Faq/Faq";
import Button from "../../../components/DesingSystem/Button/Button";
import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";
import NameSection from "../../../components/NameSection/NameSection";
import React from "react";
import {navigate} from "@storybook/addon-links";
import { useNavigate } from "react-router-dom";

//import Genres from "../../components/Genres/Genres";

function WhyContainer() {
  const navigate = useNavigate();
  return (
    <section className="why-container">
      <NameSection name="¿Por qué Bookólicos?" color="#f05a24"/>
      <PartOfSubtitleWhy />
      <div className="why-container__reasons">
        <Reasons />
      </div>

      <section className="why-bookolicos-section">
        <div className="why-bookolicos-section__section-title">
          <div>
            <div className="why-bookolicos-section__border"> </div>
            <p className="why-bookolicos-section__title">
              Algunas preguntas frecuentes
            </p>
          </div>
          <div>
            <div className="why-bookolicos-section__button-faqs">
              <Button className={"btn-white ms-auto"} text={"Ir a FAQs"} onClick={() => navigate('/faqs')} />
            </div>
          </div>
        </div>
        <div className="faqs-items">
          <Faq
            question="¿Qué es Bookólicos?"
            answer={
              <div className="faqs-answer">
                Somos la primera plataforma/comunidad que conecta todos aquellos
                escritores y profesionales del sector literario con personas
                hambrientas de cultura y conocimiento.
                <br />
                Queremos que, tanto si eres autor como lector, monetices tu
                obra, descubras títulos y autores cada día, formando parte de
                una comunidad literaria honesta y preocupada por mejorar la
                calidad del sector.
              </div>
            }
          />

          <Faq
            question="¿Para quién es Bookólicos?"
            answer={
              <div className="faqs-answer">
                Bookólicos nace como la primera plataforma digital (web)
                hispanoparlante para...
                <br />
                <ul>
                  <li>
                    Personas que necesitan un aliado especialista en el canal
                    digital para dar recorrido a sus obras.
                  </li>
                  <li>
                    Personas que necesitan un aliado especialista en el canal
                    digital para captar talento y aumentar su volumen de
                    comercialización.
                  </li>
                  <li>
                    Personas que necesitan libros y referencias novedosas en
                    formato electrónico a un precio razonable.
                  </li>
                </ul>
              </div>
            }
          />

          <Faq
            question={"¿Qué hace Bookólicos diferente?"}
            answer={
              <div className="faqs-answer">
                <strong>Reducción de costes:</strong> Reducimos las comisiones
                que se aplican actualmente a los autores por la venta de sus
                obras.
                <br />
                <strong>Mitigación del riesgo:</strong> Introducimos
                herramientas para mejorar la confianza del lector de cara a la
                compra y aportamos información totalmente transparente.
              </div>
            }
          />

          <Faq
            question={"¿Qué márgenes me cobra Bookólicos?"}
            answer={
              <div className="faqs-answer">
                Los autores obtienen el { types.AUTHOR_FEE_PERCENTAGE }% sobre el precio de venta de cada
                libro digital. El restante { types.BOOKOLICOS_FEE_PERCENTAGE }%, va directamente a Bookólicos.
              </div>
            }
          />
        </div>
      </section>
      <BannerPublic />
    </section>
  );
}

export default WhyContainer;
