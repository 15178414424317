import "./MoreGenres.scss";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getApiCategories } from "../../../services/apiCategories";
import GenreItem from "../../GenreItem/GenreItem";
import Genres from "../../../components/Genres/Genres";

function MoreGenres() {
  const navigate = useNavigate();
  const [genres, setGenres] = useState([]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = () => {
    navigate("/libros-por-genero/:slug");
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const renderGenresMobile = () => {
    return shuffleArray(genres).map((genre) => {
      if(genre.state){
        return (
          <GenreItem
            key={genre._id}
            genre={genre}
            onClick={tagClick}
            size="large"
          />
        );
      }else{
        return (
          <GenreItem
            key={genre._id}
            disabled={true}
            genre={genre}
            isNavegable={false}
            onClick={()=>{}}
            size="large"
          />
        );
      }
    });
  };

  const renderGenres = () => {
    return genres.map((genre) => {
      if(genre.state){
        return (
          <GenreItem
            key={genre._id}
            genre={genre}
            onClick={tagClick}
            size="large"
          />
        );
      }else{
        return (
          <GenreItem
            key={genre._id}
            disabled={true}
            genre={genre}
            isNavegable={false}
            onClick={()=>{}}
            size="large"
          />
        );
      }
    });
  };

  return (
    <section className="more-genres-private">
      <div className="more-genres-private__genres">
        <div className={`genres genres--center`}>  
          {renderGenres()}
        </div>
      </div>
      <div className="more-genres-private__genres-mobile more-genres-private__genres-mobile-left">
        {renderGenresMobile()}
      </div>
      <div className="more-genres-private__genres-mobile more-genres-private__genres-mobile-right">
        {renderGenresMobile()}
      </div>
      <div className="more-genres-private__genres-mobile more-genres-private__genres-mobile-left">
        {renderGenresMobile()}
      </div>
    </section>
  );
}

export default MoreGenres;
