import "./ModalBookDetail.scss";
import { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import BookDetailComponent from "../BookDetailComponent/BookDetailComponent";

function ModalBookDetails(props) {
  useEffect(() => {
    document.querySelector('.modal')?.addEventListener('click', event => {

      const modalContainer = document.querySelector('.modal-dialog')
      if (typeof event.target.className === 'string' && !modalContainer.querySelectorAll(`.${event.target.className}`).length) {
        props.hide()
      }
    })
    return () => {
      document.querySelector('.modal')?.removeEventListener('click', event => {})
    }
  })

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.show}
        hide={props.hide}
        index={props.index}
        dialogClassName="modal-book-detail"
        fullscreen={true}
      >
        <Modal.Body>
          <BookDetailComponent book={props.book} index={props.index} hide={props.hide} />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ModalBookDetails;
