import "./Home.scss";

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getNewBooks, getFeaturedBooks } from "../../redux/actions/book";
import { getApiCategories } from "../../services/apiCategories";
import BookNews from "../../components/HomeComponents/BookNews/BookNews";
import BookForYou from "../../components/HomeComponents/BookForYou/BookForYou";
import GreetingsSection from "../../components/HomeComponents/GreetingsSection/GreetingsSection";
import MoreGenres from "../../components/HomeComponents/MoreGenres/MoreGenres"
import News from "../../components/HomeComponents/News/News";
import Genres from "../../components/Genres/Genres";

function Home() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const featured_books = useSelector((store) => store.book.featured_books);
  const new_books = useSelector((store) => store.book.new_books);
  const [genres, setGenres] = useState([]);

  useEffect(() => {
    dispatch(getFeaturedBooks());
    dispatch(getNewBooks());
  }, [dispatch]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = (genre) => {
    navigate("/book-list-genre");
  };

  return (
    <section className="home">
      <div>
        <GreetingsSection />
        <BookNews title="Últimas novedades" books={new_books}/>
        <BookForYou books={featured_books} />
        <MoreGenres />
        <News />
      </div>
    </section>
  );
}

export default Home;
