import "./ModalMessage.scss";
import PropTypes from 'prop-types';
import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button"

function ModalMessage({ okButton, okButtonText, okButtonType, cancelButton, cancelButtonText, cancelButtonType, isConfirmModal, ...props }) {
  return (
    <section className="modal-cancel-account">
      <div onClick={(e) => e && e.stopPropagation()}></div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop={ isConfirmModal ? 'static' : true }
      >
        <Modal.Body>
          <div className="modal-cancel-account p-4">
            
            { props.header && props.header !== '' ?
              <h3 className="modal-cancel-account__title">
                { props.header }
              </h3>
            : null }

            { props.text && props.text !== '' ?
              <p className="modal-cancel-account__text">
                { props.text }
              </p>
            : null }

            <div className="modal-cancel-account__container-button">
              { okButton ?
                <Button 
                className={`${okButtonType} mt-4`}
                text={ okButtonText ? okButtonText : 'Aceptar' }
                onClick={props.onAccept} />
              : null }

              { cancelButton ?
                <Button 
                className={`${cancelButtonType} mt-2`}
                text={ cancelButtonText ? cancelButtonText : 'Cancelar' }
                onClick={props.onCancel} />
              : null }
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

// Definimos las props 
ModalMessage.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  okButton: PropTypes.bool,
  cancelButton: PropTypes.bool,
  okButtonText: PropTypes.string,
  cancelButtonText: PropTypes.string,
  okButtonType: PropTypes.string,
  cancelButtonType: PropTypes.string,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
  isConfirmModal: PropTypes.bool
};

ModalMessage.defaultProps = {
  okButton: true,
  okButtonText: 'Aceptar',
  okButtonType: 'btn-orange',
  cancelButton: false,
  cancelButtonText: 'Cancelar',
  cancelButtonType: 'btn-gray',
  isConfirmModal: true
};

export default ModalMessage;
