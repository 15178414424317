import { types } from "../constants/constants";

export const postApiCategories = (attributes) => {
  return fetch(`${types.API_URL}category/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(attributes),
  }).then((response) => response.json());
};

export const getApiCategories = (attributes) => {
  return fetch(`${types.API_URL}categories`)
      .then((response) => response.json())
};

