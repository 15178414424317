import "./SinopsisBook.scss";

function SinopsisBook(props) {
  return (
    <div className="sinopsis-book">
      <h2 className="sinopsis-book__title">{props.book ? props.book.title : '--'}</h2>
      <p className="sinopsis-book__author">Por { props.book ? props.book.user.firstName + ' ' +props.book.user.lastName : '--' }</p>
      <p className="sinopsis-book__text">Sinopsis</p>
      <p className="sinopsis-book__sinopsis">{ props.book ? props.book.summary : '--' }</p>
    </div>
  );
}

export default SinopsisBook;
