import "./SectionImages.scss";

import Avatar from "../../ProfileComponents/Avatar/Avatar";

function SectionImages(props) {
  return (
    <section className="section-images">
      <div className="section-images__container-image">
        <div className="wrapper-cover">
          <div className="image-bg">
          <img
            className="section-images__container-image-bgimg"
            src={ props.author.coverPic ? props.author.coverPic : "/images/bg-profile.png"}
            alt="img"
          /></div>
        </div>
        { props.author.profilePic ? 
          <img
          className="section-images__container-image-img"
          src={props.author.profilePic} alt="img" />
        : 
              <Avatar
              className={'section-images__container-image-img'}
              user={{ firstName: props.author.firstName, lastName: props.author.lastName }}
              size={"l"}
              />
        }  
      </div>
      <div className="section-images__container-name">
        <p className="section-images__container-name-name">{ props.author.firstName } { props.author.lastName }
        </p>
        { Object.keys(props.author.books).length === 1 ?
          <p className="section-images__container-name-book"> {`${ props.author.books.length } obra publicada`}</p>
        :
          <p className="section-images__container-name-book"> {`${ props.author.books ? props.author.books.length : 0 } obras publicadas`}</p>
        }
      </div>
    </section>
  );
}

export default SectionImages;
