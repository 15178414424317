import "./ConfirmationEmail.scss";
import React, { useEffect } from "react"; 
import { useNavigate, useSearchParams } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";
import ls from "../../services/localStorage"

function ConfirmationEmailOk() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    const tokenParam = searchParams.get("token");
    ls.set("token", tokenParam);
    
  }, []);

  return (
    <section className="confirmation">
      <img
        className="confirmation__img"
        src="/images/silla-2.png"
        alt="not-found"
      />
      <div className="confirmation__section-text">
        <Icon name="faceTwo" size="large" color="principal" />
        <h1 className="confirmation__title">¡Todo listo!</h1>
        <p className="confirmation__text">
          Gracias por verificar tu correo electrónico. Ahora sigue disfrutando de Bookólicos.
        </p>
        <Button className={"btn-orange"} text={"Volver a la home"} onClick={() => { navigate('/') }}  />
      </div>
    </section>
  );
}

export default ConfirmationEmailOk;
