import ls from "../../services/localStorage";
import validateToken from "../../services/validateToken";
import {
  postApiLogin,
  postApiGoogleLogin,
  postApiFacebookLogin,
  postApiIsRegistered,
  postApiRegister,
  getApiGetUserById,
  putApiUpdateUser,
  putApiUpdateAuthor,
  putApiUpdateAccount,
  getApiGetUserByNickName,
  getApiUsersAuthor,
  getApiUsers
} from "../../services/apiUser";

export const isRegistered = (attributes) => {
  return async function (dispatch) {
    return postApiIsRegistered(attributes)
      .then((response) => {
        return dispatch({
          type: "IS_REGISTERED",
          payload: response.registered ? true : false,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const doLogin = (attributes) => {
  return async function (dispatch) {
    return postApiLogin(attributes)
      .then((response) => {
        const token = response.token;
        ls.set("token", token);
        const tokenDecoded = validateToken.decode(token);
        return dispatch({
          type: "IS_LOGGED",
          payload: tokenDecoded.user,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const doRegister = (attributes) => {
  return async function (dispatch) {
    return postApiRegister(attributes)
      .then((response) => {
        console.log(response)
        const token = response.token;
        ls.set("token", token);
        const tokenDecoded = validateToken.decode(token);
        return dispatch({
          type: "IS_LOGGED",
          payload: tokenDecoded.user,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const isLogged = () => {
  return async function (dispatch) {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if (
      tokenDecoded === undefined ||
      tokenDecoded === null ||
      tokenDecoded.user === null ||
      tokenDecoded.user === undefined
    ) {
      dispatch({
        type: "IS_LOGGED_ERROR",
        payload: "Este token no existe",
      });
    } else {
      if (Date.now() > tokenDecoded.exp * 1000) {
        dispatch({
          type: "IS_LOGGED_ERROR",
          payload: "La sesión ha expirado",
        });
      } else {
        dispatch({
          type: "IS_LOGGED",
          payload: tokenDecoded.user,
        });
      }
    }
  };
};

export const getUsers = (attributes) =>{
  return async function (dispatch) {
    return getApiUsers(attributes)
      .then((response) => {
        const users = response;
        return dispatch({
          type: "GET_USERS",
          payload: users,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getUsersAuthor = (attributes) =>{
  return async function (dispatch) {
    return getApiUsersAuthor(attributes)
      .then((response) => {
        const users = response;
        return dispatch({
          type: "GET_USERS_AUTHOR",
          payload: users,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getUserById = (attributes) =>{
  return async function (dispatch) {
    return getApiGetUserById(attributes)
      .then((response) => {
        const user = response;
        return dispatch({
          type: "GET_USER_BY_ID",
          payload: user,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const getUserByNickName = (attributes) =>{
  return async function (dispatch) {
    return getApiGetUserByNickName(attributes)
      .then((response) => {
        const user = response;
        return dispatch({
          type: "GET_USER_BY_NICKNAME",
          payload: user,
        });
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message.message,
        });
      });
  };
};

export const updateUser = (attributes) => {
  return async function (dispatch) {
    return putApiUpdateUser(attributes)
      .then((response) => {
        const user = response;
        return dispatch({
          type: "UPDATE_USER",
          payload: user,
        });
      })
      .catch((message) => {
        console.log(message)
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message,
        });
      });
  };
}

export const updateAuthor = (attributes) => {
  return async function (dispatch) {
    return putApiUpdateAuthor(attributes)
      .then((response) => {
        const user = response;
        return dispatch({
          type: "UPDATE_USER",
          payload: user,
        });
      })
      .catch((message) => {
        console.log(message)
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message,
        });
      });
  };
}

export const updateAccount = (attributes) => {
  return async function (dispatch) {
    return putApiUpdateAccount(attributes)
      .then((response) => {
        const user = response;
        return dispatch({
          type: "UPDATE_USER",
          payload: user,
        });
      })
      .catch((message) => {
        console.log(message)
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message,
        });
      });
  };
}

// falta ver los parametros que hay que meter.

export const doGoogleLogin = (token) => {
  return async function (dispatch) {
    return postApiGoogleLogin({ token : token })
      .then((response) => {
        const token = response.token;
        if(token === null){
          return dispatch({
            type: "IS_REGISTERED_SOCIAL",
            payload: { isRegistered : false, emailToRegister : response.email, nameToRegister: response.first_name, lastNameToRegister: response.last_name },
          });
        }else{
          ls.set("token", token);
          const tokenDecoded = validateToken.decode(token);
          return dispatch({
            type: "IS_LOGGED",
            payload: tokenDecoded.user,
          });
        }
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message,
        });
      });
  };
};

// falta ver los parametros que hay que meter.

export const doFacebookLogin = (token, user_id) => {
  return async function (dispatch) {
    return postApiFacebookLogin({ token : token, user_id : user_id })
      .then((response) => {
        const token = response.token;
        if(token === null){
          return dispatch({
            type: "IS_REGISTERED_SOCIAL",
            payload: { isRegistered : false, emailToRegister : response.email, nameToRegister: response.first_name, lastNameToRegister: response.last_name },
          });
        }else{
          ls.set("token", token);
          const tokenDecoded = validateToken.decode(token);
          return dispatch({
            type: "IS_LOGGED",
            payload: tokenDecoded.user,
          });
        }
      })
      .catch((message) => {
        return dispatch({
          type: "SET_USER_ERROR_MESSAGE",
          payload: message,
        });
      });
  };
};



