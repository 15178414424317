import "./SalesItem.scss";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getApiSalesByBook } from "../../../services/apiSale";

function SalesItem(props) {
  const user = useSelector((store) => store.user.user);
  const [salesTotal, setSalesTotal] = useState(null);

  useEffect(() => {
    const getSales = async () =>{
      const response = await getApiSalesByBook({
        book_id : props.sale.book._id
      })

      setSalesTotal(response.total)
    }

    getSales()
  }, [user]);

  return (
    <section className="sales-item">
      <div className="sales-item__container">
        <div className="sales-item__container-component">
          <img
            className="sales-item__container-component-image"
            src={ props.sale.book.picture_medium ? props.sale.book.picture_medium : '/images/image1.jpg' }
            alt="image1"
          />
          <div className="sales-item__container-component-component">
            <h3 className="sales-item__container-component-title">
              { props.sale && props.sale.book ? props.sale.book.title : '--' }
            </h3>
            <div className="sales-item__container-component-section">
              { salesTotal === 1 ?
                <p className="sales-item__container-component-section-sales">
                  { salesTotal ? salesTotal : 0 } venta
                </p>
              :
                <p className="sales-item__container-component-section-sales">
                  { salesTotal ? salesTotal : 0 } ventas
                </p>
              }
              {/*<p className="sales-item__container-component-section-date">
                25 Sep 2022
              </p>*/}
            </div>
          </div>
          <div className="sales-item__container-component-info-component">
            <p className="sales-item__container-component-info">
              Ganancias: { salesTotal && props.sale && props.sale.book ? (parseInt(salesTotal) * parseFloat(props.sale.book.price)).toFixed(2) : 0 }€
            </p>
            <p className="sales-item__container-component-info">
              Precio: { props.sale && props.sale.book ? (props.sale.book.price / 0.7).toFixed(2) : 0 }€
            </p>
            <p className="sales-item__container-component-info">
              Ventas: { salesTotal ? salesTotal : 0  }
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SalesItem;
