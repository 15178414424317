import "./FormRegisterSelectGenres.scss";
import React, { useState, useEffect, } from "react";
import { getApiCategories } from "../../../services/apiCategories";
import Genres from "../../Genres/Genres";
import Button from "../../DesingSystem/Button/Button";
import { useSelector } from "react-redux";

function FormRegisterSelectGenres(props) {
  const [selectedGenres, setSelectedGenres] = useState([]);
  const [genres, setGenres] = useState([]);
  const errorMessage = useSelector((state) => { return state.user.errorMessage });

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });
  }, []);

  const tagClick = (genre) => {
    if (selectedGenres.find((g) => g.tag === genre.tag)) {
      setSelectedGenres(selectedGenres.filter((g) => g.tag !== genre.tag));
    } else if (selectedGenres.length < 20) {
      setSelectedGenres([...selectedGenres, genre]);
    }
  };

  const onClick = (event) => {
    event.preventDefault();
    props.onStepSubmit(selectedGenres);
  };

  return (
    <section className="select-genres">
      <h1 className="login-container__title">Elige tus intereses</h1>
      <Genres
        className="w-100"
        genres={genres}
        genresSelected={selectedGenres}
        align="center"
        onClick={tagClick}
        isNavegable={false}
        size={'medium'}
      />

      { errorMessage && errorMessage !== '' ?
        <p className="m-0 error text-center">{errorMessage}</p>
      : null }

      <div className="select-genres__container-btn mt-5 mt-md-0">
      <Button
            className={
              selectedGenres.length > 0 ? "btn-orangebg w-90" : "btn-graybg w-90"
            }
            text={"Finalizar registro "}
            type={"submit"}
            iconRight="chevronRight"
            onClick={onClick}
            disabled={!selectedGenres.length}
          />
      </div>
      <div className="select-genres__container-btn">
        <button className="select-genres__btn-return" onClick={()=>{ props.goBack(1)}}>
          Volver
        </button>
      </div>
    </section>
  );
}
export default FormRegisterSelectGenres;
