import "./Events.scss";
import { useState, useEffect } from "react";
import { types } from "../../constants/constants";
import NameSection from "../../components/NameSection/NameSection";
import ComponentInfo from "../../components/ComponentInfo/ComponentInfo";
import Filter from "../../components/Filter/Filter";
import EventCard from "../../components/EventCard/EventCard";
import Button from "../../components/DesingSystem/Button/Button";
import FormCta from "../../components/FormCta/FormCta";
import { getApiEvents } from "../../services/apiEvent";

const Events = (props) => {
  const title = "Fomentando la colaboración en comunidad";
  const subtitle = "En Bookólicos queremos que descubras un mundo de experiencias inolvidables diseñadas especialmente para aquellos amantes del mundo literario.";
  const [events, setEvents] = useState(null);
  const [totalEvents, setTotalEvents] = useState(0);
  const [limit, setLimit] = useState(types.PAGE_SIZE);
  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState('timestamp');
  const [sortDirection, setSortDirection] = useState(-1);

  /*const events = [
    {
      id: 1,
      title: "Cómo escribir un bestseller",
      description: "Aprende a escribir un bestseller de la mano de Javier Cercas, uno de los escritores más reconocidos de España.",
      price: 19.99,
      date: new Date(),
      url: "/events/1",
      image: "https://blog.bookolicos.com/wp-content/uploads/2023/08/unseen-studio-s9CC2SKySJM-unsplash-scaled.jpg"
    }
  ]*/

  useEffect(() => {
    getEvents()
  }, [sortBy, sortDirection]);

  const getEvents = async () => {
    const response = await getApiEvents({ limit: limit, offset: offset, sortBy: sortBy, sortDirection: sortDirection })

    setEvents(response.events)
    setTotalEvents(response.total)
  }

  const loadMoreResults = async () => {
    const offsetPage = offset + limit;
    setOffset(offsetPage);

    const response = await getApiEvents({ limit: limit, offset: offsetPage, sortBy: sortBy, sortDirection: sortDirection })

    setEvents(events.concat(response.events))
    setTotalEvents(response.total)
  }

  const doSort = async (filters) => { 
    if(filters.sortSelected === 'NEW'){ // De más antiguos a más recientes
      setSortBy('timestamp');
      setSortDirection(1)
    }

    if(filters.sortSelected === 'OLD'){ // De más recientes a más antiguos
      setSortBy('timestamp');
      setSortDirection(-1)
    }

    if(filters.sortSelected === 'PRICE_HIGH'){ // De más económicos a más costosos
      setSortBy('price');
      setSortDirection(1)
    }

    if(filters.sortSelected === 'PRICE_LOW'){ // De más costosos a más económicos
      setSortBy('price');
      setSortDirection(-1);
    }
  }

  return (
    <>
      <NameSection name="Eventos" color="#f05a24" />
      <ComponentInfo title={title} subtitle={subtitle} />
      <Filter
        title="Todos los eventos"
        onFilterChange={(filter) => console.log(filter)}
        >
        <div className="events">
          {events && events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
            />
          ))}

          { events && events.length === 0 && 
            <p className="ms-4">No hay ningún evento disponible</p>
          }
        </div>
      </Filter>
      <div className="events__load-more">
        { events && (totalEvents > events.length) && 
          <Button className={'btn-white'} text={'Cargar más cursos'} onClick={ () => { loadMoreResults() } } />
        }
      </div>

      <FormCta text="¿Te gustaría que tu evento aparezca en la web de Bookólicos?" url="/eventos/formulario" />
    </>
  )
}

export default Events
