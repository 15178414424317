import "./CatalogPublic.scss";
import BookListPublic from "../../../components/aPublicArea/BookListPublic/BookListPublic";
import BookListPartOfTitle from "../../../components/aPublicArea/BookListPartOfTitle/BookListPartOfTitle";
import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";
import NameSection from "../../../components/NameSection/NameSection";

function CatalogPublic() {


  return (
    <section className="catalog-public">
      <NameSection name="Catálogo" color="#f05a24" />
      <BookListPublic />
      <BannerPublic/>
    </section>
  );
}

export default CatalogPublic;
