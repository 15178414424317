import "./FormRegister.scss";

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import FormRegisterUser from "../FormRegisterUser/FormRegisterUser";
import FormRegisterSelectGenres from "../FormRegisterSelectGenres/FormRegisterSelectGenres";
import { doRegister } from "../../../redux/actions/user";
//import { store } from "../../../redux/store/store";

function FormRegister() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [user, setUser] = useState(undefined);
  const registerSuccess = useSelector((state) => { return state.user.registerSuccess });

  const onStep1Submit = (attributes) => {
    setUser(attributes);
    setStep(2);
  };

  const onStep2Submit = async (selectedGenres) => {
    const attributes = {
      user: {
        ...user,
        roleId: "63eca70a3ddd1bd17322c63d",
        categories: selectedGenres,
      },
    };
    dispatch(doRegister(attributes));

    if(registerSuccess){
      navigate('/')
    }
  };

  const goBack = (step) => {
    setStep(step)
  }

  const renderStep = () => {
    if (step === 1) {
      return <FormRegisterUser onStepSubmit={onStep1Submit} goBack={goBack} user={user} />;
    } else if (step === 2) {
      return <FormRegisterSelectGenres onStepSubmit={onStep2Submit} goBack={goBack} />;
    }
  };

  return <section className="register">{renderStep()}</section>;
}

export default FormRegister;
