import "./GeneralInfoBook.scss";

function GeneralInfoBook(props) {
  return props.generalInfo ? (
    <div className="information">
      <p className="information__title">Información general</p>
      <div className="information__container">
        <p className="information__container-text">Nº de ventas</p>
        <p className="information__container-text">
          {props.generalInfo.sales}
        </p>
      </div>
      <div className="information__container">
        <p className="information__container-text">Última edición</p>
        <p className="information__container-text capitalize">
          {props.generalInfo.last_edition}
        </p>
      </div>
      <div className="information__container">
        <p className="information__container-text">Me gusta</p>
        <p className="information__container-text capitalize">
          {props.generalInfo.likes}
        </p>
      </div>
      <div className="information__container">
        <p className="information__container-text">Neutral</p>
        <p className="information__container-text capitalize">
          {props.generalInfo.neutral}
        </p>
      </div>
      <div className="information__container">
        <p className="information__container-text">No me gusta</p>
        <p className="information__container-text capitalize">
          {props.generalInfo.dislikes}
        </p>
      </div>
      { /*<div className="information__container">
        <p className="information__container-text">Nº de páginas</p>
        <p className="information__container-text">
          {props.generalInfo.pages}
        </p>
      </div>*/}
      <div className="information__container">
        <p className="information__container-text">Idioma</p>
        <p className="information__container-text">
          {props.generalInfo.language}
        </p>
      </div>
      <div className="information__container">
        <p className="information__container-text">ISBN</p>
        <p className="information__container-text">
          {props.generalInfo.isbn}
        </p>
      </div>
    </div>
  ) : null ;
}

export default GeneralInfoBook;
