import { useNavigate } from "react-router-dom";
import AuthorItemGenre from "../AuthorItemGenre/AuthorItemGenre";
import Button from "../../DesingSystem/Button/Button";
import "./AuthorListGenre.scss";
import Slider from "react-slick";
import React, { useRef } from "react";

function AuthorListGenre(props) {
  const navigate = useNavigate();
  const slider = useRef();

  function renderAuthors(authors) {
    const limitedAuthors = props.authors.slice(0, 4);
    // let newArr = [...new Array(4)].map((x, i) => limitedAuthors[i] || {})
    return limitedAuthors.map((author) => {
      if (!author._id) return <div className="author-item" key={Math.random()}></div>
      return <AuthorItemGenre author={author} key={ author._id } totalBooks={ author.totalBooks ? author.totalBooks : author.books.length } />;
    });
  };

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    adaptiveHeight: true,
    variableWidth: false,
    className: "author-list__item",
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1.1,
          variableWidth: false,
          adaptiveHeight: false
        },
      },
    ],
  };

  return props.authors ? (
    <>
      <div className="author-list__container-title">
        <div>
          <p className="author-list__title">Autores relacionados</p>
        </div>
        <div className="author-list__arrows">
          <Button className={"btn-small"} text={"Ver todos"} onClick={() => navigate('/autores')} />
          </div>
      </div>
      <div className="author-list">
        <Slider ref={slider} {...settings}>{renderAuthors(props.authors)}</Slider>

        <div className="author-list__actions">
          <Button
            className="btn-blackbg"
            text="Ver todos"
            iconRight="arrowDiagonal"
            onClick={() => navigate('/autores')}
          />
        </div>
      </div>
    </>
  ) : null;
}

export default AuthorListGenre;
