import "./PoliticaPrivacidad.scss";

function PoliticaPrivacidad() {
  return (
    <section className="politica-privacidad">

        <ol>
        <li><strong>1.- Informaci&oacute;n previa</strong></li>
        </ol>
        <p>N&eacute;stor Mart&iacute;nez Roda en adelante, &ldquo;EL TITULAR&rdquo; de esta p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a> en cumplimiento de las disposiciones legales de aplicaci&oacute;n, mediante el presente texto ofrece a todos los usuarios que accede a esta p&aacute;gina web las normas rectoras de la protecci&oacute;n de datos personales de los propios usuarios. Por lo cual, EL TITULAR determina que es de OBLIGATORIA LECTURA LA PRESENTE POL&Iacute;TICA DE PRIVACIDAD PARA CADA USUARIO.</p>
        <p>EL TITULAR entiende que s&iacute; el usuario est&aacute; navegando por la p&aacute;gina web ha le&iacute;do, ha comprendido y ha aceptado la presente POL&Iacute;TICA DE PRIVACIDAD que regula el tratamiento de sus datos personales.</p>
        <p>Es necesario que el usuario sea conocedor que la prestaci&oacute;n de determinados servicios ofrecidos en esta p&aacute;gina web supone la necesidad de recopilar, guardar y gestionar los datos personales de los usuarios. En consecuencia, una vez recogidos los datos personales de los usuarios, los mismos se incorporan en el correspondiente registro de actividades de tratamiento, siempre y cuando fuere necesario tratar dichos datos personales, que viene gestionado por EL TITULAR.</p>
        <p>Los datos personales de los usuarios siempre vendr&aacute;n tratados con la finalidad especifica para la cual fueron recogido y de conformidad con la normativa reguladora nacional y supranacional: 1) El Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos y por el que se deroga la Directiva 95/46/CE (Reglamento general de protecci&oacute;n de datos), y: 2) La Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales (LOPD-GDD).</p>
        <p>En definitiva, la finalidad de este documento es informar a todos los usuarios que acceden a esta p&aacute;gina web sobre sus derechos de protecci&oacute;n de datos y el destino de sus datos personales que se recogen en esta p&aacute;gina web, as&iacute; como, toda la informaci&oacute;n necesaria y requerida por el marco legal de aplicaci&oacute;n.</p>
        <ol start="2">
        <li><strong>2.- Responsable del Tratamiento de Datos Personales</strong></li>
        </ol>
        <p>N&eacute;stor Mart&iacute;nez Roda es titular de la marca personal &ldquo;BOOKOLICOS&rdquo; y de la p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a> y por ende es la persona responsable del tratamiento de los datos personales que son recabados por medio de esta p&aacute;gina web.</p>
        <ul>
        <li>Responsable de tratamiento de los datos personales: N&eacute;stor Mart&iacute;nez Roda</li>
        <li>NIF: 50866063E</li>
        <li>Domicilio sito en: Calle R&iacute;o Guadalquivir 6, 4 D. 28913 Legan&eacute;s, Madrid.</li>
        <li>Direcci&oacute;n de correo electr&oacute;nico: <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a></li>
        <li>Tel&eacute;fono: 699760246</li>
        <li>P&aacute;gina web: <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a></li>
        </ul>
        <br />
        <ol start="3">
        <li><strong>3.- Tratamientos de datos de car&aacute;cter personal</strong></li>
        </ol>
        <p>En cumplimiento con lo dispuesto en el Reglamento 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, relativo a la protecci&oacute;n de las personas f&iacute;sicas en lo que respecta al tratamiento de datos personales y a la libre circulaci&oacute;n de estos datos (en adelante RGPD) y en funci&oacute;n de las previsiones normativas de la Ley Org&aacute;nica 3/2018, de 5 de diciembre, de Protecci&oacute;n de Datos Personales y garant&iacute;a de los derechos digitales (en adelante, LOPDP-GDD), mediante el presente texto legal se informa al usuario que los datos personales e informaci&oacute;n que facilita al cumplimentar cualquier formulario electr&oacute;nico disponible en esta p&aacute;gina web, ser&aacute;n incorporados a un fichero de datos de car&aacute;cter personal titularidad de D&ordf;. N&eacute;stor Mart&iacute;nez Roda.</p>
        <p>Estos datos personales &uacute;nicamente se tratar&aacute;n en la medida en que sean adecuados, pertinentes y limitados a lo estrictamente necesario para los fines para los que son recabados. En caso contrario, cuando se trata de datos personales excesivos, la responsable del tratamiento de los datos personales de esta p&aacute;gina web asume el compromiso del borrado de esos datos personales excesivos, a fin de cumplir con el principio de minimizaci&oacute;n de datos personales reconocido en el articulado del RGPD.</p>
        <ol start="4">
        <li><strong>4.- &iquest;Con qu&eacute; finalidad tratamos sus datos personales?</strong></li>
        </ol>
        <p>EL TITULAR tratar&aacute; los datos personales de los usuarios con los siguientes fines:</p>
        <ul>
        <li>Gestionar la relaci&oacute;n contractual que pueda entablar el usuario con EL TITULAR</li>
        <li>Realizar comunicaciones comerciales siempre y cuando fueren consentidas por la persona f&iacute;sica o jur&iacute;dica, por los medios que ellas fueren indicadas.</li>
        </ul>
        <p>Asimismo, mediante la presente se informa al usuario que, siempre y cuando lo consienta, EL TITULAR podr&aacute; elaborar perfiles comerciales de los usuarios anonimizando sus datos personales para focalizar y gestionar de forma eficaz su actividad comercial. Todos los perfiles comerciales se elaboran en base a la informaci&oacute;n que sea proporcionada por el propio usuario, se sea obtenida del uso leg&iacute;timo y autorizado de las cookies y de las fuentes p&uacute;blicas (redes sociales).</p>
        <p>Por otro lado, salvo que el usuario lo autorice expresamente, EL TITULAR no ceder&aacute; ni los perfiles comerciales ni datos personales a terceras personas.</p>
        <ol start="5">
        <li><strong>5.- Uso de datos personales</strong></li>
        </ol>
        <p>Los datos personales de los usuarios &uacute;nicamente se tratar&aacute;n en la medida en que sean adecuados, pertinentes y limitados a lo estrictamente necesario para los fines para los que son recabados, tal y como se ha indicado previamente.</p>
        <p>En este sentido, el usuario puede facilitar libremente sus datos personales a LA TITUALR a trav&eacute;s de los distintos procedimientos establecidos para ello, que ser&aacute;n tratados siempre y cuando el usuario haya prestado su consentimiento de forma espec&iacute;fica, informada e inequ&iacute;voca para las siguientes finalidades:</p>
        <ul>
        <li>Recibir informaci&oacute;n acerca de los servicios profesionales de ofrecidos por EL TITULAR. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>Contrataci&oacute;n de servicios profesionales ofrecidos por EL TITULAR. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>Emisi&oacute;n de facturas por los servicios profesionales de EL TITULAR. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>Comunicaci&oacute;n de publicidad relacionada con los productos o servicios ofertados por EL TITULAR. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>Comunicaciones de novedades o de &ldquo;NEWS LETTER&rdquo;. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>La atenci&oacute;n de solicitudes de informaci&oacute;n, quejas y sugerencias a trav&eacute;s de los medios que EL TITULAR de la p&aacute;gina web ponga a disposici&oacute;n de los usuarios. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>El env&iacute;o de informaci&oacute;n comercial de terceros que pueda resultar de inter&eacute;s para el usuario. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        <li>La gesti&oacute;n de tu suscripci&oacute;n al blog de la p&aacute;gina web, manteniendo informado al usuario sobre las novedades y &uacute;ltimas actualizaciones. Para esta finalidad EL TITULAR podr&aacute; recabar de los usuarios datos identificativos y de contacto y, por ende, tratarlos.</li>
        </ul>
        <p>Adem&aacute;s de los fines indicados, todos esos datos personales anonimizados en su totalidad pueden utilizarse con fines de realizar pruebas de nuestros sistemas inform&aacute;ticos, para realizar an&aacute;lisis de datos, creaci&oacute;n de modelos de marketing y promoci&oacute;n, mejorar el servicio y para desarrollar nuevos productos o servicios.</p>
        <p>El consentimiento para el uso de estos datos viene detallado en el apartado de &ldquo;Derechos del usuario&rdquo; y como tal, podr&aacute; revocarse en cualquier momento, ejerciendo los derechos que tiene el usuario a tal efecto.</p>
        <ol start="6">
        <li><strong>6.- M&eacute;todo de recogida de datos personales</strong></li>
        </ol>
        <p>Los datos personales de los usuarios podr&aacute;n ser recogidos de las siguientes formas:</p>
        <ul>
        <li><u>Con el acceso a la p&aacute;gina web</u>: Direcci&oacute;n IP, Cookies y similares. La direcci&oacute;n IP es un c&oacute;digo que identifica la conexi&oacute;n a internet del Usuario en un momento concreto y las Cookies son ficheros que se descargan al navegador del Usuario. Para mayor conocimiento sobre las cookies, se recomiendo al usuario la lectura de la <a href="/politica-cookies" target="_blank">Pol&iacute;tica de Cookies</a> de esta p&aacute;gina web.</li>
        <li><u>Con suscripci&oacute;n a nuestras &ldquo;newsletter&rdquo; y/o publicaciones</u>: Los datos recogidos ser&aacute;n los datos identificativos necesarios para hacerle llegar al usuario el contenido al que se ha suscrito.</li>
        <li><u>Con suscripci&oacute;n a eventos y actividades similares</u>: Los datos recogidos ser&aacute;n los datos identificativos necesarios para gestionar su participaci&oacute;n, como su nombre, apellidos, correo electr&oacute;nico, empresa, cargo y datos de contacto profesional.</li>
        <li><u>Contactando directamente con EL TITULAR por cualquier medio</u>: Para contratar los servicios o productos comercializados por medio de esta p&aacute;gina web.</li>
        </ul>
        <br />
        <ol start="7">
        <li><strong>7.- Cesi&oacute;n de datos personales a terceros</strong></li>
        </ol>
        <p>EL TITULAR de esta p&aacute;gina web sigue las normas &eacute;ticas, profesionales y legales que respetan siempre la privacidad de los usuarios y de sus derechos inherentes al derecho fundamental de privacidad.</p>
        <p>Por lo cual, la cesi&oacute;n o comunicaci&oacute;n de los datos personales de los usuarios a terceros <strong><u>&uacute;nicamente ser&aacute; posible en los casos legalmente previstos o bien para cumplir con las obligaciones contractuales asumidas con el usuario</u></strong>. En los dem&aacute;s casos, solo ser&aacute; posible la cesi&oacute;n de los datos personales de los usuarios a terceros siempre y cuando as&iacute; lo indique y consienta el usuario de manera libre, expresa, informada y especificando los datos que han de ser cedidos. Es decir, el consentimiento del usuario para la cesi&oacute;n de sus datos personales siempre ha de ser inequ&iacute;voco.</p>
        <ol start="8">
        <li><strong>8.- Conservaci&oacute;n de tus datos personales</strong></li>
        </ol>
        <p>Los datos personales de los usuarios ser&aacute;n conservados por plazos distintos que dependen de la finalidad para la cual se hayan obtenido dichos datos que objeto de tratamiento.</p>
        <p>Por la regla general, los datos personales de los usuarios se conservar&aacute;n durante el tiempo necesario para cumplir con la finalidad para la cual fueron obtenidos. Por lo tanto, en todo caso se informar&aacute; al usuario cuanto tiempo durar&aacute; la prestaci&oacute;n del servicio o la relaci&oacute;n comercial y si una vez finalizada la misma, EL TITULAR segu&eacute; conservando dichos datos personales, se indicar&aacute; la finalidad y la legitimaci&oacute;n a tal efecto.</p>
        <p>Todo usuario puede en cualquier momento ejerce su derecho de supresi&oacute;n, cancelaci&oacute;n y/o limitaci&oacute;n del tratamiento de sus datos personales.</p>
        <p>El tratamiento y/o procesamiento de los datos personales fundado en el consentimiento del usuario supone que el usuario en cualquier momento pude, haciendo uso de los medios puestos a su disposici&oacute;n en la p&aacute;gina web, retirar su consentimiento a tales efectos. Lo que conlleva que EL TITULAR ya no podr&aacute;n tratar esos datos personales a excepci&oacute;n de los caos legalmente establecidos.</p>
        <ol start="9">
        <li><strong>9.- Medidas de seguridad</strong></li>
        </ol>
        <p>En la p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a>, se han adoptado los niveles m&aacute;ximos de seguridad de la informaci&oacute;n exigidos por la normativa de protecci&oacute;n de datos personales RGPD y LOPDP &ndash; GDD, adem&aacute;s de cumplir con las medidas inform&aacute;ticas de seguridad establecidas en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la informaci&oacute;n y de comercio electr&oacute;nico.</p>
        <p>Por lo cual, en esta p&aacute;gina web se cumplen con todas las medidas de seguridad de la informaci&oacute;n y de los datos personales que son adecuadas a la naturaleza de los datos que son objeto del tratamiento en cada momento mediante la aplicaci&oacute;n de medidas t&eacute;cnicas y organizativas apropiadas, tales como procesos de verificaci&oacute;n de los datos y de los usuarios junto con el cifrado de los datos recogidas por medio de la p&aacute;gina web con el protocolo HTTPS.</p>
        <p>Sin embargo, la seguridad t&eacute;cnica en un medio digital no es inexpugnable y pueden existir actuaciones dolosas de terceros que puedan estar dirigidas al robo o sustracci&oacute;n de todos esos datos personales o partes de los mismos, es por ello que EL TITULAR de p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a> se compromete adoptan todas las medidas necesarias para evitar que algo as&iacute; suceda.</p>
        <ol start="10">
        <li><strong>10.- Uso de las cookies</strong></li>
        </ol>
        <p>En la p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a> se hace uso de cookies respecto de los usuarios que navegan en el sitio web. El usuario dispones de informaci&oacute;n acerca del uso de las cookies en la <a href="/politica-cookies" target="_blank">Pol&iacute;tica de Cookies</a>.</p>
        <p>El uso de las cookies permite que el servidor en el cual est&aacute; almacenada la p&aacute;gina web <a href="https://www.bookolicos.com/">https://www.bookolicos.com/</a>reconozca al navegador web utilizado por el usuario con la finalidad de que la navegaci&oacute;n sea m&aacute;s sencilla, identific&aacute;ndote cuando visitas nuestro sitio web y permitiendo preservar el estado de acceso del usuario, permitiendo medir la audiencia, par&aacute;metros del tr&aacute;fico web, controlar el progreso, n&uacute;mero de entradas en la p&aacute;gina web, guardar los datos de sesi&oacute;n y recordar las operaciones m&aacute;s relevantes de los usuarios.</p>
        <p>Con todo ello, se recopila informaci&oacute;n personal de cada usuario, como es su direcci&oacute;n IP entre otros datos personales.</p>
        <p>Dado que existen cookies de terceros que pueden instalarse en el dispositivo inform&aacute;tico del usuario, siempre con su consentimiento, las mismas trasferir&aacute;n datos del usuario al titular de la cookie en cuesti&oacute;n.</p>
        <ol start="11">
        <li><strong>11.- Derechos que asisten al usuario</strong></li>
        </ol>
        <p>El Usuario podr&aacute; ejercer sus derechos de Acceso, Rectificaci&oacute;n, Portabilidad, Cancelaci&oacute;n y Oposici&oacute;n en los t&eacute;rminos legalmente previstos, debiendo para ello dirigir una comunicaci&oacute;n enviando un mensaje de correo electr&oacute;nico a la siguiente direcci&oacute;n: <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a>, indicando en ambos casos su nombre, apellidos y el derecho que quiere ejercitar.</p>
        <p>No es necesario que el usuario aporte documentaci&oacute;n acreditativa de su identidad, <strong>en caso de que dicha documentaci&oacute;n ni haya sido exigida para la recopilaci&oacute;n de sus datos personales</strong>.</p>
        <p>En el marco normativo el usuario tiene los siguientes derechos:</p>
        <ul>
        <li><strong><u>Derecho de acceso</u></strong>: el derecho a obtener en un fichero con formato legible un informe sobre si EL TITULAR de esta p&aacute;gina web ha tenido acceso a sus datos personales, el origen de dichos datos, como han sido tratados dichos datos y si son tratados y/o procesados en el momento de la solicitud. Esto, adem&aacute;s, supone que el usuario tendr&aacute; derecho a obtener una copia de todos esos datos personales.</li>
        <li><strong><u>Derecho de rectificaci&oacute;n</u></strong>: el derecho a solicitar la rectificaci&oacute;n de los datos personales inexactos que obran en poder de EL TITULAR de esta p&aacute;gina web y conciernen al usuario.</li>
        <li><strong><u>Derecho de supresi&oacute;n</u></strong>: el derecho a solicitar la supresi&oacute;n de sus datos personales por el usuario, sin necesidad de motivaci&oacute;n o justificaci&oacute;n alguna.</li>
        <li><strong><u>Derecho de oposici&oacute;n</u></strong>: el usuario tiene el derecho a oponerte al tratamiento de sus datos personales, sin motivaci&oacute;n alguna. En tal caso, EL TITULAR cesar&aacute; en el tratamiento y/o procesamiento de dichos datos, salvo que existan motivos u obligaciones legales que exijan lo contrario.</li>
        <li><strong><u>Derecho a la limitaci&oacute;n del tratamiento</u></strong>: el usuario puede solicitar la limitaci&oacute;n del tratamiento de sus datos personales, en cuyo caso EL TITULAR &uacute;nicamente podr&aacute; conservar esos datos personales de los usuarios, pero no podr&aacute; tratar dichos datos personales con los fines que han sido limitados expresamente por el usuario.</li>
        <li><strong><u>Derecho a la portabilidad de los datos</u></strong>: los usuarios tienen derecho a recibir en un formato legible los datos personales que obran en poder de EL TITULAR y, en su caso, si el usuario lo solicitase remitir esos datos personales a un tercero elegido por el usuario en un formato estructurado, de uso com&uacute;n y lectura mec&aacute;nica, siempre y cuando dicho tratamiento y/o procesamiento de datos personales se base en su consentimiento y se efect&uacute;e por medios automatizados.</li>
        </ul>
        <br />
        <ol start="12">
        <li><strong>12.- Ejercicio de derecho</strong></li>
        </ol>
        <p>El usuario puede ejercitar sus derechos de manera individual dirigi&eacute;ndose por medio del correo electr&oacute;nico <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a>, a la Responsable de Tratamiento, indicando qu&eacute; derecho quiere ejercitar ejercitas y sus pretensiones.</p>
        <p>EL TITULAR se compromete a responderte y cumplir con tus derechos en el plazo establecido en la Ley.</p>
        <p>No obstante, en caso de que el usuario considere que no se han atendido adecuadamente sus derechos, puedes dirigir una reclamaci&oacute;n ante la Autoridad de Control que le corresponda (su residencia habitual o su lugar de trabajo, o la Autoridad de Control del lugar de la supuesta infracci&oacute;n). En Espa&ntilde;a la autoridad de control es:</p>
        <ul>
        <li>Agencia Espa&ntilde;ola de Protecci&oacute;n de Datos:
        <ul>
        <li>P&aacute;gina web: <a href="http://www.aepd.es/">aepd.es</a></li>
        <li>Con domicilio en: C/ Jorge Juan, 6, 28001-Madrid</li>
        <li>Tel&eacute;fono de contacto: 912663517</li>
        </ul>
        </li>
        </ul>
        <br />
        <ol start="13">
        <li><strong>13.- Enlaces</strong></li>
        </ol>
        <p>Esta web contiene enlaces o links a otras p&aacute;ginas web. Los usuarios han de ser conscientes que EL TITULAR no es responsable de las pr&aacute;cticas de privacidad ni de los contenidos de esas otras webs. Recomendamos a los usuarios que sean conscientes de que al utilizar uno de estos links est&aacute;n abandonando esta web y por ello lean las pol&iacute;ticas de privacidad de esas otras webs.</p>
        <ol start="14">
        <li><strong>14.- Cambios en la pol&iacute;tica de privacidad</strong></li>
        </ol>
        <p>Es posible que actualicemos la pol&iacute;tica de privacidad de esta web, por ello es recomendable que los usuarios revisen esta pol&iacute;tica de privacidad cada vez que accedan a esta web con el objetivo de estar adecuadamente informados.</p>

    </section>
  );
}

export default PoliticaPrivacidad;
