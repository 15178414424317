import { ReactComponent as add } from "./add.svg";
import { ReactComponent as arrowDiagonal } from "./arrow-diagonal.svg";
import { ReactComponent as arrowLeft } from "./arrow-left.svg";
import { ReactComponent as arrowLong } from "./arrow-long.svg";
import { ReactComponent as arrowRight } from "./arrow-right.svg";
import { ReactComponent as chevronDown } from "./chevron-down.svg";
import { ReactComponent as chevronUp } from "./chevron-up.svg";
import { ReactComponent as chevronLeft } from "./chevron-left.svg";
import { ReactComponent as chevronRight } from "./chevron-right.svg";
import { ReactComponent as close } from "./close.svg";
import { ReactComponent as dislike } from "./dislike.svg";
import { ReactComponent as download } from "./download.svg";
import { ReactComponent as edit } from "./edit.svg";
import { ReactComponent as eye } from "./eye.svg";
import { ReactComponent as facebook } from "./facebook.svg";
import { ReactComponent as file } from "./file.svg";
import { ReactComponent as instagram } from "./instagram.svg";
import { ReactComponent as like } from "./like.svg";
import { ReactComponent as linkedin } from "./linkedin.svg";
import { ReactComponent as loaderIcon } from "./loader.svg";
import { ReactComponent as logout } from "./logout.svg";
import { ReactComponent as mail } from "./mail.svg";
import { ReactComponent as mobile } from "./mobile.svg";
import { ReactComponent as notifications } from "./notifications.svg";
import { ReactComponent as option } from "./option.svg";
import { ReactComponent as profile } from "./profile.svg";
import { ReactComponent as search } from "./search.svg";
import { ReactComponent as share } from "./share.svg";
import { ReactComponent as tick } from "./tick.svg";
import { ReactComponent as twitter } from "./twitter.svg";
import { ReactComponent as write } from "./write.svg";
import { ReactComponent as pencil } from "./pencil.svg";
import { ReactComponent as deleteIcon } from "./delete.svg";
import { ReactComponent as visa } from "./visa.svg";
import { ReactComponent as mastercard } from "./mastercard.svg";
import { ReactComponent as amex } from "./amex.svg";
import { ReactComponent as face } from "./face.svg";
import { ReactComponent as faceTwo } from "./face-two.svg";
import { ReactComponent as tooltip } from "./tooltip.svg";
import { ReactComponent as google } from "./google.svg";
import { ReactComponent as menu } from "./menu.svg";
import { ReactComponent as sad } from "./sad.svg";
import { ReactComponent as euro } from "./euro.svg";
import { ReactComponent as filter } from "./filter.svg";
import { ReactComponent as calendar } from "./calendar.svg";
import { ReactComponent as reviewDislike } from "./review-dislike.svg";
import { ReactComponent as reviewNeutral } from "./review-neutral.svg";
import { ReactComponent as reviewLike } from "./review-like.svg";

const icons = {
  add,
  arrowDiagonal,
  arrowLeft,
  arrowLong,
  arrowRight,
  chevronUp,
  chevronDown,
  chevronLeft,
  chevronRight,
  close,
  dislike,
  download,
  edit,
  eye,
  facebook,
  file,
  instagram,
  like,
  linkedin,
  loaderIcon,
  logout,
  mail,
  mobile,
  notifications,
  option,
  pencil,
  profile,
  search,
  share,
  tick,
  twitter,
  write,
  deleteIcon,
  visa,
  mastercard,
  amex,
  face,
  faceTwo,
  tooltip,
  google,
  menu,
  sad,
  euro,
  filter,
  calendar,
  reviewDislike,
  reviewLike,
  reviewNeutral
};

export default icons;
