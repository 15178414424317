import "./HeaderLanding.scss";

import LogoSillaBlanco from "../../styles/images/logo-silla-pequeña-blanco.svg";
import LogoSilla from "../../styles/images/logo.svg";
import Button from "../../components/DesingSystem/Button/Button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

const HeaderLanding = () => {
  const navigate = useNavigate();

  const scrollEvent = () => {
    const header = document.querySelector('.header-landing');
    const scrollY = window.scrollY;
    if(scrollY > 150) {
      header.classList.add('header-landing--scroll');
    } else {
      header.classList.remove('header-landing--scroll');
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    }
  }, []);

  return (
    <header className="header-landing">
      <div className="header-landing__container">
        <div className="header-landing__logo-container" onClick={() => navigate('/')}>
          <img className="header-landing__logo header-landing__logo--white" src={LogoSillaBlanco} alt="logo" />
          <img className="header-landing__logo header-landing__logo--orange" src={LogoSilla} alt="logo" />
        </div>
        <div className="header-landing__logo-white">
        </div>
        <div className="header-landing__button">
          <Button text="Visitar Bookólicos" className="btn btn-blackbg" onClick={() => navigate('/')} />
        </div>
      </div>
    </header>
  );
}

export default HeaderLanding;
