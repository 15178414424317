import "./Login.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ls from "../../services/localStorage";
import logoLogin from "./../../styles/images/logo.svg";
import FormRegister from "../../components/Login/FormRegister/FormRegister";
import FormIsRegistered from "../../components/Login/FormIsRegistered/FormIsRegistered";
import Welcome from "../../components/Login/Welcome/Welcome";
import validateToken from "../../services/validateToken";
import Icon from "../../components/Icon/Icon";

function Login() {
  const navigate = useNavigate();
  const queryParameters = new URLSearchParams(window.location.search)
  const returnURL = queryParameters.get("return")
  const userRegistered = useSelector((state) => {
    return state.user.isRegistered;
  });

  const token = ls.get("token");

  useEffect(() => {
    if (validateToken.validate(token)) {
      navigate("/");
    }
  }, [validateToken, token, navigate]);

  const renderStep = () => {
    if (userRegistered === undefined) {
      return <FormIsRegistered />;
    } else if (userRegistered === true) {
      return <Welcome />;
    } else if (userRegistered === false) {
      return <FormRegister />;
    }
  };
  const goToPublicHome = () => {
    if(returnURL){
      navigate(returnURL)
    }else{
      navigate("/");
    }
  };

  const TABLET_SIZE = 768
  let closeIconColor = window.innerWidth <= TABLET_SIZE ? 'principal' : 'secondary-1';;
  // Change color if window width is less than tablet width: 768px
  // TODO: Make this reactive in Icon component
  window.addEventListener('resize', () => {
    closeIconColor = window.innerWidth <= TABLET_SIZE ? 'principal' : 'secondary-1';
  });


  return (
    <section className="login">
      <div className="login-container__icon login-container__top-actions">
        <Icon
          name="close"
          size="medium"
          color={closeIconColor}
          className="login-container__icon login-container__close-icon"
          onClick={goToPublicHome}
        />
      </div>
      <div className="login-container">
        <img
          className="login-container__logo"
          src={logoLogin}
          alt="Logo Bookolicos"
        ></img>
        {renderStep()}
      </div>
    </section>
  );
}
export default Login;
