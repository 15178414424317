import "./ProfileAuthor.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ls from "../../services/localStorage";
import validateToken from "../../services/validateToken";
import AuthorListGenre from "../../components/GenrePageComponents/AuthorListGenre/AuthorListGenre";
import SectionImages from "../../components/ProfileAuthorComponents/SectionImages/SectionImages";
import InfoAuthor from "../../components/ProfileAuthorComponents/InfoAuthor/InfoAuthor";
import GenresAuthor from "../../components/ProfileAuthorComponents/GenresAuthor/GenresAuthor";
import BooksAuthor from "../../components/ProfileAuthorComponents/BooksAuthor/BooksAuthor";

import { getUserByNickName } from '../../redux/actions/user'

function ProfileAuthor(props) {
  const urlParams = useParams()
  const dispatch = useDispatch();
  const [loaded, setLoaded] = useState(false);
  const [genres, setGenres] = useState([]);
  const [relatedAuthors, setRelatedAuthors] = useState([]);
  const user = useSelector((store) => store.user.author);
  const errorMessage = useSelector((store) => store.user.errorMessage);

  useEffect(() => {
    dispatch(getUserByNickName({ nickName : urlParams.slug })).then(async response => {
      // Get the list of book categories
      setLoaded(true)
      let writtenGenres = []
      await Promise.all(response.payload.books.map(async book => {
        await Promise.all(book.categories.map(category =>{
          if(JSON.stringify(writtenGenres).indexOf(category._id) === -1){
            writtenGenres.push(category);
          }
        }))
      }))

      setGenres(writtenGenres)
    });
  }, [urlParams]);

  return user && loaded ? (
    <div className="profile-author-container">
      <SectionImages author={user}/>
      <InfoAuthor author={user}/>
      <GenresAuthor genres={genres}/>
      <BooksAuthor author={user}/>
      <AuthorListGenre authors={user.relatedAuthors}/>
    </div>
  ) : null;
}

export default ProfileAuthor;
