import "./ProfileAuthor.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { types } from "../../../constants/constants";
import ls from "../../../services/localStorage";
import validateToken from "../../../services/validateToken";
import { getUserById, updateAuthor } from "../../../redux/actions/user";
import Form from "react-bootstrap/Form";
import Icon from "../../Icon/Icon";
import Button from "../../DesingSystem/Button/Button";
import InputText from "../../DesingSystem/InputText/InputText";
import utils from "../../../services/utils";
import Avatar from "../Avatar/Avatar";

function ProfileAuthor() {
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const errorMessage = useSelector((store) => store.user.errorMessage);
  const [errorForm, setErrorForm] = useState("");
  const [profilePic, setProfilePic] = useState("");
  const [coverPic, setCoverPic] = useState("");
  const [pseudonym, setPseudonym] = useState("");
  const [authorDescription, setAuthorDescription] = useState("");
  const [twitterUrl, setTwitterUrl] = useState("");
  const [facebookUrl, setFacebookUrl] = useState("");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [instagramUrl, setInstagramUrl] = useState("");
  const [isNickNameEntered, setIsNickNameEntered] = useState(false);
  const [isTwEntered, setIsTwEntered] = useState(false);
  const [isFbEntered, setIsFbEntered] = useState(false);
  const [isLiEntered, setIsLiEntered] = useState(false);
  const [isInEntered, setIsInEntered] = useState(false);
  const [saveDataIcon, setSaveDataIcon] = useState("");
  const [saveDataButtonDisabled, setSaveDataButtonDisabled] = useState(false);

  const inputCoverFile = React.useRef();
  const inputProfileFile = React.useRef();

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    dispatch(getUserById(tokenDecoded.user._id));
    document.querySelector('meta[name=theme-color]').setAttribute('content', '#f7f7f7')
    return () => {
      document.querySelector('meta[name=theme-color]').setAttribute('content', '#f05a24')
    }
  }, []);

  useEffect(() => {
    if (user) {
      setProfilePic(user.profilePic);
      setCoverPic(user.coverPic);
      setAuthorDescription(user.authorDescription);
      setPseudonym(user.pseudonym);
      setTwitterUrl(user.twitterUrl);
      setFacebookUrl(user.facebookUrl);
      setLinkedinUrl(user.linkedinUrl);
      setInstagramUrl(user.instagramUrl);
    }
  }, [user]);

  const handleInputNickNameFocus = () => {
    setIsNickNameEntered(true);
  };
  const handleInputNickNameBlur = () => {
    setIsNickNameEntered(false);
  };

  const handleInputTwFocus = () => {
    setIsTwEntered(true);
  };
  const handleInputTwBlur = () => {
    setIsTwEntered(false);
  };

  const handleInputFbFocus = () => {
    setIsFbEntered(true);
  };
  const handleInputFbBlur = () => {
    setIsFbEntered(false);
  };

  const handleInputInFocus = () => {
    setIsInEntered(true);
  };
  const handleInputInBlur = () => {
    setIsInEntered(false);
  };

  const handleInputLiFocus = () => {
    setIsLiEntered(true);
  };
  const handleInputLiBlur = () => {
    setIsLiEntered(false);
  };

  const onChangeProfilePicture = (event) => {
    if (event.target.files.length > 0) {
      setProfilePic(event.target.files);
    } else {
      setProfilePic(undefined);
    }
  };

  const onChangeCoverPicture = (event) => {
    if (event.target.files.length > 0) {
      setCoverPic(event.target.files);
    } else {
      setCoverPic(undefined);
    }
  };

  const saveData = async () => {
    setSaveDataButtonDisabled(true);
    setSaveDataIcon("loaderIcon");
    setErrorForm("");

    let error = false;
    let formErrorsTemp = [];

    if (twitterUrl && twitterUrl !== "" && !utils.isURLValid(twitterUrl)) {
      formErrorsTemp.twitterUrl = "La URL no es válida";
      error = true;
    }
    if (instagramUrl && instagramUrl !== "" && !utils.isURLValid(instagramUrl)) {
      formErrorsTemp.instagramUrl = "La URL no es válida";
      error = true;
    }
    if (linkedinUrl && linkedinUrl !== "" && !utils.isURLValid(linkedinUrl)) {
      formErrorsTemp.linkedinUrl = "La URL no es válida";
      error = true;
    }
    if (facebookUrl && facebookUrl !== "" && !utils.isURLValid(facebookUrl)) {
      formErrorsTemp.facebookUrl = "La URL no es válida";
      error = true;
    }

    setErrorForm({
      twitterUrl: formErrorsTemp.twitterUrl ? formErrorsTemp.twitterUrl : null,
      facebookUrl: formErrorsTemp.facebookUrl ? formErrorsTemp.facebookUrl : null,
      linkedinUrl: formErrorsTemp.linkedinUrl ? formErrorsTemp.linkedinUrl : null,
      instagramUrl: formErrorsTemp.instagramUrl ? formErrorsTemp.instagramUrl : null,
    });

    if (!error) {
      try {
        const token = ls.get("token");
        const loggedUser = validateToken.getUser(token);
        let profilePictureUrl = undefined;
        let coverPictureUrl = undefined;

        // Upload cover picture if changed
        if (coverPic && coverPic.length && coverPic !== user.coverPic) {
          let formDataBook = new FormData();

          formDataBook.append("file", coverPic[0]);

          let responseCoverPictureUpload = await fetch(
            `${types.API_URL}upload`,
            {
              method: "POST",
              headers: {
                token: token,
              },
              body: formDataBook,
            }
          );

          if (!responseCoverPictureUpload.ok) {
            alert(await responseCoverPictureUpload.json());
            return false;
          }

          const responseCoverPictureUploadJson =
            await responseCoverPictureUpload.json();
          coverPictureUrl = responseCoverPictureUploadJson.url_big;
        }

        // Upload profile picture if changed
        if (profilePic && profilePic.length && profilePic !== user.profilePic) {
          let formDataBook = new FormData();

          formDataBook.append("file", profilePic[0]);

          let responseProfilePictureUpload = await fetch(
            `${types.API_URL}upload`,
            {
              method: "POST",
              headers: {
                token: token,
              },
              body: formDataBook,
            }
          );

          if (!responseProfilePictureUpload.ok) {
            alert(await responseProfilePictureUpload.json());
            return false;
          }

          const responseProfilePictureUploadJson =
            await responseProfilePictureUpload.json();
          profilePictureUrl = responseProfilePictureUploadJson.url_medium;
        }

        let attributes = {
          user: {},
        };
        attributes.user._id = user._id;
        attributes.user.pseudonym = pseudonym;
        attributes.user.profilePic = profilePictureUrl;
        attributes.user.coverPic = coverPictureUrl;
        attributes.user.authorDescription = authorDescription;
        attributes.user.twitterUrl = twitterUrl;
        attributes.user.facebookUrl = facebookUrl;
        attributes.user.linkedinUrl = linkedinUrl;
        attributes.user.instagramUrl = instagramUrl;

        dispatch(updateAuthor(attributes)).then(() => {
          setSaveDataIcon("");
          setSaveDataButtonDisabled(false);
        });
      } catch (err) {
        setSaveDataIcon("");
        setSaveDataButtonDisabled(false);
        console.log(err.stack);
      }
    } else {
      setSaveDataIcon("");
      setSaveDataButtonDisabled(false);
    }
  };

  return user ? (
    <div>
      <section className="profile-author">
        <div className="profile-author__container-image">
          <div className="wrapper-cover">
            <img
              className="profile-author__container-image-bgimg"
              src={
                coverPic
                  ? typeof coverPic === "object"
                    ? URL.createObjectURL(coverPic[0])
                    : coverPic
                  : "/images/rectangle.jpg"
              }
              alt="Author cover picture"
            />
          </div>
          {profilePic && profilePic !== "" ? (
          <img
            className="profile-author__container-image-img"
            src={
              profilePic
                ? typeof profilePic === "object"
                  ? URL.createObjectURL(profilePic[0])
                  : profilePic
                : "/images/image3.jpg"
            }
            alt="Author profile picture"
            onClick={() => {
              inputProfileFile.current.click();
            }}
          />
          ) : (
            <Avatar user={user} size="l" className="profile-author__container-image-img" onClick={() => {
              inputProfileFile.current.click();
            }} />
          )}

          <div
            className="profile-author__edit-cover"
            onClick={() => {
              inputCoverFile.current.click();
            }}
          >
            <Icon color="secondary-6" name="edit" size="small" />
          </div>

          <Form.Control
            type="file"
            accept="image/png, image/jpeg"
            onChange={onChangeProfilePicture}
            style={{ display: "none" }}
            ref={inputProfileFile}
          />

          <Form.Control
            type="file"
            accept="image/png, image/jpeg"
            onChange={onChangeCoverPicture}
            style={{ display: "none" }}
            ref={inputCoverFile}
          />
        </div>
        <p className="profile-author__title">Seudónimo</p>
        <div className="profile-author__container-input">
          <InputText
            error={isNickNameEntered}
            onFocus={handleInputNickNameFocus}
            onBlur={handleInputNickNameBlur}
            type="text"
            id="pseudonym"
            name="pseudonym"
            value={pseudonym}
            placeholder="Introduce tu seudónimo"
            onChange={(event) => {
              setPseudonym(event.target.value);
            }}
          />
          <p className="profile-author__help">
            El seudónimo que ingreses reemplazará tu nombre en tu perfil público
          </p>
        </div>
        <p className="profile-author__title">Acerca del autor</p>
        <textarea
          className="profile-author__textarea"
          type="text"
          id="authorDescription"
          name="authorDescription"
          value={authorDescription}
          placeholder="Escribe aquí lo que quieras para conocerte mejor."
          onChange={(event) => {
            setAuthorDescription(event.target.value);
          }}
        />
        <p className="profile-author__title">Redes Sociales</p>
        <div className="profile-author__container-rrss">
          <div className="profile-author__input-container">
            <div className="profile-author__input-container-input-tw">
              <InputText
                iconPosition="right"
                placeholderPadding="16px 60px"
                icon="twitter"
                iconSize="large"
                iconColor="secondary-6"
                iconBorder="background-4"
                error={isTwEntered}
                onFocus={handleInputTwFocus}
                onBlur={handleInputTwBlur}
                type="text"
                id="twitterUrl"
                name="twitterUrl"
                value={twitterUrl}
                placeholder="https://twitter.com/profile"
                onChange={(event) => {
                  setTwitterUrl(event.target.value);
                }}
              />

              {errorForm && errorForm.twitterUrl &&  errorForm.twitterUrl !== "" ? (
                <p className="error mt-2 ms-2">{errorForm.twitterUrl}</p>
              ) : null}
            </div>
          </div>
          <div className="profile-author__input-container">
            <div className="profile-author__input-container-input-fb">
              <InputText
                iconPosition="right"
                placeholderPadding="15px 60px"
                icon="facebook"
                iconSize="large"
                iconColor="secondary-6"
                iconBorder="background-4"
                error={isFbEntered}
                onFocus={handleInputFbFocus}
                onBlur={handleInputFbBlur}
                type="text"
                id="facebookUrl"
                name="facebookUrl"
                value={facebookUrl}
                placeholder="https://facebook.com/profile"
                onChange={(event) => {
                  setFacebookUrl(event.target.value);
                }}
              />

              {errorForm && errorForm.facebookUrl && errorForm.facebookUrl !== "" ? (
                <p className="error mt-2 ms-2">{errorForm.facebookUrl}</p>
              ) : null}
            </div>
          </div>
          <div className="profile-author__input-container">
            <div className="profile-author__input-container-input-li">
              <InputText
                iconPosition="right"
                placeholderPadding="15px 60px"
                icon="linkedin"
                iconSize="large"
                iconColor="secondary-6"
                iconBorder="background-4"
                error={isLiEntered}
                onFocus={handleInputLiFocus}
                onBlur={handleInputLiBlur}
                type="text"
                id="linkedinUrl"
                name="linkedinUrl"
                value={linkedinUrl}
                placeholder="https://linkedin.com/profile"
                onChange={(event) => {
                  setLinkedinUrl(event.target.value);
                }}
              />

              {errorForm && errorForm.linkedinUrl && errorForm.linkedinUrl !== "" ? (
                <p className="error mt-2 ms-2">{errorForm.linkedinUrl}</p>
              ) : null}
            </div>
          </div>
          <div className="profile-author__input-container">
            <div className="profile-author__input-container-input-ig">
              <InputText
                iconPosition="right"
                placeholderPadding="15px 60px"
                icon="instagram"
                iconSize="large"
                iconColor="secondary-6"
                iconBorder="background-4"
                error={isInEntered}
                onFocus={handleInputInFocus}
                onBlur={handleInputInBlur}
                type="text"
                id="instagramUrl"
                name="instagramUrl"
                value={instagramUrl}
                placeholder="https://instagram.com/profile"
                onChange={(event) => {
                  setInstagramUrl(event.target.value);
                }}
              />

              {errorForm && errorForm.instagramUrl && errorForm.instagramUrl !== "" ? (
                <p className="error mt-2 ms-2">{errorForm.instagramUrl}</p>
              ) : null}
            </div>
          </div>
        </div>
      </section>

      {errorMessage && errorMessage !== "" ? (
        <p>{errorMessage.toString()}</p>
      ) : null}

      <section className="buttons">
        <div className="buttons__containers">
          <Button
            className="btn-orangebg big"
            iconRight={saveDataIcon}
            text={"Guardar cambios"}
            disabled={saveDataButtonDisabled}
            onClick={() => {
              saveData();
            }}
          />
        </div>
      </section>
    </div>
  ) : null;
}

export default ProfileAuthor;
