import "./EventForm.scss";
import FormContainer from "../../components/FormContainer/FormContainer";
import InputText from "../../components/DesingSystem/InputText/InputText";
import { useState } from "react";
import CheckBox from "../../components/DesingSystem/CheckBox/CheckBox";
import { postApiEventProposal } from "../../services/apiEvent";
import ModalFormSuccess from "../../components/FormContainer/ModalFormSuccess";

const EventForm = () => {
  // First form part
  const [isNameEntered, setIsNameEntered] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [isLastNameEntered, setIsLastNameEntered] = useState(false);
  const [lastName, setLastName] = useState("");
  const [isEmailEntered, setIsEmailEntered] = useState(false);
  const [email, setEmail] = useState("");
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [phone, setPhone] = useState("");
  const [formErrors, setFormErrors] = useState();
  const [error, setError] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  // Second form part
  const [isEventNameEntered, setIsEventNameEntered] = useState(false);
  const [eventName, setEventName] = useState("");
  const [isEventPriceEntered, setIsEventPriceEntered] = useState(false);
  const [eventPrice, setEventPrice] = useState("");


  const handleInputNameFocus = () => { setIsNameEntered(true) }
  const handleInputNameBlur = () => { setIsNameEntered(false) }
  const handleInputLastNameFocus = () => { setIsLastNameEntered(true) }
  const handleInputLastNameBlur = () => { setIsLastNameEntered(false) }
  const handleInputEmailFocus = () => { setIsEmailEntered(true) }
  const handleInputEmailBlur = () => { setIsEmailEntered(false) }
  const handleInputPhoneFocus = () => { setIsPhoneEntered(true) }
  const handleInputPhoneBlur = () => { setIsPhoneEntered(false) }
  const handleInputEventNameFocus = () => { setIsEventNameEntered(true) }
  const handleInputEventNameBlur = () => { setIsEventNameEntered(false) }
  const handleInputEventPriceFocus = () => { setIsEventPriceEntered(true) }
  const handleInputEventPriceBlur = () => { setIsEventPriceEntered(false) }


  const modalTexts = {
    title: "¡Tu propuesta de evento se ha enviado correctamente!",
    content: "Nos pondremos en contacto contigo con la mayor brevedad posible.",
    buttonText: "Volver a eventos",
    backUrl: "/eventos"
  }

  const submitForm = async (e) => {
    e.preventDefault();
    // Form data
    const formData = new FormData(e.target);
    const data = Object.fromEntries(formData.entries());
    let errorVal = false

    setFormErrors((formErrors) => Object.assign({}));

    if (!data.name || data.name == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { name: "El nombre es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.lastName || data.lastName == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { lastName: "El apellido es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.email || data.email == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { email: "El email es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.phone || data.phone == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { phone: "El teléfono es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.EventName || data.EventName == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { eventName: "El nombre del evento es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if (!data.eventPrice || data.eventPrice == '') {
      setFormErrors((formErrors) => Object.assign({}, formErrors, { eventPrice: "El precio del evento es obligatorio" }));
      setError(true);
      errorVal = true;
    }

    if(!errorVal){
      setShowSuccessModal(true)
      const response = await postApiEventProposal(data);
    }
  }

  return (
    <div>
      <FormContainer
        title="Formulario de evento"
        modalTexts={modalTexts}
        onSubmit={submitForm}
        error={error}
      >
        <div className="event-form">
          <div className="event-form__section event-form__bordered">
            <h2 className="event-form__section-title">Datos personales</h2>
            <div className="event-form__section-body">
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.name ? true : false}
                  onFocus={handleInputNameFocus}
                  onBlur={handleInputNameBlur}
                  type="text"
                  id="name"
                  name="name"
                  label="Nombre*"
                  size="small"
                  placeholder="Ingresa tu nombre"
                  onChange={(event) => {
                    setFirstName(event.target.value);
                  }}
                  
                />

                { formErrors && formErrors.name && 
                  <p className="error">{ formErrors.name }</p>
                }
              </div>
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.lastName ? true : false}
                  onFocus={handleInputLastNameFocus}
                  onBlur={handleInputLastNameBlur}
                  type="text"
                  id="lastName"
                  name="lastName"
                  label="Apellidos*"
                  size="small"
                  placeholder="Ingresa tus apellidos"
                  onChange={(event) => {
                    setLastName(event.target.value);
                  }}
                  
                />

                { formErrors && formErrors.lastName && 
                  <p className="error">{ formErrors.lastName }</p>
                }
              </div>
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.email ? true : false}
                  onFocus={handleInputEmailFocus}
                  onBlur={handleInputEmailBlur}
                  type="email"
                  id="email"
                  name="email"
                  label="Correo electrónico*"
                  size="small"
                  placeholder="Ingresa tu correo electrónico"
                  onChange={(event) => {
                    setEmail(event.target.value);
                  }}
                  
                />

                { formErrors && formErrors.email && 
                  <p className="error">{ formErrors.email }</p>
                }
              </div>
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.phone ? true : false}
                  onFocus={handleInputPhoneFocus}
                  onBlur={handleInputPhoneBlur}
                  type="tel"
                  id="phone"
                  name="phone"
                  label="Teléfono*"
                  size="small"
                  placeholder="Ingresa tu teléfono"
                  onChange={(event) => {
                    setPhone(event.target.value);
                  }}
                  
                />

                { formErrors && formErrors.phone && 
                  <p className="error">{ formErrors.phone }</p>
                }
              </div>
            </div>
          </div>
          <div className="event-form__section mb-0 pb-0">
            <h2 className="event-form__section-title">Datos del evento</h2>
            <div className="event-form__section-body">
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.eventName ? true : false}
                  onFocus={handleInputEventNameFocus}
                  onBlur={handleInputEventNameBlur}
                  type="text"
                  id="eventName"
                  name="EventName"
                  label="Título del evento*"
                  size="small"
                  placeholder="Ingresa el título del evento"
                  onChange={(event) => {
                    setEventName(event.target.value);
                  }
                  }
                  
                />

                { formErrors && formErrors.eventName && 
                  <p className="error">{ formErrors.eventName }</p>
                }
              </div>
              <div className="event-form__section-body-item">
                <InputText
                  error={formErrors && formErrors.eventPrice ? true : false}
                  onFocus={handleInputEventPriceFocus}
                  onBlur={handleInputEventPriceBlur}
                  type="number"
                  id="eventPrice"
                  name="eventPrice"
                  label="Precio unitario (iva incluido)*"
                  size="small"
                  placeholder="Introduce el precio de tu evento"
                  onChange={(event) => {
                    setEventPrice(event.target.value);
                  }
                  }
                  iconPosition="right"
                  icon="euro"
                  iconColor="secondary-4"
                  iconSize="small"
                  groupRight
                  
                />

                { formErrors && formErrors.eventPrice && 
                  <p className="error">{ formErrors.eventPrice }</p>
                }
              </div>
              <div className="event-form__section-body-item event-form__section-body-item--full">
                <p className="event-form__input-label">¿Algo más que quieras comentar?</p>
                <textarea
                  className="event-form__textarea"
                  name="comment"
                  id="comment"
                  placeholder="Escribe cualquier cosa que quieras que te faltó comentar"
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </FormContainer>

      <ModalFormSuccess
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        {...modalTexts}
      />
    </div>
  )
}

export default EventForm;
