import "./ProfileAuthorPublic.scss";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
//import ls from "../../services/localStorage";
//import validateToken from "../../services/validateToken";
import SectionImages from "../../../components/ProfileAuthorComponents/SectionImages/SectionImages";
import InfoAuthor from "../../../components/ProfileAuthorComponents/InfoAuthor/InfoAuthor";
import GenresAuthor from "../../../components/ProfileAuthorComponents/GenresAuthor/GenresAuthor";
import BooksAuthor from "../../../components/ProfileAuthorComponents/BooksAuthor/BooksAuthor";
import AuthorListGenre from "../../../components/GenrePageComponents/AuthorListGenre/AuthorListGenre";

import { getUserByNickName } from '../../../redux/actions/user'
import BannerPublic from "../../../components/aPublicArea/BannerPublic/BannerPublic";

function ProfileAuthorPublic(props) {
  const urlParams = useParams()
  const dispatch = useDispatch();
  const [genres, setGenres] = useState([]);
  const user = useSelector((store) => store.user.author);
  const authors = useSelector((store) => store.book.authors);
  const errorMessage = useSelector((store) => store.user.errorMessage);

  useEffect(() => {
    console.log(urlParams.slug)
    dispatch(getUserByNickName({ nickName : urlParams.slug })).then(async response => {
      // Get the list of book categories
      let writtenGenres = []
      await Promise.all(response.payload.books.map(async book => {
        await Promise.all(book.categories.map(category =>{
          if(JSON.stringify(writtenGenres).indexOf(category._id) === -1){
            writtenGenres.push(category);
          }
        }))
      }))

      setGenres(writtenGenres)
    });
  }, [urlParams]);

  return user ? (
    <div className="profile-author-container">
      <SectionImages author={user}/>
      <InfoAuthor author={user}/>
      <GenresAuthor genres={genres}/>
      <BooksAuthor author={user}/>
      <AuthorListGenre authors={user.relatedAuthors}/>
      <BannerPublic/>
    </div>
  ) : null;
}

export default ProfileAuthorPublic;
