import "./LogOff.scss";
import Modal from "react-bootstrap/Modal";
import Button from "../../DesingSystem/Button/Button"

function LogOff(props) {
  return (
    <section>
      <Modal
        {...props}
        dialogClassName="log-off__modal"
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="log-off">
            <h3 className="log-off__title">
              ¿Seguro que quieres cerrar sesión?
            </h3>
            <div className="log-off__container-button">
              <button
                className="log-off__button-logoff"
                text="Cerrar sesión"
                onClick={props.onAccept}
              >
                Cerrar sesión
              </button>
              <Button
                className="btn-orange mt-4"
                text="Cancelar"
                onClick={props.onHide} />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </section>
  );
}

export default LogOff;
