import "./HeaderPublicMobile.scss";

import ls from "../../../services/localStorage";
import {useEffect, useRef, useState} from "react";
import { useSelector, useDispatch } from "react-redux";
import logoHeader from "./../../../styles/images/logo.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Icon from "../../Icon/Icon";
import validateToken from "../../../services/validateToken";
import { getUserById } from "../../../redux/actions/user";
import { Transition } from 'react-transition-group';
import Button from "../../DesingSystem/Button/Button";

function HeaderPublicMobile() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector((store) => store.user.user);
  const [menuContentOpen, setMenuContentOpen] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const isActive = location.pathname === "/descubre";
  const nodeRef = useRef(null);

  const showMenuContent = () => {
    setMenuContentOpen(true);
  };

  const hideMenuContent = () => {
    setMenuContentOpen(false);
  };

  useEffect(() => {
    hideMenuContent()
    closeServices()
  }, [location]);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    if (tokenDecoded) {
      dispatch(getUserById(tokenDecoded.user._id));
    }
  }, []);
  const duration = 300;

  const defaultStyle = {
    transition: `all ${duration}ms ease-in-out`,
    opacity: 0,
  }

  const transitionStyles = {
    entering: { display: 'flex', opacity: 0 },
    entered: { display: 'flex', opacity: 1, transform: 'none'},
    exiting: { display: 'flex', opacity: 0, transform: 'translateY(2rem)' },
    exited: { display: 'none', opacity: 0, transform: 'translateY(2rem)' }
  };

  const search = (e) => {
    const keyword = e.target.value;
    if (e.key === "Enter" && e.currentTarget.value !== '') {
      navigate('/busqueda/?keyword=' + keyword);
      window.location.href = '/busqueda/?keyword=' + keyword;
    }
  }

  const openServices = () => {
    setIsServicesOpen(true)
  }

  const closeServices = () => {
    setIsServicesOpen(false)
  }

  return (
    <section className="headerPublicMobile">
      <div className={`headerPublicMobile__left`}>
        <Link to={"/"}>
          <img
            className="headerPublicMobile__left-img"
            src={logoHeader}
            alt="Logo Bookolicos"
          ></img>
        </Link>
      </div>
      <div className={'headerPublicMobile__right'}>
        <Icon name="menu" size='medium' color={'secondary-1'} onClick={showMenuContent}></Icon>
      </div>


      <Transition
        in={menuContentOpen}
        nodeRef={nodeRef}
        timeout={100}
      >
        {state => (
        <div
          // className={`headerPublicMobile__menu-container ${menuContentOpen && `headerPublicMobile__menu-container--active`}`}
          className={`headerPublicMobile__menu-container`}
          style={{
            ...defaultStyle,
            ...transitionStyles[state]
          }}
          ref={nodeRef}
        >
          <div className="headerPublicMobile__menu-header">
            <Icon name={'close'} size={'medium'} onClick={hideMenuContent} />
            { isServicesOpen &&
              <Icon name={'arrowLeft'} size={'medium'} color={'secondary-1'} onClick={()=>closeServices()} /> }
          </div>
          <div className="headerPublicMobile__menu-content">
          { isServicesOpen ? 
              <>
                <h3 className="headerMobile__menu-content-title">Servicios</h3>
                <ul className="headerMobile__menu-list mb-5">
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/servicio-lector-cero"}>Lector cero</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/servicio-correccion-estilo"}>Corrección de estilo</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/servicio-coaching"}>Coaching</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/servicio-correccion-ortotipografica"}>Corrección ortotipográfica</Link>
                  </li>
                  <li className="headerMobile__menu-list-item-submenu">
                    <Link to={"/servicio-maquetacion-digital"}>Maquetación digital</Link>
                  </li>
                </ul>
              </>
            :
              <>
                <h3 className="headerPublicMobile__menu-content-title">Menu</h3>
                <ul className="headerPublicMobile__menu-list">
                  <li className="headerPublicMobile__menu-list-item">
                    <Link to={"/"}>Home</Link>
                  </li>
                  <li className="headerPublicMobile__menu-list-item">
                    <Link to={"/descubre"}>Catálogo</Link>
                  </li>
                  <li className="headerPublicMobile__menu-list-item">
                    <Link to={"/por-que-bookolicos"}>¿Por qué Bookólicos?</Link>
                  </li>
                  <li className="headerPublicMobile__menu-list-item">
                    <Link to={"/quienes-somos"}>Quiénes somos</Link>
                  </li>
                  <li className="headerPublicMobile__menu-list-item">
                    <Link to={"/agenda"}>Eventos</Link>
                  </li>
                  <li className="headerMobile__menu-list-item">
                    <div className="d-flex align-items-center justify-content-between pointer" onClick={() => openServices()}>
                      <span>Servicios</span>
                      <Icon name={'chevronRight'} size={'medium2'} color={'secondary-1'} />
                    </div>
                  </li>
                </ul>
                <div className="headerPublicMobile__menu-search">
                  <Icon name="search" size="medium" color="secondary-3" />
                  <input
                    className="headerPublicMobile__menu-search-input"
                    placeholder="Buscar obra, género, autor"
                    name="keyword"
                    autoComplete="off"
                    onKeyDown={search}
                  />
                </div>
              </>
          }
          </div>

          <div className="headerPublicMobile__menu-content">
            <h3 className="headerPublicMobile__menu-content-title">Contáctanos</h3>
            <ul className='headerPublicMobile__menu-list'>
              <li className="headerPublicMobile__menu-list-item--small">
                <a href="mailto:hola@bookolicos.com">hola@bookolicos.com</a>
              </li>
              { /*<li className="headerPublicMobile__menu-list-item--small">
                <a href="tel:+344423423423423">+34 31283771283</a>
              </li> */ }
            </ul>
          </div>
          <div className="headerPublicMobile__menu-content">
            <Button text="Acceder" onClick={() => {
              if(currentLocation){
                navigate("/login?return=" + currentLocation);
              } else {
                navigate("/login");
              }
             }} />
          </div>
        </div>
        )}
      </Transition>
    </section>
  );
}

export default HeaderPublicMobile;
