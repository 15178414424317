import "./Authors.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getApiCategories } from "../../services/apiCategories";
import { getUsersAuthor } from "../../redux/actions/user";
import Genres from "../../components/Genres/Genres";

import AuthorsPublic from "../../components/aPublicArea/AuthorsPublic/ListAuthors/ListAuthorsPublic";
import NameSection from "../../components/NameSection/NameSection";
import Button from "../../components/DesingSystem/Button/Button";
import BannerPublic from "../../components/aPublicArea/BannerPublic/BannerPublic";

function Authors() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authors = useSelector((store) => store.user.users);
  const [genres, setGenres] = useState([]);

  useEffect((attributes) => {
    getApiCategories(attributes).then((response) => {
      setGenres(response);
    });

    dispatch(getUsersAuthor(attributes));
  }, []);

  const moreAuthors = () => {
    console.log("MAS AUTORES")
  };

  return (
    <section className="authors">
      <NameSection name="Autores" color="#f05a24" />
      {/*genres ? (
        <div className="authors__genres">
          <p className="authors__genres-filter">Filtra por género</p>
          <Genres
            genres={genres}
            align="left"
            size="medium"
            onClick={tagClick}
          />
      </div>
      ) : null*/}


      {authors ? <AuthorsPublic authors={authors} /> : null}
      {/*<div className="authors__button">
        <Button text="Ver más" onClick={moreAuthors} />
      </div>*/}
    </section>
  );
}

export default Authors;
