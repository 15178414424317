import "./ErrorHandler.scss";
import { useNavigate } from "react-router-dom";
import Icon from "../../components/Icon/Icon";
import Button from "../../components/DesingSystem/Button/Button";

function ErrorHandler() {
  const navigate = useNavigate();
  const clickHandler = (event) => {
    event.stopPropagation();
    // navigate('/'); no funciona 😔
    window.location.href = '/';
  }

  return (
    <section className="error-handler">
      <div className="error-handler__section-text">
        <Icon name="face" size="large" color="principal" />
        <h1 className="error-handler__title">Oh no...</h1>
        <p className="error-handler__text">
          ¡Lo sentimos! Parece que no encontramos la página que solicitaste.
        </p>
        <div className="error-handler__action">
          <Button iconRight="arrowLong" className={"btn-orangebg"} text={"Volver a la home"} onClick={clickHandler} />
        </div>
      </div>
    </section>
  );
}

export default ErrorHandler;
