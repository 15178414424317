import "./PublishBook.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ls from "../../services/localStorage";
import validateToken from "../../services/validateToken";
import FormPublishBook from "../../components/PublishBook/FormPublishBook/FormPublishBook";
import ModalMessage from "../../components/DesingSystem/ModalMessage/ModalMessage";
import { getUserById } from "../../redux/actions/user";

function PublishBook() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showModalMessage, setShowModalMessage] = useState(false);
  const user = useSelector((store) => store.user.user);

  useEffect(() => {
    const token = ls.get("token");
    const tokenDecoded = validateToken.decode(token);
    dispatch(getUserById(tokenDecoded.user._id));
  }, []);

  useEffect(() => {
    if (user && ( !user.stripe_account || user.stripe_account === '')) {
      setShowModalMessage(true)
    }
  }, [user]);

  const okButtonClick = () => {
    // Redirect to bank account details
    navigate('/profile/datos-generales')
  }

  return (
    <section className="publish-book">
      <FormPublishBook />

      <ModalMessage
        show={showModalMessage}
        header={'¡Ups! Todavía no has completado los datos necesarios para publicar'}
        text={'Ve a tu perfil y completa todos aquellos datos necesarios para que puedas seguir con el proceso de publicación.'}
        okButton={true}
        okButtonText={'Ir a mi perfil'}
        okButtonType={'btn-white'}
        onAccept={() => { okButtonClick() }}
        />
    </section>
  );
}

export default PublishBook;
