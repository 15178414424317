import "./CheckoutForm.scss";

import React, { useState } from 'react';
import { useSelector } from "react-redux";
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Button from '../DesingSystem/Button/Button';
import { postApiCreateIsbnSale } from "../../services/apiSale";

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [confirmButtonIcon, setConfirmButtonIcon] = useState("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const user = useSelector((store) => store.user.user);

  const options = {}

  const processPayment = async (event) => {
    event.preventDefault();

    setConfirmButtonDisabled(true)
    setConfirmButtonIcon('loaderIcon')

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return null;
    }

    try{
      // Save sale on ddbb with status pending
      const responseSale = await postApiCreateIsbnSale({
        saleisbn :{
          ...(props.book && props.book.title && { title: props.book.title }),
          amount: 1000,
          customer: user._id,
          payment_intent: elements._commonOptions.clientSecret.id,
          status: 1
        }
      })

      const {error, paymentIntent} = await stripe.confirmPayment({
        //`Elements` instance that was used to create the Payment Element
        elements,
        redirect: "if_required"
      });

      if (paymentIntent.status === "succeeded") {
        // console.log('paymentIntent', paymentIntent)
        props.onSucceededPayment({ pi: paymentIntent });
      } else {
        setErrorMessage(error.message);
        setConfirmButtonDisabled(false)
        setConfirmButtonIcon('')
      }
    }catch(err){
      setErrorMessage(err.message);
      setConfirmButtonDisabled(false)
      setConfirmButtonIcon('')
    }
  }

  return (
    <form>
      <PaymentElement options={options} />
      { stripe ?
        <React.Fragment>
          { errorMessage !== '' ?
            <p className="m-0 mt-3 mb-3 error text-center">{errorMessage}</p>
          : null }

          <Button
            text={`${props.btnText ? props.btnText : 'Pagar'}`}
            iconRight={confirmButtonIcon}
            disabled={confirmButtonDisabled}
            className={`${props.btnClass ? props.btnClass : 'btn-white'} m-auto mt-4`}
            onClick={ processPayment }
          />

        </React.Fragment>
      : null }
    </form>
  );
};

export default CheckoutForm;
