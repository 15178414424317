import "./ModalFormSuccess.scss";

import PropTypes from "prop-types";

import Modal from "react-bootstrap/Modal";
import Button from "../DesingSystem/Button/Button";
import { useNavigate } from "react-router-dom";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.isOpen
 * @param {string} props.content
 * @param {string} props.buttonText
 * @param {string} props.backUrl
 * @param {string} props.buttonOnClick
 */
const ModalFormSuccess = (props) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    props.buttonOnClick ? props.buttonOnClick() : navigate(props.backUrl);
  };

  return (
    <div onClick={(e) => e && e.stopPropagation()}>
      <Modal
        show={props.isOpen}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="modal-form-success__container"
      >
        <Modal.Body>
          <div className="modal-form-success">
            <div className="modal-form-success__title">{props.title}</div>
            <div className="modal-form-success__content">
              <p>{props.content}</p>
            </div>
            <Button text={props.buttonText} className="btn btn-border-orange" onClick={handleButtonClick} />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};
ModalFormSuccess.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  content: PropTypes.string,
  buttonText: PropTypes.string,
  backUrl: PropTypes.string,
  buttonOnClick: PropTypes.func
};

export default ModalFormSuccess;
