import "./Profile.scss";
import { Routes, Route, useLocation } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Menu from "../../components/ProfileComponents/Menu/Menu";
import GeneralData from "../../components/ProfileComponents/GeneralData/GeneralData";
import ProfileAuthor from "../../components/ProfileComponents/ProfileAuthor/ProfileAuthor";
import Account from "../../components/ProfileComponents/Account/Account";
import Publications from "../../components/ProfileComponents/Publications/Publications";
import Sales from "../../components/ProfileComponents/Sales/Sales";
import Shopping from "../../components/ProfileComponents/Shopping/Shopping";
import ShoppingCourses from "../../components/ProfileComponents/Shopping/ShoppingCourses";

function Profile() {
  const location = useLocation();
  const [firstRender, setFirstRender] = useState(true);
  useEffect(() => {
    if (firstRender) return setFirstRender(false);
    const item = document.querySelector(".col-sm-8");
    item.scrollIntoView({ behavior: "smooth" })
  }, [location])

  return (
    <div className="profile-container">
      <Container>
        <Row>
          <Col sm={4}>
            <Menu />
          </Col>
          <Col sm={8}>
            <Routes>
              {/*<Route path="/" element={<Navigate to="datos-generales" replace />} />*/}
              <Route path="datos-generales" element={<GeneralData />} />
              <Route path="perfil-autor" element={<ProfileAuthor />} />
              <Route path="cuenta" element={<Account />} />
              <Route path="publicaciones" element={<Publications />} />
              <Route path="ventas" element={<Sales />} />
              <Route path="tus-obras" element={<Shopping />} />
              <Route path="tus-cursos" element={<ShoppingCourses />} />
            </Routes>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Profile;
